export interface Signature {
  id: number;
  workspaceId: number;
  name: string;
  content: string;
}

export type SignaturePreview = Pick<Signature, 'id' | 'name' | 'workspaceId'>;

export interface SignatureDefaults {
  workspaceId: number;
  newEmailSignatureId: number | undefined;
  replyEmailSignatureId: number | undefined;
}

export enum SignatureType {
  NewEmail = 'newEmail',
  ReplyEmail = 'replyEmail',
}

export interface SignaturesStateModel {
  signatures: SignaturePreview[];
  defaults: SignatureDefaults[];
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error';
}

export const defaultSignaturesState: SignaturesStateModel = {
  signatures: [],
  defaults: [],
  loadingStatus: 'void',
};

import type { SortingOptions } from '@clover/core/helpers/paging';
import type { FilterableWorkflowStatus, WorkflowPreview } from './workflows-state.model';
import type { WorkflowsSortingProperty } from './workflows.service';
import type { StateOperator } from '@ngxs/store';

export class LoadWorkflows {
  static readonly type = '[Workflows] Load Workflows';

  constructor(
    public payload: {
      query?: string;
      status?: FilterableWorkflowStatus;
      sortingOptions?: SortingOptions<WorkflowsSortingProperty>;
    },
  ) {}
}

export class LoadNextWorkflows {
  static readonly type = '[Workflows] Load Next Workflows';
}

export class PatchWorkflow {
  static readonly type = '[Workflows] Patch workflow';

  constructor(
    public workflowId: number,
    public patch: StateOperator<WorkflowPreview>,
  ) {}
}

export class RemoveWorkflow {
  static readonly type = '[Workflows] Remove Workflow';

  constructor(public workflowId: number) {}
}

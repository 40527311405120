import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WorkflowStatus } from '@clover/workflows-v2/state/workflows-state.model';

const backgroundColors: Partial<Record<WorkflowStatus, string>> = {
  [WorkflowStatus.Published]: 'var(--comp-status-chip-color-completed-bg, #D6F9EA)',
  [WorkflowStatus.Draft]: 'var(--comp-status-chip-color-no-connection-bg, #FBE4E9)',
};

const foregroundColors: Partial<Record<WorkflowStatus, string>> = {
  [WorkflowStatus.Published]: 'var(--comp-status-chip-color-completed-fg, #08A262)',
  [WorkflowStatus.Draft]: 'var(--comp-status-chip-color-no-connection-fg, #AB2345)',
};

const defaultBackgroundColor = 'var(--comp-status-chip-color-closed-bg, #DCDFE3)';
const defaultForegroundColor = 'var(--comp-status-chip-color-closed-fg, #5E6A75)';

@Component({
  selector: 'cc-workflow-status-chip',
  standalone: true,
  templateUrl: './workflow-status-chip.component.html',
  styleUrls: ['./workflow-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowStatusChipComponent {
  @Input()
  status: WorkflowStatus;

  protected readonly WorkflowStatus = WorkflowStatus;

  get backgroundColor(): string {
    return backgroundColors[this.status] ?? defaultBackgroundColor;
  }

  get foregroundColor(): string {
    return foregroundColors[this.status] ?? defaultForegroundColor;
  }
}

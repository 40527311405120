import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cc-workspace-settings-settings',
  standalone: true,
  imports: [],
  templateUrl: './workspace-settings-settings.component.html',
  styleUrls: ['./workspace-settings-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsSettingsComponent {}

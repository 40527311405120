import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  type OnInit,
  ViewChild,
} from '@angular/core';

import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DOCUMENT } from '@core/helpers/global-objects';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { UpdateDocument } from '@network/company/state/wiki/wiki.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { MarkdownModule } from 'ngx-markdown';
import { MARKDOWN_FORMAT_OPTIONS, MarkdownEditorMode, MarkdownFormat } from './markdown-editor';

@UntilDestroy()
@Component({
  selector: 'cc-markdown-editor',
  standalone: true,
  imports: [MarkdownModule, ReactiveFormsModule, ButtonComponent, TooltipDirective],
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownEditorComponent implements OnInit {
  @Input()
  markdownContent = '';

  @Input()
  mode: MarkdownEditorMode = MarkdownEditorMode.Edit;

  @ViewChild('textarea', { static: true })
  textarea: ElementRef;

  markdownEditorFormControl = new FormControl('');
  formatMarks = new Map<MarkdownFormat, boolean>();

  protected readonly MarkdownFormat = MarkdownFormat;
  protected readonly MarkdownEditorMode = MarkdownEditorMode;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly document = inject<Document>(DOCUMENT);
  private readonly store = inject(Store);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly selectionChangeHandler = this.updateFormatMarks.bind(this);

  get formattingButtonsDisabled(): boolean {
    return this.mode === MarkdownEditorMode.Preview;
  }

  ngOnInit(): void {
    this.setEditorMode(this.mode);
    this.markdownEditorFormControl.setValue(this.markdownContent, { emitEvent: false });

    this.markdownEditorFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((content) => {
      this.store.dispatch(new UpdateDocument({ content }));
    });
  }

  interceptMarkdownLinkClick(event: MouseEvent) {
    // TODO (Oleksandr D.): Implement proper handling of markdown links

    const target = event.target as HTMLElement;

    if (target.tagName === 'A') {
      const href = target.getAttribute('href');

      if (href && href.includes('://')) return;
      if (href && !href.endsWith('.md')) return;

      event.preventDefault();
    }
  }

  handleFormatButtonClick(formatType: MarkdownFormat): void {
    const selectionStart = this.textarea.nativeElement.selectionStart;
    const selectionEnd = this.textarea.nativeElement.selectionEnd;
    const selectedText = this.markdownEditorFormControl.value.substring(selectionStart, selectionEnd) || '';

    this.textarea.nativeElement.focus();

    const { toMarkdown, toPlaintext } = MARKDOWN_FORMAT_OPTIONS.get(formatType);

    const formatApplied = this.formatMarks.get(formatType);
    const formattedText = formatApplied ? toPlaintext(selectedText) : toMarkdown(selectedText);

    const getNewSelection = () => {
      const lengthDifference = selectedText.length - formattedText.length;
      return [selectionStart, selectionEnd - lengthDifference];
    };

    const newMarkdownContent = this.replaceAtRange(
      selectionStart,
      selectionEnd,
      this.markdownEditorFormControl.value,
      formattedText,
    );

    const [newSelectionStart, newSelectionEnd] = getNewSelection();
    this.markdownEditorFormControl.setValue(newMarkdownContent);
    this.textarea.nativeElement.setSelectionRange(newSelectionStart, newSelectionEnd);
  }

  setEditorMode(mode: MarkdownEditorMode): void {
    this.mode = mode;

    switch (mode) {
      case MarkdownEditorMode.Edit:
        this.document.addEventListener('selectionchange', this.selectionChangeHandler);
        this.updateFormatMarks();
        break;
      case MarkdownEditorMode.Preview:
        this.document.removeEventListener('selectionchange', this.selectionChangeHandler);
        break;
    }
  }

  private replaceAtRange(start: number, end: number, originalText: string, replacement: string): string {
    return originalText.substring(0, start) + replacement + originalText.substring(end);
  }

  private isFormatApplied(format: MarkdownFormat, text: string): boolean {
    const { regex } = MARKDOWN_FORMAT_OPTIONS.get(format);
    return regex.test(text);
  }

  private getSelection(): {
    selectionStart: number;
    selectionEnd: number;
    selectedText: string;
  } {
    const selectionStart = this.textarea.nativeElement.selectionStart;
    const selectionEnd = this.textarea.nativeElement.selectionEnd;
    const selectedText = this.markdownEditorFormControl.value.substring(selectionStart, selectionEnd) || '';
    return { selectionStart, selectionEnd, selectedText };
  }

  private updateFormatMarks(): void {
    if (!this.textarea) return;

    const { selectedText } = this.getSelection();

    this.formatMarks = new Map(
      Array.from(MARKDOWN_FORMAT_OPTIONS.keys()).map((format) => {
        return [format, this.isFormatApplied(format, selectedText)];
      }),
    );

    this.cdr.detectChanges();
  }
}

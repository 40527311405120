export enum MicrosoftButtonType {
  Standard = 'standard',
  Icon = 'icon',
}

export enum MicrosoftButtonText {
  SignInWithMicrosoft = 'Sign in with Microsoft',
  ContinueWithMicrosoft = 'Continue with Microsoft',
  SignUpWithMicrosoft = 'Sign up with Microsoft',
  SignIn = 'Sign in',
}

export const MICROSOFT_BUTTON_TEXT_MAP = new Map<MicrosoftButtonText, string>([
  [MicrosoftButtonText.SignInWithMicrosoft, 'microsoftButton.signInWithMicrosoft'],
  [MicrosoftButtonText.ContinueWithMicrosoft, 'microsoftButton.continueWithMicrosoft'],
  [MicrosoftButtonText.SignUpWithMicrosoft, 'microsoftButton.signUpWithMicrosoft'],
  [MicrosoftButtonText.SignIn, 'microsoftButton.signIn'],
]);

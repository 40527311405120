import { inject, Injectable, Injector } from '@angular/core';
import { LabelsSelectors } from '@clover/conversations-v4/workspaces/state/labels/labels.selectors';
import { ProviderType } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.selectors';
import { RouterSelectors } from '@clover/custom-router-state-serializer';
import {
  ConversationSnoozeService,
  SnoozeOption,
} from '@conversations/conversation/state/conversation/conversation-snooze.service';
import {
  ConversationAssignmentErrorCode,
  ConversationFolder,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  PatchConversationDetails,
  ReloadConversationDetails,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import {
  Conversation,
  ConversationPerformer,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import {
  RemoveConversation,
  UpsertConversation,
} from '@conversations/conversations/state/conversations/conversations.actions';
import { ConversationsSelectors } from '@conversations/conversations/state/conversations/conversations.selectors';
import { ConversationsService } from '@conversations/conversations/state/conversations/conversations.service';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { ccPreventDuplicateSubscriptions } from '@core/helpers/prevent-duplicate-subscriptions';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { DateService } from '@core/services/date.service';
import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { ToastType } from '@design/overlays/toast/toast';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConversationActionsDistributorService {
  private readonly conversationsService = inject(ConversationsService);
  private readonly conversationSnoozeService = inject(ConversationSnoozeService);
  private readonly dateService = inject(DateService);
  private readonly http = inject(HttpService);
  private readonly store = inject(Store);
  private readonly portalService = inject(CdkPortalService);
  private readonly injector = inject(Injector);
  private readonly userService = inject(UserService);
  private readonly translate = inject(TranslateService);

  @ccPreventDuplicateSubscriptions('first-wins')
  updateReadStatus(conversationId: string, read: boolean, silent = false) {
    return this.http
      .postV2(`api/stream-conversations/${conversationId}/${read ? 'read' : 'unread'}`, {})
      .pipe(
        tap(() => {
          if (!silent)
            this.portalService.presentToast(
              read
                ? this.translate.instant('conversations-v4.conversation.toasts.markedAsRead')
                : this.translate.instant('conversations-v4.conversation.toasts.markedAsUnread'),
              ToastType.Success,
            );
        }),
        catchError((error) => {
          if (!silent)
            this.portalService.presentToast(
              read
                ? this.translate.instant('conversations-v4.conversation.toasts.failedToMarkAsRead')
                : this.translate.instant('conversations-v4.conversation.toasts.failedToMarkAsUnread'),
              ToastType.Error,
            );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  setStatus(conversationId: string, status: ConversationStatus) {
    return this.http
      .postV2(
        `api/stream-conversations/${conversationId}/${status === ConversationStatus.Open ? 'reopen' : 'close'}`,
        {},
      )
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            status === ConversationStatus.Open
              ? this.translate.instant('conversations-v4.conversation.toasts.reopened')
              : this.translate.instant('conversations-v4.conversation.toasts.closed'),
            ToastType.Success,
          );

          this.handleConversationUpdate(conversationId);
        }),
        catchError((error) => {
          this.portalService.presentToast(
            status === ConversationStatus.Open
              ? this.translate.instant('conversations-v4.conversation.toasts.failedToReopen')
              : this.translate.instant('conversations-v4.conversation.toasts.failedToClose'),
            ToastType.Error,
          );

          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  assignConversation(conversationId: string, assignee: ConversationPerformer) {
    return this.http
      .patchV2(`api/stream-conversations/${conversationId}/assign`, {
        assigneeUserId: assignee.id,
      })
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.assignedTo', { name: assignee.name }),
            ToastType.Success,
          );
        }),
        catchError((errorResponse) => {
          const errorCodes =
            errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

          if (errorCodes.includes(ConversationAssignmentErrorCode.AssigneeHasDraft)) {
            const currentAssignee = this.store.selectSnapshot(ConversationSelectors.details)?.assignment.assignee;
            const currentUserId = this.userService.userProfile.id;

            const isCurrentUserAssignee = currentAssignee?.id === currentUserId.toString();

            presentConfirmationDialog(this.injector, {
              title: this.translate.instant('conversations-v4.conversation.toasts.failedToReassignDialog.title'),
              description: isCurrentUserAssignee
                ? this.translate.instant(
                    'conversations-v4.conversation.toasts.failedToReassignDialog.currentAssigneeMessage',
                  )
                : this.translate.instant(
                    'conversations-v4.conversation.toasts.failedToReassignDialog.notCurrentUserAssigneeMessage',
                    { name: currentAssignee.name },
                  ),
              confirmActionText: this.translate.instant('common.buttons.ok'),
              style: 'compact',
            });
          } else {
            this.portalService.presentToast(
              this.translate.instant('conversations-v4.conversation.toasts.failedToAssign'),
              ToastType.Error,
            );
          }

          return of();
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  unassignConversation(conversationId: string) {
    return this.http
      .patchV2(`api/stream-conversations/${conversationId}/unassign`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.unassigned'),
            ToastType.Success,
          );
        }),
        catchError((errorResponse) => {
          const errorCodes =
            errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

          if (errorCodes.includes(ConversationAssignmentErrorCode.AssigneeHasDraft)) {
            const currentAssignee = this.store.selectSnapshot(ConversationSelectors.details)?.assignment.assignee;
            const currentUserId = this.userService.userProfile.id;

            const isCurrentUserAssignee = currentAssignee?.id === currentUserId.toString();

            presentConfirmationDialog(this.injector, {
              title: this.translate.instant('conversations-v4.conversation.toasts.failedToUnassignDialog.title'),
              description: isCurrentUserAssignee
                ? this.translate.instant(
                    'conversations-v4.conversation.toasts.failedToUnassignDialog.currentAssigneeMessage',
                  )
                : this.translate.instant(
                    'conversations-v4.conversation.toasts.failedToUnassignDialog.notCurrentUserAssigneeMessage',
                    { name: currentAssignee.name },
                  ),
              confirmActionText: this.translate.instant('common.buttons.ok'),
              style: 'compact',
            });
          } else {
            this.portalService.presentToast(
              this.translate.instant('conversations-v4.conversation.toasts.failedToUnassign'),
              ToastType.Error,
            );
          }

          return of();
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  setPrioritizedStatus(conversationId: string, prioritized: boolean) {
    return this.http
      .postV2(`api/stream-conversations/${conversationId}/prioritized`, prioritized)
      .pipe(
        tap(() => {
          this.store.dispatch(new PatchConversationDetails(conversationId, patch({ prioritized })));
          this.portalService.presentToast(
            prioritized
              ? this.translate.instant('conversations-v4.conversation.toasts.markedAsPrioritized')
              : this.translate.instant('conversations-v4.conversation.toasts.removedFromPrioritized'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            prioritized
              ? this.translate.instant('conversations-v4.conversation.toasts.failedToMarkAsPrioritized')
              : this.translate.instant('conversations-v4.conversation.toasts.failedToRemoveFromPrioritized'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  setSnooze(conversationId: string, snoozeOption: SnoozeOption) {
    const snoozedUntil = this.conversationSnoozeService.snoozeOptionToISOString(snoozeOption);
    const humanizedSnoozeOption = this.dateService.format(
      this.dateService.representLocalDateInProfileTimezoneDate(new Date(snoozedUntil)),
      {
        date: snoozeOption !== SnoozeOption.LaterToday ? 'long-no-year' : undefined,
        time: 'default',
      },
    );

    return this.http
      .postV2(`api/stream-conversations/${conversationId}/snooze`, {
        snoozeUntil: snoozedUntil,
      })
      .pipe(
        tap(() => {
          this.store.dispatch(new PatchConversationDetails(conversationId, patch({ snoozedUntil })));
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.snoozedUntil', {
              date: humanizedSnoozeOption,
            }),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.failedToSnooze'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  unsnooze(conversationId: string) {
    return this.http
      .postV2(`api/stream-conversations/${conversationId}/unsnooze`, {})
      .pipe(
        tap(() => {
          this.store.dispatch(new PatchConversationDetails(conversationId, patch({ snoozedUntil: undefined })));
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.unsnoozed'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.failedToUnsnooze'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  assignLabel(externalAccountId: number, conversationId: string, labelId: number) {
    return this.http
      .patchV2(`api/stream-conversations/${conversationId}/tags/${labelId}/assign`, {})
      .pipe(
        tap(() => {
          // Microsoft accounts support only one label per conversation
          const externalAccount = this.store.selectSnapshot(WorkspacesSelectors.externalAccountById(externalAccountId));
          const isMicrosoftAccount = externalAccount.providerType === ProviderType.Microsoft;

          if (!isMicrosoftAccount) {
            this.store.dispatch(new PatchConversationDetails(conversationId, patch({ labelIds: insertItem(labelId) })));
          } else {
            // Check if conversation is active
            const conversation = this.store.selectSnapshot(ConversationSelectors.details);
            const active = conversation && conversation.id === conversationId;
            if (!active) return;

            const labelIds = conversation.labelIds || [];
            const labels = this.store.selectSnapshot(LabelsSelectors.labelsByIds(labelIds));
            const updatedLabelIds = labels
              .filter((label) => label.system) // Keep system labels
              .map((label) => label.id) // Get only label ids
              .concat(labelId); // Add new label id

            this.store.dispatch(new PatchConversationDetails(conversationId, patch({ labelIds: updatedLabelIds })));
          }

          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.labelAssigned'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.failedToAssignLabel'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  unassignLabel(conversationId: string, labelId: number) {
    return this.http
      .patchV2(`api/stream-conversations/${conversationId}/tags/${labelId}/unassign`, {})
      .pipe(
        tap(() => {
          this.store.dispatch(
            new PatchConversationDetails(conversationId, patch({ labelIds: removeItem((id) => id === labelId) })),
          );
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.labelRemoved'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.failedToRemoveLabel'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  moveToFolder(conversationId: string, folder: ConversationFolder) {
    const folderMap = {
      [ConversationFolder.Inbox]: 'restore',
      [ConversationFolder.Archive]: 'archive',
      [ConversationFolder.Trash]: 'trash',
      [ConversationFolder.Spam]: 'spam',
    };

    const getHumanizedFolder = (folder: ConversationFolder) => {
      switch (folder) {
        case ConversationFolder.Inbox:
          return this.translate.instant('conversations-v4.conversation.folders.inbox');
        case ConversationFolder.Archive:
          return this.translate.instant('conversations-v4.conversation.folders.archive');
        case ConversationFolder.Trash:
          return this.translate.instant('conversations-v4.conversation.folders.trash');
        case ConversationFolder.Spam:
          return this.translate.instant('conversations-v4.conversation.folders.spam');
      }
    };

    return this.http
      .postV2(`api/stream-conversations/${conversationId}/${folderMap[folder]}`, {})
      .pipe(
        tap(() => {
          this.store.dispatch(new PatchConversationDetails(conversationId, patch({ folder })));
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.conversationMovedTo', {
              folder: getHumanizedFolder(folder),
            }),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.failedToMoveConversationTo', {
              folder: getHumanizedFolder(folder),
            }),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  handleConversationCreation(conversationId: string) {
    return this.patchConversationInListOrDelete(conversationId, true).subscribe();
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  handleConversationUpdate(conversationId: string) {
    return forkJoin([
      this.store.dispatch(new ReloadConversationDetails(conversationId)),
      this.patchConversationInListOrDelete(conversationId, true),
    ]).subscribe();
  }

  private patchConversationInListOrDelete(
    conversationId: string,
    deactivateIfNoLongerExists: boolean,
  ): Observable<void> {
    return this.conversationsService.checkExistenceInCurrentView(conversationId).pipe(
      map((conversation: Conversation | undefined) => {
        if (conversation) return this.store.dispatch(new UpsertConversation(conversation));

        if (deactivateIfNoLongerExists) this.navigateToNextConversation(conversationId);
        this.store.dispatch(new RemoveConversation(conversationId));
      }),
      map(() => undefined),
    );
  }

  private navigateToNextConversation(conversationId: string): void {
    const currentConversationId = this.store.selectSnapshot(ConversationSelectors.details)?.id;
    if (currentConversationId !== conversationId) return;

    const conversations = this.store.selectSnapshot(ConversationsSelectors.conversations);
    const conversationIndex = conversations.findIndex((conversation) => conversation.id === conversationId);
    const nextConversation = conversations[conversationIndex + 1];
    const previousConversation = conversations[conversationIndex - 1];

    const currentUrlSegments = this.store.selectSnapshot(RouterSelectors.state).segments;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, workspaceSegment, categorySegment] = currentUrlSegments;
    if (!workspaceSegment || !categorySegment) return;

    this.store.dispatch(
      new Navigate([
        '/',
        CONVERSATIONS_BASE_URL,
        workspaceSegment.path,
        workspaceSegment.parameters,
        categorySegment.path,
        categorySegment.parameters,
        nextConversation?.id || previousConversation?.id || '',
      ]),
    );
  }
}

<ng-scrollbar>
  <div class="workspace-settings-signatures">
    <div class="workspace-settings-signatures__description">
      @if (workspace().ownerType === 'user') {
        {{ 'conversations-v4.workspaces.settings.signatures.messagePrivateWorkspace' | translate }}
      } @else {
        {{
          'conversations-v4.workspaces.settings.signatures.messageTeamWorkspace'
            | translate: { teamName: workspace().name }
        }}
      }

      @if (externalAccounts().length) {
        <span
          [innerHTML]="
            'conversations-v4.workspaces.settings.signatures.affectedAccounts'
              | translate
                : {
                    affectedAccounts: affectedAccountsString(),
                  }
          "
        ></span>
      }
    </div>

    @if (canEditSignatures() === false) {
      <div
        class="workspace-settings-signatures__read-only-notice"
        [innerHTML]="'conversations-v4.workspaces.settings.signatures.readonlyNotice' | translate"
      ></div>
    }

    <cc-workspace-settings-signatures-defaults
      [workspaceId]="workspace().id"
      [signatures]="signatures()"
      [defaultNewEmailSignature]="defaultNewEmailSignature()"
      [defaultReplyEmailSignature]="defaultReplyEmailSignature()"
      [canEditSignatures]="canEditSignatures()"
    />

    <cc-workspace-settings-signatures-table
      (create)="createSignature()"
      (edit)="editSignature($event)"
      (delete)="deleteSignature($event)"
      [signatures]="signatures()"
      [canEditSignatures]="canEditSignatures()"
    />
  </div>
</ng-scrollbar>

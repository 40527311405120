import { Selector } from '@ngxs/store';
import { ICompanyWikiDocument, WikiEditorMode, WikiStateModel } from '@network/company/state/wiki/wiki.entities';
import { WikiState } from '@network/company/state/wiki/wiki.state';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

export class WikiSelectors {
  static mapChildren(document: ICompanyWikiDocument): NzTreeNodeOptions[] {
    return document.children.map((child) => ({
      title: child.name,
      key: child.id,
      children: this.mapChildren(child),
      isLeaf: child.children.length === 0,
    })) as unknown as NzTreeNodeOptions[];
  }

  @Selector([WikiState])
  static documents(state: WikiStateModel): ICompanyWikiDocument[] {
    return state.documents || [];
  }

  @Selector([WikiState])
  static activeDocument(state: WikiStateModel) {
    return state.activeDocument;
  }

  @Selector([WikiState])
  static isEditing(state: WikiStateModel): boolean {
    return state.editorMode === WikiEditorMode.Edit;
  }

  @Selector([WikiState])
  static mapToTree(state: WikiStateModel): NzTreeNodeOptions[] {
    return state.documents.map((document) => ({
      title: document.name,
      key: document.id,
      children: this.mapChildren(document),
      isLeaf: document.children.length === 0,
    })) as unknown as NzTreeNodeOptions[];
  }

  @Selector([WikiState])
  static companyId(state: WikiStateModel): string {
    return state.companyId;
  }
}

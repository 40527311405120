import { OverlayRef } from '@angular/cdk/overlay';

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  privateWorkspaceSettingsModalTabs,
  teamWorkspaceSettingsModalTabs,
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TabSelectorComponent, TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-workspace-settings-modal',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLinkWithHref,
    TabSelectorComponent,
    AutoAnimateDirective,
    ButtonComponent,
    TooltipDirective,
    TranslateModule,
  ],
  templateUrl: './workspace-settings-modal.component.html',
  styleUrls: ['./workspace-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalTemplate', { static: true })
  modalTemplate: TemplateRef<never>;

  workspaceAlias: string;
  workspace: Workspace | undefined;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private overlayRef: OverlayRef | null = null;
  private readonly cdkPortalService = inject(CdkPortalService);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  private _tabs: TabSelectorItem[] = [
    {
      id: 'inbox',
      labelTranslationKey: 'conversations-v4.workspaces.settings.accounts.title',
      disabled: false,
    },
    {
      id: 'labels',
      labelTranslationKey: 'conversations-v4.workspaces.settings.labels.title',
      disabled: false,
    },
    {
      id: 'signatures',
      labelTranslationKey: 'conversations-v4.workspaces.settings.signatures.title',
      disabled: false,
    },
    {
      id: 'members',
      labelTranslationKey: 'conversations-v4.workspaces.settings.members.title',
      disabled: false,
    },
    {
      id: 'notifications',
      labelTranslationKey: 'conversations-v4.workspaces.settings.notifications.title',
      disabled: false,
    },
    {
      id: 'settings',
      labelTranslationKey: 'conversations-v4.workspaces.settings.settings.title',
      disabled: false,
    },
  ];

  get tabs(): TabSelectorItem[] {
    if (this.workspace.ownerType === 'team') {
      return this._tabs.filter((tab) => teamWorkspaceSettingsModalTabs.includes(tab.id as WorkspaceSettingsModalTab));
    }

    return this._tabs.filter((tab) => privateWorkspaceSettingsModalTabs.includes(tab.id as WorkspaceSettingsModalTab));
  }

  get selectedTabId(): string {
    return this.tabs.find((tab) =>
      this.router.isActive(
        this.router.createUrlTree([
          '/',
          CONVERSATIONS_BASE_URL,
          { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [this.workspaceAlias, tab.id] } },
        ]),
        {
          queryParams: 'ignored',
          paths: 'subset',
          fragment: 'ignored',
          matrixParams: 'subset',
        },
      ),
    ).id;
  }

  async ngOnInit(): Promise<void> {
    this.workspaceAlias = this.activatedRoute.snapshot.params.workspaceAlias;
    this.workspace = this.activatedRoute.snapshot.data['workspace'];

    this.overlayRef = this.cdkPortalService.openPortal({
      containerRef: this.viewContainerRef,
      hasBackdrop: true,
      templateRef: this.modalTemplate,
      backdropClickHandler: () => this.closeModal(),
    });
  }

  async handleTabSelect(tabId: string): Promise<void> {
    await this.router.navigate([
      '/',
      CONVERSATIONS_BASE_URL,
      { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [this.workspaceAlias, tabId] } },
    ]);
  }

  async closeModal(): Promise<void> {
    await this.router.navigate(['/', CONVERSATIONS_BASE_URL, { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: null } }]);
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }
}

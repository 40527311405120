import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import * as R from 'ramda';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { TaskPerformerSelectComponent } from '@conversations/tasks/task-performer-select/task-performer-select.component';
import { TaskProductCardComponent } from '@conversations/tasks/task-product-card/task-product-card.component';
import { Task, TaskPerformer, TaskRole, TaskStatus } from '@conversations/tasks/tasks.model';
import { CoreModule } from '@core/core.module';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { UserService } from '@core/services/user.service';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'cc-task-drawer-details',
  standalone: true,
  templateUrl: './task-drawer-details.component.html',
  styleUrls: ['./task-drawer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TaskProductCardComponent,
    TaskPerformerSelectComponent,
    CoreModule,
    TooltipDirective,
    TranslateModule,
    NgScrollbarModule,
    CdkScrollable,
  ],
})
export class TaskDrawerDetailsComponent {
  @Input()
  task: Task;

  private readonly userService = inject(UserService);

  get canChangeAssignee(): boolean {
    if (!this.canAssignToCurrentUser && !this.canReroute) return false;

    return (
      this.task.status !== TaskStatus.Closed &&
      this.task.status !== TaskStatus.Completed &&
      (this.task.taskRole === TaskRole.Assignee || this.task.taskRole === TaskRole.AssigneeCollaborator)
    );
  }

  get canAssignToCurrentUser(): boolean {
    return !this.task.acceptedBy;
  }

  get canReroute(): boolean {
    return this.task.acceptedBy?.id === this.userService.userProfile.id;
  }

  get usersWithAccess(): TaskPerformer[] {
    return R.uniqBy(
      (performer) => performer.id,
      [this.task.acceptedBy, ...this.task.assigneeUsers]
        .map((performer) => {
          if (!performer) return;
          if (performer.avatarUrl) return performer;

          const { firstName, lastName } = performer;
          const avatar = generateGenericAvatar(firstName, lastName, 48);

          return { ...performer, avatarUrl: avatar };
        })
        .filter(Boolean),
    );
  }
}

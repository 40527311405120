import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import type { ConnectionStatus } from '@clover/core/models/company';
import type { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { ButtonComponent } from '../../buttons/button/button.component';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { CompanyPickerDialogTableComponent } from './company-picker-dialog-table/company-picker-dialog-table.component';

export interface CompanyPickerDialogData {
  connectionStatuses?: Array<ConnectionStatus.Connected | ConnectionStatus.Invited> | undefined;
}

export type CompanyPickerDialogResult = AssociatedCompany;

@Component({
  selector: 'cc-company-picker-dialog',
  standalone: true,
  imports: [ButtonComponent, TranslateModule, TooltipDirective, NgScrollbar, CompanyPickerDialogTableComponent],
  templateUrl: './company-picker-dialog.component.html',
  styleUrl: './company-picker-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPickerDialogComponent {
  protected readonly dialogData: CompanyPickerDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<CompanyPickerDialogResult | undefined> = inject(
    DialogRef<CompanyPickerDialogResult | undefined>,
  );

  protected readonly selectedCompany = signal<AssociatedCompany | undefined>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  save(): void {
    this.dialogRef.close(this.selectedCompany());
  }
}

import { ButtonType, ButtonSize } from '@design/buttons/button/types';
import { ChangeDetectionStrategy, Component, HostListener, input, output } from '@angular/core';
import {
  FormControl,
  ReactiveFormsModule,
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';
import { TextboxComponent } from '../../../stories/forms/textbox/textbox.component';
import { ButtonComponent } from '../../../stories/buttons/button/button.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

function createCustomRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return { required: true };
    if (!value.trim()) return { required: true };

    return null;
  };
}

@Component({
  selector: 'cc-assistant-composer',
  standalone: true,
  imports: [ReactiveFormsModule, TextboxComponent, ButtonComponent, NgScrollbarModule],
  templateUrl: './assistant-composer.component.html',
  styleUrl: './assistant-composer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantComposerComponent {
  sendDisabled = input<boolean>(false);

  messageSend = output<string>();

  protected readonly messageFormControl = new FormControl('', [createCustomRequiredValidator()]);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.send();
    }
  }

  send(): void {
    if (this.messageFormControl.invalid || this.sendDisabled()) return;

    this.messageSend.emit(this.messageFormControl.value);
    this.messageFormControl.reset();
  }
}

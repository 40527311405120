import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
  signal,
  type OnInit,
} from '@angular/core';
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TaskAudience } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, iif, of, take, tap, type Observable } from 'rxjs';
import { TaskAssignmentAudienceService } from '../../../app/conversations-v4/tasks/task-assignment/audience.service';
import type {
  TaskAudienceContact,
  TaskAudienceDepartment,
} from '../../../app/conversations-v4/tasks/task-assignment/task-assignment.model';
import { ButtonComponent } from '../../buttons/button/button.component';
import {
  AssigneePickerDialogComponent,
  type AssigneePickerDialogData,
  type AssigneePickerDialogResult,
} from './assignee-picker-dialog/assignee-picker-dialog.component';
import { TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent } from './contacts-preview-table/contacts-preview-table.component';
import { TaskAssignmentAssigneePickerDialogDepartmentsPreviewTableComponent } from './departments-preview-table/departments-preview-table.component';

interface AssigneePickerPreviewData {
  contacts: TaskAudienceContact[];
  departments: TaskAudienceDepartment[];
}

export function createAudienceRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const audience: TaskAudience = control.value;
    if (!audience) return { required: true };

    return audience.contactIds.length > 0 || audience.departmentIds.length > 0 ? null : { required: true };
  };
}

@UntilDestroy()
@Component({
  selector: 'cc-task-assignment-assignee-picker',
  standalone: true,
  imports: [
    ButtonComponent,
    AutoAnimateDirective,
    TranslateModule,
    TaskAssignmentAssigneePickerDialogDepartmentsPreviewTableComponent,
    TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent,
  ],
  templateUrl: './assignee-picker.component.html',
  styleUrl: './assignee-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentAssigneePickerComponent implements OnInit {
  audience = model<TaskAudience>();
  companyId = input.required<number>(); // TODO: This should be removed when multi-company task assignment is implemented

  childDialogOpened = output<void>();
  childDialogClosed = output<void>();

  protected readonly previewData = signal<AssigneePickerPreviewData>({
    contacts: [],
    departments: [],
  });
  protected readonly previewDataLoading = signal<boolean>(false);

  protected readonly hasContacts = computed(() => this.audience().contactIds.length > 0);
  protected readonly hasDepartments = computed(() => this.audience().departmentIds.length > 0);
  protected readonly hasAudience = computed(() => this.hasContacts() || this.hasDepartments());

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly dialog = inject(Dialog);
  private readonly taskAssignmentAudienceService = inject(TaskAssignmentAudienceService);

  ngOnInit(): void {
    this.loadPreviewData();
  }

  openSelectDialog(): void {
    const dialog = this.dialog.open<AssigneePickerDialogResult, AssigneePickerDialogData>(
      AssigneePickerDialogComponent,
      {
        data: {
          companyId: this.companyId(),
          audience: this.audience(),
        },
      },
    );

    this.childDialogOpened.emit();

    dialog.closed
      .pipe(
        take(1),
        tap((audience: AssigneePickerDialogResult | undefined) => {
          this.childDialogClosed.emit();

          if (!audience) return;

          this.audience.set({
            contactIds: audience.contactIds,
            departmentIds: audience.departmentIds,
          });
          this.loadPreviewData();
        }),
      )
      .subscribe();
  }

  removeContact(id: number): void {
    this.audience.update((audience) => {
      return {
        ...audience,
        contactIds: audience.contactIds.filter((contactId) => contactId !== id),
      };
    });
    this.previewData.update((previewData) => {
      return {
        ...previewData,
        contacts: previewData.contacts.filter((contact) => contact.id !== id),
      };
    });
  }

  removeDepartment(id: string): void {
    this.audience.update((audience) => {
      return {
        ...audience,
        departmentIds: audience.departmentIds.filter((departmentId) => departmentId !== id),
      };
    });
    this.previewData.update((previewData) => {
      return {
        ...previewData,
        departments: previewData.departments.filter((department) => department.id !== id),
      };
    });
  }

  private loadPreviewData(): void {
    const contactIds = this.audience()?.contactIds || [];
    const departmentIds = this.audience()?.departmentIds || [];

    this.previewData.set({
      contacts: [],
      departments: [],
    });
    this.previewDataLoading.set(true);

    forkJoin([
      iif(() => contactIds.length > 0, this.loadContactsPreviewData(contactIds), of([])),
      iif(() => departmentIds.length > 0, this.loadDepartmentsPreviewData(departmentIds), of([])),
    ])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([contactsPreviewData, departmentsPreviewData]) => {
        if (contactsPreviewData.length === 0 || departmentsPreviewData.length === 0) {
          this.audience.update((audience) => {
            return {
              contactIds: contactsPreviewData.length > 0 ? audience.contactIds : [],
              departmentIds: departmentsPreviewData.length > 0 ? audience.departmentIds : [],
            };
          });
        }

        this.previewData.set({
          contacts: contactsPreviewData,
          departments: departmentsPreviewData,
        });
        this.previewDataLoading.set(false);
      });
  }

  private loadContactsPreviewData(contactIds: number[]): Observable<TaskAudienceContact[]> {
    return this.taskAssignmentAudienceService.getContactsByIds(this.companyId(), contactIds);
  }

  private loadDepartmentsPreviewData(departmentIds: string[]): Observable<TaskAudienceDepartment[]> {
    return this.taskAssignmentAudienceService.getDepartmentsByIds(departmentIds);
  }
}

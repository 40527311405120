<div class="message-group-date-header">
  <div class="message-group-date-header__line"></div>

  <div
    #dropdownTrigger
    (click)="dropdownVisible = !dropdownVisible"
    class="message-group-date-header__date"
  >
    <div class="message-group-date-header__date-title">{{ title }}</div>

    <i
      [class.message-group-date-header__date-icon--flipped]="dropdownVisible"
      class="message-group-date-header__date-icon icon-chevron-down-small"
    ></i>
  </div>

  <div class="message-group-date-header__line"></div>
</div>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown>
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.jumpTo' | translate }}
      </ng-container>
    </cc-dropdown-text>

    @if (dropdownOptions.includes('today')) {
      <cc-dropdown-action (click)="selectDate('today')">
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.today' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    @if (dropdownOptions.includes('yesterday')) {
      <cc-dropdown-action (click)="selectDate('yesterday')">
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.yesterday' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    @if (dropdownOptions.includes('last-week')) {
      <cc-dropdown-action (click)="selectDate('last-week')">
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.lastWeek' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-action (click)="selectDate('beginning')">
      <ng-container slot="title">
        {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.beginning' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-divider></cc-dropdown-divider>

    <cc-dropdown-action (click)="selectDate('custom')">
      <ng-container slot="title">
        {{ 'conversations-v4.conversation.messages.jumpToMessageHeader.custom' | translate }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>

import { Component, inject, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { WikiSelectors } from '@network/company/state/wiki/wiki.selectors';
import { combineLatest, Observable } from 'rxjs';
import { CompanyWikiTreeViewComponent } from '@network/company/company-wiki/company-wiki-file-explorer/company-wiki-tree-view/company-wiki-tree-view.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs/operators';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { CreatePage } from '@network/company/state/wiki/wiki.actions';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { UserService } from '@core/services/user.service';

@UntilDestroy()
@Component({
  selector: 'cc-company-wiki-file-explorer',
  standalone: true,
  imports: [CommonModule, TextboxComponent, ButtonComponent, ReactiveFormsModule, CompanyWikiTreeViewComponent],
  templateUrl: './company-wiki-file-explorer.component.html',
  styleUrls: ['./company-wiki-file-explorer.component.scss'],
})
export class CompanyWikiFileExplorerComponent implements OnInit {
  documents$: Observable<ICompanyWikiDocument[]>;

  searchFormControl = new FormControl<string>('');

  canEdit$: Observable<boolean>;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);

  ngOnInit(): void {
    const allDocuments$ = this.store.select(WikiSelectors.documents);
    const searchValue$ = this.searchFormControl.valueChanges.pipe(startWith(''));

    this.documents$ = combineLatest([allDocuments$, searchValue$]).pipe(
      untilDestroyed(this),
      map(([allDocuments, searchValue]) => {
        if (!searchValue) return allDocuments;
        return this.filterDocuments(allDocuments, searchValue);
      }),
    );

    this.canEdit$ = this.store.select(WikiSelectors.companyId).pipe(
      map((companyId) => {
        if (!this.userService.permissions.Company_ResourcePage_Manage) return false;

        const myCompanyId = this.userService.userCompany.id.toString();
        return companyId === myCompanyId;
      }),
    );
  }

  createPage() {
    this.store.dispatch(new CreatePage());
  }

  private filterDocuments(documents: ICompanyWikiDocument[], searchValue: string): ICompanyWikiDocument[] {
    const filteredDocuments: ICompanyWikiDocument[] = [];

    for (const document of documents) {
      const filteredDocument: ICompanyWikiDocument = {
        ...document,
        children: document.children ? this.filterDocuments(document.children, searchValue) : null,
      };

      if (this.documentMatchesSearch(filteredDocument, searchValue)) {
        filteredDocuments.push(filteredDocument);
      } else if (filteredDocument.children && filteredDocument.children.length > 0) {
        filteredDocuments.push(filteredDocument);
      }
    }

    return filteredDocuments;
  }

  private documentMatchesSearch(document: ICompanyWikiDocument, searchValue: string): boolean {
    return document.name.toLowerCase().includes(searchValue.toLowerCase());
  }
}

<div
  (click)="openSyncIssuesModal()"
  [ccTooltip]="
    'conversations-v4.conversation.accountIssueNotice.lastUpdateAt'
      | translate
        : {
            date: (account.lastActiveAt | ccDateFormat: dateFormatOptions),
          }
  "
  [class.conversation-header-account-issue-notice--disconnected]="accountIssue === 'disconnected'"
  [class.conversation-header-account-issue-notice--expired]="accountIssue === 'expired'"
  [class.conversation-header-account-issue-notice--failed]="accountIssue === 'failed'"
  [tooltipPosition]="TooltipPosition.Bottom"
  class="conversation-header-account-issue-notice"
  tooltipMaxWidth="auto"
>
  <i
    [class.icon-sync-auth-error]="['failed', 'disconnected'].includes(accountIssue)"
    [class.icon-sync-error]="accountIssue === 'expired'"
    class="conversation-header-account-issue-notice__icon"
  ></i>

  <div class="conversation-header-account-issue-notice__title">
    @switch (accountIssue) {
      @case ('failed') {
        {{ 'conversations-v4.conversation.accountIssueNotice.failed' | translate: { email: account.email } }}
      }
      @case ('expired') {
        {{ 'conversations-v4.conversation.accountIssueNotice.expired' | translate: { email: account.email } }}
      }
      @case ('disconnected') {
        {{ 'conversations-v4.conversation.accountIssueNotice.disconnected' | translate: { email: account.email } }}
      }
    }
  </div>

  <div class="conversation-header-account-issue-notice__description">
    {{ 'conversations-v4.conversation.accountIssueNotice.localCopy' | translate }}
  </div>
</div>

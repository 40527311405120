<div class="company-picker-dialog">
  <div class="company-picker-dialog__header">
    <div class="company-picker-dialog__header-main">
      <div class="company-picker-dialog__header-main-title">Select company</div>

      <app-button
        (click)="dialogRef.close()"
        [ccTooltip]="'common.buttons.close' | translate"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-close"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="company-picker-dialog__content">
    <cc-company-picker-dialog-table
      [(selectedCompany)]="selectedCompany"
      [connectionStatuses]="dialogData.connectionStatuses"
    />
  </div>

  <div class="company-picker-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="save()"
      [disabled]="!selectedCompany()"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.save' | translate }}
    </app-button>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  output,
  SimpleChanges,
} from '@angular/core';

import { ComposerRecipientsSelectorEditorComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-editor/composer-recipients-selector-editor.component';
import { ComposerRecipientsSelectorPreviewComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-preview/composer-recipients-selector-preview.component';
import { EmailParticipants } from '@conversations/conversation/state/conversation/conversation-state.model';

@Component({
  selector: 'cc-composer-recipients-selector',
  standalone: true,
  templateUrl: './composer-recipients-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ComposerRecipientsSelectorEditorComponent, ComposerRecipientsSelectorPreviewComponent],
})
export class ComposerRecipientsSelectorComponent implements OnChanges {
  @Input()
  recipients: Omit<EmailParticipants, 'from'>;

  recipientsChange = output<Omit<EmailParticipants, 'from'>>();

  protected editorActive = false;

  private readonly elementRef = inject(ElementRef);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recipients']) {
      const { to, cc, bcc } = changes['recipients'].currentValue as Omit<EmailParticipants, 'from'>;
      this.editorActive = to.length === 0 && cc.length === 0 && bcc.length === 0;
      this.cdr.detectChanges();
    }
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event: MouseEvent): void {
    const isInsideDropdown = (node: HTMLElement): boolean => {
      while (node.parentElement) {
        if (node.parentElement.classList.contains('cc-recipients-selector-dropdown')) return true;
        node = node.parentElement;
      }

      return false;
    };

    if (
      !this.elementRef.nativeElement.contains(event.target as Node) &&
      !isInsideDropdown(event.target as HTMLElement)
    ) {
      this.editorActive = false;
      this.cdr.detectChanges();
    }
  }
}

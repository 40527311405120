import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { ReassignTaskModalComponent } from '@clover/tasks/modals/reassign-task-modal/reassign-task-modal.component';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ModalService } from '@core/services/modal.service';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TaskPerformer, TaskPerformerCompany } from '@conversations/tasks/tasks.model';
import { TasksService } from '@conversations/tasks/tasks.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-task-performer-select',
  standalone: true,
  templateUrl: './task-performer-select.component.html',
  styleUrls: ['./task-performer-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownActionComponent,
    DropdownComponent,
    OverlayModule,
    NgOptimizedImage,
    TooltipDirective,
    TranslateModule,
  ],
})
export class TaskPerformerSelectComponent {
  @Input()
  taskId: number;

  @Input()
  performer: TaskPerformer | undefined;

  @Input()
  performerCompany: TaskPerformerCompany;

  @Input()
  canChangeAssignee = false;

  @Input()
  canAssignToCurrentUser = false;

  @Input()
  canReroute = false;

  @Input()
  type: 'assignee' | 'assigner' = 'assignee';

  protected dropdownVisible = false;
  protected dropdownPositionStrategy: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 4 },
  ];

  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly tasksService = inject(TasksService);
  private readonly modalService = inject(ModalService); // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.

  get performerAvatarUrl(): string | undefined {
    const performer = this.performer;

    if (!performer) return undefined;
    if (performer.avatarUrl) return performer.avatarUrl;

    const { firstName, lastName } = performer;
    return generateGenericAvatar(firstName, lastName, 48);
  }

  assignToCurrentUser(): void {
    this.tasksService.assignToCurrentUser(this.taskId);
  }

  reroute(): void {
    // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.
    this.modalService.open({
      content: ReassignTaskModalComponent,
      inputs: {
        taskId: this.taskId,
        fromConversation: true,
      },
    });
  }
}

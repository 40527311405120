<div class="workspace-settings-signatures-defaults">
  <cc-select
    [disabled]="!canEditSignatures()"
    [formControl]="newEmailSignatureIdControl"
    [placeholder]="'conversations-v4.workspaces.settings.signatures.defaultsInput.noSignature' | translate"
    [allowEmpty]="true"
  >
    <ng-container ccTitle>
      {{ 'conversations-v4.workspaces.settings.signatures.defaultsInput.newEmailsTitle' | translate }}
    </ng-container>

    <ng-container ccOptions>
      @if (selectOptions().length > 0) {
        @for (option of selectOptions(); track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      } @else {
        <cc-dropdown-text>
          <ng-container slot="title">{{
            'conversations-v4.workspaces.settings.signatures.noSignaturesPlaceholder' | translate
          }}</ng-container>
        </cc-dropdown-text>
      }
    </ng-container>
  </cc-select>

  <cc-select
    [disabled]="!canEditSignatures()"
    [formControl]="replyEmailSignatureIdControl"
    [placeholder]="'conversations-v4.workspaces.settings.signatures.defaultsInput.noSignature' | translate"
    [allowEmpty]="true"
  >
    <ng-container ccTitle>
      {{ 'conversations-v4.workspaces.settings.signatures.defaultsInput.repliesAndForwardsTitle' | translate }}
    </ng-container>

    <ng-container ccOptions>
      @if (selectOptions().length > 0) {
        @for (option of selectOptions(); track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      } @else {
        <cc-dropdown-text>
          <ng-container slot="title">{{
            'conversations-v4.workspaces.settings.signatures.noSignaturesPlaceholder' | translate
          }}</ng-container>
        </cc-dropdown-text>
      }
    </ng-container>
  </cc-select>
</div>

import { CommonModule } from '@angular/common';
import { Component, inject, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { WikiSelectors } from '@network/company/state/wiki/wiki.selectors';

@Component({
  selector: 'cc-move-page',
  standalone: true,
  imports: [CommonModule, NzTreeSelectModule, ReactiveFormsModule, ButtonComponent],
  templateUrl: './move-page.component.html',
  styleUrls: ['./move-page.component.scss'],
})
export class MovePageComponent {
  cancel = output<void>();

  confirm = output<number>();

  treeControl = new FormControl();

  private readonly _store = inject(Store);

  tree$ = this._store.select(WikiSelectors.mapToTree);
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}

<div
  #dropdownTrigger
  (click)="dropdownVisible = !dropdownVisible"
  [ccTooltip]="'conversations-v4.conversation.assigneePicker.changeAssignee' | translate"
  [class.conversation-header-actions-assignee-picker--disabled]="disabled"
  [tooltipPosition]="TooltipPosition.Bottom"
  class="conversation-header-actions-assignee-picker"
  role="button"
>
  @if (assignee) {
    <img
      [alt]="assignee.name"
      [src]="assignee.avatarUrl"
      class="conversation-header-actions-assignee-picker__avatar"
      height="48px"
      width="48px"
    />
  }

  <div class="conversation-header-actions-assignee-picker__name">
    @if (assignee) {
      {{ assignee.name }}
    } @else {
      {{ 'conversations-v4.conversation.assigneePicker.unassigned' | translate }}
    }
  </div>

  <i
    [class.icon-collapse]="dropdownVisible"
    [class.icon-expand]="!dropdownVisible"
    class="conversation-header-actions-assignee-picker__toggle"
  ></i>
</div>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown
    maxHeight="300px"
    width="240px"
  >
    <cc-dropdown-text>
      <ng-container slot="title">{{ workspace.name }}</ng-container>
    </cc-dropdown-text>

    @for (availableAssignee of availableAssignees; track availableAssignee.id) {
      <cc-dropdown-action
        (click)="assign(availableAssignee); dropdownVisible = false"
        [selectable]="true"
        [selected]="availableAssignee.id === assignee?.id"
      >
        <ng-container slot="leading">
          <img
            [alt]="availableAssignee.name"
            [src]="availableAssignee.avatarUrl"
            class="conversation-header-actions-assignee-picker__dropdown-avatar"
            height="40px"
            width="40px"
          />
        </ng-container>
        <ng-container slot="title">{{ availableAssignee.name }}</ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-divider></cc-dropdown-divider>

    <cc-dropdown-action
      (click)="unassign(); dropdownVisible = false"
      [selectable]="true"
      [selected]="!assignee"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.conversation.assigneePicker.unassigned' | translate }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>

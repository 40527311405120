import { Overlay, type ConnectedPosition, type OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  computed,
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  ViewContainerRef,
  type OnDestroy,
  type TemplateRef,
} from '@angular/core';
import { createNotifier } from 'ngxtension/create-notifier';
import type { Subscription } from 'rxjs';
import { DropdownComponent } from './dropdown.component';

type BasicPosition = 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end';

interface BasicPositionsConfig {
  offsetX?: number;
  offsetY?: number;
  positions?: BasicPosition[];
}

function createPosition(offsetX: number, offsetY: number, type: BasicPosition): ConnectedPosition {
  switch (type) {
    case 'top-start':
      // [o] <- overlay
      // [t t]
      // [t t] <- trigger
      return {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX,
        offsetY: -offsetY,
      };
    case 'top-end':
      //   [o] <- overlay
      // [t t]
      // [t t] <- trigger
      return {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: -offsetX,
        offsetY: -offsetY,
      };
    case 'bottom-start':
      // [t t] <- trigger
      // [t t]
      // [o] <- overlay
      return {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX,
        offsetY,
      };
    case 'bottom-end':
      // [t t] <- trigger
      // [t t]
      //   [o] <- overlay
      return {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: -offsetX,
        offsetY,
      };
  }
}

const DEFAULT_OFFSET_X = 0;
const DEFAULT_OFFSET_Y = 0;
const DEFAULT_POSITIONS: BasicPosition[] = ['bottom-start', 'bottom-end'];

@Directive({
  selector: '[ccDropdownTrigger]',
  standalone: true,
  exportAs: 'ccDropdownTrigger',
})
export class DropdownTriggerDirective implements OnDestroy {
  dropdownRef = input.required<TemplateRef<DropdownComponent>>({
    alias: 'ccDropdownTrigger',
  });

  positionsConfig = input<BasicPositionsConfig | undefined>(undefined, {
    alias: 'ccDropdownPositions',
  });

  customPositions = input<ConnectedPosition[]>([], {
    alias: 'ccDropdownCustomPositions',
  });

  disabled = input<boolean>(false, {
    alias: 'ccDropdownDisabled',
  });

  toggleOnClick = input<boolean>(true, {
    alias: 'ccDropdownToggleOnClick',
  });

  isOpen = computed(() => {
    this.attachedNotifier.listen();
    return this.attached;
  });

  private overlayRef: OverlayRef | undefined = undefined;
  private outsidePointerEventsSubscription: Subscription | undefined = undefined;
  private keydownEventsSubscription: Subscription | undefined = undefined;
  private detachmentEventsSubscription: Subscription | undefined = undefined;

  private readonly templatePortal = computed(() => new TemplatePortal(this.dropdownRef(), this.viewContainerRef));
  private readonly attachedNotifier = createNotifier();

  private readonly elementRef = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly overlay = inject(Overlay);
  private readonly cdr = inject(ChangeDetectorRef);

  private get attached(): boolean {
    return this.overlayRef?.hasAttached() ?? false;
  }

  @HostListener('click')
  handleClick(): void {
    if (this.toggleOnClick()) this.toggle();
  }

  toggle(): void {
    if (this.attached) this.detach();
    else this.attach();
  }

  open(): void {
    this.attach();
  }

  close(): void {
    this.detach();
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef.nativeElement)
        .withPositions(this.createPositions()),
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private attach(): void {
    if (this.disabled()) return;
    if (!this.overlayRef) this.createOverlay();
    if (this.attached) return;

    this.overlayRef.attach(this.templatePortal());
    this.attachedNotifier.notify();

    this.outsidePointerEventsSubscription = this.overlayRef
      .outsidePointerEvents()
      .subscribe((e) => this.handleOutsidePointerEvents(e));

    this.detachmentEventsSubscription = this.overlayRef.detachments().subscribe(() => {
      this.attachedNotifier.notify();
    });

    this.keydownEventsSubscription = this.overlayRef.keydownEvents().subscribe((e) => this.handleKeydownEvents(e));
  }

  private detach(): void {
    if (this.disabled()) return;
    if (!this.attached) return;

    this.overlayRef?.detach();
    this.outsidePointerEventsSubscription?.unsubscribe();
    this.keydownEventsSubscription?.unsubscribe();
    this.detachmentEventsSubscription?.unsubscribe();

    this.cdr.markForCheck();
  }

  private createPositions(): ConnectedPosition[] {
    if (this.customPositions().length) return this.customPositions();

    const offsetX = this.positionsConfig()?.offsetX ?? DEFAULT_OFFSET_X;
    const offsetY = this.positionsConfig()?.offsetY ?? DEFAULT_OFFSET_Y;
    const positions = this.positionsConfig()?.positions ?? DEFAULT_POSITIONS;

    return positions.map((position) => createPosition(offsetX, offsetY, position));
  }

  private handleOutsidePointerEvents(event: MouseEvent): void {
    if (this.elementRef.nativeElement.contains(event.target as Node)) return;

    const otherDialogs = Array.from(document.querySelectorAll('cdk-dialog-container'));
    const clickInOtherDialog = otherDialogs.some((modal) => modal.contains(event.target as Node));
    if (clickInOtherDialog) return;

    this.detach();
  }

  private handleKeydownEvents(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.detach();
    }
  }

  ngOnDestroy(): void {
    this.detach();
    if (this.overlayRef) this.overlayRef.dispose();
  }
}

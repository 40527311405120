import { sanitize } from '@clover/core/helpers/sanitize-email/sanitizer';
import { placeholderSelectors } from '../extensions/dynamic-values/dynamic-values.service';

export default function isHTMLEmpty(content: string): boolean {
  const plainText = sanitize(content, null, {
    noWrapper: true,
    dropAllHtmlTags: true,
  });

  const hasImages = /<img[^>]+>/g.test(content);

  const hasPlaceholders = placeholderSelectors.reduce((acc, selector) => {
    const regex = new RegExp(`<${selector}[^>]*>`, 'g');
    return acc || regex.test(content);
  }, false);

  return !plainText.trim() && !hasImages && !hasPlaceholders;
}

import { createSelector, Selector } from '@ngxs/store';
import {
  ConversationDetails,
  ConversationStateModel,
  Message,
  PendingMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { ConversationState } from '@conversations/conversation/state/conversation/conversation.state';
import { LabelsState } from '@conversations/workspaces/state/labels/labels.state';
import { Label, LabelsStateModel } from '@conversations/workspaces/state/labels/labels-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import {
  ExternalAccount,
  ExternalAccountStatus,
  Workspace,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';

export class ConversationSelectors {
  @Selector([ConversationState])
  static details(state: ConversationStateModel): ConversationDetails {
    return state.details;
  }

  @Selector([ConversationSelectors.details, LabelsState])
  static labels(details: ConversationDetails, labelsState: LabelsStateModel): Label[] {
    if (!details) return [];

    const { labelIds } = details;
    return labelsState.labels.filter((label) => labelIds.includes(label.id));
  }

  @Selector([ConversationState])
  static messages(state: ConversationStateModel): Message[] {
    return state.messages.data
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .map((message) => ({
        ...message,
        drafts: message.drafts.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()),
      }));
  }

  @Selector([ConversationState])
  static pendingMessages(state: ConversationStateModel): PendingMessage[] {
    const hasNextMessages = ConversationSelectors.hasNextMessages(state);
    return !hasNextMessages ? state.pendingMessages : [];
  }

  @Selector([ConversationState])
  static loadingStatus(state: ConversationStateModel): 'void' | 'loading' | 'loaded' | 'error' {
    return state.loadingStatus;
  }

  @Selector([ConversationState])
  static messagesLoadingStatus(
    state: ConversationStateModel,
  ): 'void' | 'loading' | 'loaded' | 'loading-prev' | 'loading-next' | 'error' {
    return state.messagesLoadingStatus;
  }

  @Selector([ConversationState])
  static hasPreviousMessages(state: ConversationStateModel): boolean {
    return !!state.messages.cursor.previous;
  }

  @Selector([ConversationState])
  static hasNextMessages(state: ConversationStateModel): boolean {
    return !!state.messages.cursor.next;
  }

  @Selector([ConversationSelectors.details, WorkspacesSelectors.externalAccounts])
  static account(details: ConversationDetails, externalAccounts: ExternalAccount[]): ExternalAccount | undefined {
    if (!details) return undefined;
    return externalAccounts.find((account) => account.id === details.externalAccountId);
  }

  @Selector([WorkspacesSelectors.externalAccounts, ConversationSelectors.details])
  static parentAccountIssue(
    externalAccounts: ExternalAccount[],
    details: ConversationDetails,
  ): 'failed' | 'expired' | 'disconnected' | undefined {
    const parentExternalAccount = externalAccounts.find((account) => account.id === details?.externalAccountId);
    if (!parentExternalAccount) return undefined;

    if ([ExternalAccountStatus.Failed].includes(parentExternalAccount.status)) return 'failed';
    if ([ExternalAccountStatus.Expired].includes(parentExternalAccount.status)) return 'expired';
    if ([ExternalAccountStatus.Disconnected].includes(parentExternalAccount.status)) return 'disconnected';

    return undefined;
  }

  static currentUserIsAssignee(currentUserId: string) {
    return createSelector(
      [ConversationSelectors.details, WorkspacesSelectors.privateWorkspace],
      (details: ConversationDetails, privateWorkspace: Workspace): boolean => {
        if (!details) return false;
        return details.assignment.assignee?.id === currentUserId || privateWorkspace.id === details.workspaceId;
      },
    );
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpService } from '@clover/core/services/http.service';
import { map, type Observable } from 'rxjs';

interface TaskTokenResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class TaskResultsService {
  private readonly http = inject(HttpService);

  getTaskShareToken(taskId: number): Observable<string> {
    return this.http.postV2<TaskTokenResponse>(`api/tasks/${taskId}/share`, {}).pipe(map((response) => response.token));
  }
}

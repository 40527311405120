import { Routes } from '@angular/router';
import { WorkspaceSettingsModalComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-modal.component';
import { WorkspaceSettingsInboxComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-inbox/workspace-settings-inbox.component';
import { WorkspaceSettingsLabelsComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-labels/workspace-settings-labels.component';
import { workspaceSettingsModalResolver } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-modal.resolver';
import { WorkspaceSettingsNotificationsComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-notifications/workspace-settings-notifications.component';
import { WorkspaceSettingsSettingsComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-settings/workspace-settings-settings.component';
import { WorkspaceSettingsMembersComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-members/workspace-settings-members.component';
import { WorkspaceSettingsSignaturesComponent } from './workspace-settings-signatures/workspace-settings-signatures.component';

export const WORKSPACE_SETTINGS_MODAL_OUTLET = 'settings';

export enum WorkspaceSettingsModalTab {
  Inbox = 'inbox',
  Labels = 'labels',
  Signatures = 'signatures',
  Members = 'members',
  Notifications = 'notifications',
  Settings = 'settings',
}

export const privateWorkspaceSettingsModalTabs: WorkspaceSettingsModalTab[] = [
  WorkspaceSettingsModalTab.Inbox,
  WorkspaceSettingsModalTab.Labels,
  WorkspaceSettingsModalTab.Signatures,
  WorkspaceSettingsModalTab.Notifications,
  WorkspaceSettingsModalTab.Settings,
];

export const teamWorkspaceSettingsModalTabs: WorkspaceSettingsModalTab[] = [
  WorkspaceSettingsModalTab.Inbox,
  WorkspaceSettingsModalTab.Labels,
  WorkspaceSettingsModalTab.Signatures,
  WorkspaceSettingsModalTab.Members,
  WorkspaceSettingsModalTab.Notifications,
  WorkspaceSettingsModalTab.Settings,
];

export const workspaceSettingsModalRoutes: Routes = [
  {
    path: '',
    component: WorkspaceSettingsModalComponent,
    resolve: {
      workspace: workspaceSettingsModalResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: WorkspaceSettingsModalTab.Inbox + '',
      },
      {
        path: WorkspaceSettingsModalTab.Inbox,
        component: WorkspaceSettingsInboxComponent,
      },
      {
        path: WorkspaceSettingsModalTab.Labels,
        component: WorkspaceSettingsLabelsComponent,
      },
      {
        path: WorkspaceSettingsModalTab.Signatures,
        component: WorkspaceSettingsSignaturesComponent,
      },
      {
        path: WorkspaceSettingsModalTab.Members,
        component: WorkspaceSettingsMembersComponent,
      },
      {
        path: WorkspaceSettingsModalTab.Notifications,
        component: WorkspaceSettingsNotificationsComponent,
      },
      {
        path: WorkspaceSettingsModalTab.Settings,
        component: WorkspaceSettingsSettingsComponent,
      },
    ],
  },
];

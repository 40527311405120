import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {
  categoriesWithExternalAccounts,
  categoriesWithLabels,
  categoriesWithStatus,
  ConversationsCategory,
  defaultCategory,
  defaultStatusCategorySegmentOption,
  externalAccountCategorySegmentParam,
  labelsCategorySegmentParam,
  StatusCategorySegmentOption,
  statusCategorySegmentParam,
  statusesCategorySegmentOptions,
  validateCategory,
} from '@conversations/conversations/categories';
import * as R from 'ramda';

export const canActivateCategory: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const { workspaceAlias } = route.parent.params;
  const [categorySegment] = route.url;

  const categoryValid = validateCategory(categorySegment.path as ConversationsCategory, workspaceAlias);
  if (!categoryValid) return createUrlTreeFromSnapshot(route, ['..', defaultCategory]);

  const label = categorySegment.parameters[labelsCategorySegmentParam] as string | undefined;
  const externalAccount = categorySegment.parameters[externalAccountCategorySegmentParam] as string | undefined;
  const status = categorySegment.parameters[statusCategorySegmentParam] as StatusCategorySegmentOption | undefined;

  const validatedLabel: string | undefined = validateLabelParam(categorySegment);
  const validatedExternalAccount: string | undefined = validateExternalAccountParam(categorySegment);
  const validatedStatus: StatusCategorySegmentOption | undefined = validateStatusParam(categorySegment);

  if (label !== validatedLabel || externalAccount !== validatedExternalAccount || status !== validatedStatus) {
    const segmentParams = {
      [labelsCategorySegmentParam]: validatedLabel,
      [externalAccountCategorySegmentParam]: validatedExternalAccount,
      [statusCategorySegmentParam]: validatedStatus,
    };

    // Remove undefined parameters
    const definedParams = R.pickBy((value) => value !== undefined, segmentParams);

    return createUrlTreeFromSnapshot(route, [definedParams]);
  }

  return true;
};

function validateLabelParam(categorySegment: UrlSegment): string | undefined {
  const category = categorySegment.path as ConversationsCategory;
  const label = categorySegment.parameters[labelsCategorySegmentParam] as string | undefined;
  const shouldHaveLabel = categoriesWithLabels.includes(category);

  return shouldHaveLabel ? label : undefined;
}

function validateExternalAccountParam(categorySegment: UrlSegment): string | undefined {
  const category = categorySegment.path as ConversationsCategory;
  const externalAccount = categorySegment.parameters[externalAccountCategorySegmentParam] as string | undefined;
  const shouldHaveExternalAccount = categoriesWithExternalAccounts.includes(category);

  return shouldHaveExternalAccount ? externalAccount : undefined;
}

function validateStatusParam(categorySegment: UrlSegment): StatusCategorySegmentOption | undefined {
  const category = categorySegment.path as ConversationsCategory;
  const status = categorySegment.parameters[statusCategorySegmentParam] as StatusCategorySegmentOption | undefined;
  const shouldHaveStatus = categoriesWithStatus.includes(category);

  if (shouldHaveStatus && !statusesCategorySegmentOptions.includes(status)) {
    return defaultStatusCategorySegmentOption;
  }

  if (!shouldHaveStatus && status) {
    return undefined;
  }

  return status;
}

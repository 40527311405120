@if (!contentEmpty) {
  <div
    [innerHtml]="sanitizedContent"
    class="message-body"
  ></div>
} @else {
  <div class="message-empty-placeholder">
    {{ 'conversations-v4.common.emptyMessage' | translate }}
  </div>
}

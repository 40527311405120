import { ChangeDetectionStrategy, Component, computed, effect, input, untracked } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { Editor } from '@tiptap/core';
import { createNotifier } from 'ngxtension/create-notifier';

@Component({
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorBaseControlComponent {
  editors = input.required<Editor[], Editor[]>({
    transform: (editors) => editors.filter(Boolean),
  });

  protected readonly activeEditor = computed(() => {
    this.focusNotifier.listen();

    const editors = this.editors();
    return editors.find((editor) => editor.isFocused) ?? editors[0];
  });

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  protected readonly focusNotifier = createNotifier();
  protected readonly selectionNotifier = createNotifier();

  constructor() {
    effect(() => {
      const editors = this.editors();

      untracked(() => {
        editors.forEach((editor) => {
          editor.on('focus', () => this.focusNotifier.notify());
          editor.on('update', () => this.selectionNotifier.notify());
          editor.on('selectionUpdate', () => this.selectionNotifier.notify());
        });
      });
    });
  }
}

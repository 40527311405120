import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Injector, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import type { SignaturePreview } from '@clover/conversations-v4/workspaces/state/signatures/signatures-state.model';
import {
  CreateSignature,
  DeleteSignature,
  UpdateSignature,
} from '@clover/conversations-v4/workspaces/state/signatures/signatures.actions';
import { SignaturesSelectors } from '@clover/conversations-v4/workspaces/state/signatures/signatures.selectors';
import { SignaturesService } from '@clover/conversations-v4/workspaces/state/signatures/signatures.service';
import type { Workspace } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.selectors';
import { UserRole } from '@clover/core/models/user';
import { UserService } from '@clover/core/services/user.service';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngxs/store';
import { NgScrollbar } from 'ngx-scrollbar';
import { map, switchMap, take, tap, type Subscription } from 'rxjs';
import {
  SignatureEditorModalComponent,
  type SignatureEditorDialogData,
  type SignatureEditorDialogResult,
} from '../../signature-editor-modal/signature-editor-modal.component';
import { WorkspaceSettingsSignaturesDefaultsComponent } from './workspace-settings-signatures-defaults/workspace-settings-signatures-defaults.component';
import { WorkspaceSettingsSignaturesTableComponent } from './workspace-settings-signatures-table/workspace-settings-signatures-table.component';

@Component({
  selector: 'cc-workspace-settings-signatures',
  standalone: true,
  imports: [
    NgScrollbar,
    TranslateModule,
    WorkspaceSettingsSignaturesTableComponent,
    WorkspaceSettingsSignaturesDefaultsComponent,
  ],
  templateUrl: './workspace-settings-signatures.component.html',
  styleUrl: './workspace-settings-signatures.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsSignaturesComponent {
  workspace = toSignal(inject(ActivatedRoute).parent.data.pipe(map((data) => data['workspace'] as Workspace)));
  externalAccounts = select(WorkspacesSelectors.externalAccountsByWorkspaceId(this.workspace().id));

  signatures = select(SignaturesSelectors.signaturesByWorkspaceId(this.workspace().id));

  defaultNewEmailSignature = select(SignaturesSelectors.defaultNewEmailSignatureByWorkspaceId(this.workspace().id));
  defaultReplyEmailSignature = select(SignaturesSelectors.defaultReplyEmailSignatureByWorkspaceId(this.workspace().id));

  private readonly editDialogRequested = signal<boolean>(false);

  protected readonly canEditSignatures = computed(() => {
    if (this.workspace().ownerType === 'user') return true;
    return this.isCompanyAdmin();
  });

  private readonly isCompanyAdmin = toSignal(
    inject(UserService).profile$.pipe(map((user) => user.roles?.includes(UserRole.companyAdmin))),
  );

  protected readonly affectedAccountsString = computed(() =>
    this.externalAccounts()
      .map((account) => account.email)
      .join(', '),
  );

  private readonly store = inject(Store);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);
  private readonly signaturesService = inject(SignaturesService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);

  createSignature(): void {
    const dialog = this.dialog.open<SignatureEditorDialogResult, SignatureEditorDialogData>(
      SignatureEditorModalComponent,
      {
        data: {
          mode: 'create',
          workspaceId: this.workspace().id,
        },
        disableClose: true,
      },
    );

    dialog.closed.pipe(take(1)).subscribe((resultSignature: SignatureEditorDialogResult | undefined) => {
      if (!resultSignature) return;
      this.store.dispatch(new CreateSignature(this.workspace().id, resultSignature));
    });
  }

  editSignature(signaturePreview: SignaturePreview): Subscription {
    if (this.editDialogRequested()) return;
    this.editDialogRequested.set(true);

    return this.signaturesService
      .getWorkspaceSignature(signaturePreview.workspaceId, signaturePreview.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((signature) => {
          return this.dialog.open<SignatureEditorDialogResult, SignatureEditorDialogData>(
            SignatureEditorModalComponent,
            {
              data: {
                mode: 'edit',
                workspaceId: signaturePreview.workspaceId,
                predefinedValues: signature,
              },
              disableClose: true,
            },
          );
        }),
        tap(() => this.editDialogRequested.set(false)),
        switchMap((dialog) => dialog.closed.pipe(take(1))),
        tap((resultSignature: SignatureEditorDialogResult | undefined) => {
          if (!resultSignature) return;
          this.store.dispatch(new UpdateSignature(this.workspace().id, signaturePreview.id, resultSignature));
        }),
      )
      .subscribe();
  }

  async deleteSignature(signature: SignaturePreview): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: this.translate.instant('conversations-v4.workspaces.settings.signatures.deletePrompt.title'),
        description: this.translate.instant('conversations-v4.workspaces.settings.signatures.deletePrompt.message'),
        confirmActionText: this.translate.instant(
          'conversations-v4.workspaces.settings.signatures.deletePrompt.deleteSignature',
        ),
        cancelActionText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.store.dispatch(new DeleteSignature(signature.workspaceId, signature.id));
  }
}

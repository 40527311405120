import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import {
  DraftAttachment,
  MessageAttachment,
} from '@conversations/conversation/state/conversation/conversation-state.model';

import { CoreModule } from '@core/core.module';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { FormatBytesPipePipe } from '@core/pipes/format-bytes.pipe';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { AttachmentsService } from '@conversations/conversation/state/conversation/attachments.service';
import { MessageCollapsibleGroupComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-collapsible-group/message-collapsible-group.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-message-attachments',
  standalone: true,
  templateUrl: './message-attachments.component.html',
  styleUrls: ['./message-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MessageCollapsibleGroupComponent,
    CoreModule,
    ButtonComponent,
    FormatBytesPipePipe,
    TooltipDirective,
    TranslateModule,
  ],
})
export class MessageAttachmentsComponent {
  @Input()
  conversationId: string | undefined;

  @Input()
  messageOrDraftId: string | number;

  @Input()
  draftId: number | undefined;

  @Input()
  attachments: (MessageAttachment | DraftAttachment)[] = [];

  @Input()
  mode: 'message' | 'draft' | 'pending' = 'message';

  collapsed = true;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  private readonly attachmentsService = inject(AttachmentsService);

  downloadAll(): void {
    if (this.mode === 'message')
      this.attachmentsService.downloadAllAttachments(this.conversationId, this.messageOrDraftId as string);

    if (['draft', 'pending'].includes(this.mode))
      this.attachmentsService.downloadAllDraftAttachments(this.messageOrDraftId as number);
  }

  downloadAttachment(attachment: MessageAttachment | DraftAttachment): void {
    if (this.mode === 'message')
      this.attachmentsService.downloadAttachment(
        this.conversationId,
        this.messageOrDraftId as string,
        attachment.id as string,
        attachment.name,
      );

    if (['draft', 'pending'].includes(this.mode))
      this.attachmentsService.downloadDraftAttachment(
        this.messageOrDraftId as number,
        attachment.id as number,
        attachment.name,
      );
  }
}

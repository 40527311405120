import { Injectable } from '@angular/core';
import OpenAI from 'openai';
import { BehaviorSubject, type Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import type { AssistantMessage, AssistantThread, AssistantThreadContext } from './assistant.entities';
import { createConversationContextMessage } from './context-helpers/conversation-context.helpers';

const PROJECT_ID =
  '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';

const ASSISTANT_ID = 'asst_6m1X7dSL3k8DdYdciGPvDWVf';

export function createAssistantMessage(text: string, sender: 'user' | 'assistant'): AssistantMessage {
  return {
    id: uuid(),
    sender,
    text,
    timestamp: new Date().toISOString(),
    finished: sender === 'user',
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssistantService {
  client: OpenAI;

  constructor() {
    this.client = new OpenAI({
      apiKey: PROJECT_ID,
      dangerouslyAllowBrowser: true,
    });
  }

  async createThreadFromMessage(
    userMessage: string,
    context: AssistantThreadContext | undefined,
  ): Promise<AssistantThread> {
    const messages = [];

    if (context)
      messages.push({
        role: 'user',
        content: this.createAdditionalIntrustions(context),
      });

    messages.push({
      role: 'user',
      content: userMessage,
    });

    const thread = await this.client.beta.threads.create({ messages });

    return {
      id: thread.id,
      metadata: {
        relatedConversationId: context ? context.id : undefined,
      },
      messages: [createAssistantMessage(userMessage, 'user')],
      assistantState: 'busy',
    };
  }

  async sendUserMessage(threadId: string, message: string): Promise<AssistantMessage> {
    await this.client.beta.threads.messages.create(threadId, {
      role: 'user',
      content: message,
    });

    return {
      id: uuid(),
      sender: 'user',
      text: message,
      timestamp: new Date().toISOString(),
      finished: true,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  runThread(threadId: string, context: AssistantThreadContext | undefined): Observable<AssistantMessage> {
    const message: AssistantMessage = {
      id: uuid(),
      sender: 'assistant',
      text: '',
      timestamp: new Date().toISOString(),
      finished: false,
    };

    const message$ = new BehaviorSubject<AssistantMessage>(message);

    const run = this.client.beta.threads.runs.stream(threadId, {
      assistant_id: ASSISTANT_ID,
      // additional_instructions: context ? this.createAdditionalIntrustions(context) : undefined,
    });

    run.on('textCreated', (text) => {
      message$.next({
        ...message$.value,
        text: this.formatResponseText(text.value),
      });
    });

    run.on('textDelta', (_delta, snapshot) => {
      message$.next({
        ...message$.value,
        text: this.formatResponseText(snapshot.value),
      });
    });

    run.on('messageDone', () => {
      message$.next({
        ...message$.value,
        finished: true,
      });

      message$.complete();
    });

    return message$.asObservable();
  }

  private createAdditionalIntrustions(context: AssistantThreadContext): string {
    switch (context.contextType) {
      case 'conversation':
        return createConversationContextMessage(context);
    }
  }

  private formatResponseText(text: string): string {
    // Removing the 【...】 source text from the response
    return text.replace(/【.*?】/g, '');
  }
}

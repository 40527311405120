@switch (placeholderType) {
  @case ('firstName') {
    {{ 'richTextEditor.actions.dynamicValues.personalInformation.firstName' | translate }}
  }
  @case ('lastName') {
    {{ 'richTextEditor.actions.dynamicValues.personalInformation.lastName' | translate }}
  }
  @case ('jobTitle') {
    {{ 'richTextEditor.actions.dynamicValues.jobInformation.jobTitle' | translate }}
  }
  @case ('companyName') {
    {{ 'richTextEditor.actions.dynamicValues.jobInformation.companyName' | translate }}
  }
  @case ('workEmail') {
    {{ 'richTextEditor.actions.dynamicValues.contactInformation.workEmail' | translate }}
  }
  @case ('workPhoneNumber') {
    {{ 'richTextEditor.actions.dynamicValues.contactInformation.workPhoneNumber' | translate }}
  }
}

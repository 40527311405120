<div class="message-collapsible-group">
  <div class="message-collapsible-group__header">
    <app-button
      (click)="toggle()"
      [ccTooltip]="collapsed ? ('common.buttons.expand' | translate) : ('common.buttons.collapse' | translate)"
      [class.message-collapsible-group__header-toggle--collapsed]="collapsed"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
      class="message-collapsible-group__header-toggle"
    >
      <i
        class="icon-down-small-fill"
        slot="ghost-icon"
      ></i>
    </app-button>

    <div class="message-collapsible-group__header-title">
      <ng-content select="[slot='title']" />
    </div>

    <div class="message-collapsible-group__header-divider">–</div>

    <div class="message-collapsible-group__header-actions">
      <ng-content select="[slot='action']" />
    </div>
  </div>

  <div
    [@expandCollapse]="animationState"
    [class.message-collapsible-group__content--collapsed]="collapsed"
    class="message-collapsible-group__content"
  >
    <ng-content />
  </div>
</div>

<!-- TODO (Oleksandr D.): Fix an issue when container under scrollbar becomes a few pixels wider after resizing the screen, so the scrolling enables -->
<ng-scrollbar
  cdkScrollable
  class="conversation"
  orientation="horizontal"
>
  <div
    ccAutoAnimate
    class="conversation__content"
  >
    @if (loadingStatus$ | async; as loadingStatus) {
      @if (loadingStatus === 'loaded') {
        <cc-active-conversation class="conversation__content-conversation" />
      } @else {
        <cc-conversation-placeholder
          [status]="loadingStatus"
          class="conversation__content-placeholder"
        />
      }
    }
  </div>
</ng-scrollbar>

import { Action, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import {
  defaultSignaturesState,
  SignaturesStateModel,
} from '@conversations/workspaces/state/signatures/signatures-state.model';
import { SignaturesService } from '@conversations/workspaces/state/signatures/signatures.service';
import {
  CreateSignature,
  DeleteSignature,
  LoadSignatures,
  SetSignaturesDefaults,
  UpdateSignature,
} from '@conversations/workspaces/state/signatures/signatures.actions';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { upsertItem } from '@core/helpers/custom-state-operators';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';
import { TranslateService } from '@ngx-translate/core';

@State<SignaturesStateModel>({
  name: 'signatures',
  defaults: defaultSignaturesState,
})
@Injectable()
export class SignaturesState {
  private readonly signaturesService = inject(SignaturesService);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  @Action(LoadSignatures)
  loadSignatures(ctx: StateContext<SignaturesStateModel>, { workspaceIds }: LoadSignatures): Observable<void> {
    ctx.patchState({ loadingStatus: 'loading' });

    const loadSignatures = () =>
      forkJoin(workspaceIds.map((workspaceId) => this.signaturesService.getWorkspaceSignatures(workspaceId))).pipe(
        tap((workspacesSignatures) => {
          ctx.patchState({ signatures: R.flatten(workspacesSignatures) });
        }),
      );

    const loadDefaults = () =>
      forkJoin(workspaceIds.map((workspaceId) => this.signaturesService.getSignatureDefaults(workspaceId))).pipe(
        tap((workspacesDefaults) => {
          ctx.patchState({ defaults: workspacesDefaults });
        }),
      );

    return forkJoin([loadSignatures(), loadDefaults()])
      .pipe(map(() => undefined))
      .pipe(
        tap(() => {
          ctx.patchState({ loadingStatus: 'loaded' });
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of();
        }),
      );
  }

  @Action(CreateSignature)
  createSignature(
    ctx: StateContext<SignaturesStateModel>,
    { workspaceId, signature }: CreateSignature,
  ): Observable<void> {
    return this.signaturesService.createSignature(workspaceId, signature).pipe(
      tap((createdSignature) => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.created'),
          ToastType.Success,
        );

        ctx.setState(
          patch<SignaturesStateModel>({
            signatures: insertItem(createdSignature),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.failedToCreate'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(UpdateSignature)
  updateSignature(
    ctx: StateContext<SignaturesStateModel>,
    { workspaceId, signatureId, signature }: UpdateSignature,
  ): Observable<void> {
    return this.signaturesService.updateSignature(workspaceId, signatureId, signature).pipe(
      tap((updatedSignature) => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.updated'),
          ToastType.Success,
        );

        ctx.setState(
          patch<SignaturesStateModel>({
            signatures: upsertItem((signature) => signature.id === updatedSignature.id, updatedSignature),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.failedToUpdate'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(DeleteSignature)
  deleteSignature(
    ctx: StateContext<SignaturesStateModel>,
    { workspaceId, signatureId }: DeleteSignature,
  ): Observable<void> {
    return this.signaturesService.deleteSignature(workspaceId, signatureId).pipe(
      tap(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.deleted'),
          ToastType.Success,
        );

        ctx.setState(
          patch<SignaturesStateModel>({
            signatures: removeItem((signature) => signature.id === signatureId),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.failedToDelete'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(SetSignaturesDefaults)
  setSignaturesDefaults(
    ctx: StateContext<SignaturesStateModel>,
    { workspaceId, defaults }: SetSignaturesDefaults,
  ): Observable<void> {
    return this.signaturesService.updateSignatureDefaults(workspaceId, defaults).pipe(
      tap(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.defaultsSet'),
          ToastType.Success,
        );

        ctx.setState(
          patch<SignaturesStateModel>({
            defaults: updateItem((defaults) => defaults.workspaceId === workspaceId, { workspaceId, ...defaults }),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.workspaces.settings.signatures.toasts.failedToSetDefaults'),
          ToastType.Error,
        );
        return of();
      }),
      map(() => undefined),
    );
  }
}

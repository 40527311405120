<ng-scrollbar cdkScrollable>
  <div class="workspace-settings-inbox">
    <div class="workspace-settings-inbox__header">
      <div class="workspace-settings-inbox__header-title">
        {{ 'conversations-v4.workspaces.settings.accounts.subtitle' | translate }}
      </div>

      <div class="workspace-settings-inbox__header-description">
        @if (workspace.ownerType === 'user') {
          {{ 'conversations-v4.workspaces.settings.accounts.messagePrivateWorkspace' | translate }}
        } @else {
          {{ 'conversations-v4.workspaces.settings.accounts.messageTeamWorkspace' | translate }}
        }
      </div>
    </div>

    <div class="workspace-settings-inbox__list">
      <div class="workspace-settings-inbox__list-header">
        <app-textbox
          [formControl]="searchFormControl"
          [placeholder]="'conversations-v4.workspaces.settings.accounts.searchPlaceholder' | translate"
          [type]="TextboxType.Text"
          class="workspace-settings-inbox__list-header-search"
          icon="icon-search"
          width="244px"
        ></app-textbox>

        <div #addChannelDropdownTrigger>
          <app-button
            (click)="addChannelDropdownVisible = !addChannelDropdownVisible"
            [disabled]="(canConnectNewAccount$ | async) === false"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Primary"
            class="workspace-settings-inbox__list-header-add-channel"
          >
            {{ 'conversations-v4.workspaces.settings.accounts.addChannel' | translate }}
          </app-button>
        </div>
      </div>

      <div class="workspace-settings-inbox__list-table">
        <div class="workspace-settings-inbox__list-table-header">
          <div class="workspace-settings-inbox__list-table-header-item">
            {{ 'conversations-v4.workspaces.settings.accounts.channel' | translate }}
          </div>
          <div class="workspace-settings-inbox__list-table-header-item">
            {{ 'conversations-v4.workspaces.settings.accounts.status' | translate }}
          </div>
        </div>

        <div class="workspace-settings-inbox__list-table-rows">
          @if (externalAccounts$ | async; as accounts) {
            @for (account of accounts; track account.id) {
              <cc-external-account-table-row
                (reconnect)="connectAccount($event, undefined)"
                (remove)="removeAccount($event)"
                [account]="account"
              ></cc-external-account-table-row>
            }

            @if (!accounts.length) {
              <div class="workspace-settings-inbox__list-table-rows-empty">
                {{ 'conversations-v4.workspaces.settings.accounts.noAccountsPlaceholder' | translate }}
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
</ng-scrollbar>

<ng-template
  (detach)="addChannelDropdownVisible = false"
  (overlayOutsideClick)="
    addChannelDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, addChannelDropdownTrigger)
  "
  [cdkConnectedOverlayOpen]="addChannelDropdownVisible"
  [cdkConnectedOverlayOrigin]="addChannelDropdownTrigger"
  [cdkConnectedOverlayPositions]="addChannelDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="fit-content">
    <div class="workspace-settings-inbox__list-header-add-channel-connect">
      <cc-dropdown-text class="workspace-settings-inbox__list-header-add-channel-connect-title">
        <ng-container slot="title">
          {{ 'conversations-v4.workspaces.settings.accounts.providers.google.account' | translate }}
        </ng-container>
      </cc-dropdown-text>

      <cc-google-button
        (click)="connectAccount(undefined, ProviderType.Google); addChannelDropdownVisible = false"
        [text]="GoogleButtonText.SignInWithGoogle"
        class="workspace-settings-inbox__list-header-add-channel-connect-button"
      ></cc-google-button>

      <cc-dropdown-text class="workspace-settings-inbox__list-header-add-channel-connect-title">
        <ng-container slot="title">
          {{ 'conversations-v4.workspaces.settings.accounts.providers.microsoft.account' | translate }}
        </ng-container>
      </cc-dropdown-text>

      <cc-microsoft-button
        (click)="connectAccount(undefined, ProviderType.Microsoft); addChannelDropdownVisible = false"
        [text]="MicrosoftButtonText.SignInWithMicrosoft"
        class="workspace-settings-inbox__list-header-add-channel-connect-button"
      >
      </cc-microsoft-button>
    </div>
  </cc-dropdown>
</ng-template>

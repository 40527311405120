import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { Store } from '@ngxs/store';
import { ConversationsSelectors } from '@conversations/conversations/state/conversations/conversations.selectors';
import {
  ConversationListFilterParams,
  ConversationsListComponent,
} from '@conversations/conversations/conversations-list/conversations-list.component';
import { filter, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ConversationsCategory } from '@conversations/conversations/categories';
import { Navigate } from '@ngxs/router-plugin';
import { RouterSelectors, RouterStateParams } from '@clover/custom-router-state-serializer';
import { NzResizableModule, NzResizeEvent } from 'ng-zorro-antd/resizable';
import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';
import { CoreModule } from '@core/core.module';
import { LocalStorageService } from '@core/services/persistance.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ConversationsResolverParams } from '@conversations/conversations/conversations.resolver';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { LoadConversations } from '@conversations/conversations/state/conversations/conversations.actions';
import * as R from 'ramda';

export const conversationsListMinWidth = 280;
const conversationMinWidth = 480;
const conversationListWidthStorageKey = 'conversations.conversationListWidth';

@UntilDestroy()
@Component({
  selector: 'cc-conversations',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    RouterLinkWithHref,
    ConversationsListComponent,
    NzResizableModule,
    CoreModule,
    CdkScrollableModule,
    NgScrollbarModule,
    ResizeDirective,
  ],
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsComponent implements OnInit {
  conversations$ = inject(Store).select(ConversationsSelectors.conversations);
  loadingStatus$ = inject(Store).select(ConversationsSelectors.loadingStatus);

  resolvedParams$: Observable<ConversationsResolverParams>;

  workspaceId$: Observable<number | undefined>;
  category$: Observable<ConversationsCategory>;
  filterParams$: Observable<ConversationListFilterParams>;

  hasActiveConversation$: Observable<boolean>;

  protected conversationsListWidth = conversationsListMinWidth;
  protected conversationListMaxWidth: number | undefined;
  protected compactMode = false;

  protected readonly conversationsListMinWidth = conversationsListMinWidth;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly store = inject(Store);
  private readonly storage = inject(LocalStorageService);

  ngOnInit(): void {
    this.resolvedParams$ = this.activatedRoute.data.pipe(map((data) => data['params'] as ConversationsResolverParams));

    this.workspaceId$ = this.resolvedParams$.pipe(map((params) => params.workspaceId));
    this.category$ = this.resolvedParams$.pipe(map((params) => params.categoryId));
    this.filterParams$ = this.resolvedParams$.pipe(map((params) => params.filterParams));

    this.hasActiveConversation$ = this.store.select(RouterSelectors.state).pipe(
      map(({ params }: RouterStateParams) => {
        return Object.hasOwn(params, 'conversationId');
      }),
    );

    this.initConversationsLoading();
    this.initConversationPreselection();

    this.conversationsListWidth =
      Number(this.storage.get(conversationListWidthStorageKey)) || conversationsListMinWidth;
  }

  resizeConversationList({ width }: NzResizeEvent): void {
    this.conversationsListWidth = width;
    this.storage.set(conversationListWidthStorageKey, width);
  }

  handleContainerResize({ newRect: { width } }: ResizeEvent): void {
    this.compactMode = width <= conversationsListMinWidth + conversationMinWidth;
    this.conversationListMaxWidth = width - conversationMinWidth - 1;
    this.conversationsListWidth = Math.max(
      Math.min(this.conversationsListWidth, this.conversationListMaxWidth),
      conversationsListMinWidth - 1,
    );
    this.storage.set(conversationListWidthStorageKey, this.conversationsListWidth);
  }

  resetConversationsListWidth(): void {
    this.conversationsListWidth = conversationsListMinWidth;
    this.storage.set(conversationListWidthStorageKey, conversationsListMinWidth);
  }

  private initConversationsLoading(): void {
    this.resolvedParams$
      .pipe(
        untilDestroyed(this),
        switchMap((params) => {
          const { workspaceId } = params;
          return this.store
            .select(WorkspacesSelectors.workspaceSyncingExternalAccounts(workspaceId))
            .pipe(map((syncingAccounts) => ({ params, hasSyncingExternalAccounts: syncingAccounts.length > 0 })));
        }),
        distinctUntilChanged((a, b) => R.equals(a, b)),
        switchMap(({ params, hasSyncingExternalAccounts }) => {
          if (hasSyncingExternalAccounts) {
            this.deselectActiveConversation();
            return of();
          }

          const { workspaceId, categoryId, filterParams } = params;
          return this.store.dispatch(new LoadConversations(workspaceId, categoryId, filterParams));
        }),
      )
      .subscribe();
  }

  private initConversationPreselection(): void {
    this.loadingStatus$
      .pipe(
        untilDestroyed(this),
        filter((status) => status === 'loaded'),
        map(() => !this.hasActiveConversation() && !this.compactMode),
        tap((preselectConversation) => {
          if (!preselectConversation) return;

          const firstConversation = this.store.selectSnapshot(ConversationsSelectors.conversations)?.[0];
          if (!firstConversation) return;

          this.store.dispatch(
            new Navigate(
              [firstConversation.id],
              {},
              {
                relativeTo: this.activatedRoute,
                replaceUrl: true,
              },
            ),
          );
        }),
      )
      .subscribe();
  }

  private deselectActiveConversation(): void {
    if (!this.hasActiveConversation()) return;

    this.store.dispatch(
      new Navigate(
        ['..'],
        {},
        {
          relativeTo: this.activatedRoute.firstChild,
          replaceUrl: true,
        },
      ),
    );
  }

  private hasActiveConversation(): boolean {
    return Object.hasOwn(this.store.selectSnapshot(RouterSelectors.state).params, 'conversationId');
  }
}

import { inject, Injectable } from '@angular/core';
import type { BPIInvoice, BPIInvoiceStatus, BPIOrder, BPIOrderStatus } from './business-object';
import type { Content } from '@tiptap/core';
import { DateService } from '@clover/core/services/date.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessObjectService {
  private readonly dateService = inject(DateService);

  generateInvoiceTable(invoice: BPIInvoice): Content {
    const statusMap: Record<BPIInvoiceStatus, string> = {
      paid: 'Paid',
      disputed: 'Disputed',
    };

    return `<table>
    <tr>
      <th colspan="2"><strong>Invoice</strong></th>
    </tr>
    <tr>
      <th>Invoice Number:</th>
      <td>${invoice.invoiceNumber}</td>
    </tr>
    <tr>
      <th>Invoice Date:</th>
      <td>${this.dateService.format(invoice.invoiceDate, { date: 'long' })}</td>
    </tr>
    <tr>
      <th>PO Number:</th>
      <td>${invoice.poNumber}</td>
    </tr>
    <tr>
      <th>PO Date:</th>
      <td>${this.dateService.format(invoice.poDate, { date: 'long' })}</td>
    </tr>
    <tr>
      <th>Supplier:</th>
      <td>${invoice.supplier}<br>${invoice.supplierAddress}</td>
    </tr>
    <tr>
      <th>Retailer:</th>
      <td>${invoice.retailer}<br>${invoice.retailerAddress}</td>
    </tr>
    <tr>
      <th>Payment Terms:</th>
      <td>${invoice.paymentTerms}</td>
    </tr>
    <tr>
      <th>Due Date:</th>
      <td>${this.dateService.format(invoice.dueDate, { date: 'long' })}</td>
    </tr>
    <tr>
      <th>Amount:</th>
      <td>${this.formatCurrency(invoice.totalAmountDue)}</td>
    </tr>
    <tr>
      <th>Invoice Status:</th>
      <td>${statusMap[invoice.status]}</td>
    </tr>
  </table>`;
  }

  generateOrderTable(order: BPIOrder): Content {
    const statusMap: Record<BPIOrderStatus, string> = {
      'partially-invoiced': 'Partially Invoiced',
    };

    return `<table>
    <tr>
      <th colspan="2"><strong>Purchase Order</strong></th>
    </tr>
    <tr>
      <th>PO Number:</td>
      <td>${order.poNumber}</td>
    </tr>
    <tr>
      <th>PO Date:</th>
      <td>${this.dateService.format(order.poDate, { date: 'long' })}</td>
    </tr>
    <tr>
      <th>Supplier:</th>
      <td>${order.supplier}<br>${order.supplierAddress}</td>
    </tr>
    <tr>
      <th>Retailer:</th>
      <td>${order.retailer}<br>${order.retailerAddress}</td>
    </tr>
    <tr>
      <th>Shipping Address:</th>
      <td>${order.retailer}<br>${order.shippingAddress}</td>
    </tr>
    <tr>
      <th>Payment Terms:</th>
      <td>${order.paymentTerms}</td>
    </tr>
    <tr>
      <th>Requested Delivery Date:</th>
      <td>${this.dateService.format(order.requestedDeliveryDate, { date: 'long' })}</td>
    </tr>
    <tr>
      <th>Total PO Amount:</th>
      <td>${this.formatCurrency(order.totalPOAmount)}</td>
    </tr>
    <tr>
      <th>PO Status:</th>
      <td>${statusMap[order.status]}</td>
    </tr>
  </table>`;
  }

  private formatCurrency(amount: number): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount);
  }
}

import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { ConversationDetails } from '@conversations/conversation/state/conversation/conversation-state.model';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { LabelChipComponent } from '@design/chips/label-chip/label-chip.component';
import { Store } from '@ngxs/store';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { Navigate } from '@ngxs/router-plugin';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { ConversationsCategory, labelsCategorySegmentParam } from '@conversations/conversations/categories';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-conversation-header-details',
  standalone: true,
  imports: [LabelChipComponent, TooltipDirective, TranslateModule],
  templateUrl: './conversation-header-details.component.html',
  styleUrls: ['./conversation-header-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationHeaderDetailsComponent {
  @Input()
  details: ConversationDetails;

  @Input()
  assignee: boolean;

  @Input()
  accountIssue: 'failed' | 'expired' | 'disconnected' | undefined;

  @Input()
  labels: Label[];

  protected systemLabelsVisible = false;
  protected readonly TooltipPosition = TooltipPosition;

  private readonly store = inject(Store);
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);

  get subjectEmpty(): boolean {
    return !this.details.subject?.trim();
  }

  get nonSystemLabels(): Label[] {
    return this.labels.filter((label) => !label.system);
  }

  get systemLabels(): Label[] {
    return this.labels.filter((label) => label.system);
  }

  navigateToLabel(label: Label): void {
    const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(label.workspaceId));

    this.store.dispatch(
      new Navigate([
        '/',
        CONVERSATIONS_BASE_URL,
        workspaceAlias,
        ConversationsCategory.Inbox,
        { [labelsCategorySegmentParam]: label.id.toString() },
      ]),
    );
  }

  removeLabel(label: Label): void {
    this.conversationActionsService.unassignLabel(this.details.id, label.id);
  }
}

<div class="markdown-editor">
  <div class="markdown-editor__header">
    <div class="markdown-editor__header-mode">
      <div class="segment">
        <button
          class="segment__button"
          [class.segment__button--active]="mode === MarkdownEditorMode.Edit"
          (click)="setEditorMode(MarkdownEditorMode.Edit)"
        >
          Edit
        </button>

        <button
          class="segment__button"
          [class.segment__button--active]="mode === MarkdownEditorMode.Preview"
          (click)="setEditorMode(MarkdownEditorMode.Preview)"
        >
          Preview
        </button>
      </div>
    </div>

    <div class="markdown-editor__header-formatting-actions">
      <app-button
        ccTooltip="Bold"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Bold)"
        [active]="formatMarks.get(MarkdownFormat.Bold) && !formattingButtonsDisabled"
      >
        <i
          class="icon-bold"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Italic"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Italic)"
        [active]="formatMarks.get(MarkdownFormat.Italic) && !formattingButtonsDisabled"
      >
        <i
          class="icon-italic"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Strikethrough"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Strikethrough)"
        [active]="formatMarks.get(MarkdownFormat.Strikethrough) && !formattingButtonsDisabled"
      >
        <i
          class="icon-strikethrough"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Quote"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Quote)"
        [active]="formatMarks.get(MarkdownFormat.Quote) && !formattingButtonsDisabled"
      >
        <i
          class="icon-quote"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Code"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Code)"
        [active]="formatMarks.get(MarkdownFormat.Code) && !formattingButtonsDisabled"
      >
        <i
          class="icon-code"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Link"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.Link)"
        [active]="formatMarks.get(MarkdownFormat.Link) && !formattingButtonsDisabled"
      >
        <i
          class="icon-link"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Unordered list"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.UnorderedList)"
        [active]="formatMarks.get(MarkdownFormat.UnorderedList) && !formattingButtonsDisabled"
      >
        <i
          class="icon-unordered-list"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Ordered list"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.OrderedList)"
        [active]="formatMarks.get(MarkdownFormat.OrderedList) && !formattingButtonsDisabled"
      >
        <i
          class="icon-ordered-list"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="Todo list"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
        (click)="handleFormatButtonClick(MarkdownFormat.TodoList)"
        [active]="formatMarks.get(MarkdownFormat.TodoList) && !formattingButtonsDisabled"
      >
        <i
          class="icon-todo-list"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        ccTooltip="More actions"
        [tooltipDisabled]="formattingButtonsDisabled"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        [disabled]="formattingButtonsDisabled"
      >
        <i
          class="icon-more"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  @if (mode === MarkdownEditorMode.Edit) {
    <textarea
      autofocus
      #textarea
      spellcheck="false"
      class="markdown-editor__editor"
      [formControl]="markdownEditorFormControl"
      placeholder="Write your markdown here..."
    >
    </textarea>
  }

  @if (mode === MarkdownEditorMode.Preview) {
    <div class="markdown-editor__preview">
      <markdown
        (click)="interceptMarkdownLinkClick($event)"
        [data]="markdownContent"
        class="markdown-editor__preview-markdown"
      >
      </markdown>
    </div>
  }
</div>

import { Component, inject, Injector, Input, type OnInit } from '@angular/core';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { DialogModule } from '@angular/cdk/dialog';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { MarkdownEditorComponent } from '@network/company/company-wiki/company-wiki-document-editor/markdown-editor/markdown-editor.component';
import { LeaveEditMode, SaveDocument, UpdateDocument } from '@network/company/state/wiki/wiki.actions';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'cc-company-wiki-document-editor',
  standalone: true,
  templateUrl: './company-wiki-document-editor.component.html',
  styleUrls: ['./company-wiki-document-editor.component.scss'],
  imports: [
    ReactiveFormsModule,
    MarkdownEditorComponent,
    ButtonComponent,
    TextboxComponent,
    TooltipDirective,
    DialogModule,
  ],
})
export class ICompanyWikiDocumentEditorComponent implements OnInit {
  @Input()
  document: ICompanyWikiDocument;

  documentTitleFormControl = new FormControl<string>('');

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly store = inject(Store);
  private readonly injector = inject(Injector);

  ngOnInit(): void {
    this.documentTitleFormControl.setValue(this.document.name, { emitEvent: false });

    this.documentTitleFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((title) => {
      this.store.dispatch(new UpdateDocument({ name: title }));
    });
  }

  async leaveEditMode(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Leave edit mode?',
        description: 'All unsaved changes will be lost.',
        confirmActionText: 'Discard changes',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.store.dispatch(new LeaveEditMode());
  }

  saveDocument(): void {
    this.store.dispatch(new SaveDocument());
  }
}

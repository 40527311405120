import { Component, Input } from '@angular/core';

import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { CompanyWikiTreeViewItemComponent } from '@network/company/company-wiki/company-wiki-file-explorer/company-wiki-tree-view/company-wiki-tree-view-item/company-wiki-tree-view-item.component';

@Component({
  selector: 'cc-company-wiki-tree-view',
  standalone: true,
  imports: [CompanyWikiTreeViewItemComponent],
  templateUrl: './company-wiki-tree-view.component.html',
  styleUrls: ['./company-wiki-tree-view.component.scss'],
})
export class CompanyWikiTreeViewComponent {
  @Input()
  documents: ICompanyWikiDocument[] = [];
}

import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { Observable } from 'rxjs';
import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { Store } from '@ngxs/store';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { map } from 'rxjs/operators';
import { ProgressBarComponent } from '@design/misc/progress-bar/progress-bar.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { CdkConnectedOverlay, ConnectionPositionPair } from '@angular/cdk/overlay';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { Router } from '@angular/router';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { WORKSPACE_SETTINGS_MODAL_OUTLET } from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-accounts-sync-notice',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    TooltipDirective,
    ProgressBarComponent,
    DropdownComponent,
    DropdownTextComponent,
    DropdownActionComponent,
    CdkConnectedOverlay,
    TranslateModule,
  ],
  templateUrl: './accounts-sync-notice.component.html',
  styleUrl: './accounts-sync-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountsSyncNoticeComponent implements OnInit {
  syncingAccounts$: Observable<ExternalAccount[] | undefined>;
  syncingAccountsCount$: Observable<number>;

  protected dropdownVisible = false;

  protected readonly dropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -6,
    },
  ];
  protected readonly ExternalAccountStatus = ExternalAccountStatus;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly store = inject(Store);
  private readonly router = inject(Router);

  ngOnInit(): void {
    this.syncingAccounts$ = this.store.select(WorkspacesSelectors.syncingExternalAccounts).pipe(
      map((externalAccounts) => {
        if (!externalAccounts?.length) return undefined;
        return externalAccounts;
      }),
    );
    this.syncingAccountsCount$ = this.syncingAccounts$.pipe(map((accounts) => accounts?.length || 0));
  }

  async navigateToWorkspaceSettings(account: ExternalAccount): Promise<void> {
    const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(account.workspaceId));
    await this.router.navigate([
      `/${CONVERSATIONS_BASE_URL}`,
      { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias] } },
    ]);
  }
}

<div class="confirm-account-removal-modal">
  <div class="confirm-account-removal-modal__content">
    <div class="confirm-account-removal-modal__content-title">
      {{ 'conversations-v4.workspaces.dialogs.confirmAccountRemoval.title' | translate }}
    </div>

    <div class="confirm-account-removal-modal__content-description">
      {{
        'conversations-v4.workspaces.dialogs.confirmAccountRemoval.message'
          | translate: { email: externalAccount.email }
      }}
      <br />
      <strong>
        {{ 'conversations-v4.workspaces.dialogs.confirmAccountRemoval.warning' | translate }}
      </strong>
    </div>

    <app-textbox
      [formControl]="emailFormControl"
      [label]="'conversations-v4.workspaces.dialogs.confirmAccountRemoval.inputLabel' | translate"
      [placeholder]="externalAccount.email"
      class="confirm-account-removal-modal__content-input"
      width="100%"
    >
    </app-textbox>
  </div>

  <div class="confirm-account-removal-modal__footer">
    <app-button
      (click)="cancelRemoval()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="removeAccount()"
      [disabled]="!canRemove"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Danger"
    >
      {{ 'conversations-v4.workspaces.dialogs.confirmAccountRemoval.disconnect' | translate }}
    </app-button>
  </div>
</div>

import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, signal, type OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TaskAssignmentAudienceService,
  type TaskAudienceDepartmentsSortingProperty,
} from '@clover/conversations-v4/tasks/task-assignment/audience.service';
import type { TaskAudienceDepartment } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import {
  PagingOrder,
  sortDirectionToPagingOrder,
  type OffsetPagingWrapper,
  type SortingOptions,
} from '@clover/core/helpers/paging';
import type { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, catchError, combineLatest, map, of, startWith, switchMap, tap, type Observable } from 'rxjs';
import { CheckboxComponent } from '../../../../forms/checkbox/checkbox.component';
import { AssigneePickerDialogDepartmentsTableRowComponent } from './departments-table-row/departments-table-row.component';

@UntilDestroy()
@Component({
  selector: 'cc-assignee-picker-dialog-departments-table',
  standalone: true,
  imports: [
    NgScrollbar,
    TableComponent,
    ThComponent,
    TrComponent,
    AsyncPipe,
    AssigneePickerDialogDepartmentsTableRowComponent,
    CdkScrollable,
    CheckboxComponent,
    TranslateModule,
  ],
  templateUrl: './departments-table.component.html',
  styleUrl: './departments-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneePickerDialogDepartmentsTableComponent implements OnInit {
  companyId = input.required<number>();
  departmentIds = model.required<string[]>();

  protected searchFormControl = new FormControl<string>('');
  protected sortingOptions$ = new BehaviorSubject<SortingOptions<TaskAudienceDepartmentsSortingProperty>>({
    orderBy: 'title',
    order: PagingOrder.Ascending,
  });

  protected titleSortingOrder$: Observable<SortDirection>;

  protected loadedDepartments = signal<OffsetPagingWrapper<TaskAudienceDepartment> | undefined>(undefined);
  protected loadingStatus = signal<'void' | 'loaded' | 'loading' | 'loading-next' | 'error'>('void');

  protected readonly departments = computed(() => this.loadedDepartments()?.data ?? []);
  protected readonly departmentsTotal = computed(() => this.loadedDepartments()?.total ?? 0);

  protected readonly checkboxChecked = computed(() => {
    return this.departmentIds().length >= this.departmentsTotal() && this.departmentsTotal() > 0;
  });

  protected readonly checkboxIndeterminate = computed(() => {
    const selectedCount = this.departmentIds().length;
    return selectedCount > 0 && selectedCount < this.departmentsTotal();
  });

  private readonly taskAssignmentAudienceService = inject(TaskAssignmentAudienceService);

  ngOnInit(): void {
    this.titleSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'title' ? sortingOptions.order : null)),
    );

    this.initDepartments();
  }

  handleDepartmentSelect(departmentId: string, selected: boolean): void {
    this.departmentIds.update((ids) => {
      if (selected) return [...ids, departmentId];
      return ids.filter((id) => id !== departmentId);
    });
  }

  handleAllDepartmentsCheckboxChange(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    this.departmentIds.set(checked ? this.departments().map((department) => department.id) : []);
  }

  handleSearchChange(query: string): void {
    this.searchFormControl.setValue(query);
  }

  changeSort(property: TaskAudienceDepartmentsSortingProperty, direction: SortDirection) {
    this.sortingOptions$.next({
      orderBy: property,
      order: sortDirectionToPagingOrder(direction),
    });
  }

  private initDepartments(): void {
    combineLatest([this.searchFormControl.valueChanges.pipe(startWith('')), this.sortingOptions$])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loadingStatus.set('loading');
        }),
        switchMap(([query, sorting]) => {
          return this.taskAssignmentAudienceService.searchDepartments(this.companyId(), query, {
            order: sorting.order,
            orderBy: sorting.orderBy,
          });
        }),
        tap((response: OffsetPagingWrapper<TaskAudienceDepartment>) => {
          this.loadedDepartments.set(response);
          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }
}

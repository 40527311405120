import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ComposerInstance, ComposerMessageType } from '@conversations/composer/state/composers/composers-state.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import {
  ChangePresentation,
  SetMessageType,
  SetRecipients,
} from '@conversations/composer/state/composers/composers.actions';
import { Store } from '@ngxs/store';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { ComposerRecipientsSelectorComponent } from './composer-recipients-selector/composer-recipients-selector.component';
import { TooltipAlignment, TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { EmailParticipants } from '@conversations/conversation/state/conversation/conversation-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-message-type-selector',
  standalone: true,
  templateUrl: './composer-message-type-selector.component.html',
  styleUrls: ['./composer-message-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OverlayModule,
    DropdownComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    ComposerRecipientsSelectorComponent,
    ButtonComponent,
    TooltipDirective,
    TranslateModule,
  ],
})
export class ComposerMessageTypeSelectorComponent implements OnChanges {
  @Input()
  composer: ComposerInstance;

  @Input()
  allowedMessageTypes: Exclude<ComposerMessageType, 'new'>[];

  @Input()
  forceAllowOnlyInternal = false;

  @Input()
  replyForbidden = false;

  protected messageTypeDropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
  ];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly store = inject(Store);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['replyForbidden'] || changes['forceAllowOnlyInternal']) this.healMessageType();
  }

  setMessageType(type: Exclude<ComposerMessageType, 'new'>): void {
    this.store.dispatch(new SetMessageType(this.composer.id, type, this.composer.replyToMessage));
  }

  updateRecipients(participants: Omit<EmailParticipants, 'from'>): void {
    this.store.dispatch(new SetRecipients(this.composer.id, participants));
  }

  openOverlay(): void {
    this.store.dispatch(new ChangePresentation(this.composer.id, 'overlay-expanded'));
  }

  private healMessageType(): void {
    const replyForbiddenViolated = this.replyForbidden && ['reply', 'replyAll'].includes(this.composer.messageType);
    const forceAllowOnlyInternalViolated =
      this.forceAllowOnlyInternal && ['reply', 'replyAll', 'forward'].includes(this.composer.messageType);

    if (!replyForbiddenViolated && !forceAllowOnlyInternalViolated) return;

    // If the message type is no longer allowed, reset it to 'internal'
    this.store.dispatch(new SetMessageType(this.composer.id, 'internal', this.composer.replyToMessage));
  }
}

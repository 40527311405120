import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';

import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ConversationDetails } from '@conversations/conversation/state/conversation/conversation-state.model';
import { ConversationPerformer } from '@conversations/conversations/state/conversations/conversations-state.model';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-conversation-header-actions-assignee-picker',
  standalone: true,
  imports: [
    DropdownComponent,
    DropdownTextComponent,
    DropdownActionComponent,
    OverlayModule,
    TooltipDirective,
    DropdownDividerComponent,
    TranslateModule,
  ],
  templateUrl: './conversation-header-actions-assignee-picker.component.html',
  styleUrls: ['./conversation-header-actions-assignee-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationHeaderActionsAssigneePickerComponent {
  @Input()
  details: ConversationDetails;

  @Input()
  workspace: Workspace;

  @Input()
  disabled = false;

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly TooltipPosition = TooltipPosition;
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);

  get assignee(): ConversationPerformer | undefined {
    const assignee = this.details.assignment.assignee;
    if (!assignee) return undefined;
    if (assignee.avatarUrl) return assignee;

    const nameTokens = assignee.name.split(' ');
    const firstName = nameTokens[0];
    const lastName = nameTokens[1] || '';

    return {
      ...assignee,
      avatarUrl: generateGenericAvatar(firstName, lastName, 128),
    };
  }

  get availableAssignees(): ConversationPerformer[] {
    return this.details.assignment.availableAssignees.map((assignee) => {
      if (assignee.avatarUrl) return assignee;

      const nameTokens = assignee.name.split(' ');
      const firstName = nameTokens[0];
      const lastName = nameTokens[1] || '';

      return {
        ...assignee,
        avatarUrl: generateGenericAvatar(firstName, lastName, 128),
      };
    });
  }

  assign(assignee: ConversationPerformer): void {
    if (assignee.id === this.assignee?.id) return;
    this.conversationActionsService.assignConversation(this.details.id, assignee);
  }

  unassign(): void {
    if (!this.assignee) return;
    this.conversationActionsService.unassignConversation(this.details.id);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NzResizableModule, NzResizeEvent } from 'ng-zorro-antd/resizable';
import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';
import { LocalStorageService } from '@core/services/persistance.service';
import { CoreModule } from '@core/core.module';
import { ConversationComponent } from '@conversations/conversation/active-conversation/conversation/conversation.component';
import { Store } from '@ngxs/store';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import { AsyncPipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { switchMap } from 'rxjs/operators';

export const conversationDrawerMinWidth = 280;
export const conversationDrawerDefaultWidth = 320;

const conversationMinWidth = 480;
const conversationDrawerWidthStorageKey = 'conversations.conversationInfoWidth';

@Component({
  selector: 'cc-active-conversation',
  standalone: true,
  imports: [
    RouterOutlet,
    NgScrollbarModule,
    CdkScrollableModule,
    NzResizableModule,
    ConversationComponent,
    CoreModule,
    AsyncPipe,
    ResizeDirective,
  ],
  templateUrl: './active-conversation.component.html',
  styleUrls: ['./active-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveConversationComponent implements OnInit {
  details$ = inject(Store).select(ConversationSelectors.details);
  assignee$: Observable<boolean>;
  workspace$: Observable<Workspace>;
  labels$ = inject(Store).select(ConversationSelectors.labels);
  parentAccount$ = inject(Store).select(ConversationSelectors.account);
  parentAccountIssue$ = inject(Store).select(ConversationSelectors.parentAccountIssue);
  messages$ = inject(Store).select(ConversationSelectors.messages);
  pendingMessages$ = inject(Store).select(ConversationSelectors.pendingMessages);
  messagesLoadingStatus$ = inject(Store).select(ConversationSelectors.messagesLoadingStatus);
  hasPreviousMessages$ = inject(Store).select(ConversationSelectors.hasPreviousMessages);
  hasNextMessages$ = inject(Store).select(ConversationSelectors.hasNextMessages);

  protected conversationDrawerWidth = conversationDrawerDefaultWidth;
  protected conversationDrawerMaxWidth: number | undefined;
  protected drawerOverlayMode = false;

  protected readonly conversationDrawerMinWidth = conversationDrawerMinWidth;
  protected readonly cdr = inject(ChangeDetectorRef);

  private readonly storage = inject(LocalStorageService);
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);

  ngOnInit(): void {
    this.workspace$ = this.details$.pipe(
      switchMap((details) => {
        if (!details) return of(undefined);
        return this.store.select(WorkspacesSelectors.workspaceById(details.workspaceId));
      }),
    );

    this.assignee$ = this.store.select(
      ConversationSelectors.currentUserIsAssignee(this.userService.userProfile.id.toString()),
    );

    this.conversationDrawerWidth =
      Number(this.storage.get(conversationDrawerWidthStorageKey)) || conversationDrawerDefaultWidth;
  }

  resizeConversationDrawer({ width }: NzResizeEvent): void {
    this.conversationDrawerWidth = width;
    this.storage.set(conversationDrawerWidthStorageKey, width);
  }

  handleContainerResize({ newRect: { width } }: ResizeEvent): void {
    this.drawerOverlayMode = width < conversationDrawerMinWidth + conversationMinWidth;
    this.conversationDrawerMaxWidth = this.drawerOverlayMode ? width * 0.8 : width - conversationMinWidth;
    this.conversationDrawerWidth = Math.max(
      Math.min(this.conversationDrawerWidth, this.conversationDrawerMaxWidth),
      conversationDrawerMinWidth,
    );
    this.storage.set(conversationDrawerWidthStorageKey, this.conversationDrawerWidth);
  }

  resetConversationDrawerWidth(): void {
    this.conversationDrawerWidth = conversationDrawerDefaultWidth;
    this.storage.set(conversationDrawerWidthStorageKey, conversationDrawerDefaultWidth);
  }
}

import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { type CompactMessage } from '@conversations/conversation/state/conversation/conversation-state.model';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-reply-to-message-preview',
  standalone: true,
  templateUrl: './composer-reply-to-message-preview.component.html',
  styleUrls: ['./composer-reply-to-message-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
})
export class ComposerReplyToMessagePreviewComponent {
  @Input()
  replyToMessage: CompactMessage;

  jumpToReplyToMessage = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  get senderAvatar(): string {
    if (this.replyToMessage.sender.avatarUrl) return this.replyToMessage.sender.avatarUrl;

    const senderNameTokens = this.replyToMessage.sender.name.split(' ');
    const firstName = senderNameTokens[0];
    const lastName = senderNameTokens[1];

    return generateGenericAvatar(firstName || '—', lastName, 64);
  }
}

import {
  Draft,
  EmailParticipants,
  type CompactMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { Subject } from 'rxjs';

export interface ComposersStateModel {
  composers: ComposerInstance[];
}

export interface ComposerInstance {
  id: string;
  workspaceId: number; // Not defined for new messages
  conversationId: string | undefined; // Not defined for new messages
  messageType: ComposerMessageType;
  senderAccountId: number;
  emailParticipants: Omit<EmailParticipants, 'from'>;
  subject: string;
  message: string;
  quote: string;
  signature: string;
  replyToMessage: CompactMessage | undefined;
  replyForbidden: boolean;
  activeFileUploads: FileUpload[];
  draft: Draft | undefined; // Not defined for new messages (this functionality could be added in the future)
  draftSaveStatus: DraftSaveStatus;
  sendStatus: SendStatus;
  presentation: ComposerPresentation;
}

export type DraftSaveStatus = 'void' | 'saving' | 'saved' | 'error';
export type SendStatus = 'void' | 'sending';
export type ComposerMessageType = 'internal' | 'reply' | 'replyAll' | 'forward' | 'new';
export type ComposerPresentation = 'inline' | 'overlay-expanded' | 'overlay-collapsed';

export interface FileUpload {
  id: string;
  file: File;
  status: 'uploading' | 'error';
  progress: number;
  error?: FileUploadError;
  cancel$: Subject<void>;
}

export enum FileUploadError {
  // TODO (Oleksandr D.): add more error types
  Unknown = 'unknown',
}

export const defaultComposersState: ComposersStateModel = {
  composers: [],
};

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TaskDrawerComponent } from '@conversations/tasks/task-drawer/task-drawer.component';
import { Task, TaskStatus } from '@conversations/tasks/tasks.model';
import { TasksService } from '@conversations/tasks/tasks.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [AsyncPipe, TaskDrawerComponent, ButtonComponent, RouterLink, TranslateModule],
  templateUrl: './task-info-drawer-wrapper.component.html',
  styleUrls: ['./task-info-drawer-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskInfoDrawerWrapperComponent implements OnInit {
  task$: Observable<Task>;

  canOpenTaskWizard$: Observable<boolean>;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly store = inject(Store);
  private readonly tasksService = inject(TasksService);
  private readonly activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    this.task$ = this.activatedRoute.data.pipe(
      map((data) => data['task']),
      switchMap((task) => this.tasksService.subscribeToTaskUpdates(task.id).pipe(startWith(task))),
    );

    this.canOpenTaskWizard$ = this.task$.pipe(
      map((task) => {
        return (
          task.status === TaskStatus.NotStarted ||
          task.status === TaskStatus.InProgress ||
          task.status === TaskStatus.Completed
        );
      }),
    );
  }

  close(): void {
    this.store.dispatch(new Navigate([{ outlets: { info: null } }], {}, { relativeTo: this.activatedRoute.parent }));
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';

import { toSignal } from '@angular/core/rxjs-interop';
import { htmlRequiredValidator } from '@design/forms/rich-text-editor/helpers/required-validator';
import { Store } from '@ngxs/store';
import { ifValidator } from 'ngxtension/if-validator';
import { startWith } from 'rxjs';
import { TextboxComponent } from '../../../../../stories/forms/textbox/textbox.component';
import type { Signature } from '../../state/signatures/signatures-state.model';
import { SignaturesSelectors } from '../../state/signatures/signatures.selectors';
import { SignatureEditorComponent } from './signature-editor/signature-editor.component';
import { SignaturePreviewComponent } from './signature-preview/signature-preview.component';

interface CreateSignatureEditorDialogData {
  mode: 'create';
  workspaceId: number;
  predefinedValues?: Partial<Pick<Signature, 'name' | 'content'>>;
}

interface EditSignatureEditorDialogData {
  mode: 'edit';
  workspaceId: number;
  predefinedValues: Pick<Signature, 'name' | 'content'>;
}

export type SignatureEditorDialogData = CreateSignatureEditorDialogData | EditSignatureEditorDialogData;

export type SignatureEditorDialogResult = Pick<Signature, 'name' | 'content'>;

function sameAsInitialValueValidator(predefinedValues?: Partial<Pick<Signature, 'name' | 'content'>>): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const { name, content } = formGroup.value;

    return name === predefinedValues?.name && content === predefinedValues?.content
      ? { sameAsInitialValueValidator: true }
      : null;
  };
}

@Component({
  selector: 'cc-signature-editor-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    TooltipDirective,
    TranslateModule,
    NgScrollbar,
    TextboxComponent,
    ReactiveFormsModule,
    CdkScrollable,
    SignatureEditorComponent,
    SignaturePreviewComponent,
  ],
  templateUrl: './signature-editor-modal.component.html',
  styleUrl: './signature-editor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureEditorModalComponent {
  readonly dialogData: SignatureEditorDialogData = inject(DIALOG_DATA);

  protected readonly form = new FormGroup(
    {
      name: new FormControl<string>(this.dialogData.predefinedValues?.name || '', [Validators.required]),
      content: new FormControl<string>(this.dialogData.predefinedValues?.content || '', [htmlRequiredValidator()]),
    },
    [
      ifValidator(
        () => this.dialogData.mode === 'edit',
        [sameAsInitialValueValidator(this.dialogData.predefinedValues)],
      ),
    ],
  );

  protected readonly isNameUnique = computed(() => {
    const name = this.name$();
    const signatures = this.signatures$();

    return (
      !name ||
      signatures.every((signature) => signature.name.trim() !== name.trim()) ||
      (this.dialogData.mode === 'edit' && name.trim() === this.dialogData.predefinedValues?.name.trim())
    );
  });

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TextboxType = TextboxType;

  protected readonly dialogRef: DialogRef<SignatureEditorDialogResult> = inject(DialogRef);

  private name$ = toSignal(this.nameControl.valueChanges.pipe(startWith(this.nameControl.value)));
  private readonly signatures$ = toSignal(
    inject(Store).select(SignaturesSelectors.signaturesByWorkspaceId(this.dialogData.workspaceId)),
  );

  get nameControl(): FormControl<string> {
    return this.form.get('name') as FormControl<string>;
  }

  get contentControl(): FormControl<string> {
    return this.form.get('content') as FormControl<string>;
  }

  save(): void {
    if (this.form.invalid || !this.isNameUnique()) return;

    this.dialogRef.close({
      name: this.nameControl.value,
      content: this.contentControl.value,
    });
  }
}

import { Routes } from '@angular/router';
import { ConversationContainerComponent } from '@conversations/conversation/conversation-container.component';
import { conversationResolver } from '@conversations/conversation/conversation.resolver';
import { TaskInfoDrawerWrapperComponent } from '@conversations/conversation/active-conversation/info-drawer/task-info-drawer-wrapper/task-info-drawer-wrapper.component';
import { taskInfoResolver } from '@conversations/conversation/task-info.resolver';

export const conversationRoutes: Routes = [
  {
    path: '',
    component: ConversationContainerComponent,
    resolve: {
      conversation: conversationResolver,
    },
  },
  {
    path: ':conversationId',
    component: ConversationContainerComponent,
    resolve: {
      conversation: conversationResolver,
    },
    children: [
      {
        path: 'task/:taskId',
        outlet: 'info',
        component: TaskInfoDrawerWrapperComponent,
        resolve: {
          task: taskInfoResolver,
        },
      },
    ],
  },
];

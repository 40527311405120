import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-message-collapsible-group',
  standalone: true,
  templateUrl: './message-collapsible-group.component.html',
  styleUrls: ['./message-collapsible-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*', opacity: 1 })),
      state('collapsed', style({ height: 0, opacity: 0 })),
      transition('expanded <=> collapsed', animate('0.2s ease-in-out')),
    ]),
  ],
})
export class MessageCollapsibleGroupComponent {
  @Input()
  collapsed = true;

  expand = output<void>();

  collapse = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  get animationState(): 'collapsed' | 'expanded' {
    return this.collapsed ? 'collapsed' : 'expanded';
  }

  toggle(): void {
    if (this.collapsed) {
      this.expand.emit();
    } else {
      this.collapse.emit();
    }
  }
}

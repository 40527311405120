@if (details?.id) {
  <div class="conversation">
    <div class="conversation__header">
      <cc-conversation-header
        [accountIssue]="accountIssue"
        [account]="account"
        [assignee]="assignee"
        [details]="details"
        [labels]="labels"
        [workspace]="workspace"
      />
    </div>

    <div
      [@messages]="messagesAnimationState"
      class="conversation__messages"
    >
      <cc-conversation-messages
        [accountIssue]="accountIssue"
        [assignee]="assignee"
        [details]="details"
        [hasNextMessages]="hasNextMessages"
        [hasPreviousMessages]="hasPreviousMessages"
        [messagesLoadingStatus]="messagesLoadingStatus"
        [messages]="messages"
        [pendingMessages]="pendingMessages"
      />
    </div>

    <div
      [@composer]="composerAppearance"
      class="conversation__composer"
    >
      <cc-conversation-inline-composer
        (appearanceChange)="composerAppearance = $event"
        [appearance]="composerAppearance"
        [conversationId]="details.id"
      />
    </div>
  </div>
}

<cc-image-resizer
  [(width)]="width"
  [(height)]="height"
  [(radius)]="radius"
>
  <div
    class="cc-placeholder"
    role="img"
  >
    @switch (type()) {
      @case ('avatar') {
        <!-- Because we render editor in shadow DOM, generated icon font is not available, so we inline an SVG -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
        </svg>
      }
    }
  </div>
</cc-image-resizer>

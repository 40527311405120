import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Task } from '@conversations/tasks/tasks.model';
import { inject } from '@angular/core';
import { TasksService } from '@conversations/tasks/tasks.service';

export const taskInfoResolver: ResolveFn<Task> = (route: ActivatedRouteSnapshot): Observable<Task> => {
  const { taskId } = route.params || {};

  const tasksService = inject(TasksService);
  return tasksService.getTask(taskId);
};

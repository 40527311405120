import { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { ComposerInstance, ComposerMessageType } from '@conversations/composer/state/composers/composers-state.model';
import {
  ConversationDetails,
  Draft,
  DraftMessageType,
  EmailParticipant,
  EmailParticipants,
  MessageType,
  PendingMessage,
  type CompactMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { ConversationPerformer } from '@conversations/conversations/state/conversations/conversations-state.model';
import { User } from '@core/models/user';

export const emptyEditorHtml = '<p></p>';

export const getEmptyParticipants = (): Omit<EmailParticipants, 'from'> => ({
  to: [],
  cc: [],
  bcc: [],
});

export function createConversationComposer(
  id: string,
  details: ConversationDetails,
  draft: Draft | undefined,
): ComposerInstance {
  return {
    id,
    workspaceId: details.workspaceId,
    conversationId: details.id,
    messageType: draft?.type ? draftMessageTypeToComposerType(draft.type) : 'internal',
    senderAccountId: details.externalAccountId,
    emailParticipants: draft?.emailParticipants || getEmptyParticipants(),
    subject: draft?.subject || details.subject,
    message: draft?.content || emptyEditorHtml,
    quote: draft?.quote || emptyEditorHtml,
    signature: draft?.signature || emptyEditorHtml,
    replyToMessage: draft?.replyToMessage || details.lastReplyableMessage,
    replyForbidden: details.replyForbidden,
    activeFileUploads: [],
    draft: draft || undefined,
    draftSaveStatus: draft ? 'saved' : 'void',
    sendStatus: 'void',
    presentation: 'inline',
  };
}

// TODO: Remove this function when draft support for new emails is added.
export function createNewConversationComposer(
  id: string,
  workspaceId: number,
  externalAccountId: number,
  signature = emptyEditorHtml,
): ComposerInstance {
  return {
    id,
    workspaceId,
    conversationId: undefined,
    messageType: 'new',
    senderAccountId: externalAccountId,
    emailParticipants: getEmptyParticipants(),
    subject: '',
    message: emptyEditorHtml,
    quote: emptyEditorHtml,
    signature,
    replyToMessage: undefined,
    replyForbidden: true,
    activeFileUploads: [],
    draft: undefined,
    draftSaveStatus: 'void',
    sendStatus: 'void',
    presentation: 'overlay-expanded',
  };
}

export function draftToPendingMessage(
  draft: Draft,
  conversationId: string,
  traceId: string,
  currentUser: User,
): PendingMessage {
  const sender: ConversationPerformer = {
    id: currentUser.id.toString(),
    name: currentUser.fullName,
    avatarUrl: currentUser.logoUrl,
    type: ContactType.CloverUser,
  };

  const emailSender: EmailParticipant = {
    name: currentUser.fullName,
    email: currentUser.email,
    avatarUrl: currentUser.logoUrl,
  };

  return {
    id: `pending-${traceId}`,
    conversationId,
    type: draftTypeToMessageType(draft.type),
    content: draft.content,
    snippet: draft.content,
    sender,
    emailParticipants: {
      ...draft.emailParticipants,
      from: emailSender,
    },
    systemMetadata: undefined,
    tasksIds: draft.tasksIds,
    attachments: draft.attachments,
    drafts: [],
    replyToMessage: draft.replyToMessage,
    traceId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function draftMessageTypeToComposerType(
  draftMessageType: DraftMessageType,
): Exclude<ComposerMessageType, 'new'> {
  switch (draftMessageType) {
    case DraftMessageType.Note:
      return 'internal';
    case DraftMessageType.Reply:
      return 'reply';
    case DraftMessageType.ReplyAll:
      return 'replyAll';
    case DraftMessageType.Forward:
      return 'forward';
  }
}

export function composerTypeToDraftMessageType(
  composerMessageType: Exclude<ComposerMessageType, 'new'>,
): DraftMessageType {
  switch (composerMessageType) {
    case 'internal':
      return DraftMessageType.Note;
    case 'reply':
      return DraftMessageType.Reply;
    case 'replyAll':
      return DraftMessageType.ReplyAll;
    case 'forward':
      return DraftMessageType.Forward;
  }
}

function draftTypeToMessageType(draftType: DraftMessageType): MessageType {
  switch (draftType) {
    case DraftMessageType.Note:
      return MessageType.Text;
    case DraftMessageType.Reply:
    case DraftMessageType.ReplyAll:
    case DraftMessageType.Forward:
      return MessageType.Email;
  }
}

export function getAllowedMessageTypesByReplyToMessage(
  replyToMessage: CompactMessage | undefined,
  myEmail: string,
): Exclude<ComposerMessageType, 'new'>[] {
  const allowedMessageTypes: Exclude<ComposerMessageType, 'new'>[] = ['internal'];

  if (!replyToMessage || replyToMessage.type !== MessageType.Email) return allowedMessageTypes;

  // Reply and forward are always allowed for email messages.
  allowedMessageTypes.push('reply', 'forward');

  // For reply-all we need to check if the linked message would have multiple recipients.
  // We also need to exclude the sender from the list of recipients.
  const emailParticipants = replyToMessage.emailParticipants;

  const sender = emailParticipants.from;
  const recipients = emailParticipants.to || [];
  const carbonCopy = emailParticipants.cc || [];

  const replyAllRecipients = [sender, ...recipients, ...carbonCopy].filter((recipient) => recipient.email !== myEmail);
  if (replyAllRecipients.length > 1) allowedMessageTypes.push('replyAll');

  return allowedMessageTypes;
}

export function getInitialEmailRecipients(
  replyToMessage: CompactMessage,
  messageType: ComposerMessageType,
  myEmail: string,
): Omit<EmailParticipants, 'from'> | undefined {
  const emailParticipants = replyToMessage.emailParticipants;

  switch (messageType) {
    case 'reply':
      return {
        to: [emailParticipants.from],
        cc: [],
        bcc: [],
      };
    case 'replyAll':
      return {
        to: [emailParticipants.from],
        cc: [...(emailParticipants.to || []), ...(emailParticipants.cc || [])].filter(
          (recipient) => recipient.email !== myEmail,
        ),
        bcc: [],
      };
    case 'new':
    case 'forward':
      return {
        to: [],
        cc: [],
        bcc: [],
      };
    case 'internal':
      return undefined;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';

@Component({
  selector: 'cc-conversations-list-placeholder',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './conversations-list-placeholder.component.html',
  styleUrls: ['./conversations-list-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsListPlaceholderComponent {
  @Input()
  type: 'loading' | 'error' | 'empty' | 'initializing' = 'empty';

  @Input()
  syncingAccounts: ExternalAccount[] = [];

  get syncedThreadsCount(): number {
    return this.syncingAccounts.reduce((acc, account) => acc + account.syncedThreads || 0, 0);
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpService } from '@clover/core/services/http.service';
import { map, type Observable } from 'rxjs';
import type { WorkflowFolder } from './workflow-library.model';

interface WorkflowFolderResponse {
  id: number;
  name: string;
  createdAt: string;
}

function mapWorkflowFolder(w: WorkflowFolderResponse): WorkflowFolder {
  return {
    id: w.id,
    name: w.name,
    createdAt: w.createdAt,
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkflowLibraryService {
  private readonly http = inject(HttpService);

  getFolders(): Observable<WorkflowFolder[]> {
    return this.http
      .getV2<WorkflowFolderResponse[]>('/api/companies/my/workflows/folders')
      .pipe(map((folders) => folders.map(mapWorkflowFolder).sort((a, b) => a.name.localeCompare(b.name))));
  }

  getFolder(folderId: number): Observable<WorkflowFolder | undefined> {
    return this.getFolders().pipe(map((folders) => folders.find((f) => f.id === folderId)));
  }
}

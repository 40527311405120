<div class="company-wiki-document-editor">
  <div class="company-wiki-document-editor__header">
    <div class="company-wiki-document-editor__header-breadcrumbs">
      @for (breadcrumb of document.path; track breadcrumb; let isLast = $last) {
        @if (!isLast) {
          <span
            class="company-wiki-document-editor__header-breadcrumbs-item"
            [ccTooltip]="breadcrumb.title"
            [showOnlyOnOverflow]="true"
            tooltipMaxWidth="min(400px, 90vw)"
          >
            {{ breadcrumb.title }}
          </span>
          <span class="company-wiki-document-editor__header-breadcrumbs-separator"> / </span>
        }
        @if (isLast) {
          <app-textbox
            width="100%"
            placeholder="Document title..."
            [formControl]="documentTitleFormControl"
          >
          </app-textbox>
        }
      }
    </div>

    <div class="company-wiki-document-editor__header-actions">
      <app-button
        (click)="leaveEditMode()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        Cancel
      </app-button>

      <app-button
        (click)="saveDocument()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Primary"
      >
        Save
      </app-button>
    </div>
  </div>

  <cc-markdown-editor
    [markdownContent]="document.content"
    class="company-wiki-document-editor__editor"
  >
  </cc-markdown-editor>
</div>

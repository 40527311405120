import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule, type ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Injector,
  model,
  type OnInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { getOverlayVisibilityAfterOutsideClick } from '@clover/core/helpers/get-overlay-visibility-after-outside-click';
import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import { ModalService as LegacyModalService } from '@clover/core/services/modal.service';
import {
  WorkflowStatus,
  WorkflowType,
  type Workflow,
  type WorkflowPreview,
} from '@clover/workflows-v2/state/workflows-state.model';
import { WorkflowsService } from '@clover/workflows-v2/state/workflows.service';
import { ShareWorkflowModalComponent } from '@clover/workflows/modals/share-workflow-modal/share-workflow-modal.component';
import { ValidationErrorsModalComponent } from '@clover/workflows/modals/validation-errors-modal/validation-errors-modal.component';
import { WorkflowLibraryModalComponent } from '@clover/workflows/modals/workflow-library-modal/workflow-library-modal.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';
import { DropdownActionComponent } from '../../../../../stories/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '../../../../../stories/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '../../../../../stories/overlays/dropdown/dropdown.component';
import { WorkflowStatusChipComponent } from './workflow-status-chip/workflow-status-chip.component';

@UntilDestroy()
@Component({
  selector: 'cc-workflow-table-row',
  standalone: true,
  imports: [
    TdComponent,
    DateFormatDistancePipe,
    WorkflowStatusChipComponent,
    ButtonComponent,
    DropdownComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    OverlayModule,
    DialogModule,
    RouterLink,
    TooltipDirective,
  ],
  templateUrl: './workflow-table-row.component.html',
  styleUrl: './workflow-table-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowTableRowComponent implements OnInit {
  // Model is used here instead of input, to allow changing the value within the component
  // InputSignals are read-only, ModelSignals are not
  workflow = model<WorkflowPreview>();

  showStatusColumn = model<boolean>(true);
  showActionsColumn = model<boolean>(true);

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: 4 },
  ];

  protected readonly WorkflowType = WorkflowType;
  protected readonly WorkflowStatus = WorkflowStatus;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly workflowsService = inject(WorkflowsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly injector = inject(Injector);

  private readonly legacyModalService = inject(LegacyModalService);

  ngOnInit(): void {
    this.workflowsService
      .subscribeToWorkflowUpdates(this.workflow().id)
      .pipe(untilDestroyed(this))
      .subscribe((workflow: Workflow): void => {
        this.workflow.update((oldWorkflow) => {
          return {
            ...workflow,
            // BE does not return version in full workflow response, so for now we keep the old one.
            // TODO (Oleksandr D.): fix this to use version from BE
            version: oldWorkflow.version,
          } as WorkflowPreview;
        });
        this.cdr.detectChanges();
      });
  }

  publishDraftWorkflow(): void {
    this.legacyModalService
      .open({
        content: WorkflowLibraryModalComponent,
        inputs: {
          publishMode: true,
          workflowId: this.workflow().id,
        },
        options: {
          size: 'xl',
          backdrop: 'static',
        },
      })
      .result.then(() => {
        this.workflowsService.publishWorkflow(this.workflow().id);
      })
      .catch((result) => {
        if (result?.errors?.length) {
          this.legacyModalService.open({
            content: ValidationErrorsModalComponent,
            inputs: {
              errors: result.errors,
            },
          });
        }
      });
  }

  async unpublish(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to unpublish this workflow?',
        description: 'The selected workflow will be unpublished from all companies.',
        confirmActionText: 'Unpublish',
        cancelActionText: 'Cancel',
        destructive: false,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.workflowsService.unpublishWorkflow(this.workflow().id);
  }

  share(): void {
    this.legacyModalService
      .open({
        content: ShareWorkflowModalComponent,
        inputs: {
          workflowId: this.workflow().id,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.workflowsService.changeAccessSettings(this.workflow().id);
      })
      .catch(() => {});
  }

  async deleteDraftWorkflow(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to delete this workflow?',
        description:
          'You are about the delete the selected workflow. This cannot be undone. Are you sure you want to delete it?',
        confirmActionText: 'Delete workflow',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.workflowsService.deleteDraftWorkflow(this.workflow().id);
  }
}

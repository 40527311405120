import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularNodeViewComponent } from 'ngx-tiptap';
import { ChipComponent } from '../../../../../chips/chip/chip.component';
import type { TextPlaceholderType } from './placeholders';

@Component({
  standalone: true,
  imports: [ChipComponent, TranslateModule],
  templateUrl: './text-placeholder.component.html',
  styleUrls: ['./text-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextPlaceholderComponent extends AngularNodeViewComponent {
  get placeholderType(): TextPlaceholderType {
    return this.node.attrs['type'];
  }

  @HostBinding('class.cc-selected')
  get isSelected(): boolean {
    return this.selected;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConversationHeaderDetailsComponent } from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header-details/conversation-header-details.component';
import { AsyncPipe } from '@angular/common';
import { ConversationHeaderActionsComponent } from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header-actions/conversation-header-actions.component';
import { ConversationDetails } from '@conversations/conversation/state/conversation/conversation-state.model';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { ExternalAccount, Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ConversationHeaderAccountIssueNoticeComponent } from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header-account-issue-notice/conversation-header-account-issue-notice.component';

@Component({
  selector: 'cc-conversation-header',
  standalone: true,
  imports: [
    ConversationHeaderDetailsComponent,
    AsyncPipe,
    ConversationHeaderActionsComponent,
    ConversationHeaderAccountIssueNoticeComponent,
  ],
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationHeaderComponent {
  @Input()
  details: ConversationDetails;

  @Input()
  assignee: boolean;

  @Input()
  workspace: Workspace;

  @Input()
  account: ExternalAccount;

  @Input()
  accountIssue: 'failed' | 'expired' | 'disconnected' | undefined;

  @Input()
  labels: Label[];
}

import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DateService } from '@core/services/date.service';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import {
  endOfDay,
  endOfWeek,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';

import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  DatePickerDialogComponent,
  DatePickerDialogData,
} from '@design/forms/date-picker/date-picker-dialog/date-picker-dialog.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cc-message-group-date-header',
  standalone: true,
  templateUrl: './message-group-date-header.component.html',
  styleUrls: ['./message-group-date-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OverlayModule,
    DropdownComponent,
    DropdownTextComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    DialogModule,
    TranslateModule,
  ],
})
export class MessageGroupDateHeaderComponent {
  @Input()
  date: string;

  @Input()
  minDate: string;

  @Input()
  maxDate: string;

  dateSelect = output<string>();

  protected dropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 8,
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: null,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly dateService = inject(DateService);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);

  get title(): string {
    const date = new Date(this.date);

    const tzDate = this.dateService.representLocalDateInProfileTimezoneDate(date);
    const tzNow = this.dateService.representLocalDateInProfileTimezoneDate(new Date());
    const tzYesterday = subDays(tzNow, 1);

    if (isSameDay(tzDate, tzNow)) return 'Today';
    if (isSameDay(tzDate, tzYesterday)) return 'Yesterday';
    if (isSameMonth(tzDate, tzNow))
      return this.dateService.format(tzDate, {
        dayOfWeek: 'long',
        date: 'long-no-year',
      });

    return this.dateService.format(tzDate, { date: 'long' });
  }

  get dropdownOptions(): ('today' | 'yesterday' | 'last-week')[] {
    const options: ('today' | 'yesterday' | 'last-week')[] = [];

    const timezoneNow = this.dateService.representLocalDateInProfileTimezoneDate(new Date());

    const startOfToday = startOfDay(timezoneNow);
    const endOfToday = endOfDay(startOfToday);

    const startOfYesterday = startOfDay(subDays(timezoneNow, 1));
    const endOfYesterday = endOfDay(startOfYesterday);

    const startOfLastWeek = startOfWeek(subWeeks(timezoneNow, 1));
    const endOfLastWeek = endOfWeek(startOfLastWeek);

    if (this.hasMessagesFromRange(startOfToday, endOfToday)) options.push('today');
    if (this.hasMessagesFromRange(startOfYesterday, endOfYesterday)) options.push('yesterday');
    if (this.hasMessagesFromRange(startOfLastWeek, endOfLastWeek)) options.push('last-week');

    return options;
  }

  selectDate(type: 'today' | 'yesterday' | 'last-week' | 'beginning' | 'custom'): void {
    this.dropdownVisible = false;
    const nowInProfileTimezone = this.dateService.representLocalDateInProfileTimezoneDate(new Date());
    const profileTimezoneToLocal = (date: Date) => this.dateService.representProfileTimezoneDateInLocalDate(date);

    if (type === 'today') {
      const startOfToday = startOfDay(nowInProfileTimezone);
      return this.dateSelect.emit(profileTimezoneToLocal(startOfToday).toISOString());
    }

    if (type === 'yesterday') {
      const yesterday = subDays(nowInProfileTimezone, 1);
      const startOfYesterday = startOfDay(yesterday);
      return this.dateSelect.emit(profileTimezoneToLocal(startOfYesterday).toISOString());
    }

    if (type === 'last-week') {
      const lastWeek = subWeeks(nowInProfileTimezone, 1);
      const startOfLastWeek = startOfWeek(lastWeek, { weekStartsOn: 0 }); // Week starts on Sunday
      return this.dateSelect.emit(profileTimezoneToLocal(startOfLastWeek).toISOString());
    }

    if (type === 'beginning') {
      return this.dateSelect.emit('1970-01-01'); // Start of the Unix epoch
    }

    if (type === 'custom') {
      const minDate = formatISO(this.dateService.representLocalDateInProfileTimezoneDate(new Date(this.minDate)));
      const maxDate = formatISO(this.dateService.representLocalDateInProfileTimezoneDate(new Date(this.maxDate)));

      const dialog = this.dialog.open<string, DatePickerDialogData>(DatePickerDialogComponent, {
        data: {
          title: this.translate.instant('conversations-v4.common.jumpToDate'),
          minDate,
          maxDate,
        },
      });

      dialog.closed.pipe(take(1)).subscribe((dateISO: string | undefined) => {
        if (!dateISO) return;

        const startOfSelectedDate = startOfDay(new Date(dateISO));
        this.dateSelect.emit(profileTimezoneToLocal(startOfSelectedDate).toISOString());
      });
    }
  }

  private hasMessagesFromRange(start: Date, end: Date): boolean {
    const minDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date(this.minDate));
    const maxDate = this.dateService.representLocalDateInProfileTimezoneDate(new Date(this.maxDate));

    const isMinDateBeforeEndOfThatDay = isBefore(minDate, end) || isEqual(minDate, end);
    const isMaxDateAfterStartOfThatDay = isAfter(maxDate, start) || isEqual(maxDate, start);

    return isMinDateBeforeEndOfThatDay && isMaxDateAfterStartOfThatDay;
  }
}

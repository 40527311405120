import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import type { TaskAudienceContact } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import { TdComponent } from '@design/table/td/td.component';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';
import { UserAvatarComponent } from '../../../../user-avatar/user-logo.component';

@Component({
  selector: 'cc-assignee-picker-dialog-contacts-table-row',
  standalone: true,
  imports: [TdComponent, CheckboxComponent, UserAvatarComponent],
  templateUrl: './contacts-table-row.component.html',
  styleUrl: './contacts-table-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneePickerDialogContactsTableRowComponent {
  contact = input.required<TaskAudienceContact>();
  selectable = input<boolean>(true);

  selected = model<boolean>();

  handleSelectChange(event: Event): void {
    this.selected.set((event.target as HTMLInputElement).checked);
  }
}

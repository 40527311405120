import { WorkspaceAlias } from '@conversations/workspaces/workspaces';

export enum ConversationsCategory {
  Inbox = 'inbox',
  AssignedToMe = 'assigned-to-me',
  Mentions = 'mentions',
  Tasks = 'tasks',
  Sent = 'sent',
  Drafts = 'drafts',
  Prioritized = 'prioritized',
  Snoozed = 'snoozed',
  Important = 'important',
  AllMail = 'all-mail',
  Spam = 'spam',
  Trash = 'trash',
}

export enum StatusCategorySegmentOption {
  Open = 'open',
  Closed = 'closed',
  All = 'all',
}

const categories: ConversationsCategory[] = [
  ConversationsCategory.Inbox,
  ConversationsCategory.AssignedToMe,
  ConversationsCategory.Mentions,
  ConversationsCategory.Tasks,
  ConversationsCategory.Sent,
  ConversationsCategory.Drafts,
  ConversationsCategory.Prioritized,
  ConversationsCategory.Snoozed,
  ConversationsCategory.Important,
  ConversationsCategory.AllMail,
  ConversationsCategory.Spam,
  ConversationsCategory.Trash,
];

export const statusesCategorySegmentOptions = [
  StatusCategorySegmentOption.Open,
  StatusCategorySegmentOption.Closed,
  StatusCategorySegmentOption.All,
];

export const categoriesWithLabels = [ConversationsCategory.Inbox];

export const categoriesWithExternalAccounts = [ConversationsCategory.Inbox];

export const categoriesWithStatus = [
  ConversationsCategory.Inbox,
  ConversationsCategory.AssignedToMe,
  ConversationsCategory.Mentions,
  ConversationsCategory.AllMail,
];

export const defaultCategory = ConversationsCategory.Inbox;
export const defaultStatusCategorySegmentOption = StatusCategorySegmentOption.Open;

export const statusCategorySegmentParam = 'status';
export const labelsCategorySegmentParam = 'label';
export const externalAccountCategorySegmentParam = 'account';

export function validateCategory(categoryId: string, workspaceAlias: WorkspaceAlias | string): boolean {
  if (!categories.includes(categoryId as ConversationsCategory)) return false;

  // Workspace specific checks
  return !(workspaceAlias === WorkspaceAlias.Private && categoryId === ConversationsCategory.AssignedToMe);
}

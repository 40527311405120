import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
  type OnInit,
} from '@angular/core';
import { ContactType, type Contact } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { ContactsService } from '@clover/conversations-v4/conversation/state/contacts/contacts.service';
import type { ConversationPerformer } from '@clover/conversations-v4/conversations/state/conversations/conversations-state.model';
import { TaskAssignmentService } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.service';
import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { map, tap } from 'rxjs';
import { ButtonComponent } from '../../../../../../../../stories/buttons/button/button.component';
import { UserLogoComponent } from '@clover/core/components/user-logo/user-logo.component';
import { UserAvatarComponent } from '../../../../../../../../stories/misc/user-avatar/user-logo.component';

@UntilDestroy()
@Component({
  selector: 'cc-message-sender-info',
  standalone: true,
  imports: [TooltipDirective, ButtonComponent, TranslateModule, UserLogoComponent, UserAvatarComponent],
  templateUrl: './message-sender-info.component.html',
  styleUrl: './message-sender-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSenderInfoComponent implements OnInit {
  sender = input.required<ConversationPerformer>();

  dismiss = output<void>();

  protected senderInfo = signal<Contact | undefined>(undefined);
  protected canAssignTask = computed(() => !!this.senderInfo().company?.id);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly ContactType = ContactType;

  private readonly contactsService = inject(ContactsService);
  private readonly taskAssignmentService = inject(TaskAssignmentService);

  ngOnInit(): void {
    this.loadSenderInfo();
  }

  assignTaskToSender(): void {
    const companyId = this.senderInfo().company?.id;
    if (!companyId) return;

    this.taskAssignmentService.startTaskAssignmentFlow({
      predefinedAudience: {
        contactIds: [Number(this.sender().id)],
        departmentIds: [],
      },
      companyId,
    });
  }

  private loadSenderInfo(): void {
    const sender = this.sender();
    if (!sender) return;

    const senderId = Number(sender.id);
    if (Number.isNaN(senderId)) return;

    this.contactsService
      .getContactById(senderId, sender.type)
      .pipe(
        untilDestroyed(this),
        map((contact) => {
          if (contact.avatarUrl) return contact;

          const nameTokens = contact.name.split(' ');
          const firstName = nameTokens[0];
          const lastName = nameTokens[1];
          const avatarUrl = generateGenericAvatar(firstName || '–', lastName, 128);

          return {
            ...contact,
            avatarUrl,
          };
        }),
        tap((contact) => this.senderInfo.set(contact)),
      )
      .subscribe();
  }
}

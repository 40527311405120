@if (senderInfo(); as sender) {
  <div class="sender-info">
    @if (sender.company; as company) {
      <div class="sender-info__header">
        <img
          class="sender-info__header-logo"
          [src]="company.logoUrl || 'assets/svg/common/new-company-placeholder.svg'"
          [alt]="company.name"
          width="36px"
          height="36px"
        />

        <div class="sender-info__header-title">{{ company.name }}</div>
      </div>
    } @else if (sender.type !== ContactType.CloverUser) {
      <div class="sender-info__header">
        <div class="sender-info__header-title">
          {{ 'conversations-v4.conversation.messages.message.senderInfo.unconnectedContact' | translate }}
        </div>
      </div>
    }

    <div class="sender-info__main">
      <div class="sender-info__main-avatar">
        <cc-user-avatar
          class="sender-info__main-avatar-image"
          [src]="sender.avatarUrl"
          [id]="sender.id"
          [name]="sender.name"
          [size]="64"
        />
      </div>

      <div class="sender-info__main-details">
        <span
          class="sender-info__main-details-name"
          [class.sender-info__main-details-name--placeholder]="!sender.name"
          [ccTooltip]="sender.name"
          [tooltipDisabled]="!sender.name"
          [showOnlyOnOverflow]="true"
        >
          {{ sender.name || 'conversations-v4.conversation.messages.message.senderInfo.unknownName' | translate }}
        </span>

        <span
          class="sender-info__main-details-email"
          [class.sender-info__main-details-email--placeholder]="!sender.emails.length"
          [ccTooltip]="sender.emails[0].email"
          [tooltipDisabled]="!sender.emails.length"
          [showOnlyOnOverflow]="true"
          tooltipMaxWidth="100%"
        >
          {{
            sender.emails[0].email || 'conversations-v4.conversation.messages.message.senderInfo.unknownEmail'
              | translate
          }}
        </span>
      </div>
    </div>

    <div class="sender-info__actions">
      @if (sender.type === ContactType.CloverUser) {
        <app-button
          (click)="dismiss.emit()"
          [disabled]="true"
          [type]="ButtonType.Secondary"
          [size]="ButtonSize.Small"
        >
          {{ 'conversations-v4.conversation.messages.message.senderInfo.viewDetails' | translate }}
        </app-button>
      } @else {
        <app-button
          (click)="dismiss.emit()"
          [type]="ButtonType.Secondary"
          [size]="ButtonSize.Small"
        >
          <i
            class="icon-add"
            slot="start-icon"
          ></i>

          {{ 'conversations-v4.conversation.messages.message.senderInfo.inviteToClover' | translate }}
        </app-button>
      }

      <div class="sender-info__actions-secondary">
        <app-button
          [disabled]="sender.type !== ContactType.CloverUser"
          (click)="dismiss.emit()"
          [ccTooltip]="'conversations-v4.conversation.messages.message.senderInfo.sendMessage' | translate"
          [type]="ButtonType.Ghost"
          [size]="ButtonSize.Big"
        >
          <i
            class="icon-pencil-plus"
            slot="ghost-icon"
          ></i>
        </app-button>

        <app-button
          [disabled]="sender.type !== ContactType.CloverUser || !canAssignTask()"
          (click)="assignTaskToSender(); dismiss.emit()"
          [ccTooltip]="'conversations-v4.conversation.messages.message.senderInfo.assignTask' | translate"
          [type]="ButtonType.Ghost"
          [size]="ButtonSize.Big"
        >
          <i
            class="icon-tasks"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>
    </div>
  </div>
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageCollapsibleGroupComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-collapsible-group/message-collapsible-group.component';
import { TaskCardComponent } from '@conversations/tasks/task-card/task-card.component';
import { TaskPreview, type TaskEmailAccess } from '@conversations/tasks/tasks.model';
import { TasksService } from '@conversations/tasks/tasks.service';
import { TranslateModule } from '@ngx-translate/core';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

export interface NoAccessTaskPlaceholder {
  id: number;
  unavailable: true;
  emailAccess: TaskEmailAccess[];
}

@Component({
  selector: 'cc-message-task-references',
  standalone: true,
  templateUrl: './message-task-references.component.html',
  styleUrls: ['./message-task-references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MessageCollapsibleGroupComponent, AsyncPipe, TaskCardComponent, TranslateModule],
})
export class MessageTaskReferencesComponent implements OnChanges {
  @Input()
  tasksIds: number[] = [];

  @Input()
  associatedEmails: string[] = [];

  tasks$: Observable<(TaskPreview | NoAccessTaskPlaceholder)[]> = new Observable();
  tasksLoadingStatus: 'void' | 'loading' | 'loaded' | 'error' = 'void';

  protected availableTasks$: Observable<TaskPreview[]>;
  protected noAccessTasks$: Observable<NoAccessTaskPlaceholder[]>;

  collapsed = true;

  private taskIds$ = new BehaviorSubject<number[]>([]);
  private associatedEmails$ = new BehaviorSubject<string[]>([]);

  private readonly tasksService = inject(TasksService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tasksIds']) this.taskIds$.next(this.tasksIds);
    if (changes['associatedEmails']) this.associatedEmails$.next(this.associatedEmails);
  }

  handleCollapsibleGroupExpand(): void {
    if (this.tasksLoadingStatus === 'loading') return;
    if (this.tasksLoadingStatus !== 'loaded') return this.loadTasks();
    this.collapsed = false;
  }

  private loadTasks(): void {
    this.tasksLoadingStatus = 'loading';

    this.tasks$ = combineLatest([this.taskIds$, this.associatedEmails$]).pipe(
      distinctUntilChanged((a, b) => R.equals(a, b)),
      switchMap(([taskIds, associatedEmails]) => {
        this.tasksLoadingStatus = 'loading';

        if (!taskIds.length) return [];
        return this.tasksService.getTasksPreviews([...new Set(taskIds)], [...new Set(associatedEmails)]);
      }),
      tap(() => {
        this.tasksLoadingStatus = 'loaded';
        this.collapsed = false;
      }),
      catchError((e) => {
        console.error(e);
        this.tasksLoadingStatus = 'error';
        return [];
      }),
    );

    this.availableTasks$ = this.tasks$.pipe(
      map((tasks) => tasks.filter((task) => !task['unavailable']) as TaskPreview[]),
    );
    this.noAccessTasks$ = this.tasks$.pipe(
      map((tasks) => tasks.filter((task) => task['unavailable']) as NoAccessTaskPlaceholder[]),
    );
  }
}

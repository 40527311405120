<div class="conversation-header">
  @if (accountIssue) {
    <div class="conversation-header__account-issue-notice">
      <cc-conversation-header-account-issue-notice
        [accountIssue]="accountIssue"
        [account]="account"
      />
    </div>
  }

  <div class="conversation-header__actions">
    <cc-conversation-header-actions
      [accountIssue]="accountIssue"
      [assignee]="assignee"
      [details]="details"
      [workspace]="workspace"
    />
  </div>

  <div class="conversation-header__details">
    <cc-conversation-header-details
      [accountIssue]="accountIssue"
      [assignee]="assignee"
      [details]="details"
      [labels]="labels"
    />
  </div>
</div>

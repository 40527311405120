import { Dialog, DialogModule } from '@angular/cdk/dialog';

import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

import { CoreModule } from '@core/core.module';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import {
  AccountSyncIssuesDialogData,
  AccountSyncIssuesModalComponent,
} from '@conversations/workspaces/modals/account-sync-issues-modal/account-sync-issues-modal.component';
import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormatOptions } from '@core/services/date.service';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';

@Component({
  selector: 'cc-conversation-header-account-issue-notice',
  standalone: true,
  templateUrl: './conversation-header-account-issue-notice.component.html',
  styleUrls: ['./conversation-header-account-issue-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CoreModule, TooltipDirective, DialogModule, DateFormatPipe, TranslateModule],
})
export class ConversationHeaderAccountIssueNoticeComponent {
  @Input()
  account: ExternalAccount;

  @Input()
  accountIssue: 'failed' | 'expired' | 'disconnected' | undefined;

  protected readonly TooltipPosition = TooltipPosition;

  protected readonly dateFormatOptions: FormatOptions = { date: 'numeric', time: 'default' };

  private readonly dialog = inject(Dialog);
  private readonly store = inject(Store);

  openSyncIssuesModal(): void {
    if (this.accountIssue === 'disconnected') return;

    const getStatuses = (): ExternalAccountStatus[] => {
      switch (this.accountIssue) {
        case 'failed': {
          return [ExternalAccountStatus.Failed];
        }
        case 'expired': {
          return [ExternalAccountStatus.Expired];
        }
      }
    };

    const statuses = getStatuses();
    const failedAccounts = this.store.selectSnapshot(WorkspacesSelectors.externalAccountsByStatuses(statuses));

    const dialog = this.dialog.open<ExternalAccount, AccountSyncIssuesDialogData>(AccountSyncIssuesModalComponent, {
      data: {
        failedAccounts,
        issueType: this.accountIssue === 'failed' ? ExternalAccountStatus.Failed : ExternalAccountStatus.Expired,
      },
    });

    dialog.closed.pipe(take(1)).subscribe(async (account: ExternalAccount | undefined) => {
      if (!account) return;

      const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(account.workspaceId));
      this.store.dispatch(
        new Navigate([
          CONVERSATIONS_BASE_URL,
          { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias, WorkspaceSettingsModalTab.Inbox] } },
        ]),
      );
    });
  }
}

<div class="assistant-header">
  <div class="assistant-header__main">
    <div class="assistant-header__main-details">
      <img
        src="assets/svg/common/clover-logo.svg"
        alt="Clover logo"
        width="24"
        height="24"
      />

      <div class="assistant-header__main-details-title">Assistant</div>
    </div>

    @if (canDeleteThread()) {
      <app-button
        ccTooltip="Start a new conversation"
        [tooltipAlignment]="TooltipAlignment.End"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        (click)="deleteThread()"
      >
        <i
          class="icon-pencil-plus"
          slot="ghost-icon"
        ></i>
      </app-button>
    }
  </div>
</div>

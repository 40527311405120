<div
  (ccResize)="handleContainerResize($event)"
  [class.conversations--compact-mode]="compactMode"
  [class.conversations--has-active-conversation]="hasActiveConversation$ | async"
  class="conversations"
>
  <div
    (nzResize)="resizeConversationList($event)"
    [nzDisabled]="compactMode"
    [nzMaxWidth]="conversationListMaxWidth"
    [nzMinWidth]="conversationsListMinWidth"
    [style.width.px]="conversationsListWidth"
    class="conversations__list"
    nz-resizable
  >
    <cc-conversations-list
      [category]="category$ | async"
      [conversations]="conversations$ | async"
      [filterParams]="filterParams$ | async"
      [loadingStatus]="loadingStatus$ | async"
      [workspaceId]="workspaceId$ | async"
    />

    <nz-resize-handle
      (dblclick)="resetConversationsListWidth()"
      class="conversations__list-resize-handle"
      nzDirection="right"
    >
      <div class="conversations__list-resize-handle-line"></div>
    </nz-resize-handle>
  </div>

  <div class="conversations__conversation">
    <router-outlet />
  </div>
</div>

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  templateUrl: './confirm-account-removal-modal.component.html',
  styleUrls: ['./confirm-account-removal-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, DialogModule, TextboxComponent, ButtonComponent, TranslateModule],
})
export class ConfirmAccountRemovalModalComponent {
  readonly externalAccount: ExternalAccount = inject(DIALOG_DATA);

  protected emailFormControl = new FormControl('', [Validators.required]);
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly dialogRef: DialogRef<ExternalAccount> = inject(DialogRef<ExternalAccount>);

  get canRemove(): boolean {
    return this.emailFormControl.valid && this.emailFormControl.value === this.externalAccount.email;
  }

  removeAccount(): void {
    this.dialogRef.close(this.externalAccount);
  }

  cancelRemoval(): void {
    this.dialogRef.close();
  }
}

<div class="assistant-chat-message">
  <div class="assistant-chat-message-sider">
    <img
      [src]="senderAvatar()"
      [alt]="senderName()"
      width="64"
      height="64"
    />
  </div>

  <div class="assistant-chat-message-content">
    <div class="assistant-chat-message-content__header">
      <div
        class="assistant-chat-message-content__header-name"
        [ccTooltip]="senderName()"
        [tooltipAlignment]="TooltipAlignment.Start"
        [showOnlyOnOverflow]="true"
      >
        {{ senderName() }}
      </div>
      <div class="assistant-chat-message-content__header-timestamp">
        {{ timestamp() | ccDateFormat: { time: 'default' } }}
      </div>
    </div>

    <ng-scrollbar orientation="horizontal">
      @if (showThinkingIndicator()) {
        <div class="assistant-chat-message-content__thinking-indicator cc-ellipsis-animation">Thinking</div>
      } @else {
        <markdown
          class="assistant-chat-message-content__body"
          [data]="text()"
        />
      }
    </ng-scrollbar>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { SaveDraft, SetQuote } from '@conversations/composer/state/composers/composers.actions';
import { ComposerHtmlEditorComponent } from '@conversations/composer/composer-quote-input/composer-html-editor/composer-html-editor.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'cc-composer-quote-input',
  standalone: true,
  templateUrl: './composer-quote-input.component.html',
  styleUrls: ['./composer-quote-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [ReactiveFormsModule, ComposerHtmlEditorComponent, ButtonComponent, TooltipDirective, TranslateModule],
})
export class ComposerQuoteInputComponent implements OnInit, OnChanges {
  @Input()
  composerId: string;

  @Input()
  quote: string;

  focusIn = output<void>();

  // editor: Editor;

  protected collapsed = true;
  protected quoteFormControl = new FormControl<string>(undefined);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly store = inject(Store);

  @HostBinding('class.cc-expanded')
  get expanded(): boolean {
    return !this.collapsed;
  }

  ngOnInit(): void {
    // this.editor = new Editor();

    this.quoteFormControl.setValue(this.quote, { emitEvent: false });

    this.quoteFormControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((quote) => quote !== this.quote),
        tap((quote) => {
          this.store.dispatch(new SetQuote(this.composerId, quote));
        }),
        debounceTime(300),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId))),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['quote']) this.quoteFormControl.setValue(this.quote, { emitEvent: false });
  }
}

<cc-table
  (searchChange)="searchFormControl.setValue($event)"
  [ccEmptyRowTemplate]="noSignaturesTemplate"
  [ccSearchDebounceTime]="0"
  [ccSearchPlaceholder]="'conversations-v4.workspaces.settings.signatures.searchPlaceholder' | translate"
  [ccSearchable]="true"
  ccPadding="small"
  class="workspace-settings-signatures-table"
>
  <ng-container slot="cc-table-title">
    {{
      signaturesCount() === 1
        ? ('conversations-v4.workspaces.settings.signatures.signaturesSingular'
          | translate: { count: signaturesCount() })
        : ('conversations-v4.workspaces.settings.signatures.signaturesPlural' | translate: { count: signaturesCount() })
    }}
  </ng-container>

  <ng-container slot="сс-table-custom-actions">
    @if (canEditSignatures()) {
      <app-button
        (click)="create.emit()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.GhostText"
        [ccTooltip]="'conversations-v4.workspaces.settings.signatures.createSignature' | translate"
      >
        <i
          class="icon-create-fill"
          slot="ghost-icon"
        ></i>
      </app-button>
    }
  </ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr>
        <th
          ccTh
          [ccSortable]="true"
          [ccSortDirection]="nameSortingOrder()"
          (ccSortChange)="nameSortingOrder.set($event)"
        >
          {{ 'conversations-v4.workspaces.settings.signatures.name' | translate }}
        </th>

        <th
          ccTh
          ccWidth="80px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (signature of filteredSignatures(); track signature.id) {
        <tr
          ccTr
          class="workspace-settings-signatures-table__row"
        >
          <td ccTd>
            {{ signature.name }}
          </td>

          <td ccTd>
            <div
              [class.workspace-settings-signatures-table__row-actions--disabled]="canEditSignatures() === false"
              class="workspace-settings-signatures-table__row-actions"
            >
              <app-button
                (click)="edit.emit(signature)"
                [size]="ButtonSize.Small"
                [type]="ButtonType.Ghost"
                [ccTooltip]="'common.buttons.edit' | translate"
              >
                <i
                  class="icon-edit-pencil"
                  slot="ghost-icon"
                >
                </i>
              </app-button>

              <app-button
                (click)="delete.emit(signature)"
                [size]="ButtonSize.Small"
                [type]="ButtonType.Ghost"
                [ccTooltip]="'common.buttons.delete' | translate"
              >
                <i
                  class="icon-delete"
                  slot="ghost-icon"
                >
                </i>
              </app-button>
            </div>
          </td>
        </tr>
      }

      <ng-template #noSignaturesTemplate>
        {{ 'conversations-v4.workspaces.settings.signatures.noSignaturesPlaceholder' | translate }}
      </ng-template>
    </tbody>
  </ng-container>
</cc-table>

import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { sanitize } from '@core/helpers/sanitize-email/sanitizer';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-message-body',
  standalone: true,
  templateUrl: './message-body.component.html',
  styleUrls: ['./message-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
  imports: [TranslateModule],
})
export class MessageBodyComponent {
  @Input()
  content: string;

  private readonly domSanitizer = inject(DomSanitizer);

  get sanitizedContent(): SafeHtml {
    const sanitizedContent = sanitize(this.content, null, {
      id: 'message-content',
    });

    return this.domSanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  get contentEmpty(): boolean {
    const plainText = sanitize(this.content, null, {
      noWrapper: true,
      dropAllHtmlTags: true,
    });
    const hasImages = /<img[^>]+>/g.test(this.content);

    return !plainText.trim() && !hasImages;
  }
}

import { Clipboard } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { Task } from '@conversations/tasks/tasks.model';

import { ChangeDetectionStrategy, Component, computed, inject, Injector, input, output } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { getTaskAssignedAtLocale } from '@conversations/tasks/task-card/task-card.component';
import { TaskDrawerActivityComponent } from '@conversations/tasks/task-drawer/task-drawer-activity/task-drawer-activity.component';
import { TaskDrawerDetailsComponent } from '@conversations/tasks/task-drawer/task-drawer-details/task-drawer-details.component';
import { TaskDrawerResultsComponent } from '@conversations/tasks/task-drawer/task-drawer-results/task-drawer-results.component';
import { TaskStatusChipComponent } from '@conversations/tasks/task-status-chip/task-status-chip.component';
import { TasksService } from '@conversations/tasks/tasks.service';
import { CoreModule } from '@core/core.module';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ConfigService } from '@core/services/config.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TabSelectorComponent, TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TaskRole, TaskStatus } from '../tasks.model';

type TaskDrawerTab = 'details' | 'activity' | 'results';

@Component({
  selector: 'cc-task-drawer',
  standalone: true,
  templateUrl: './task-drawer.component.html',
  styleUrls: ['./task-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TaskStatusChipComponent,
    CoreModule,
    ButtonComponent,
    TooltipDirective,
    TabSelectorComponent,
    TaskDrawerDetailsComponent,
    TaskDrawerActivityComponent,
    TaskDrawerResultsComponent,
    AutoAnimateDirective,
    CdkScrollableModule,
    NgScrollbarModule,
    DialogModule,
    DateFormatDistancePipe,
    TranslateModule,
  ],
})
export class TaskDrawerComponent {
  task = input.required<Task>();

  drawerClose = output<void>();

  protected activeTab: TaskDrawerTab = 'details';

  protected readonly getTaskAssignedAtLocale = getTaskAssignedAtLocale;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly tasksService = inject(TasksService);
  private readonly clipboard = inject(Clipboard);
  private readonly portalService = inject(CdkPortalService);
  private readonly injector = inject(Injector);
  private readonly translate = inject(TranslateService);

  protected readonly canCloseTask = computed(() => {
    const task = this.task();
    return ![TaskStatus.Closed, TaskStatus.Completed].includes(task.status) && task.taskRole === TaskRole.Assigner;
  });

  protected readonly canSendReminder = computed(() => {
    const task = this.task();
    return [TaskStatus.NotStarted, TaskStatus.InProgress].includes(task.status) && task.taskRole === TaskRole.Assigner;
  });

  protected readonly canViewResults = computed(() => {
    const task = this.task();
    return task.status === TaskStatus.Completed;
  });

  protected readonly tabs = computed<TabSelectorItem[]>(() => {
    return [
      { id: 'details', labelTranslationKey: 'conversations-v4.tasks.drawerTabs.details', disabled: false },
      { id: 'activity', labelTranslationKey: 'conversations-v4.tasks.drawerTabs.activity', disabled: false },
      {
        id: 'results',
        labelTranslationKey: 'conversations-v4.tasks.drawerTabs.results',
        disabled: !this.canViewResults(),
      },
    ];
  });

  selectTab(tabId: string): void {
    this.activeTab = tabId as TaskDrawerTab;
  }

  async closeTask(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: this.translate.instant('conversations-v4.tasks.closePrompt.title'),
        description: this.translate.instant('conversations-v4.tasks.closePrompt.message'),
        confirmActionText: this.translate.instant('conversations-v4.tasks.closePrompt.closeTask'),
        cancelActionText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: 'compact',
      })) === 'confirm';

    if (confirmed) this.tasksService.closeTask(this.task().id);
  }

  sendReminder(): void {
    this.tasksService.sendReminder(this.task().id);
  }

  copyLink(): void {
    const link = `${ConfigService.settings.apiUrl}/tasks/task_id=${this.task().id}`;
    this.clipboard.copy(link);

    this.portalService.presentToast(this.translate.instant('common.strings.linkCopied'), ToastType.Info);
  }
}

import { HttpClient, HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ComposerInstance } from '@conversations/composer/state/composers/composers-state.model';
import {
  Draft,
  DraftAttachment,
  DraftMessageType,
  EmailParticipant,
  EmailParticipants,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  DraftAttachmentResponse,
  DraftResponse,
  mapDraft,
  mapDraftAttachment,
} from '@conversations/conversation/state/conversation/conversation.service';
import { ConfigService } from '@core/services/config.service';
import { HttpService } from '@core/services/http.service';
import { DynamicValuesService } from '@design/forms/rich-text-editor/extensions/dynamic-values/dynamic-values.service';
import isHTMLEmpty from '@design/forms/rich-text-editor/helpers/is-empty';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum QuoteType {
  Reply = 'Reply',
  Forward = 'Forward',
}

interface QuotedContentResponse {
  content: string;
}

interface TraceIdResponse {
  traceId: string;
}

function mapParticipant(participant: EmailParticipant) {
  return {
    name: participant.name,
    email: participant.email,
    logoUrl: participant.avatarUrl,
  };
}

export interface SaveDraftPayload {
  externalAccountId: number;
  conversationId: string;
  replyToMessageId: string;
  subject: string;
  content: string;
  quotedContent: string;
  signatureContent: string;
  emailParticipants: Omit<EmailParticipants, 'from'>;
  type: DraftMessageType;
}

@Injectable({
  providedIn: 'root',
})
export class ComposersService {
  private readonly http = inject(HttpService);
  private readonly angularHttp = inject(HttpClient);
  private readonly dynamicValuesService = inject(DynamicValuesService);

  private get baseUrl(): string {
    return ConfigService.settings.apiUrl;
  }

  getQuotedContent(externalAccountId: number, messageId: string, quoteType: QuoteType): Observable<string> {
    return this.http
      .postV2<QuotedContentResponse>(`/api/stream-conversations/messages/email/quotedContent`, {
        externalAccountId,
        messageId,
        type: quoteType,
      })
      .pipe(map((response) => response.content));
  }

  getDraft(draftId: number): Observable<Draft> {
    return this.http
      .getV2<DraftResponse>(`/api/stream-conversations/drafts/${draftId}`)
      .pipe(map((response) => mapDraft(response)));
  }

  saveDraft(draftId: number | undefined, draft: SaveDraftPayload): Observable<Draft> {
    const requestBody = {
      externalAccountId: draft.externalAccountId,
      streamConversationId: draft.conversationId,
      replyToStreamMessageId: draft.replyToMessageId,
      subject: draft.subject,
      content: draft.content,
      quotedContent: draft.quotedContent,
      signatureContent: draft.signatureContent,
      to: (draft.emailParticipants?.to || []).map(mapParticipant),
      cc: (draft.emailParticipants?.cc || []).map(mapParticipant),
      bcc: (draft.emailParticipants?.bcc || []).map(mapParticipant),
      type: draft.type,
    };

    if (!draftId) {
      return this.http
        .postV2<DraftResponse>(`/api/stream-conversations/drafts`, requestBody)
        .pipe(map((response) => mapDraft(response)));
    }

    return this.http
      .putV2<DraftResponse>(`/api/stream-conversations/drafts/${draftId}`, requestBody)
      .pipe(map((response) => mapDraft(response)));
  }

  uploadAttachment(draftId: number, file: File): Observable<HttpEvent<unknown>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.angularHttp.post(
      `${this.baseUrl}/api/stream-conversations/drafts/${draftId}/attachments/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  removeAttachment(draftId: number, attachmentId: number): Observable<void> {
    return this.http.deleteV2(`/api/stream-conversations/drafts/${draftId}/attachments/${attachmentId}`);
  }

  attachFileFromCloverStorage(
    draftId: number,
    userFileIds: number[],
    companyFileIds: number[],
  ): Observable<DraftAttachment[]> {
    return this.http
      .postV2<DraftAttachmentResponse[]>(`/api/stream-conversations/drafts/${draftId}/attachments/attach`, {
        userFilesIds: userFileIds,
        companyFilesIds: companyFileIds,
      })
      .pipe(map((response) => response.map(mapDraftAttachment)));
  }

  sendDraftEmail(composer: ComposerInstance): Observable<string> {
    const draft = composer.draft;

    return this.http
      .postV2<TraceIdResponse>(`/api/stream-conversations/messages/asyncEmail`, {
        externalAccountId: composer.senderAccountId,
        conversationId: composer.conversationId,
        subject: draft.subject,
        body: draft.content,
        signatureContent: draft.signature,
        to: (draft.emailParticipants?.to || []).map(mapParticipant),
        cc: (draft.emailParticipants?.cc || []).map(mapParticipant),
        bcc: (draft.emailParticipants?.bcc || []).map(mapParticipant),
        replyToMessageId: draft.replyToMessage.id,
        type: draft.type,
        messageDraftId: draft.id,
      })
      .pipe(map((response) => response.traceId));
  }

  sendDraftInternalMessage(composer: ComposerInstance): Observable<string> {
    const draft = composer.draft;

    return this.http
      .postV2<TraceIdResponse>(`/api/stream-conversations/messages/asyncNote`, {
        conversationId: composer.conversationId,
        text: draft.content,
        messageDraftId: draft.id,
      })
      .pipe(map((response) => response.traceId));
  }

  sendNewEmail(composer: ComposerInstance): Observable<void> {
    return this.http.postV2<void>(`/api/stream-conversations/messages/asyncEmail`, {
      externalAccountId: composer.senderAccountId,
      subject: composer.subject,
      body: composer.message ? composer.message.replace(/&nbsp;/g, ' ') : '',
      signatureContent: composer.signature,
      to: (composer.emailParticipants?.to || []).map(mapParticipant),
      cc: (composer.emailParticipants?.cc || []).map(mapParticipant),
      bcc: (composer.emailParticipants?.bcc || []).map(mapParticipant),
      type: 'New',
    });
  }

  getPersonalizedSignature(signature: string): string | undefined {
    const personalizedSignature = this.dynamicValuesService.replaceDynamicValues(signature);

    if (isHTMLEmpty(personalizedSignature)) return undefined;
    return personalizedSignature;
  }
}

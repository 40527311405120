import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { filter, Observable } from 'rxjs';
import {
  ExternalAccount,
  ExternalAccountStatus,
  ProviderType,
  Workspace,
  WorkspaceOwnerType,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { map, switchMap, take } from 'rxjs/operators';
import { UserService } from '@core/services/user.service';
import { User } from '@core/models/user';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';

interface WorkspaceResponse {
  id: number;
  name: string;
  owner: {
    teamId?: number;
    userId?: number;
  };
  externalAccounts: ExternalAccountResponse[];
}

interface ExternalAccountResponse {
  id: number;
  workspaceId: number;
  accountId: string;
  email: string;
  providerType: ProviderType;
  status: ExternalAccountStatus;
  lastActiveStatusDatetime: string;
  isConnected: boolean;
}

export interface NylasTokenExchangeResponse {
  grantId: string;
  email: string;
}

const mapWorkspace = (workspace: WorkspaceResponse): Workspace => {
  return {
    id: workspace.id,
    name: workspace.name,
    ownerType: workspace.owner.teamId ? WorkspaceOwnerType.Team : WorkspaceOwnerType.User,
    ownerId: workspace.owner.teamId || workspace.owner.userId,
  };
};

const mapExternalAccount = (
  externalAccount: ExternalAccountResponse,
  user: Pick<User, 'fullName' | 'firstName' | 'lastName' | 'logoUrl'>,
): ExternalAccount => {
  return {
    id: externalAccount.id,
    workspaceId: externalAccount.workspaceId,
    avatarUrl: user.logoUrl || generateGenericAvatar(user.firstName, user.lastName, 128),
    name: user.fullName, // Someday we will have an ability to customize the name.
    email: externalAccount.email,
    providerType: externalAccount.providerType,
    lastActiveAt: externalAccount.lastActiveStatusDatetime,
    status: externalAccount.status,
    syncedThreads: undefined,
  };
};

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  getWorkspacesAndExternalAccounts(): Observable<{
    workspaces: Workspace[];
    externalAccounts: ExternalAccount[];
  }> {
    return this.http.getV2<WorkspaceResponse[]>(`api/workspaces`).pipe(
      switchMap((workspaces: WorkspaceResponse[]) =>
        this.waitForUser().pipe(
          map((user: User) => ({
            workspaces,
            user,
          })),
        ),
      ),
      map(({ workspaces, user }) => {
        return {
          workspaces: workspaces.map((workspace: WorkspaceResponse) => mapWorkspace(workspace)),
          externalAccounts: workspaces
            .map((workspace: WorkspaceResponse) => workspace.externalAccounts)
            .reduce((acc, externalAccounts) => acc.concat(externalAccounts), [])
            .map((externalAccount: ExternalAccountResponse) => mapExternalAccount(externalAccount, user)),
        };
      }),
    );
  }

  createExternalAccount(workspaceId: number, accountId: string, email: string): Observable<ExternalAccount> {
    return this.http
      .postV2<ExternalAccountResponse>(`/api/workspaces/${workspaceId}/external-accounts`, {
        accountId,
        email,
      })
      .pipe(
        switchMap((externalAccount: ExternalAccountResponse) =>
          this.waitForUser().pipe(
            map((user: User) => ({
              externalAccount,
              user,
            })),
          ),
        ),
        map(({ externalAccount, user }) => mapExternalAccount(externalAccount, user)),
      );
  }

  exchangeNylasToken(code: string, redirectUri: string): Observable<NylasTokenExchangeResponse> {
    return this.http.postV2<NylasTokenExchangeResponse>(`/api/nylas/token`, {
      code,
      redirect_uri: redirectUri,
    });
  }

  disconnectExternalAccount(workspaceId: number, accountId: number): Observable<void> {
    return this.http.postV2<void>(`/api/workspaces/${workspaceId}/external-accounts/${accountId}/disconnect`, {});
  }

  private waitForUser(): Observable<User> {
    return this.userService.profile$.pipe(
      filter((user: User) => !!user),
      take(1),
    );
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { ButtonComponent } from '../../buttons/button/button.component';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

export interface RoutePickerDialogData {
  title: string;
  description: string;
  options: RoutePickerOption[];
}

interface RoutePickerOption {
  id: string;
  icon: string;
  title: string;
  description: string;
}

export type RoutePickerDialogResult = string | undefined;

@Component({
  selector: 'cc-route-picker-dialog',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
  templateUrl: './route-picker-dialog.component.html',
  styleUrl: './route-picker-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePickerDialogComponent {
  readonly dialogData: RoutePickerDialogData = inject(DIALOG_DATA);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly dialogRef: DialogRef<RoutePickerDialogResult> = inject(DialogRef);
}

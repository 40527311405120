import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import {
  DEFAULT_LABEL_BACKGROUND_COLOR,
  DEFAULT_LABEL_FOREGROUND_COLOR,
} from '@design/misc/label-icon/label-icon.component';

export interface LabelResponse {
  id: number;
  workspaceId: number;
  name: string;
  backgroundColor: string;
  textColor: string;
  isSystem: boolean;
  isMy: boolean;
  showInNav: boolean;
}

const mapLabel = (label: LabelResponse): Label => {
  return {
    id: label.id,
    workspaceId: label.workspaceId,
    name: label.name,
    backgroundColor: label.backgroundColor,
    foregroundColor: label.textColor,
    system: label.isSystem,
    private: label.isMy,
    visibleInNavigation: label.showInNav,
  };
};

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  private readonly http = inject(HttpService);

  getWorkspaceLabels(workspaceId: number): Observable<Label[]> {
    return this.http
      .getV2<LabelResponse[]>(`/api/workspaces/${workspaceId}/tags/search`)
      .pipe(map((labels) => labels.map(mapLabel)));
  }

  getWorkspaceLabel(workspaceId: number, labelId: number): Observable<Label> {
    return this.http.getV2<LabelResponse[]>(`/api/workspaces/${workspaceId}/tags?ids=${labelId}`).pipe(
      map((labels) => labels[0]),
      map(mapLabel),
    );
  }

  createLabel(
    workspaceId: number,
    label: Pick<Label, 'name' | 'backgroundColor' | 'foregroundColor' | 'visibleInNavigation'>,
  ): Observable<Label> {
    return this.http
      .postV2<LabelResponse>(`/api/workspaces/${workspaceId}/tags`, {
        name: label.name,
        backgroundColor: label.backgroundColor,
        textColor: label.foregroundColor,
        showInNav: label.visibleInNavigation,
      })
      .pipe(map(mapLabel));
  }

  updateLabel(
    workspaceId: number,
    labelId: number,
    newValues: Pick<Label, 'name' | 'backgroundColor' | 'foregroundColor' | 'visibleInNavigation'>,
  ): Observable<Label> {
    return this.http
      .putV2<LabelResponse>(`/api/workspaces/${workspaceId}/tags/${labelId}`, {
        name: newValues.name,
        backgroundColor: newValues.backgroundColor || DEFAULT_LABEL_BACKGROUND_COLOR,
        textColor: newValues.foregroundColor || DEFAULT_LABEL_FOREGROUND_COLOR,
        showInNav: newValues.visibleInNavigation,
      })
      .pipe(map(mapLabel));
  }

  deleteLabel(workspaceId: number, labelId: number): Observable<void> {
    return this.http.deleteV2(`/api/workspaces/${workspaceId}/tags/${labelId}`);
  }
}

<div class="system-message-body">
  @switch (systemMetadata.type) {
    @case (SystemMessageType.ConversationAssigned) {
      {{
        'conversations-v4.conversation.messages.message.system.assigned'
          | translate: { assignee: systemMetadata.assigneeUser.name, performer: systemMetadata.performer.name }
      }}
    }

    @case (SystemMessageType.ConversationUnassigned) {
      {{
        'conversations-v4.conversation.messages.message.system.unassigned'
          | translate: { performer: systemMetadata.performer.name }
      }}
    }

    @case (SystemMessageType.TeamMembersAdded) {
      {{
        'conversations-v4.conversation.messages.message.system.membersAdded'
          | translate: { members: membersString, performer: systemMetadata.performer.name }
      }}
    }

    @case (SystemMessageType.TeamMembersRemoved) {
      {{
        'conversations-v4.conversation.messages.message.system.membersRemoved'
          | translate: { members: membersString, performer: systemMetadata.performer.name }
      }}
    }
  }
</div>

<div
  (ccResize)="handleContainerResize($event)"
  class="active-conversation"
>
  <div class="active-conversation__content">
    <cc-conversation
      [accountIssue]="parentAccountIssue$ | async"
      [account]="parentAccount$ | async"
      [assignee]="assignee$ | async"
      [details]="details$ | async"
      [hasNextMessages]="hasNextMessages$ | async"
      [hasPreviousMessages]="hasPreviousMessages$ | async"
      [labels]="labels$ | async"
      [messagesLoadingStatus]="messagesLoadingStatus$ | async"
      [messages]="messages$ | async"
      [pendingMessages]="pendingMessages$ | async"
      [workspace]="workspace$ | async"
    ></cc-conversation>
  </div>

  <div
    (nzResize)="resizeConversationDrawer($event)"
    [class.active-conversation__drawer--hidden]="!drawerOutlet.isActivated"
    [class.active-conversation__drawer--overlay]="drawerOverlayMode"
    [nzDisabled]="!drawerOutlet.isActivated"
    [nzMaxWidth]="conversationDrawerMaxWidth"
    [nzMinWidth]="conversationDrawerMinWidth"
    [style.width.px]="conversationDrawerWidth"
    class="active-conversation__drawer"
    nz-resizable
  >
    <router-outlet
      #drawerOutlet="outlet"
      (activate)="cdr.detectChanges()"
      (deactivate)="cdr.detectChanges()"
      name="info"
    ></router-outlet>

    <nz-resize-handle
      (dblclick)="resetConversationDrawerWidth()"
      class="active-conversation__drawer-resize-handle"
      nzDirection="left"
    >
      <div class="active-conversation__drawer-resize-handle-line"></div>
    </nz-resize-handle>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Output } from '@angular/core';
import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { BpiOrderDrawerComponent } from '../bpi-order-drawer/bpi-order-drawer.component';
import { MOCK_BPI_INVOICES, type BPIInvoice } from '../business-object';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { BpiInvoiceDrawerComponent } from '../bpi-invoice-drawer/bpi-invoice-drawer.component';
import { DateFormatPipe } from '@clover/core/pipes/date-format.pipe';

@Component({
  selector: 'cc-bpi-invoice-table',
  standalone: true,
  imports: [
    TextboxComponent,
    ButtonComponent,
    NgOptimizedImage,
    TooltipDirective,
    BpiOrderDrawerComponent,
    CurrencyPipe,
    BpiInvoiceDrawerComponent,
    DateFormatPipe,
  ],
  templateUrl: './bpi-invoice-table.component.html',
  styleUrls: ['./bpi-invoice-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('drawer', [
      transition(':enter', [
        style({ width: 0, minWidth: '0' }),
        animate(
          '0.2s ease-in-out',
          style({
            width: '320px',
            minWidth: '320px',
          }),
        ),
      ]),
      transition(':leave', [
        style({ width: '320px', minWidth: '320px' }),
        animate(
          '0.2s ease-in-out',
          style({
            width: 0,
            minWidth: '0',
          }),
        ),
      ]),
    ]),
  ],
})
export class BpiInvoiceTableComponent {
  @Output()
  selectInvoice = new EventEmitter<BPIInvoice>();

  protected activeInvoiceId: string | null = null;

  protected readonly TextboxType = TextboxType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly MOCK_BPI_INVOICES = MOCK_BPI_INVOICES;

  private readonly cdr = inject(ChangeDetectorRef);

  get activeInvoice(): BPIInvoice | undefined {
    return this.MOCK_BPI_INVOICES.find((invoice) => invoice.id === this.activeInvoiceId);
  }

  showInDrawer(invoice: BPIInvoice | null): void {
    this.activeInvoiceId = invoice?.id;
    this.cdr.detectChanges();
  }

  getStatusText(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return 'Paid';
      case 'disputed':
        return 'Disputed';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#08A262';
      case 'disputed':
        return '#AB2345';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(invoice: BPIInvoice): string {
    switch (invoice.status) {
      case 'paid':
        return '#D6F9EA';
      case 'disputed':
        return '#FBE4E9';
      default:
        return '#FFFFFF';
    }
  }
}

import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { SelectComponent } from '@design/forms/select/select.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RichTextEditorBaseControlComponent } from '../base-action.component';

@Component({
  selector: 'cc-rich-text-editor-font-size-control',
  standalone: true,
  imports: [
    SelectComponent,
    SelectItemComponent,
    FormsModule,
    DropdownDividerComponent,
    DropdownTextComponent,
    TranslateModule,
    TooltipDirective,
  ],
  templateUrl: './font-size-control.component.html',
  styleUrls: ['./font-size-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorFontSizeControlComponent extends RichTextEditorBaseControlComponent {
  fontSize = computed(() => {
    this.selectionNotifier.listen();

    return (
      this.fontSizeOptions.find((option) =>
        this.activeEditor()?.isActive('textStyle', { fontSize: `${option.payload}px` }),
      ) ?? this.defaultFontSize
    );
  });

  private readonly translate = inject(TranslateService);

  protected get fontSizeOptions(): SelectItem<number>[] {
    return [
      { title: this.translate.instant('richTextEditor.actions.fontSize.small'), id: 'small', payload: 10 },
      {
        title: this.translate.instant('richTextEditor.actions.fontSize.normal'),
        id: 'normal',
        payload: undefined,
      },
      { title: this.translate.instant('richTextEditor.actions.fontSize.large'), id: 'large', payload: 20 },
      { title: this.translate.instant('richTextEditor.actions.fontSize.huge'), id: 'huge', payload: 24 },
      { title: this.translate.instant('richTextEditor.actions.fontSize.giant'), id: 'giant', payload: 32 },
    ];
  }

  protected readonly defaultFontSize = this.fontSizeOptions[1];

  setFontSize(option: SelectItem<number | undefined>): void {
    if (!this.activeEditor()) return;

    option.payload
      ? this.activeEditor().chain().focus().setFontSize(`${option.payload}px`).run()
      : this.activeEditor().chain().focus().unsetFontSize().run();

    this.selectionNotifier.notify();
  }
}

<cc-message-collapsible-group
  (collapse)="collapsed = true"
  (expand)="collapsed = false"
  [collapsed]="collapsed"
>
  <ng-container slot="title">
    @if (attachments.length === 1) {
      {{ 'conversations-v4.composer.attachments.attachmentsCountSingular' | translate }}
    }
    @if (attachments.length !== 1) {
      {{ 'conversations-v4.composer.attachments.attachmentsCountPlural' | translate: { count: attachments.length } }}
    }
  </ng-container>

  <ng-container slot="action">
    <div (click)="downloadAll()">
      {{ 'conversations-v4.common.saveAll' | translate }}
    </div>
  </ng-container>

  <div class="message-attachments">
    @for (attachment of attachments; track attachment.id) {
      <div class="message-attachments__item">
        <i class="message-attachments__item-icon icon-editor-attachment"></i>

        <div class="message-attachments__item-name">
          {{ attachment.name }}
        </div>

        <div class="message-attachments__item-size">
          {{ attachment.size | formatBytes }}
        </div>

        <app-button
          (click)="downloadAttachment(attachment)"
          [size]="ButtonSize.Small"
          [tooltipAlignment]="TooltipAlignment.End"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.common.downloadAttachment' | translate"
          class="message-attachments__item-download"
        >
          <i
            class="icon-download"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>
    }
  </div>
</cc-message-collapsible-group>

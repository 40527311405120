import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import { TaskHistoryEventType } from '@clover/tasks-history/models/task-history-event';
import { UserAvatarComponent } from '../../../../../../stories/misc/user-avatar/user-logo.component';
import type { TaskActivityEntry } from '../task-activity.service';

@Component({
  selector: 'cc-task-drawer-activity-entry',
  standalone: true,
  imports: [DateFormatDistancePipe, UserAvatarComponent, NgTemplateOutlet],
  templateUrl: './task-drawer-activity-entry.component.html',
  styleUrl: './task-drawer-activity-entry.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDrawerActivityEntryComponent {
  entry = input.required<TaskActivityEntry>();

  protected readonly EntryType = TaskHistoryEventType;

  protected iconClass = computed(() => {
    const type = this.entry().type;

    switch (type) {
      case TaskHistoryEventType.TaskStarted:
        return 'icon-task-started';
      case TaskHistoryEventType.Assigned:
        return 'icon-user';
      case TaskHistoryEventType.Reassigned:
        return 'icon-task-reassigned';
      case TaskHistoryEventType.TaskAccepted:
        return 'icon-user-add';
      case TaskHistoryEventType.StepStarted:
        return 'icon-task-step-started';
      case TaskHistoryEventType.StepSubmitted:
        return 'icon-success';
      case TaskHistoryEventType.StepRestarted:
        return 'icon-restart';
      case TaskHistoryEventType.TaskClosed:
        return 'icon-task-close';
      case TaskHistoryEventType.TaskCompleted:
        return 'icon-tasks';
      case TaskHistoryEventType.ProductTaskCopied:
        return 'icon-copy';
    }
  });

  protected readonly assignedToType = computed<'user' | 'department' | undefined>(() => {
    const entry = this.entry();

    const assignedToDepartments = entry.metadata.assigneeRoles?.length > 0;
    const assignedToUser = entry.metadata.assigneeUsers?.length > 0;

    if (assignedToDepartments) return 'department';
    if (assignedToUser) return 'user';

    return undefined;
  });
}

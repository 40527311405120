import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';

import { RouterLink } from '@angular/router';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConversationHeaderComponent } from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header.component';
import { ConversationMessagesComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/conversation-messages.component';
import {
  ConversationDetails,
  Message,
  PendingMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timer } from 'rxjs';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import { ConversationInlineComposerComponent } from '@conversations/conversation/active-conversation/conversation/conversation-composer/conversation-inline-composer.component';
import { ExternalAccount, Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';

const autoReadTimeout = 3000;

@UntilDestroy()
@Component({
  selector: 'cc-conversation',
  standalone: true,
  imports: [
    RouterLink,
    AutoAnimateDirective,
    ConversationHeaderComponent,
    ConversationMessagesComponent,
    ConversationInlineComposerComponent,
  ],
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('messages', [
      state('default', style({ height: '*', opacity: 1 })),
      state('collapsed', style({ height: 0, opacity: 0 })),
      transition('default <=> collapsed', animate('0.2s ease-in-out')),
    ]),
    trigger('composer', [
      state('default', style({ height: '*', maxHeight: '50%', overflow: 'visible' })),
      state('full-height', style({ height: '100%', maxHeight: '100%', overflow: 'hidden' })),
      transition('default <=> full-height', animate('0.2s ease-in-out')),
    ]),
  ],
})
export class ConversationComponent implements OnInit {
  @Input()
  details: ConversationDetails;

  @Input()
  assignee: boolean;

  @Input()
  workspace: Workspace;

  @Input()
  labels: Label[];

  @Input()
  account: ExternalAccount;

  @Input()
  accountIssue: 'failed' | 'expired' | 'disconnected' | undefined;

  @Input()
  messages: Message[];

  @Input()
  pendingMessages: PendingMessage[] = [];

  @Input()
  messagesLoadingStatus: 'void' | 'loading' | 'loaded' | 'loading-prev' | 'loading-next' | 'error' = 'void';

  @Input()
  hasPreviousMessages: boolean;

  @Input()
  hasNextMessages: boolean;

  protected composerAppearance: 'default' | 'full-height' = 'default';

  private readonly conversationActionsService = inject(ConversationActionsDistributorService);

  get messagesAnimationState(): 'default' | 'collapsed' {
    if (this.composerAppearance === 'full-height') return 'collapsed';
    return 'default';
  }

  ngOnInit(): void {
    timer(autoReadTimeout)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.accountIssue) return;
        this.conversationActionsService.updateReadStatus(this.details.id, true, true);
      });
  }
}

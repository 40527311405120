import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MICROSOFT_BUTTON_TEXT_MAP,
  MicrosoftButtonText,
  MicrosoftButtonType,
} from '@design/buttons/microsoft-button/microsoft-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-microsoft-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './microsoft-button.component.html',
  styleUrl: './microsoft-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MicrosoftButtonComponent {
  @Input()
  type: MicrosoftButtonType = MicrosoftButtonType.Standard;

  @Input()
  text: MicrosoftButtonText = MicrosoftButtonText.SignInWithMicrosoft;

  @Input()
  disabled = false;

  protected readonly MicrosoftButtonType = MicrosoftButtonType;

  get buttonTextKey(): string {
    return MICROSOFT_BUTTON_TEXT_MAP.get(this.text) || '';
  }
}

import { Validators } from '@angular/forms';
import { of } from 'rxjs';

/**
 * Simple Validation with If condition
 */
function ifValidator(condition, validatorFn) {
    return (control) => {
        if (!condition(control)) {
            return null;
        }
        if (!Array.isArray(validatorFn)) {
            return validatorFn(control);
        }
        if (validatorFn.length === 0) {
            return null;
        }
        if (validatorFn.length === 1) {
            return validatorFn[0](control);
        }
        const composed = Validators.compose(validatorFn);
        return composed ? composed(control) : null;
    };
}
/**
 * With Async Validation
 */
function ifAsyncValidator(condition, validatorFn) {
    return (control) => {
        if (!condition(control)) {
            return of(null);
        }
        return validatorFn(control);
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ifAsyncValidator, ifValidator };


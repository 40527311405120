import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cc-workspace-settings-notifications',
  standalone: true,
  imports: [],
  templateUrl: './workspace-settings-notifications.component.html',
  styleUrls: ['./workspace-settings-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsNotificationsComponent {}

import { Overlay, type OverlayRef, type PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DestroyRef, signal, type Injector, type WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { TaskPreview } from '@clover/conversations-v4/tasks/tasks.model';
import { ConfigService } from '@clover/core/services/config.service';
import { Extension, type Editor } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion, { type SuggestionOptions } from '@tiptap/suggestion';
import { firstValueFrom, map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { insertLink } from '../link/link.extension';
import { TaskAutocompleteDropdownComponent } from './task-autocomplete-dropdown/task-autocomplete-dropdown.component';
import { TaskAutocompleteService } from './task-autocomplete.service';

const TASK_SUGGESTIONS_LIMIT = 5;
const TASK_ID_MIN_LENGTH = 0;

interface TaskAutocompleteOverlayInstance {
  ref: OverlayRef;
  instance: TaskAutocompleteDropdownComponent;
}

export function withTaskAutocomplete(injector: Injector) {
  return [TaskAutocomplete(injector)];
}

export const TaskAutocompletePluginKey = new PluginKey('taskAutocomplete');

const TaskAutocomplete = (injector: Injector): Extension => {
  const cancel$ = new Subject<void>();
  const overlayInstance$: WritableSignal<TaskAutocompleteOverlayInstance | null> = signal(null);

  const options: Omit<SuggestionOptions<TaskPreview, TaskPreview>, 'editor'> = {
    pluginKey: TaskAutocompletePluginKey,
    char: '#',
    allowSpaces: false,
    decorationClass: 'task-suggestion',
    items: ({ query }) => getItems(injector, query, cancel$),
    render: () => {
      return {
        onStart: ({ decorationNode, items, command }) => {
          createOverlay(injector, overlayInstance$, decorationNode, items, command);
        },
        onUpdate: ({ decorationNode, items }) => {
          updateOverlay(injector, overlayInstance$, decorationNode, items);
        },
        onKeyDown({ event }) {
          const dropdownVisible = !!overlayInstance$() && overlayInstance$().instance?.tasks()?.length > 0;
          if (!dropdownVisible) return false;

          if (['Enter', 'Tab', 'Space'].includes(event.key)) {
            event.preventDefault();
            return true;
          }

          if (event.key === 'Escape') {
            destroyOverlay(overlayInstance$);
            return true;
          }
        },
        onExit: () => {
          destroyOverlay(overlayInstance$);
        },
      };
    },
    command: ({ editor, range: { from }, props: task }) => {
      destroyOverlay(overlayInstance$);

      const to = from + getDecorationNode(editor).textContent.length;
      const range = { from, to };

      insertLink(
        editor,
        {
          href: `${ConfigService.settings.apiUrl}/tasks/task_id=${task.id}`,
          title: `#${task.id}`,
        },
        range,
        true,
      );
    },
    allow: ({ state, range }) => {
      const node = state.doc.nodeAt(range.from);
      const hasLinkMark = node.marks.some((mark) => mark.type.name === 'link');

      return !hasLinkMark;
    },
  };

  return Extension.create({
    name: 'taskAutocomplete',
    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...options,
        }),
      ];
    },
  });
};

async function getItems(injector: Injector, query: string, cancel$: Subject<void>): Promise<TaskPreview[]> {
  cancel$.next();

  const taskIdRegex = /^\d+$/; // Only digits
  if (query && (query.length < TASK_ID_MIN_LENGTH || !taskIdRegex.test(query))) return [];

  const taskAutocompleteService = injector.get(TaskAutocompleteService);
  const destroyRef = injector.get(DestroyRef);

  return await firstValueFrom(
    taskAutocompleteService
      .getTasksSuggestions(query, {
        limit: TASK_SUGGESTIONS_LIMIT,
      })
      .pipe(
        takeUntil(cancel$),
        takeUntilDestroyed(destroyRef),
        map((response) => response.data),
      ),
    {
      defaultValue: [],
    },
  );
}

function createOverlay(
  injector: Injector,
  overlayInstance$: WritableSignal<TaskAutocompleteOverlayInstance | null>,
  decorationNode: Element,
  items: TaskPreview[],
  command,
): void {
  const overlayBuilder = injector.get(Overlay);
  const destroyRef = injector.get(DestroyRef);

  const overlay = overlayBuilder.create({
    positionStrategy: getPositionStrategy(decorationNode, overlayBuilder),
    hasBackdrop: false,
    scrollStrategy: overlayBuilder.scrollStrategies.reposition(),
  });

  overlay
    .outsidePointerEvents()
    .pipe(takeUntilDestroyed(destroyRef))
    .subscribe(() => {
      destroyOverlay(overlayInstance$);
    });

  const instance = overlay.attach(new ComponentPortal(TaskAutocompleteDropdownComponent)).instance;
  instance.tasks.set(items);
  instance.selectTask.subscribe(command);

  overlayInstance$.set({ ref: overlay, instance });

  destroyRef.onDestroy(() => {
    destroyOverlay(overlayInstance$);
  });
}

function updateOverlay(
  injector: Injector,
  overlayInstance$: WritableSignal<TaskAutocompleteOverlayInstance | null>,
  decorationNode: Element,
  items: TaskPreview[],
): void {
  const overlayInstance = overlayInstance$();
  if (!overlayInstance) return;

  overlayInstance.ref.updatePositionStrategy(getPositionStrategy(decorationNode, injector.get(Overlay)));
  overlayInstance.instance.tasks.set(items);
}

function destroyOverlay(overlayInstance$: WritableSignal<TaskAutocompleteOverlayInstance | null>): void {
  const overlayInstance = overlayInstance$();
  if (!overlayInstance) return;

  overlayInstance.ref.dispose();
  overlayInstance$.set(null);
}

function getPositionStrategy(decorationNode: Element, overlay: Overlay): PositionStrategy {
  return overlay
    .position()
    .flexibleConnectedTo(decorationNode)
    .withPositions([
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: -8,
        offsetY: -6,
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: 8,
        offsetY: -6,
      },
    ]);
}

function getDecorationNode(editor: Editor): Element {
  return editor.view.dom.querySelector('.task-suggestion')!;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompactMessage } from '@conversations/conversation/state/conversation/conversation-state.model';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { ConversationPerformer } from '@conversations/conversations/state/conversations/conversations-state.model';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-message-reply-to-reference',
  standalone: true,
  imports: [TooltipDirective, TranslateModule],
  templateUrl: './message-reply-to-reference.component.html',
  styleUrl: './message-reply-to-reference.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageReplyToMessageComponent {
  @Input({ required: true })
  reference: CompactMessage;

  protected readonly TooltipAlignment = TooltipAlignment;

  get sender(): ConversationPerformer {
    return this.reference.sender;
  }

  get senderAvatar(): string {
    if (this.sender.avatarUrl) return this.sender.avatarUrl;

    const senderNameTokens = this.sender.name.split(' ');
    const firstName = senderNameTokens[0];
    const lastName = senderNameTokens[1];

    return generateGenericAvatar(firstName || '—', lastName, 64);
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { downloadBlob } from '@core/helpers/download-blob';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';
import { ccPreventDuplicateSubscriptions } from '@core/helpers/prevent-duplicate-subscriptions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private readonly http = inject(HttpService);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  @ccPreventDuplicateSubscriptions('first-wins')
  downloadAttachment(conversationId: string, messageId: string, attachmentId: string, fileName: string) {
    this.portalService.presentToast(
      this.translate.instant('conversations-v4.conversation.toasts.downloadStarted'),
      ToastType.Info,
    );

    return this.http
      .getV2(
        `/api/stream-conversations/${conversationId}/messages/${messageId}/attachments/${attachmentId}/download`,
        undefined,
        undefined,
        'blob',
      )
      .pipe(
        tap((blob: Blob) => {
          downloadBlob(blob, fileName);

          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloaded'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloadFailed'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  downloadAllAttachments(conversationId: string, messageId: string) {
    this.portalService.presentToast(
      this.translate.instant('conversations-v4.conversation.toasts.downloadStarted'),
      ToastType.Info,
    );
    return this.http
      .getV2(
        `/api/stream-conversations/${conversationId}/messages/${messageId}/attachments/all/download`,
        undefined,
        undefined,
        'blob',
      )
      .pipe(
        tap((blob: Blob) => {
          downloadBlob(blob, 'attachments.zip');
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloaded'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloadFailed'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  downloadDraftAttachment(draftId: number, attachmentId: number, fileName: string) {
    this.portalService.presentToast(
      this.translate.instant('conversations-v4.conversation.toasts.downloadStarted'),
      ToastType.Info,
    );
    return this.http
      .getV2(
        `/api/stream-conversations/drafts/${draftId}/attachments/${attachmentId}/download`,
        undefined,
        undefined,
        'blob',
      )
      .pipe(
        tap((blob: Blob) => {
          downloadBlob(blob, fileName);

          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloaded'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloadFailed'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  downloadAllDraftAttachments(draftId: number) {
    this.portalService.presentToast(
      this.translate.instant('conversations-v4.conversation.toasts.downloadStarted'),
      ToastType.Info,
    );
    return this.http
      .getV2(`/api/stream-conversations/drafts/${draftId}/attachments/all/download`, undefined, undefined, 'blob')
      .pipe(
        tap((blob: Blob) => {
          downloadBlob(blob, 'attachments.zip');
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloaded'),
            ToastType.Success,
          );
        }),
        catchError((error) => {
          this.portalService.presentToast(
            this.translate.instant('conversations-v4.conversation.toasts.downloadFailed'),
            ToastType.Error,
          );
          return of(error);
        }),
      )
      .subscribe();
  }
}

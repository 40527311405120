import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { CoreModule } from '@core/core.module';
import { FormatBytesPipePipe } from '@core/pipes/format-bytes.pipe';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ComposerHtmlEditorComponent } from '@conversations/composer/composer-quote-input/composer-html-editor/composer-html-editor.component';
import {
  RemoveActiveFileUpload,
  RemoveDraftAttachment,
  UploadFile,
} from '@conversations/composer/state/composers/composers.actions';

import { FileUpload } from '@conversations/composer/state/composers/composers-state.model';
import { DraftAttachment } from '@conversations/conversation/state/conversation/conversation-state.model';
import { ProgressBarComponent } from '@design/misc/progress-bar/progress-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-attachments',
  standalone: true,
  templateUrl: './composer-attachments.component.html',
  styleUrls: ['./composer-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerHtmlEditorComponent,
    CoreModule,
    TooltipDirective,
    ButtonComponent,
    FormatBytesPipePipe,
    ProgressBarComponent,
    TranslateModule,
  ],
})
export class ComposerAttachmentsComponent implements OnChanges {
  @Input()
  composerId: string;

  @Input()
  draftAttachments: DraftAttachment[] = [];

  @Input()
  activeUploads: FileUpload[] = [];

  protected collapsed = true;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  private readonly store = inject(Store);

  @HostBinding('class.cc-expanded')
  get expanded(): boolean {
    return !this.collapsed;
  }

  get count(): number {
    return this.draftAttachments.length + this.activeUploads.length;
  }

  get totalSize(): number {
    return (
      this.draftAttachments.reduce((accumulator, attachment) => accumulator + attachment.size, 0) +
      this.activeUploads.reduce((accumulator, upload) => accumulator + upload.file.size * upload.progress, 0)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousNumberOfAttachments =
      (changes['draftAttachments']?.previousValue?.length || 0) +
      (changes['activeUploads']?.previousValue?.length || 0);

    const currentNumberOfAttachments = this.count;

    if (currentNumberOfAttachments > previousNumberOfAttachments) {
      this.collapsed = false;
    }
  }

  formatProgress(progress: number): string {
    return `${Math.round(progress * 100)}%`;
  }

  retryUpload(attachment: FileUpload): void {
    this.cancelUpload(attachment);
    this.store.dispatch(new UploadFile(this.composerId, attachment.file));
  }

  cancelUpload(attachment: FileUpload): void {
    attachment.cancel$.next();
    this.store.dispatch(new RemoveActiveFileUpload(this.composerId, attachment.id));
  }

  removeAttachment(attachment: DraftAttachment): void {
    this.store.dispatch(new RemoveDraftAttachment(this.composerId, attachment.id));
  }
}

<img
  [src]="avatarSrc()"
  [alt]="name()"
  [width]="size() * 2"
  [height]="size() * 2"
  [style.width.px]="size()"
  [style.height.px]="size()"
/>

<div
  ccTooltip="Online"
  class="online-indicator"
  [style.--size.px]="size()"
  [style.--indicator-size.px]="size() / 6"
></div>

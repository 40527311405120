import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import type { TaskPreview } from '@clover/conversations-v4/tasks/tasks.model';
import {
  type TaskPreviewResponse,
  type TasksSortingProperty,
  mapTaskPreview,
} from '@clover/conversations-v4/tasks/tasks.service';
import { type PagingOptions, type PagingWrapper, getPagingOptionsParams } from '@clover/core/helpers/paging';
import { HttpService } from '@clover/core/services/http.service';
import { type Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskAutocompleteService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  getTasksSuggestions(
    query: string,
    pagingOptions?: PagingOptions<TasksSortingProperty>,
  ): Observable<PagingWrapper<TaskPreview>> {
    const urlTree = this.router.createUrlTree(['api', 'tasks', 'suggestions'], {
      queryParams: {
        query,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<PagingWrapper<TaskPreviewResponse>>(path)
      .pipe(map((response) => ({ ...response, data: response.data.map(mapTaskPreview) })));
  }
}

import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';

import {
  DEFAULT_LABEL_BACKGROUND_COLOR,
  DEFAULT_LABEL_FOREGROUND_COLOR,
} from '@design/misc/label-icon/label-icon.component';
import { TooltipAlignment, TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';

@Component({
  selector: 'cc-label-chip',
  standalone: true,
  imports: [TooltipDirective],
  templateUrl: './label-chip.component.html',
  styleUrls: ['./label-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelChipComponent {
  @Input()
  label: Pick<Label, 'name' | 'private' | 'system' | 'backgroundColor' | 'foregroundColor'>;

  @Input()
  clickable = true;

  @Input()
  showRemoveButton = false;

  @Input()
  labelTooltip: string;

  @Input()
  removeLabelTooltip: string | undefined;

  labelClick = output<void>();

  labelRemove = output<void>();
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly TooltipAlignment = TooltipAlignment;

  get backgroundColor(): string {
    return this.label.backgroundColor || DEFAULT_LABEL_BACKGROUND_COLOR;
  }

  get foregroundColor(): string {
    return this.label.foregroundColor || DEFAULT_LABEL_FOREGROUND_COLOR;
  }
}

<div
  [class.conversations-list-item--selected]="active"
  [class.conversations-list-item--unread]="!conversation.read"
  class="conversations-list-item"
>
  <div class="conversations-list-item__sidebar">
    <div
      tabindex="0"
      (keyup.enter)="toggleReadStatus($event)"
      (click)="toggleReadStatus($event)"
      [ccTooltip]="
        !conversation.read
          ? ('conversations-v4.conversationsList.item.markAsRead' | translate)
          : ('conversations-v4.conversationsList.item.markAsUnread' | translate)
      "
      [class.conversations-list-item__sidebar-read-toggle--hidden]="parentAccountHasIssues$ | async"
      [class.conversations-list-item__sidebar-read-toggle--disabled]="readToggleDisabled()"
      [tooltipAlignment]="TooltipAlignment.Start"
      class="conversations-list-item__sidebar-read-toggle"
    >
      <div class="conversations-list-item__sidebar-read-toggle-dot"></div>
    </div>
  </div>

  <div class="conversations-list-item__content">
    <div class="conversations-list-item__content-row">
      <span class="conversations-list-item__content-sender-name">{{
        conversation.initialSender?.name || ('conversations-v4.conversationsList.item.unknownSender' | translate)
      }}</span>

      <span
        ccTooltip="Last message"
        class="conversations-list-item__content-last-message-time"
      >
        {{
          conversation.latestMessage.updatedAt
            | ccDateFormatDistance
              : {
                  strict: true,
                  locale: getLastMessageDateLocale(),
                }
        }}
      </span>
    </div>

    <div class="conversations-list-item__content-row">
      <div class="conversations-list-item__content-subject">
        @if (conversation.latestMessage.draft) {
          <span class="conversations-list-item__content-subject-draft-indicator"
            >{{ 'conversations-v4.conversationsList.item.draft' | translate }},&nbsp;</span
          >
        }

        <span
          [class.conversations-list-item__content-subject-title--empty]="subjectEmpty"
          class="conversations-list-item__content-subject-title"
        >
          @if (!subjectEmpty) {
            {{ conversation.subject }}
          } @else {
            {{ 'conversations-v4.common.noSubject' | translate }}
          }
        </span>
      </div>

      <div class="conversations-list-item__content-indicators">
        @if (hasLabels$ | async) {
          <i
            [ccTooltip]="'conversations-v4.conversationsList.item.labels' | translate"
            class="icon-tag-alt"
          ></i>
        }

        @if (conversation.hasAttachments) {
          <i
            [ccTooltip]="'conversations-v4.conversationsList.item.attachments' | translate"
            class="icon-attachment-alt"
          ></i>
        }

        <!-- TODO: Implement tasks indicator -->
        @if (false) {
          <i
            [ccTooltip]="'conversations-v4.conversationsList.item.tasks' | translate"
            class="icon-tasks"
          ></i>
        }

        @if (conversation.prioritized) {
          <i
            [ccTooltip]="'conversations-v4.conversationsList.item.prioritized' | translate"
            class="icon-star-fill"
          ></i>
        }
      </div>
    </div>

    <div class="conversations-list-item__content-row">
      <div class="conversations-list-item__content-last-message">
        <cc-user-avatar
          [ccTooltip]="conversation.latestMessage.sender?.name"
          [tooltipAlignment]="TooltipAlignment.Start"
          [tooltipDisabled]="!conversation.latestMessage.sender?.name"
          [src]="senderAvatar"
          [name]="conversation.latestMessage.sender?.name"
          [id]="conversation.latestMessage.sender?.id"
          [size]="18"
        />

        <span
          [class.conversations-list-item__content-last-message-snippet--empty]="messageEmpty"
          class="conversations-list-item__content-last-message-snippet"
        >
          @if (!messageEmpty) {
            {{ conversation.latestMessage.messageSnippet }}
          } @else {
            {{ 'conversations-v4.common.emptyMessage' | translate }}
          }
        </span>
      </div>

      <div class="conversations-list-item__content-unread-counters">
        <!-- TODO: Implement drafts indicator -->
        @if (false) {
          <span
            [ccTooltip]="'conversations-v4.conversationsList.item.drafts' | translate"
            class="conversations-list-item__content-unread-counters-drafts"
          >
            3
          </span>
        }
      </div>
    </div>
  </div>
</div>

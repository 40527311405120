import { createSelector, Selector } from '@ngxs/store';
import { SignaturesState } from './signatures.state';
import type { SignaturePreview, SignaturesStateModel } from './signatures-state.model';

export class SignaturesSelectors {
  @Selector([SignaturesState])
  static signatures(state: SignaturesStateModel): SignaturePreview[] {
    return state.signatures;
  }

  static signaturesByWorkspaceId(workspaceId: number) {
    return createSelector([SignaturesSelectors.signatures], (signatures: SignaturePreview[]) => {
      return signatures.filter((signature) => signature.workspaceId === workspaceId);
    });
  }

  static signaturesByWorkspaceIds(workspaceIds: number[]) {
    return createSelector([SignaturesSelectors.signatures], (signatures: SignaturePreview[]) => {
      return signatures.filter((signature) => workspaceIds.includes(signature.workspaceId));
    });
  }

  static signaturesByIds(signatureIds: number[]) {
    return createSelector([SignaturesSelectors.signatures], (signatures: SignaturePreview[]) => {
      return signatures.filter((signature) => signatureIds.includes(signature.id));
    });
  }

  static defaultNewEmailSignatureByWorkspaceId(workspaceId: number) {
    return createSelector([SignaturesState], (state: SignaturesStateModel) => {
      const id = state.defaults.find((defaults) => defaults.workspaceId === workspaceId)?.newEmailSignatureId;
      return state.signatures.find((signature) => signature.id === id);
    });
  }

  static defaultReplyEmailSignatureByWorkspaceId(workspaceId: number) {
    return createSelector([SignaturesState], (state: SignaturesStateModel) => {
      const id = state.defaults.find((defaults) => defaults.workspaceId === workspaceId)?.replyEmailSignatureId;
      return state.signatures.find((signature) => signature.id === id);
    });
  }
}

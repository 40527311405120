import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import {
  getOffsetPagingOptionsParams,
  PagingOrder,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
  type SortingOptions,
} from '@clover/core/helpers/paging';
import type { CommunicationRole } from '@clover/core/models/communication-role';
import { EnumService } from '@clover/core/services/enum.service';
import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';
import { map, type Observable } from 'rxjs';
import type { TaskAudienceContact, TaskAudienceDepartment } from './task-assignment.model';

interface ContactPreviewResponse {
  contact: {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    workEmail: string;
    companyId: number;
    companyLocationId: number;
    companyLocationName: string;
    status: 'Active' | 'Invited';
  };
  associatedUserId: number;
  userLogoUrl: string;
  companyName: string;
}

export type TaskAudienceContactsSortingProperty = 'name' | 'email';
export type TaskAudienceDepartmentsSortingProperty = 'title';

function mapContactPreview(contact: ContactPreviewResponse): TaskAudienceContact {
  return {
    id: contact.contact.id,
    avatarUrl: contact.userLogoUrl,
    firstName: contact.contact.firstName,
    lastName: contact.contact.lastName,
    email: contact.contact.workEmail,
    company: {
      id: contact.contact.companyId,
      name: contact.companyName,
      title: contact.contact.title,
      logoUrl: undefined, // TODO: add logoUrl when BE provides it
    },
  };
}

function mapCommunicationRole(role: CommunicationRole): TaskAudienceDepartment {
  return {
    id: role.key,
    title: role.title,
  };
}

@Injectable({
  providedIn: 'root',
})
export class TaskAssignmentAudienceService {
  private readonly http = inject(HttpService);
  private readonly enumService = inject(EnumService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly userService = inject(UserService);

  searchContacts(
    companyId: number,
    query: string,
    pagingOptions?: OffsetPagingOptions<TaskAudienceContactsSortingProperty>,
  ): Observable<OffsetPagingWrapper<TaskAudienceContact>> {
    const myCompany = this.userService.userCompany.id === companyId;

    const urlTree = this.router.createUrlTree(
      myCompany
        ? ['api', 'companies', 'my', 'contacts', 'search']
        : ['api', 'companies', 'my', 'contacts', companyId, 'search'],
      {
        queryParams: {
          query: query || undefined,
          type: myCompany ? 'YourColleagues' : undefined,
          ...getOffsetPagingOptionsParams(pagingOptions),
        },
      },
    );

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<ContactPreviewResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapContactPreview),
      })),
    );
  }

  searchDepartments(
    companyId: number,
    query: string,
    sortingOptions?: SortingOptions<TaskAudienceDepartmentsSortingProperty>,
  ): Observable<OffsetPagingWrapper<TaskAudienceDepartment>> {
    return this.enumService.getCommunicationRoles().pipe(
      map((roles) => roles.filter((role) => role.title.toLowerCase().includes(query.toLowerCase()))),
      map((roles) =>
        roles.sort((a, b) => {
          if (sortingOptions?.orderBy === 'title') {
            return sortingOptions.order === PagingOrder.Ascending
              ? a.title.localeCompare(b.title)
              : b.title.localeCompare(a.title);
          }

          return a.sortOrder - b.sortOrder;
        }),
      ),
      map((roles) => ({
        data: roles.map(mapCommunicationRole),
        // Note: next lines are added for compatibility with the OffsetPagingWrapper type
        count: roles.length,
        total: roles.length,
        paging: {
          offset: 0,
          limit: roles.length,
          cursor: '',
        },
      })),
    );
  }

  getContactsByIds(companyId: number, ids: number[]): Observable<TaskAudienceContact[]> {
    const myCompany = this.userService.userCompany.id === companyId;

    const urlTree = this.router.createUrlTree(
      myCompany
        ? ['api', 'companies', 'my', 'contacts', 'search']
        : ['api', 'companies', 'my', 'contacts', companyId, 'search'],
      {
        queryParams: {
          associatedUserIdsToInclude: ids,
          limit: ids.length,
        },
      },
    );

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<OffsetPagingWrapper<ContactPreviewResponse>>(path)
      .pipe(map((response) => response.data.map(mapContactPreview)));
  }

  getDepartmentsByIds(ids: string[]): Observable<TaskAudienceDepartment[]> {
    return this.enumService.getCommunicationRoles().pipe(
      map((roles) => roles.filter((role) => ids.includes(role.key))),
      map((roles) => roles.map(mapCommunicationRole)),
    );
  }
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarComponent } from '../../../../misc/user-avatar/user-logo.component';
import { DropdownDividerComponent } from '../../../../overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { RichTextEditorBaseControlComponent } from '../base-action.component';
import { DynamicValuesService } from './dynamic-values.service';
import type { ImagePlaceholderType } from './image-placeholder/placeholders';
import type { TextPlaceholderType } from './text-placeholder/placeholders';

@Component({
  selector: 'cc-rich-text-editor-dynamic-values-control',
  standalone: true,
  imports: [
    ButtonComponent,
    TooltipDirective,
    DropdownComponent,
    DropdownActionComponent,
    DropdownTextComponent,
    DropdownTriggerDirective,
    TranslateModule,
    DropdownDividerComponent,
    UserAvatarComponent,
  ],
  templateUrl: './dynamic-values-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicValuesControlComponent extends RichTextEditorBaseControlComponent {
  dynamicValues$ = inject(DynamicValuesService).dynamicValues$;

  insertTextPlaceholder(type: TextPlaceholderType): void {
    this.activeEditor().chain().insertTextPlaceholder({ type }).focus().run();
  }

  insertImagePlaceholder(type: ImagePlaceholderType): void {
    this.activeEditor().chain().insertImagePlaceholder({ type }).focus().run();
  }
}

<div
  [ccTooltip]="'conversations-v4.common.jumpToMessage' | translate"
  [tooltipAlignment]="TooltipAlignment.Start"
  class="message-reply-to-reference"
>
  <div class="message-reply-to-reference__bar"></div>

  <div class="message-reply-to-reference__content">
    <div class="message-reply-to-reference__content-sender">
      <img
        [alt]="sender.name"
        [src]="senderAvatar"
        class="message-reply-to-reference__content-sender-avatar"
        height="36px"
        width="36px"
      />

      <div class="message-reply-to-reference__content-sender-name">
        {{ sender.name }}
      </div>
    </div>

    <div class="message-reply-to-reference__content-snippet">
      {{ reference.snippet }}
    </div>
  </div>
</div>

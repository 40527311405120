import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import type { TaskAudienceDepartment } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import { TdComponent } from '@design/table/td/td.component';
import { CheckboxComponent } from '../../../../../forms/checkbox/checkbox.component';

@Component({
  selector: 'cc-assignee-picker-dialog-departments-table-row',
  standalone: true,
  imports: [CheckboxComponent, TdComponent],
  templateUrl: './departments-table-row.component.html',
  styleUrl: './departments-table-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneePickerDialogDepartmentsTableRowComponent {
  department = input.required<TaskAudienceDepartment>();
  selectable = input<boolean>(true);

  selected = model<boolean>();

  handleSelectChange(event: Event): void {
    this.selected.set((event.target as HTMLInputElement).checked);
  }
}

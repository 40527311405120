import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SystemMessageType,
  SystemMetadata,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-system-message-body',
  standalone: true,
  templateUrl: './system-message-body.component.html',
  styleUrls: ['./system-message-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class SystemMessageBodyComponent {
  @Input()
  systemMetadata: SystemMetadata;

  protected readonly SystemMessageType = SystemMessageType;

  get membersString(): string {
    return this.systemMetadata.members.map((member) => member.name).join(', ');
  }
}

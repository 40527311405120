import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DestroyRef, type Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Content, Editor, Range } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { Node, type Fragment } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import { firstValueFrom, skip } from 'rxjs';
import {
  LinkEditorDialogComponent,
  type LinkEditorDialogData,
  type LinkEditorDialogResult,
} from './link-editor/link-editor.component';
import { LinkInfoComponent } from './link-info/link-info.component';

interface LinkOptions {
  href: string;
  title?: string;
}

export function withLink(injector: Injector) {
  return [
    Link.extend({
      addProseMirrorPlugins() {
        return [
          new Plugin({
            key: new PluginKey('linkClickHandler'),
            props: {
              handleClickOn(view, pos, node, _nodePos, _event, direct) {
                if (!direct) return false;

                const linkNode = findChildLinkNode(node);
                const { node: domNode } = view.domAtPos(pos);

                // The `node` itself is a #text node, one of its parents is the link element
                let linkElement = domNode.parentElement as HTMLAnchorElement;
                while (linkElement && linkElement?.tagName !== 'A') {
                  linkElement = linkElement.parentElement as HTMLAnchorElement;
                }

                if (linkElement?.tagName !== 'A' || !linkNode) return;

                const href = linkElement.getAttribute('href');
                const title = linkElement.textContent;

                presentLinkInfoPopup(injector, title, href, linkElement, linkNode, view);
              },
            },
          }),
        ];
      },
    }).configure({
      autolink: true,
      openOnClick: false, // We have custom overlay for link editing
      linkOnPaste: true,
      HTMLAttributes: {
        style: ['color: #04689a;', 'text-decoration-line: underline;'].join('; '),
      },
    }),
  ];
}

export function insertLink(
  editor: Editor,
  { href, title }: LinkOptions,
  specificRange?: Range | undefined,
  addSpace = false,
): void {
  const getRange = () => {
    if (specificRange) return specificRange;

    const selection = editor.view.state.selection;
    const { from, to } = selection;
    return {
      from,
      to,
    };
  };

  const range = getRange();

  // Increase range by one, when the next node is of type "text" and starts with a space character
  const nodeAfter = editor.view.state.selection.$to.nodeAfter;
  const nodeAfterStartsWithSpace = nodeAfter?.text?.startsWith(' ');
  if (nodeAfterStartsWithSpace) range.to += 1;

  const content: Content = [
    {
      type: 'text',
      text: title,
      marks: [{ type: 'link', attrs: { href } }],
    },
  ];

  if ((range.to === range.from && !specificRange) || addSpace || nodeAfterStartsWithSpace) {
    content.push({
      type: 'text',
      text: ' ',
    });
  }

  editor.chain().focus().insertContentAt(range, content).run();

  editor.view.dom.ownerDocument.defaultView?.getSelection()?.collapseToEnd();
}

function presentLinkInfoPopup(
  injector: Injector,
  title: string,
  href: string,
  linkElement: HTMLAnchorElement,
  node: Node,
  view: EditorView,
): void {
  const overlay = injector.get(Overlay);
  const destroyRef = injector.get(DestroyRef);

  const from = view.posAtDOM(linkElement, 0);
  const to = from + node.nodeSize;

  const overlayRef = overlay.create({
    positionStrategy: overlay
      .position()
      .flexibleConnectedTo(linkElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: -20,
          offsetY: -8,
        },
      ]),
    hasBackdrop: false,
    scrollStrategy: overlay.scrollStrategies.reposition(),
  });

  const linkPopup = overlayRef.attach(new ComponentPortal(LinkInfoComponent));
  linkPopup.instance.title.set(title);
  linkPopup.instance.href.set(href);

  const closeOverlay = () => {
    overlayRef.detach();
    overlayRef.dispose();
  };

  overlayRef.outsidePointerEvents().pipe(skip(1), takeUntilDestroyed(destroyRef)).subscribe(closeOverlay);

  linkPopup.instance.dismiss.subscribe(() => {
    closeOverlay();
    view.focus();
  });

  linkPopup.instance.edit.subscribe(async () => {
    closeOverlay();

    const linkOptions = await presentLinkEditor(injector, { title, href });
    if (!linkOptions) return;

    const tr = view.state.tr.replaceWith(
      from,
      to,
      view.state.schema.text(linkOptions.title, [
        view.state.schema.marks.link.create({
          href: linkOptions.href,
        }),
      ]),
    );

    view.dispatch(tr);
    view.focus();
  });

  linkPopup.instance.delete.subscribe(() => {
    overlayRef.detach();
    overlayRef.dispose();

    const linkMark = node.marks.find((mark) => mark.type.name === 'link');

    const tr = view.state.tr.removeMark(from, to, linkMark);
    view.dispatch(tr);
    view.focus();
  });
}

export function presentLinkEditor(
  injector: Injector,
  options: LinkEditorDialogData = {},
): Promise<LinkEditorDialogResult | undefined> {
  const dialog = injector.get(Dialog);

  return firstValueFrom(
    dialog.open<LinkEditorDialogResult, LinkEditorDialogData>(LinkEditorDialogComponent, {
      data: options,
    }).closed,
  );
}

function findChildLinkNode(node: Node): Node | undefined {
  if (node.marks.some((mark) => mark.type.name === 'link')) return node;

  const nodeFragment: Fragment = node.content;
  if (!nodeFragment || nodeFragment.childCount === 0) return;

  const nodes: Node[] = [];
  nodeFragment.forEach((childNode) => nodes.push(childNode));

  for (const childNode of nodes) {
    const linkNode = findChildLinkNode(childNode);
    if (linkNode) return linkNode;
  }
}

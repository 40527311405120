import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';

import { animate, style, transition, trigger } from '@angular/animations';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import {
  categoriesWithStatus,
  ConversationsCategory,
  StatusCategorySegmentOption,
  statusCategorySegmentParam,
} from '@conversations/conversations/categories';
import { ConversationsListItemComponent } from '@conversations/conversations/conversations-list/conversations-list-item/conversations-list-item.component';
import { ConversationsListPlaceholderComponent } from '@conversations/conversations/conversations-list/conversations-list-placeholder/conversations-list-placeholder.component';
import {
  Conversation,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { LoadNextConversations } from '@conversations/conversations/state/conversations/conversations.actions';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { TabSelectorComponent, TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ConversationListFilterParams {
  labelIds: number[] | undefined;
  externalAccountId: number | undefined;
  status: ConversationStatus | undefined;
}

const categoryLabelsMap: Record<ConversationsCategory, string> = {
  [ConversationsCategory.Inbox]: 'conversations-v4.categories.inbox',
  [ConversationsCategory.AssignedToMe]: 'conversations-v4.categories.assignedToMe',
  [ConversationsCategory.Mentions]: 'conversations-v4.categories.mentions',
  [ConversationsCategory.Tasks]: 'conversations-v4.categories.tasks',
  [ConversationsCategory.Sent]: 'conversations-v4.categories.sent',
  [ConversationsCategory.Drafts]: 'conversations-v4.categories.drafts',
  [ConversationsCategory.Prioritized]: 'conversations-v4.categories.prioritized',
  [ConversationsCategory.Snoozed]: 'conversations-v4.categories.snoozed',
  [ConversationsCategory.Important]: 'conversations-v4.categories.important',
  [ConversationsCategory.AllMail]: 'conversations-v4.categories.allMail',
  [ConversationsCategory.Spam]: 'conversations-v4.categories.spam',
  [ConversationsCategory.Trash]: 'conversations-v4.categories.trash',
};

@Component({
  selector: 'cc-conversations-list',
  standalone: true,
  imports: [
    TabSelectorComponent,
    ConversationsListPlaceholderComponent,
    AutoAnimateDirective,
    CdkScrollableModule,
    NgScrollbarModule,
    ConversationsListItemComponent,
    RouterLink,
    RouterLinkActive,
    InfiniteScrollDirective,
    AsyncPipe,
  ],
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ height: 0, opacity: 0 }), animate('200ms', style({ height: '*', opacity: 1 }))]),
      transition(':leave', [style({ height: '*', opacity: 1 }), animate('200ms', style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class ConversationsListComponent implements OnChanges {
  @Input()
  workspaceId: number | undefined;

  @Input()
  category: ConversationsCategory;

  @Input()
  filterParams: ConversationListFilterParams;

  @Input()
  conversations: Conversation[] = [];

  @Input()
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error' | 'loading-next' = 'void';

  protected syncingExternalAccounts$: Observable<ExternalAccount[]>;
  protected allAccountsSynced$: Observable<boolean>;

  protected readonly statusTabs: TabSelectorItem[] = [
    { id: StatusCategorySegmentOption.Open, labelTranslationKey: 'conversations-v4.statuses.open', disabled: false },
    {
      id: StatusCategorySegmentOption.Closed,
      labelTranslationKey: 'conversations-v4.statuses.closed',
      disabled: false,
    },
    { id: StatusCategorySegmentOption.All, labelTranslationKey: 'conversations-v4.statuses.all', disabled: false },
  ];

  private readonly store = inject(Store);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);
  private readonly translate = inject(TranslateService);

  get categoryLabel(): string {
    if (this.filterParams.labelIds?.length > 0) {
      return this.store
        .selectSnapshot(LabelsSelectors.labelsByIds(this.filterParams.labelIds))
        .map((label) => label.name)
        .join(', ');
    }

    if (this.filterParams.externalAccountId) {
      return this.store.selectSnapshot(WorkspacesSelectors.externalAccountById(this.filterParams.externalAccountId))
        .email;
    }

    return this.translate.instant(categoryLabelsMap[this.category]);
  }

  get activeStatus(): StatusCategorySegmentOption {
    switch (this.filterParams.status) {
      case ConversationStatus.Open:
        return StatusCategorySegmentOption.Open;
      case ConversationStatus.Closed:
        return StatusCategorySegmentOption.Closed;
      default:
        return StatusCategorySegmentOption.All;
    }
  }

  get filterableByStatus(): boolean {
    return categoriesWithStatus.includes(this.category);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workspaceId']) {
      this.syncingExternalAccounts$ = this.store.select(
        WorkspacesSelectors.workspaceSyncingExternalAccounts(this.workspaceId),
      );
      this.allAccountsSynced$ = this.syncingExternalAccounts$.pipe(map((accounts) => accounts.length === 0));
    }
  }

  setStatus(id: string): void {
    const status = id as StatusCategorySegmentOption;
    const [categorySegment] = this.activatedRoute.snapshot.url;

    this.store.dispatch(
      new Navigate(
        [
          {
            ...categorySegment.parameters,
            [statusCategorySegmentParam]: status,
          },
        ],
        {},
        {
          relativeTo: this.activatedRoute,
        },
      ),
    );
  }

  updateReadStatus(conversation: Conversation, read: boolean): void {
    this.conversationActionsService.updateReadStatus(conversation.id, read);
  }

  loadNextConversations(): void {
    this.store.dispatch(new LoadNextConversations());
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core';

import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';
import {
  ComposerRecipientsSelectorEditorRowComponent,
  ParticipantTransferEvent,
} from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-editor/composer-recipients-selector-editor-row/composer-recipients-selector-editor-row.component';
import {
  EmailParticipant,
  EmailParticipants,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-recipients-selector-editor',
  standalone: true,
  imports: [ComposerRecipientsSelectorEditorRowComponent, DragDropModule],
  templateUrl: './composer-recipients-selector-editor.component.html',
  styleUrls: ['./composer-recipients-selector-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerRecipientsSelectorEditorComponent {
  @Input()
  recipients: Omit<EmailParticipants, 'from'>;

  recipientsChange = output<Omit<EmailParticipants, 'from'>>();

  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  updateRecipients(type: 'to' | 'cc' | 'bcc', participants: EmailParticipant[]): void {
    switch (type) {
      case 'to': {
        this.recipients.to = participants;
        break;
      }
      case 'cc': {
        this.recipients.cc = participants;
        break;
      }
      case 'bcc': {
        this.recipients.bcc = participants;
        break;
      }
    }

    this.recipientsChange.emit(this.recipients);
  }

  transferItem(event: ParticipantTransferEvent): void {
    if (event.from === event.to) return;

    if (this.recipients[event.to].find((participant) => participant.email === event.participant.email)) {
      this.portalService.presentToast(
        this.translate.instant('conversations-v4.composer.recipientSelector.participantAlreadyInList'),
        ToastType.Error,
      );

      return;
    }

    this.recipients[event.from] = this.recipients[event.from].filter(
      (participant) => participant.email !== event.participant.email,
    );
    this.recipients[event.to] = [...this.recipients[event.to], event.participant];

    this.recipientsChange.emit(this.recipients);
  }
}

import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectComponent } from '../../../../../../../stories/forms/select/select.component';
import { SelectItemComponent } from '../../../../../../../stories/forms/select/select-item/select-item.component';
import type { SignaturePreview } from '@clover/conversations-v4/workspaces/state/signatures/signatures-state.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetSignaturesDefaults } from '@clover/conversations-v4/workspaces/state/signatures/signatures.actions';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownTextComponent } from '../../../../../../../stories/overlays/dropdown/dropdown-text/dropdown-text.component';

function createSelectItemFromSignature(
  signature: SignaturePreview | undefined,
): SelectItem<SignaturePreview> | undefined {
  if (!signature) return undefined;

  return {
    id: String(signature.id),
    title: signature.name,
    payload: signature,
  };
}

@Component({
  selector: 'cc-workspace-settings-signatures-defaults',
  standalone: true,
  imports: [SelectComponent, SelectItemComponent, ReactiveFormsModule, TranslateModule, DropdownTextComponent],
  templateUrl: './workspace-settings-signatures-defaults.component.html',
  styleUrl: './workspace-settings-signatures-defaults.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsSignaturesDefaultsComponent implements OnInit {
  workspaceId = input.required<number>();
  signatures = input.required<SignaturePreview[]>();
  defaultNewEmailSignature = input.required<SignaturePreview | undefined>();
  defaultReplyEmailSignature = input.required<SignaturePreview | undefined>();
  canEditSignatures = input.required<boolean>();

  protected readonly selectOptions = computed(() =>
    this.signatures().map((signature) => createSelectItemFromSignature(signature)),
  );

  protected readonly defaultsForm = new FormGroup({
    newEmailSignature: new FormControl<SelectItem<SignaturePreview>>(undefined),
    replyEmailSignature: new FormControl<SelectItem<SignaturePreview>>(undefined),
  });

  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      this.defaultsForm.patchValue(
        {
          newEmailSignature: createSelectItemFromSignature(this.defaultNewEmailSignature()),
          replyEmailSignature: createSelectItemFromSignature(this.defaultReplyEmailSignature()),
        },
        { emitEvent: false },
      );
    });
  }

  get newEmailSignatureIdControl(): FormControl<SelectItem<SignaturePreview>> {
    return this.defaultsForm.get('newEmailSignature') as FormControl<SelectItem<SignaturePreview>>;
  }

  get replyEmailSignatureIdControl(): FormControl<SelectItem<SignaturePreview>> {
    return this.defaultsForm.get('replyEmailSignature') as FormControl<SelectItem<SignaturePreview>>;
  }

  ngOnInit(): void {
    this.defaultsForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(({ newEmailSignature, replyEmailSignature }) => {
          if (!this.canEditSignatures()) return of();

          return this.store.dispatch(
            new SetSignaturesDefaults(this.workspaceId(), {
              newEmailSignatureId: newEmailSignature ? newEmailSignature.payload.id : undefined,
              replyEmailSignatureId: replyEmailSignature ? replyEmailSignature.payload.id : undefined,
            }),
          );
        }),
      )
      .subscribe();
  }
}

export enum GoogleButtonType {
  Standard = 'standard',
  Icon = 'icon',
}

export enum GoogleButtonText {
  SignInWithGoogle = 'Sign in with Google',
  ContinueWithGoogle = 'Continue with Google',
  SignUpWithGoogle = 'Sign up with Google',
  SignIn = 'Sign in',
}

export const GOOGLE_BUTTON_TEXT_MAP = new Map<GoogleButtonText, string>([
  [GoogleButtonText.SignInWithGoogle, 'googleButton.signInWithGoogle'],
  [GoogleButtonText.ContinueWithGoogle, 'googleButton.continueWithGoogle'],
  [GoogleButtonText.SignUpWithGoogle, 'googleButton.signUpWithGoogle'],
  [GoogleButtonText.SignIn, 'googleButton.signIn'],
]);

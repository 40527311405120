<div class="assistant-composer">
  <textarea
    class="assistant-composer__input"
    placeholder="Chat with Clover..."
    [formControl]="messageFormControl"
  ></textarea>

  <div class="assistant-composer__actions">
    <span class="assistant-composer__actions-notice">
      Clover Assistant can make mistakes.<br />
      Check important information.
    </span>

    <app-button
      [disabled]="messageFormControl.invalid || sendDisabled()"
      (click)="send()"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      Send
    </app-button>
  </div>
</div>

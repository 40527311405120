import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, output } from '@angular/core';
import { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import {
  Draft,
  DraftMessageType,
  Message,
  MessageType,
  PendingMessage,
  SystemMetadata,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { ConversationPerformer } from '@conversations/conversations/state/conversations/conversations-state.model';
import { UserService } from '@core/services/user.service';

import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { MessageBodyComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/body/message-body/message-body.component';
import { SystemMessageBodyComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/body/system-message-body/system-message-body.component';
import { MessageAttachmentsComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-attachments/message-attachments.component';
import { MessageCollapsibleGroupComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-collapsible-group/message-collapsible-group.component';
import { MessageReplyToMessageComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-reply-to-reference/message-reply-to-reference.component';
import { MessageTaskReferencesComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-task-references/message-task-references.component';
import { CoreModule } from '@core/core.module';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { DateFormatDistancePipe, DateFormatPipe } from '@core/pipes/date-format.pipe';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageSenderInfoComponent } from './message-sender-info/message-sender-info.component';

@Component({
  selector: 'cc-message',
  standalone: true,
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CoreModule,
    MessageBodyComponent,
    SystemMessageBodyComponent,
    OverlayModule,
    DropdownComponent,
    DropdownActionComponent,
    MessageCollapsibleGroupComponent,
    MessageAttachmentsComponent,
    MessageTaskReferencesComponent,
    DateFormatPipe,
    DateFormatDistancePipe,
    ButtonComponent,
    TooltipDirective,
    MessageReplyToMessageComponent,
    TranslateModule,
    MessageSenderInfoComponent,
    UserAvatarComponent,
  ],
})
export class MessageComponent {
  @Input()
  message: Message | Draft;

  @Input()
  mode: 'message' | 'draft' | 'pending' = 'message';

  @Input()
  appearance: 'default' | 'compact' = 'default';

  @Input()
  replyForbidden = false;

  @Input()
  actionsDisabled = false;

  reply = output<void>();

  draftEdit = output<void>();

  draftDelete = output<void>();

  jumpToMessage = output<string>();

  protected senderInfoDropdownVisible = false;
  protected readonly senderInfoDropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 4,
    },
  ];

  protected moreActionsDropdownVisible = false;
  protected readonly moreActionsDropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 8,
    },
  ];

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly userService = inject(UserService);
  private readonly translate = inject(TranslateService);

  get type(): 'email' | 'note' | 'system' {
    if (['message', 'pending'].includes(this.mode)) {
      switch ((this.message as Message | PendingMessage).type) {
        case MessageType.Email:
          return 'email';
        case MessageType.Text:
          return 'note';
        case MessageType.System:
          return 'system';
      }
    } else {
      switch ((this.message as Draft).type) {
        case DraftMessageType.Reply:
        case DraftMessageType.ReplyAll:
        case DraftMessageType.Forward:
          return 'email';
        case DraftMessageType.Note:
          return 'note';
      }
    }
  }

  get sender(): ConversationPerformer {
    if (this.mode === 'message') {
      return (this.message as Message).sender;
    }

    const currentUserProfile = this.userService.userProfile;
    return {
      id: currentUserProfile.id.toString(),
      name: currentUserProfile.fullName,
      avatarUrl: currentUserProfile.logoUrl,
      type: ContactType.CloverUser,
    };
  }

  get hasEmailRecipients(): boolean {
    if (this.type !== 'email') return false;

    return (
      this.message.emailParticipants?.to?.length > 0 ||
      this.message.emailParticipants?.cc?.length > 0 ||
      this.message.emailParticipants?.bcc?.length > 0
    );
  }

  get emailRecipientsText(): string {
    if (!this.hasEmailRecipients) return '';

    const emailMetadata = this.message.emailParticipants;
    const to = emailMetadata.to || [];
    const cc = emailMetadata.cc || [];
    const bcc = emailMetadata.bcc || [];
    const count = to.length + cc.length + bcc.length;

    if (to[0])
      return `${this.translate.instant('conversations-v4.conversation.messages.message.to')}: ${to[0].name || to[0].email}${count > 1 ? `, +${count - 1}` : ''}`;
    if (cc[0])
      return `${this.translate.instant('conversations-v4.conversation.messages.message.cc')}: ${cc[0].name || cc[0].email}${count > 1 ? `, +${count - 1}` : ''}`;
    if (bcc[0])
      return `${this.translate.instant('conversations-v4.conversation.messages.message.bcc')}: ${bcc[0].name || bcc[0].email}${count > 1 ? `, +${count - 1}` : ''}`;
  }

  get systemMetadata(): SystemMetadata | undefined {
    if (this.type !== 'system') return undefined;
    return (this.message as Message).systemMetadata;
  }

  get updatedJustNow(): boolean {
    const now = new Date();
    const updated = new Date(this.message.updatedAt);
    return now.getTime() - updated.getTime() < 1000;
  }

  get canViewContactInfo(): boolean {
    if (this.type === 'system') return false;
    return !Number.isNaN(Number(this.sender.id));
  }

  get associatedEmails(): string[] {
    if (!this.hasEmailRecipients) return [];

    const emailMetadata = this.message.emailParticipants;
    const to = emailMetadata.to || [];
    const cc = emailMetadata.cc || [];
    const bcc = emailMetadata.bcc || [];

    return [...to.map((p) => p.email), ...cc.map((p) => p.email), ...bcc.map((p) => p.email)];
  }

  @HostBinding('class.cc-message--compact')
  get compact(): boolean {
    return this.appearance === 'compact';
  }

  @HostBinding('class.cc-message--has-active-dropdown')
  get hasActiveDropdown(): boolean {
    return this.moreActionsDropdownVisible;
  }

  @HostBinding('attr.data-message-id')
  get messageId(): string | undefined {
    if (this.mode !== 'message') return undefined;
    return (this.message as Message).id;
  }
}

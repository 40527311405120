import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import {
  checkWorkspaceExistence,
  getWorkspaceIdByAlias,
} from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { Navigate } from '@ngxs/router-plugin';
import { Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import {
  privateWorkspaceSettingsModalTabs,
  teamWorkspaceSettingsModalTabs,
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';

export const workspaceSettingsModalResolver: ResolveFn<Workspace | undefined> = (
  route: ActivatedRouteSnapshot,
): Observable<Workspace | undefined> => {
  const { workspaceAlias } = route.params;
  const [{ path: selectedTab }] = route.firstChild.url;

  const store = inject(Store);

  const workspaces = store.selectSnapshot(WorkspacesSelectors.workspaces);
  if (
    !workspaceAlias ||
    workspaceAlias === WorkspaceAlias.Aggregated ||
    !checkWorkspaceExistence(workspaces, workspaceAlias)
  ) {
    store.dispatch(
      new Navigate(['/', CONVERSATIONS_BASE_URL, { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: null } }]),
    );
    return of(undefined);
  }

  const workspaceId = getWorkspaceIdByAlias(workspaces, workspaceAlias);
  const workspace = store.selectSnapshot(WorkspacesSelectors.workspaceById(workspaceId));

  if (!validateTab(selectedTab, workspace)) {
    store.dispatch(
      new Navigate(['/', CONVERSATIONS_BASE_URL, { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: null } }]),
    );
    return of(undefined);
  }

  return of(workspace);
};

function validateTab(selectedTab: string, workspace: Workspace): boolean {
  if (workspace.ownerType === 'team')
    return teamWorkspaceSettingsModalTabs.includes(selectedTab as WorkspaceSettingsModalTab);

  return privateWorkspaceSettingsModalTabs.includes(selectedTab as WorkspaceSettingsModalTab);
}

import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import type { SignaturePreview } from '@clover/conversations-v4/workspaces/state/signatures/signatures-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { TranslateModule } from '@ngx-translate/core';
import { startWith } from 'rxjs';

@Component({
  selector: 'cc-workspace-settings-signatures-table',
  standalone: true,
  imports: [TableComponent, ThComponent, TdComponent, TrComponent, TranslateModule, ButtonComponent, TooltipDirective],
  templateUrl: './workspace-settings-signatures-table.component.html',
  styleUrl: './workspace-settings-signatures-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsSignaturesTableComponent {
  signatures = input.required<SignaturePreview[]>();
  canEditSignatures = input.required<boolean>();

  create = output<void>();
  edit = output<SignaturePreview>();
  delete = output<SignaturePreview>();

  protected readonly searchFormControl = new FormControl<string>('');
  protected readonly searchQuery = toSignal(
    this.searchFormControl.valueChanges.pipe(startWith(this.searchFormControl.value)),
  );

  protected readonly nameSortingOrder = signal<SortDirection>('asc');

  protected readonly filteredSignatures = computed(() => {
    const searchQuery = this.searchQuery().toLowerCase();
    const nameSortingOrder = this.nameSortingOrder();

    return this.signatures()
      .filter((signature) => signature.name.toLowerCase().includes(searchQuery))
      .sort((a, b) => {
        if (nameSortingOrder === 'asc') {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      });
  });
  protected readonly signaturesCount = computed(() => this.filteredSignatures().length);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipPosition = TooltipPosition;
}

<div class="signature-editor-modal">
  <div class="signature-editor-modal__header">
    <div class="signature-editor-modal__header-title">
      @switch (dialogData.mode) {
        @case ('create') {
          {{ 'conversations-v4.workspaces.dialogs.signatureEditor.newSignature' | translate }}
        }
        @case ('edit') {
          {{ 'conversations-v4.workspaces.dialogs.signatureEditor.editSignature' | translate }}
        }
      }
    </div>

    <app-button
      [ccTooltip]="'common.buttons.close' | translate"
      [type]="ButtonType.Ghost"
      [size]="ButtonSize.Big"
      (click)="dialogRef.close()"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <ng-scrollbar
    class="signature-editor-modal__content--scrollbar"
    orientation="vertical"
    cdkScrollable
  >
    <div class="signature-editor-modal__content">
      <div class="signature-editor-modal__content-form">
        <app-textbox
          [errorMessage]="
            isNameUnique() ? null : ('conversations-v4.workspaces.dialogs.signatureEditor.name.uniqueError' | translate)
          "
          [placeholder]="'conversations-v4.workspaces.dialogs.signatureEditor.name.placeholder' | translate"
          [formControl]="nameControl"
          [label]="'conversations-v4.workspaces.dialogs.signatureEditor.name.title' | translate"
          width="100%"
        />

        <cc-signature-editor [control]="contentControl" />
      </div>

      <div class="signature-editor-modal__content-preview">
        <div class="signature-editor-modal__content-preview-title">
          {{ 'conversations-v4.workspaces.dialogs.signatureEditor.preview.title' | translate }}
        </div>
        <cc-signature-preview [rawContent]="contentControl.value" />
      </div>
    </div>
  </ng-scrollbar>

  <div class="signature-editor-modal__actions">
    <app-button
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
      (click)="dialogRef.close()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="save()"
      [disabled]="form.invalid || !isNameUnique()"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      @switch (dialogData.mode) {
        @case ('create') {
          {{ 'conversations-v4.workspaces.dialogs.signatureEditor.createSignature' | translate }}
        }
        @case ('edit') {
          {{ 'conversations-v4.workspaces.dialogs.signatureEditor.saveChanges' | translate }}
        }
      }
    </app-button>
  </div>
</div>

import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SaveDraft, SetSubject } from '@conversations/composer/state/composers/composers.actions';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'cc-composer-subject-input',
  standalone: true,
  templateUrl: './composer-subject-input.component.html',
  styleUrls: ['./composer-subject-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule],
})
export class ComposerSubjectInputComponent implements OnInit, OnChanges {
  @Input()
  composerId: string;

  @Input()
  subject: string;

  protected subjectFormControl = new FormControl<string>('');

  private readonly store = inject(Store);

  ngOnInit(): void {
    this.subjectFormControl.setValue(this.subject, { emitEvent: false });

    this.subjectFormControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((subject) => subject !== this.subject),
        tap((subject) => {
          this.store.dispatch(new SetSubject(this.composerId, subject));
        }),
        debounceTime(300),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId))),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subject']) this.subjectFormControl.setValue(this.subject, { emitEvent: false });
  }
}

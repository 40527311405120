import { inject, Injectable } from '@angular/core';
import { OffsetPagingWrapper } from '@clover/core/helpers/paging';
import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';
import { forkJoin, map, type Observable } from 'rxjs';

interface ProductResponse {
  id: number;
  vendor: ProductVendorResponse;
  globalProductClassification: {
    segment: ProductClassificationTypeResponse;
    family: ProductClassificationTypeResponse;
    class: ProductClassificationTypeResponse;
    category: ProductClassificationTypeResponse;
  };
  logos: ProductLogoResponse[];
  accessType: {
    key: string;
    title: string;
  };
  status: string;
  name: string;
  marketingMessage: string;
  packSize: string;
  manufactureProductCode: string;
  brandName: string;
  globalTradeIdentificationNumber: string;
  internalItemNumber: string;
  department: string;
  productId: string;
  categoryCodes: string;
  length: ProductFeatureResponse;
  height: ProductFeatureResponse;
  width: ProductFeatureResponse;
  storageTemperatureMinimum: ProductFeatureResponse;
  storageTemperatureMaximum: ProductFeatureResponse;
}

interface ProductVendorResponse {
  internalId: string;
  internalName: string;
  companyId: number;
  deferredAliasId: number;
  companyName: string;
}

interface ProductFeatureResponse {
  value: number;
  unitOfMeasure: {
    key: string;
    title: string;
    description: string;
    sortOrder: number;
  };
}

interface ProductLogoResponse {
  logo: {
    logoUrl: string;
    fullSizeLogoUrl: string;
    logoSettings: string;
    modifiedAt: string;
  };
  type: string;
  slotId: number;
}

interface ProductClassificationTypeResponse {
  key: string;
  title: string;
}

export interface Product {
  id: number;
  name: string;
  vendor: ProductVendor | undefined;
  images: ProductImage[];
  access: ProductAccess;
  itemNumber: string;
  gtin: string;
}

interface ProductVendorMainInfo {
  companyId: number;
  companyName: string;
  companyLogoUrl: string | undefined;
}

export interface ProductVendor extends ProductVendorMainInfo {
  internalCompanyId: string;
  internalCompanyName: string;
}

export interface ProductImage {
  url: string;
  description: string;
}

export enum ProductAccess {
  Public = 'Public',
  Private = 'Private',
}

function mapProduct(p: ProductResponse): Product {
  return {
    id: p.id,
    name: p.name,
    vendor: mapProductVendor(p.vendor),
    images: p.logos.map(mapProductImage),
    access: p.accessType.key as ProductAccess,
    itemNumber: p.internalItemNumber,
    gtin: p.globalTradeIdentificationNumber,
  };
}

function mapProductVendor(p: ProductVendorResponse): ProductVendor {
  return {
    companyId: p.companyId,
    companyName: p.companyName,
    companyLogoUrl: undefined,
    internalCompanyId: p.internalId,
    internalCompanyName: p.internalName,
  };
}

function mapProductImage(p: ProductLogoResponse): ProductImage {
  return {
    url: p.logo.logoUrl,
    description: p.type,
  };
}

@Injectable({
  providedIn: 'root',
})
// TODO (Oleksandr D.): Unify with products service from products page
export class TempProductsService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  getProduct(id: number): Observable<Product | undefined> {
    const companyId = this.userService.userCompany.id;

    return this.http.getV2<ProductResponse>(`/api/companies/${companyId}/products/${id}`).pipe(map(mapProduct));
  }

  getProductWithFixedVendor(id: number, vendorId: number): Observable<Product | undefined> {
    return forkJoin([this.getProduct(id), this.getVendor(vendorId)]).pipe(
      map(([product, vendor]) => {
        if (!product || !vendor) return undefined;

        return {
          ...product,
          vendor: { ...product.vendor, ...vendor },
        };
      }),
    );
  }

  getVendor(companyId: number): Observable<ProductVendorMainInfo | undefined> {
    return this.http
      .postV2<
        OffsetPagingWrapper<{
          id: number;
          name: string;
          logoUrl: string | undefined;
        }>
      >(`/api/companies/search`, {
        companiesIdsToInclude: [companyId],
        includeOnlyConnected: true,
        includeDeferred: true,
      })
      .pipe(
        map((response) => response.data.find((c) => c.id === companyId)),
        map(
          (company) =>
            company && {
              companyId: company.id as number,
              companyName: company.name,
              companyLogoUrl: company.logoUrl || undefined,
            },
        ),
      );
  }
}

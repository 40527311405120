<ng-scrollbar
  orientation="horizontal"
  externalViewport
>
  <div
    scrollViewport
    class="signature-preview"
  >
    @if (!contentEmpty()) {
      <div [innerHtml]="safeHtmlContent()"></div>
    } @else {
      <div class="signature-preview__placeholder">
        {{ 'conversations-v4.workspaces.dialogs.signatureEditor.preview.placeholder' | translate }}
      </div>
    }
  </div>
</ng-scrollbar>

import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  Injector,
  input,
  Input,
} from '@angular/core';
import { ComposerInstance } from '@conversations/composer/state/composers/composers-state.model';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TaskAccessService } from '@clover/conversations-v4/tasks/task-access-notice-dialog/task-access.service';
import { FilesModule } from '@clover/files/files.module';
import { FilesSelectorModalComponent } from '@clover/files/modals/files-selector-modal/files-selector-modal.component';
import { UploadedFile } from '@clover/files/models/file';
import { ComposersActionsDistributorService } from '@conversations/composer/state/composers/composers-actions-distributor.service';
import {
  AttachFileFromCloverStorage,
  ResetComposer,
  SendDraft,
  SendNewEmail,
  UploadFile,
} from '@conversations/composer/state/composers/composers.actions';
import {
  TaskEmbedDialogData,
  TaskEmbedDialogResult,
  TaskEmbedModalComponent,
} from '@conversations/tasks/task-embed-modal/task-embed-modal.component';
import { EMAIL_REGEX_PATTERN } from '@core/constants/regex-patterns';
import { CoreModule } from '@core/core.module';
import { sanitize } from '@core/helpers/sanitize-email/sanitizer';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { ConfigService } from '@core/services/config.service';
import { ModalService } from '@core/services/modal.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { insertLink } from '@design/forms/rich-text-editor/extensions/link/link.extension';
import {
  BusinessObjectModalComponent,
  type BPIEmbedDialogData,
  type BPIEmbedDialogResult,
} from '@design/overlays/business-object-modal/business-object-modal.component';
import { BusinessObjectService } from '@design/overlays/business-object-modal/business-object.service';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor, type Content } from '@tiptap/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { defer, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RichTextEditorBoldControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/bold/bold-control.component';
import { RichTextEditorBulletListControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/bullet-list/bullet-list-control.component';
import { RichTextEditorFontFamilyControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/font-family/font-family-control.component';
import { RichTextEditorFontSizeControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/font-size/font-size-control.component';
import { RichTextEditorItalicControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/italic/italic-control.component';
import { RichTextEditorLinkControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/link/link-control.component';
import { RichTextEditorOrderedListControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/ordered-list/ordered-list-control.component';
import { RichTextEditorStrikeControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/strike/strike-control.component';
import { RichTextEditorTableControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/table/table-control.component';
import { RichTextEditorUnderlineControlComponent } from '../../../../stories/forms/rich-text-editor/extensions/underline/underline-control.component';

@UntilDestroy()
@Component({
  selector: 'cc-composer-actions',
  standalone: true,
  templateUrl: './composer-actions.component.html',
  styleUrls: ['./composer-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownActionComponent,
    DropdownDividerComponent,
    DropdownTextComponent,
    DropdownComponent,
    OverlayModule,
    DialogModule,
    CoreModule,
    FilesModule,
    ButtonComponent,
    TooltipDirective,
    DateFormatDistancePipe,
    TranslateModule,
    RichTextEditorBoldControlComponent,
    RichTextEditorItalicControlComponent,
    RichTextEditorStrikeControlComponent,
    RichTextEditorUnderlineControlComponent,
    RichTextEditorFontFamilyControlComponent,
    RichTextEditorFontSizeControlComponent,
    RichTextEditorBulletListControlComponent,
    RichTextEditorOrderedListControlComponent,
    RichTextEditorLinkControlComponent,
    RichTextEditorTableControlComponent,
    NgScrollbar,
  ],
})
export class ComposerActionsComponent {
  @Input()
  composer: ComposerInstance;

  @Input()
  newTaskCreationDisabled = false;

  editors = input<Editor[]>([]);

  protected plusDropdownVisible = false;

  protected readonly activeEditor = computed(() => {
    return (
      this.editors()
        .filter(Boolean)
        .find((editor) => editor.isFocused) || this.editors()[0]
    );
  });

  protected readonly dropdownPositionStrategy: ConnectionPositionPair[] = [
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -4 },
  ];
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly dialog = inject(Dialog);
  private readonly store = inject(Store);
  private readonly legacyModalService = inject(ModalService);
  private readonly composersActionsService = inject(ComposersActionsDistributorService);
  private readonly businessObjectService = inject(BusinessObjectService);
  private readonly taskAccessService = inject(TaskAccessService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly translate = inject(TranslateService);
  private readonly injector = inject(Injector);

  protected get draftSavedJustNow(): boolean {
    if (!this.composer.draft) return false;

    const now = new Date();
    const draftSavedAt = new Date(this.composer.draft.updatedAt);
    return now.getTime() - draftSavedAt.getTime() < 60 * 1000;
  }

  protected get sendDisabled(): boolean {
    if (this.composer.sendStatus === 'sending') return true;

    if (this.composer.messageType !== 'internal') {
      // Recipients should be present and valid for email messages
      const { to, cc, bcc } = this.composer.emailParticipants;
      const recipients = [...to, ...cc, ...bcc];

      const recipientsInvalid =
        recipients.length < 1 || recipients.some((recipient) => !EMAIL_REGEX_PATTERN.test(recipient.email));

      if (recipientsInvalid) return true;
    }

    if (this.composer.messageType !== 'new') {
      // Draft should be saved
      const draftInvalid = !this.composer.draft || this.composer.draftSaveStatus !== 'saved';
      if (draftInvalid) return true;
    }

    if (this.composer.messageType === 'internal') {
      // Internal messages should have content
      const plainText = sanitize(this.composer.draft.content, null, {
        noWrapper: true,
        dropAllHtmlTags: true,
      });

      const emptyContent = !plainText.trim();
      if (emptyContent) return true;
    }

    return false;
  }

  protected async deleteDraft(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.title'),
        description: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.message'),
        confirmActionText: this.translate.instant('common.buttons.delete'),
        cancelActionText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: 'compact',
      })) === 'confirm';

    if (confirmed) this.composersActionsService.deleteDraft(this.composer.draft);
  }

  protected async createNewDraft(): Promise<void> {
    const result = await presentConfirmationDialog(this.injector, {
      title: this.translate.instant('conversations-v4.composer.newDraftPrompt.title'),
      description: this.translate.instant('conversations-v4.composer.newDraftPrompt.message'),
      confirmActionText: this.translate.instant('conversations-v4.composer.newDraftPrompt.save'),
      secondaryActionText: this.translate.instant('conversations-v4.composer.newDraftPrompt.dontSave'),
      cancelActionText: this.translate.instant('common.buttons.cancel'),
      destructive: false,
      style: 'compact',
    });

    if (result === 'confirm') this.store.dispatch(new ResetComposer(this.composer.id));
    else if (result === 'secondaryAction') this.composersActionsService.deleteDraft(this.composer.draft);
  }

  protected presentTaskEmbedDialog(): void {
    const dialog = this.dialog.open<TaskEmbedDialogResult, TaskEmbedDialogData>(TaskEmbedModalComponent);

    dialog.closed.pipe(take(1)).subscribe((task: TaskEmbedDialogResult | undefined) => {
      if (!task) return;

      insertLink(this.activeEditor(), {
        href: `${ConfigService.settings.apiUrl}/tasks/task_id=${task.id}`,
        title: `#${task.id}`,
      });
    });
  }

  protected presentBusinessObjectEmbedDialog(): void {
    const dialog = this.dialog.open<BPIEmbedDialogResult, BPIEmbedDialogData>(BusinessObjectModalComponent);

    dialog.closed.pipe(take(1)).subscribe((result: BPIEmbedDialogResult | undefined) => {
      if (!result) return;

      const tableContent: Content | undefined =
        result.type === 'order'
          ? this.businessObjectService.generateOrderTable(result.order)
          : result.type === 'invoice'
            ? this.businessObjectService.generateInvoiceTable(result.invoice)
            : undefined;

      if (!tableContent) return;

      this.activeEditor()
        .chain()
        .insertContent(tableContent, {
          parseOptions: { preserveWhitespace: false },
        })
        .focus()
        .run();
    });
  }

  protected uploadFile(event: Event): void {
    const uploadInput = event.target as HTMLInputElement;

    const [file] = Array.from(uploadInput.files);
    uploadInput.value = null;
    this.store.dispatch(new UploadFile(this.composer.id, file));
  }

  protected attachFileFromCloverStorage(): void {
    this.legacyModalService
      .open({
        content: FilesSelectorModalComponent,
        options: {
          size: 'xl',
        },
      })
      .result.then((files: UploadedFile[]) => {
        const userFilesIds = files.filter((file) => file.fileOwner === 'User').map((file) => file.id);
        const companyFilesIds = files.filter((file) => file.fileOwner === 'Company').map((file) => file.id);

        this.store.dispatch(new AttachFileFromCloverStorage(this.composer.id, userFilesIds, companyFilesIds));
      })
      .catch(() => {});
  }

  protected send(): void {
    if (this.composer.messageType === 'new') {
      this.store.dispatch(new SendNewEmail(this.composer.id));
      return;
    }

    const { draft } = this.composer;
    if (!draft) return;

    defer(() => {
      if (this.composer.messageType === 'internal') return of(true);
      return this.taskAccessService.confirmUnauthorizedTaskRecipientsIfExist(draft.tasksIds, draft.emailParticipants);
    })
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef),
        tap((confirmed) => {
          if (!confirmed) return;

          this.store.dispatch(new SendDraft(this.composer.id));
        }),
      )
      .subscribe();
  }
}

<div class="company-wiki">
  <div class="company-wiki__content-area">
    @if (activeDocument$ | async; as document) {
      @if (isEditing$ | async) {
        <cc-company-wiki-document-editor [document]="document"></cc-company-wiki-document-editor>
      } @else {
        <cc-company-wiki-document-preview [document]="document"></cc-company-wiki-document-preview>
      }
    }
  </div>

  @if (showFileExplorer$ | async) {
    <cc-company-wiki-file-explorer class="company-wiki__file-explorer"></cc-company-wiki-file-explorer>
  }
</div>

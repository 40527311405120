import type { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import isHTMLEmpty from './is-empty';

export function htmlRequiredValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const { value } = control;

    if (!value || value.trim() === '') return { required: true };

    return isHTMLEmpty(value) ? { required: true } : null;
  };
}

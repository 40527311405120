@if (composer$ | async; as composer) {
  @if (composer.presentation === 'inline') {
    <div class="conversation-composer">
      <div
        [class.conversation-composer__inline--full-height]="appearance === 'full-height'"
        class="conversation-composer__inline"
      >
        <cc-composer [composer]="composer"></cc-composer>
      </div>
      <div
        (click)="toggleAppearance()"
        [ccTooltip]="
          appearance === 'default'
            ? ('conversations-v4.composer.expandComposer' | translate)
            : ('conversations-v4.composer.collapseComposer' | translate)
        "
        [tooltipAlignment]="TooltipAlignment.End"
        class="conversation-composer__height-toggle"
        role="button"
      >
        <i
          [class.icon-collapse-alt]="appearance === 'full-height'"
          [class.icon-expand-alt]="appearance === 'default'"
        ></i>
      </div>
    </div>
  } @else {
    <div class="conversation-composer-placeholder">
      <div class="conversation-composer-placeholder__inner">
        {{ 'conversations-v4.composer.separateWindowNotice.message' | translate }}&nbsp;<span
          (click)="switchToInlineComposer()"
          class="conversation-composer-placeholder__inner-action"
          role="button"
        >
          {{ 'conversations-v4.composer.separateWindowNotice.showHere' | translate }}
        </span>
      </div>
    </div>
  }
}

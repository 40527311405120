import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit, output } from '@angular/core';
import { Store } from '@ngxs/store';
import { v4 as uuid } from 'uuid';
import {
  ChangePresentation,
  DestroyComposer,
  InitActiveConversationComposer,
} from '@conversations/composer/state/composers/composers.actions';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { Observable } from 'rxjs';
import { ComposerInstance } from '@conversations/composer/state/composers/composers-state.model';
import { AsyncPipe } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, tap } from 'rxjs/operators';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ComposerComponent } from '@conversations/composer/composer.component';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'cc-conversation-inline-composer',
  standalone: true,
  imports: [ComposerComponent, AsyncPipe, AutoAnimateDirective, TooltipDirective, TranslateModule],
  templateUrl: './conversation-inline-composer.component.html',
  styleUrls: ['./conversation-inline-composer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationInlineComposerComponent implements OnInit, OnDestroy {
  @Input()
  conversationId: string;

  @Input()
  appearance: 'default' | 'full-height' = 'default';

  appearanceChange = output<'default' | 'full-height'>();

  protected composer$: Observable<ComposerInstance>;

  protected readonly TooltipAlignment = TooltipAlignment;

  private composerId: string;

  private readonly store = inject(Store);

  ngOnInit(): void {
    const existingComposer = this.store.selectSnapshot(
      ComposersSelectors.composerByConversationId(this.conversationId),
    );

    if (existingComposer) {
      this.composerId = existingComposer.id;
    } else {
      this.composerId = uuid();
      this.store.dispatch(new InitActiveConversationComposer(this.composerId));
    }

    this.composer$ = this.store.select(ComposersSelectors.composerById(this.composerId));

    // When the composer presentation changes to something other than inline, switch to the default appearance.
    this.composer$
      .pipe(
        untilDestroyed(this),
        filter((composer) => composer !== undefined),
        map((composer) => composer.presentation),
        filter((presentation) => presentation !== 'inline'),
        tap(() => this.appearanceChange.emit('default')),
      )
      .subscribe();
  }

  switchToInlineComposer(): void {
    this.store.dispatch(new ChangePresentation(this.composerId, 'inline'));
  }

  toggleAppearance(): void {
    this.appearanceChange.emit(this.appearance === 'default' ? 'full-height' : 'default');
  }

  ngOnDestroy(): void {
    // If this component gets destroyed, then the active conversation is closed,
    // so we need to destroy its inline composer.
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(this.composerId));
    if (composer.presentation === 'inline') this.store.dispatch(new DestroyComposer(this.composerId));
  }
}

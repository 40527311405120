import {
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  Renderer2,
  viewChild,
  viewChildren,
  type ElementRef,
} from '@angular/core';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ResizeDirective } from '@clover/core/directives/resize.directive';
import { TranslateModule } from '@ngx-translate/core';
import { createNotifier } from 'ngxtension/create-notifier';

export interface TabSelectorItem {
  id: string;
  label?: string;
  labelTranslationKey?: string;
  count?: number;
  disabled: boolean;
}

@Component({
  selector: 'cc-tab-selector',
  standalone: true,
  imports: [TranslateModule, ResizeDirective, AutoAnimateDirective],
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss'],
})
export class TabSelectorComponent {
  selectedTabId = model<string | undefined>(undefined);

  tabs = input.required<TabSelectorItem[]>();
  inlinePadding = input(0);

  tabsContainer = viewChild.required<ElementRef<HTMLDivElement>>('tabsContainer');
  tabElements = viewChildren<ElementRef<HTMLDivElement>>('tab');
  underline = viewChild.required<ElementRef<HTMLDivElement>>('underline');

  private readonly renderer = inject(Renderer2);

  protected readonly selectedTab = computed(() => {
    const tabs = this.tabs();
    const selectedTabId = this.selectedTabId();

    return tabs.find((tab) => tab.id === selectedTabId) || tabs[0];
  });

  protected readonly recalculateUnderlineParams = createNotifier();

  constructor() {
    effect((cleanup) => {
      this.recalculateUnderlineParams.listen();

      const selectedTab = this.selectedTab();
      const tabRefs = this.tabElements();
      const containerRef = this.tabsContainer();

      const frameId = this.updateUnderline(selectedTab, tabRefs, containerRef);

      cleanup(() => cancelAnimationFrame(frameId));
    });
  }

  selectTab(tab: TabSelectorItem): void {
    this.selectedTabId.set(tab.id);
  }

  private updateUnderline(
    selectedTab: TabSelectorItem,
    tabRefs: readonly ElementRef<HTMLDivElement>[],
    containerRef: ElementRef<HTMLDivElement>,
  ): number {
    const tabs = tabRefs.map((tab) => tab.nativeElement);
    const activeTab = tabs.find((tab) => tab.dataset.tabId === selectedTab.id);
    const underline = this.underline().nativeElement;

    const width = activeTab.clientWidth;
    const tabLeft = activeTab.offsetLeft;
    const containerLeft = containerRef.nativeElement.offsetLeft;
    const left = tabLeft - containerLeft;

    return requestAnimationFrame(() => {
      this.renderer.setStyle(underline, 'width', CSS.px(width).toString());
      this.renderer.setStyle(underline, 'left', CSS.px(left).toString());
    });
  }
}

export enum MarkdownEditorMode {
  Edit = 'edit',
  Preview = 'preview',
}

export enum MarkdownFormat {
  Bold = 'bold',
  Italic = 'italic',
  Strikethrough = 'strikethrough',
  Quote = 'quote',
  Code = 'code',
  Link = 'link',
  UnorderedList = 'unorderedList',
  OrderedList = 'orderedList',
  TodoList = 'todoList',
}

export interface FormatOptions {
  regex: RegExp;
  toMarkdown: (plaintext: string) => string;
  toPlaintext: (markdown: string) => string;
}

export const MARKDOWN_FORMAT_OPTIONS = new Map<MarkdownFormat, FormatOptions>([
  [
    MarkdownFormat.Bold,
    {
      regex: /^\*\*(.*?)\*\*$/,
      toMarkdown: (plaintext) => `**${plaintext}**`,
      toPlaintext: (markdown) => markdown.replace(/\*\*(.*?)\*\*/, '$1'),
    },
  ],
  [
    MarkdownFormat.Italic,
    {
      regex: /^_(.*?)_$/,
      toMarkdown: (plaintext) => `_${plaintext}_`,
      toPlaintext: (markdown) => markdown.replace(/^_(.*?)_$/, '$1'),
    },
  ],
  [
    MarkdownFormat.Strikethrough,
    {
      regex: /^~~(.*?)~~$/,
      toMarkdown: (plaintext) => `~~${plaintext}~~`,
      toPlaintext: (markdown) => markdown.replace(/^~~(.*?)~~$/, '$1'),
    },
  ],
  [
    MarkdownFormat.Quote,
    {
      regex: /^>(.*?)$/,
      toMarkdown: (plaintext) => `> ${plaintext}`,
      toPlaintext: (markdown) => markdown.replace(/^>(.*?)$/, '$1').trim(),
    },
  ],
  [
    MarkdownFormat.Code,
    {
      regex: /^`(.*?)`$/,
      toMarkdown: (plaintext) => `\`${plaintext}\``,
      toPlaintext: (markdown) => markdown.replace(/^`(.*?)`$/, '$1'),
    },
  ],
  [
    MarkdownFormat.Link,
    {
      regex: /^\[(.*?)]\((.*?)\)$/,
      toMarkdown: (plaintext) => `[${plaintext}](https://clovercollab.com)`,
      toPlaintext: (markdown) => {
        const [_, title, url] = markdown.match(/^\[(.*?)]\((.*?)\)$/).map((match) => match.trim());

        if (!title) return url;
        if (!url) return title;

        return `${title} (${url})`;
      },
    },
  ],
  [
    MarkdownFormat.UnorderedList,
    {
      regex: /^-(.*?)$/,
      toMarkdown: (plaintext) => `- ${plaintext}`,
      toPlaintext: (markdown) => markdown.replace(/^-(.*?)$/, '$1').trim(),
    },
  ],
  [
    MarkdownFormat.OrderedList,
    {
      regex: /^\d+\. (.*?)$/,
      toMarkdown: (plaintext) => `1. ${plaintext}`,
      toPlaintext: (markdown) => markdown.replace(/^\d+\.(.*?)$/, '$1').trim(),
    },
  ],
  [
    MarkdownFormat.TodoList,
    {
      regex: /^\[([x ])](.*?)$/,
      toMarkdown: (plaintext) => `[ ] ${plaintext}`,
      toPlaintext: (markdown) => markdown.replace(/^\[([x ])](.*?)$/, '$2').trim(),
    },
  ],
]);

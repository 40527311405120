<ng-scrollbar cdkScrollable>
  <div class="workspace-settings-labels">
    <div class="workspace-settings-labels__description">
      @if (workspace.ownerType === 'user') {
        {{ 'conversations-v4.workspaces.settings.labels.messagePrivateWorkspace' | translate }}
      } @else {
        {{
          'conversations-v4.workspaces.settings.labels.messageTeamWorkspace' | translate: { teamName: workspace.name }
        }}
      }

      @if (externalAccounts.length) {
        <span
          [innerHTML]="
            'conversations-v4.workspaces.settings.labels.affectedAccounts'
              | translate
                : {
                    affectedAccounts: affectedAccountsString,
                  }
          "
        ></span>
      }
    </div>

    @if ((canEditLabels$ | async) === false) {
      <div
        class="workspace-settings-labels__read-only-notice"
        [innerHTML]="'conversations-v4.workspaces.settings.labels.readonlyNotice' | translate"
      ></div>
    }

    <cc-table
      (searchChange)="searchFormControl.setValue($event)"
      [ccEmptyRowTemplate]="noLabelsTemplate"
      [ccSearchDebounceTime]="0"
      [ccSearchPlaceholder]="'conversations-v4.workspaces.settings.labels.searchPlaceholder' | translate"
      [ccSearchable]="true"
      ccPadding="small"
      class="workspace-settings-labels__table"
    >
      <ng-container slot="cc-table-title">
        @if (labelsCount$ | async; as count) {
          {{
            count === 1
              ? ('conversations-v4.workspaces.settings.labels.labelsSingular' | translate: { count })
              : ('conversations-v4.workspaces.settings.labels.labelsPlural' | translate: { count })
          }}
        }
      </ng-container>

      <ng-container slot="сс-table-custom-actions">
        @if ((canEditLabels$ | async) === true) {
          <app-button
            (click)="createLabel()"
            [size]="ButtonSize.Small"
            [type]="ButtonType.GhostText"
            [ccTooltip]="'conversations-v4.workspaces.dialogs.labelEditor.createLabel' | translate"
          >
            <i
              class="icon-create-fill"
              slot="ghost-icon"
            ></i>
          </app-button>
        }
      </ng-container>

      <ng-container slot="cc-table">
        <thead>
          <tr>
            <th ccTh>{{ 'conversations-v4.workspaces.settings.labels.label' | translate }}</th>

            <th
              [ccThTooltip]="'conversations-v4.workspaces.settings.labels.showInNavTooltip' | translate"
              ccTh
              ccWidth="120px"
            >
              {{ 'conversations-v4.workspaces.settings.labels.showInNav' | translate }}
            </th>

            <th
              ccTh
              ccWidth="80px"
            ></th>
          </tr>
        </thead>

        <tbody>
          @for (label of labels$ | async; track label.id) {
            <tr
              ccTr
              class="workspace-settings-labels__table-row"
            >
              <td ccTd>
                <div class="workspace-settings-labels__table-row-details">
                  <cc-label-icon [label]="label"></cc-label-icon>
                  {{ label.name }}
                </div>
              </td>
              <td ccTd>
                <app-toggle
                  (change)="toggleLabelVisibility(label, $event.target['checked'])"
                  [checked]="label.visibleInNavigation"
                  [disabled]="(canEditLabels$ | async) === false"
                >
                </app-toggle>
              </td>
              <td ccTd>
                <div
                  [class.workspace-settings-labels__table-row-actions--disabled]="(canEditLabels$ | async) === false"
                  class="workspace-settings-labels__table-row-actions"
                >
                  <app-button
                    (click)="editLabel(label)"
                    [size]="ButtonSize.Small"
                    [type]="ButtonType.Ghost"
                    [ccTooltip]="'common.buttons.edit' | translate"
                  >
                    <i
                      class="icon-edit-pencil"
                      slot="ghost-icon"
                    >
                    </i>
                  </app-button>
                  <app-button
                    (click)="deleteLabel(label)"
                    [size]="ButtonSize.Small"
                    [type]="ButtonType.Ghost"
                    [ccTooltip]="'common.buttons.delete' | translate"
                  >
                    <i
                      class="icon-delete"
                      slot="ghost-icon"
                    >
                    </i>
                  </app-button>
                </div>
              </td>
            </tr>
          }

          <ng-template #noLabelsTemplate>
            {{ 'conversations-v4.workspaces.settings.labels.noLabelsPlaceholder' | translate }}
          </ng-template>
        </tbody>
      </ng-container>
    </cc-table>
  </div>
</ng-scrollbar>

<div class="conversations-list-placeholder">
  @switch (type) {
    @case ('loading') {
      <div class="conversations-list-placeholder__spinner"></div>
      <div class="conversations-list-placeholder__title cc-ellipsis-animation">
        {{ 'conversations-v4.conversationsList.placeholders.loading.title' | translate }}
      </div>
      <div class="conversations-list-placeholder__description">
        {{ 'conversations-v4.conversationsList.placeholders.loading.message' | translate }}
      </div>
    }

    @case ('error') {
      <i class="conversations-list-placeholder__icon icon-mailbox-open-outline"></i>
      <div class="conversations-list-placeholder__title">
        {{ 'conversations-v4.conversationsList.placeholders.error.title' | translate }}
      </div>
      <div class="conversations-list-placeholder__description">
        {{ 'conversations-v4.conversationsList.placeholders.error.message' | translate }}
      </div>
    }

    @case ('empty') {
      <i class="conversations-list-placeholder__icon icon-mailbox-open-outline"></i>
      <div class="conversations-list-placeholder__title">
        {{ 'conversations-v4.conversationsList.placeholders.empty.title' | translate }}
      </div>
      <div class="conversations-list-placeholder__description">
        {{ 'conversations-v4.conversationsList.placeholders.empty.message' | translate }}
      </div>
    }

    @case ('initializing') {
      <div class="conversations-list-placeholder__spinner"></div>
      <div class="conversations-list-placeholder__title">
        {{ 'conversations-v4.conversationsList.placeholders.initializing.title' | translate }}
      </div>

      <div class="conversations-list-placeholder__description">
        @if (syncedThreadsCount === 0) {
          <span class="cc-ellipsis-animation">
            {{ 'conversations-v4.workspaces.accountsSyncNotice.connecting' | translate }}
          </span>
        } @else if (syncedThreadsCount === 1) {
          <span class="cc-ellipsis-animation">
            {{ 'conversations-v4.workspaces.accountsSyncNotice.threadsSingularSynced' | translate }}
          </span>
        } @else {
          <span class="cc-ellipsis-animation">
            {{
              'conversations-v4.workspaces.accountsSyncNotice.threadsPluralSynced'
                | translate: { count: syncedThreadsCount }
            }}
          </span>
        }
      </div>

      <div class="conversations-list-placeholder__divider"></div>

      <div class="conversations-list-placeholder__message">
        {{ 'conversations-v4.conversationsList.placeholders.initializing.message' | translate }}
      </div>
    }
  }
</div>

import { AssistantThread, AssistantThreadContext } from './state/assistant.entities';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { AssistantHeaderComponent } from './assistant-header/assistant-header.component';
import { AssistantChatComponent } from './assistant-chat/assistant-chat.component';
import { AssistantComposerComponent } from './assistant-composer/assistant-composer.component';
import { AssistantPlaceholderComponent } from './assistant-placeholder/assistant-placeholder.component';
import { Store } from '@ngxs/store';
import { DeleteThread, SendMessage } from './state/assistant.actions';

@Component({
  selector: 'cc-assistant',
  standalone: true,
  imports: [
    AssistantHeaderComponent,
    AssistantChatComponent,
    AssistantComposerComponent,
    AssistantPlaceholderComponent,
  ],
  templateUrl: './assistant.component.html',
  styleUrl: './assistant.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantComponent {
  thread = input.required<AssistantThread | undefined>();
  context = input.required<AssistantThreadContext>();

  canDeleteThread = computed(() => !!this.thread());

  sendDisabled = computed(() => {
    const thread = this.thread();
    if (!thread) return false;

    return thread.assistantState === 'busy';
  });

  private readonly store = inject(Store);

  sendMessage(text: string): void {
    const threadId = this.thread()?.id;
    this.store.dispatch(new SendMessage(threadId, text, this.context()));
  }

  deleteCurrentThread(): void {
    const thread = this.thread();
    if (!thread) return;

    this.store.dispatch(new DeleteThread(thread.id));
  }
}

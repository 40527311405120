import { ChangeDetectionStrategy, Component, inject, Injector, input, output } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ButtonComponent } from '../../../stories/buttons/button/button.component';

@Component({
  selector: 'cc-assistant-header',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective],
  templateUrl: './assistant-header.component.html',
  styleUrl: './assistant-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantHeaderComponent {
  canDeleteThread = input.required();

  threadDelete = output<void>();

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly injector = inject(Injector);

  async deleteThread(): Promise<void> {
    const confirmed =
      (await presentConfirmationDialog(this.injector, {
        title: 'Are you sure you want to start a new conversation?',
        description: 'This will delete the current one. You will not be able to recover it.',
        confirmActionText: 'Confirm',
        cancelActionText: 'Cancel',
        destructive: true,
        style: 'default',
      })) === 'confirm';

    if (confirmed) this.threadDelete.emit();
  }
}

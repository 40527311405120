<div
  [class.composer-quote-input--collapsed]="collapsed"
  class="composer-quote-input"
>
  <div
    (click)="collapsed = false"
    (keydown.enter)="collapsed = false"
    [ccTooltip]="'conversations-v4.composer.quoteInput.showQuotedMessages' | translate"
    [tooltipAlignment]="TooltipAlignment.Start"
    class="composer-quote-input__expand-button"
    role="button"
    tabindex="0"
  >
    <div class="composer-quote-input__expand-button-dot"></div>
    <div class="composer-quote-input__expand-button-dot"></div>
    <div class="composer-quote-input__expand-button-dot"></div>
  </div>

  <div class="composer-quote-input__expanded-container">
    <app-button
      (click)="collapsed = true"
      [ccTooltip]="'conversations-v4.composer.quoteInput.hideQuotedMessages' | translate"
      [size]="ButtonSize.Small"
      [tooltipAlignment]="TooltipAlignment.Start"
      [type]="ButtonType.Ghost"
      class="composer-quote-input__expanded-container-collapse-button"
    >
      <i
        class="icon-collapse"
        slot="ghost-icon"
      ></i>
    </app-button>

    <div class="composer-quote-input__expanded-container-header">
      {{ 'conversations-v4.composer.quoteInput.title' | translate }}
    </div>

    <!-- ProseMirror does not support email html with the default schema. -->
    <!-- TODO (Oleksandr D.): find proper solution for quote editing -->

    <!--    <ngx-editor-->
    <!--      (focusIn)="focusIn.emit()"-->
    <!--      [editor]="editor"-->
    <!--      [formControl]="quoteFormControl"-->
    <!--      class="composer-quote-input__expanded-container-editor"-->
    <!--      outputFormat="html"-->
    <!--      placeholder="Enter quoted message..."-->
    <!--    ></ngx-editor>-->

    <cc-composer-html-editor
      (htmlChange)="quoteFormControl.setValue($event)"
      [html]="quoteFormControl.value"
      [placeholder]="'conversations-v4.composer.quoteInput.placeholder' | translate"
    ></cc-composer-html-editor>
  </div>
</div>

import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, signal, type OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TaskAssignmentAudienceService,
  type TaskAudienceContactsSortingProperty,
} from '@clover/conversations-v4/tasks/task-assignment/audience.service';
import type { TaskAudienceContact } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import {
  PagingOrder,
  sortDirectionToPagingOrder,
  type OffsetPagingWrapper,
  type SortingOptions,
} from '@clover/core/helpers/paging';
import type { SortDirection } from '@design/table/table';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, catchError, combineLatest, map, of, startWith, switchMap, tap, type Observable } from 'rxjs';
import { TableComponent } from '../../../../table/table.component';
import { AssigneePickerDialogContactsTableRowComponent } from './contacts-table-row/contacts-table-row.component';
import { CheckboxComponent } from '../../../../forms/checkbox/checkbox.component';

@UntilDestroy()
@Component({
  selector: 'cc-assignee-picker-dialog-contacts-table',
  standalone: true,
  imports: [
    NgScrollbar,
    TableComponent,
    ThComponent,
    TrComponent,
    AsyncPipe,
    AssigneePickerDialogContactsTableRowComponent,
    InfiniteScrollDirective,
    CdkScrollable,
    TranslateModule,
    CheckboxComponent,
  ],
  templateUrl: './contacts-table.component.html',
  styleUrl: './contacts-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneePickerDialogContactsTableComponent implements OnInit {
  companyId = input.required<number>();
  contactIds = model.required<number[]>();

  protected searchFormControl = new FormControl<string>('');
  protected sortingOptions$ = new BehaviorSubject<SortingOptions<TaskAudienceContactsSortingProperty>>({
    orderBy: 'name',
    order: PagingOrder.Ascending,
  });

  protected nameSortingOrder$: Observable<SortDirection>;
  protected emailSortingOrder$: Observable<SortDirection>;

  protected loadedContacts = signal<OffsetPagingWrapper<TaskAudienceContact> | undefined>(undefined);
  protected loadingStatus = signal<'void' | 'loaded' | 'loading' | 'loading-next' | 'error'>('void');

  protected readonly contacts = computed(() => this.loadedContacts()?.data ?? []);
  protected readonly contactsTotal = computed(() => this.loadedContacts()?.total ?? 0);

  protected readonly checkboxChecked = computed(() => {
    return this.contactIds().length >= this.contactsTotal() && this.contactsTotal() > 0;
  });

  protected readonly checkboxIndeterminate = computed(() => {
    const selectedCount = this.contactIds().length;
    return selectedCount > 0 && selectedCount < this.contactsTotal();
  });

  private readonly taskAssignmentAudienceService = inject(TaskAssignmentAudienceService);

  ngOnInit(): void {
    this.nameSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'name' ? sortingOptions.order : null)),
    );
    this.emailSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'email' ? sortingOptions.order : null)),
    );

    this.initContacts();
  }

  handleContactSelect(contactId: number, selected: boolean): void {
    this.contactIds.update((ids) => {
      if (selected) return [...ids, contactId];
      return ids.filter((id) => id !== contactId);
    });
  }

  handleAllContactsCheckboxChange(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    this.contactIds.set(checked ? this.contacts().map((contact) => contact.id) : []);
  }

  handleSearchChange(query: string): void {
    this.searchFormControl.setValue(query);
  }

  changeSort(property: TaskAudienceContactsSortingProperty, direction: SortDirection) {
    this.sortingOptions$.next({
      orderBy: property,
      order: sortDirectionToPagingOrder(direction),
    });
  }

  loadNextContacts(): void {
    if (this.loadingStatus() !== 'loaded') return;
    this.loadingStatus.set('loading-next');

    this.taskAssignmentAudienceService
      .searchContacts(this.companyId(), this.searchFormControl.value, {
        limit: 30,
        offset: this.loadedContacts().count,
        order: this.sortingOptions$.value.order,
        orderBy: this.sortingOptions$.value.orderBy,
      })
      .pipe(
        untilDestroyed(this),
        tap((response: OffsetPagingWrapper<TaskAudienceContact>) => {
          this.loadedContacts.set({
            ...this.loadedContacts(),
            data: this.loadedContacts().data.concat(response.data),
            count: this.loadedContacts().count + response.count,
            paging: response.paging,
          });

          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }

  private initContacts(): void {
    combineLatest([this.searchFormControl.valueChanges.pipe(startWith('')), this.sortingOptions$])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loadingStatus.set('loading');
        }),
        switchMap(([query, sorting]) => {
          return this.taskAssignmentAudienceService.searchContacts(this.companyId(), query, {
            limit: 30,
            order: sorting.order,
            orderBy: sorting.orderBy,
          });
        }),
        tap((response: OffsetPagingWrapper<TaskAudienceContact>) => {
          this.loadedContacts.set(response);
          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';

import { TaskDrawerComponent } from '@conversations/tasks/task-drawer/task-drawer.component';
import { TaskEmbedModalPlaceholderComponent } from '@conversations/tasks/task-embed-modal/task-embed-modal-placeholder/task-embed-modal-placeholder.component';
import { TaskTableRowComponent } from '@conversations/tasks/task-table-row/task-table-row.component';
import { FilterableTaskStatus, Task, TaskPreview, TaskStatus, TaskType } from '@conversations/tasks/tasks.model';
import { TasksService } from '@conversations/tasks/tasks.service';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { PagingOrder, PagingWrapper } from '@core/helpers/paging';
import { ButtonGroupComponent } from '@design/buttons/button-group/button-group.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { LabelIconComponent } from '@design/misc/label-icon/label-icon.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { TranslateModule } from '@ngx-translate/core';

export type TaskEmbedDialogData = void; // No need to pass data for now
export type TaskEmbedDialogResult = Task;

@UntilDestroy()
@Component({
  selector: 'cc-task-embed-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    LabelIconComponent,
    TableComponent,
    TdComponent,
    ThComponent,
    TrComponent,
    TaskTableRowComponent,
    CdkScrollableModule,
    InfiniteScrollModule,
    NgScrollbarModule,
    TaskDrawerComponent,
    ReactiveFormsModule,
    TaskEmbedModalPlaceholderComponent,
    ButtonGroupComponent,
    OverlayModule,
    DropdownComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    ButtonComponent,
    TooltipDirective,
    TextboxComponent,
    TranslateModule,
  ],
  templateUrl: './task-embed-modal.component.html',
  styleUrls: ['./task-embed-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('drawerAnimation', [
      transition(':enter', [
        style({ width: '0', minWidth: '0' }),
        animate('0.2s ease-in-out', style({ width: '*', minWidth: 'unset' })),
      ]),
      transition(':leave', [
        style({ width: '*', minWidth: 'unset' }),
        animate('0.2s ease-in-out', style({ width: '0', minWidth: '0' })),
      ]),
    ]),
  ],
})
export class TaskEmbedModalComponent implements OnInit {
  readonly dialogData: TaskEmbedDialogData = inject(DIALOG_DATA);

  protected searchFormControl = new FormControl<string>('');
  protected tasksType$ = new BehaviorSubject<TaskType>(TaskType.Received);
  protected tasksStatus$ = new BehaviorSubject<FilterableTaskStatus>(FilterableTaskStatus.All);

  protected loadedTasks: PagingWrapper<TaskPreview>;
  protected tasksLoadingStatus: 'void' | 'loaded' | 'loading' | 'loading-next' | 'error' = 'void';

  protected activeTaskId$ = new BehaviorSubject<number | undefined>(undefined);
  protected activeTask$: Observable<Task | undefined>;

  protected statusSelectDropdownVisible = false;
  protected readonly statusSelectDropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 4,
    },
  ];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly TaskStatus = TaskStatus;
  protected readonly TaskType = TaskType;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly FilterableTaskStatus = FilterableTaskStatus;

  private readonly tasksService = inject(TasksService);
  private readonly dialogRef: DialogRef<TaskEmbedDialogResult> = inject(DialogRef);
  private readonly cdr = inject(ChangeDetectorRef);

  get tasks(): TaskPreview[] {
    return this.loadedTasks?.data || [];
  }

  ngOnInit(): void {
    this.initTasks();
    this.initActiveTask();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  protected selectTask(task: Task): void {
    this.dialogRef.close(task);
  }

  protected loadNextTasks(): void {
    if (this.tasksLoadingStatus !== 'loaded') return;
    this.tasksLoadingStatus = 'loading-next';
    this.cdr.detectChanges();

    this.tasksService
      .searchTasks(this.searchFormControl.value, this.tasksType$.value, this.tasksStatus$.value, false, {
        limit: 30,
        offset: this.loadedTasks.count,
        order: PagingOrder.Descending,
        orderBy: 'createdAt',
      })
      .pipe(
        untilDestroyed(this),
        tap((response: PagingWrapper<Task>) => {
          this.loadedTasks = {
            ...this.loadedTasks,
            data: this.loadedTasks.data.concat(response.data),
            count: this.loadedTasks.count + response.count,
            paging: response.paging,
          };

          this.tasksLoadingStatus = 'loaded';
          this.cdr.detectChanges();
        }),
        catchError(() => {
          this.tasksLoadingStatus = 'error';
          this.cdr.detectChanges();
          return of();
        }),
      )
      .subscribe();
  }

  private initTasks(): void {
    combineLatest([this.searchFormControl.valueChanges.pipe(startWith('')), this.tasksType$, this.tasksStatus$])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.tasksLoadingStatus = 'loading';
          this.cdr.detectChanges();
        }),
        switchMap(([query, type, status]) => {
          return this.tasksService.searchTasks(query, type, status, false, {
            limit: 30,
            order: PagingOrder.Descending,
            orderBy: 'createdAt',
          });
        }),
        tap((response: PagingWrapper<Task>) => {
          this.loadedTasks = response;
          this.tasksLoadingStatus = 'loaded';
          this.cdr.detectChanges();
        }),
        catchError(() => {
          this.tasksLoadingStatus = 'error';
          this.cdr.detectChanges();
          return of();
        }),
      )
      .subscribe();
  }

  private initActiveTask(): void {
    this.activeTask$ = this.activeTaskId$.pipe(
      switchMap((taskId) => {
        if (!taskId) return of(undefined);
        return this.tasksService.getTask(taskId);
      }),
      switchMap((task) => {
        if (!task) return of(undefined);
        return this.tasksService.subscribeToTaskUpdates(task.id).pipe(startWith(task));
      }),
    );
  }
}

<app-button
  #dropdownTrigger="ccDropdownTrigger"
  [ccDropdownTrigger]="dropdown"
  [ccDropdownPositions]="{
    offsetY: 6,
    positions: ['bottom-start', 'top-start', 'bottom-end', 'top-end'],
  }"
  [active]="dropdownTrigger.isOpen()"
  [size]="ButtonSize.Big"
  [type]="ButtonType.Ghost"
  [ccTooltip]="'richTextEditor.actions.dynamicValues.title' | translate"
>
  <i
    class="icon-dynamic-value"
    slot="ghost-icon"
  ></i>
</app-button>

<ng-template #dropdown>
  <cc-dropdown [shadow]="false">
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.personalInformation.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action (click)="insertTextPlaceholder('firstName'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.personalInformation.firstName' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().firstName || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action (click)="insertTextPlaceholder('lastName'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.personalInformation.lastName' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().lastName || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action (click)="insertImagePlaceholder('avatar'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.personalInformation.profileAvatar' | translate }}
      </ng-container>

      <ng-container slot="trailing">
        <cc-user-avatar
          [src]="dynamicValues$().avatarUrl"
          [name]="dynamicValues$().fullName"
          [size]="20"
        />
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-divider />

    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.jobInformation.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action (click)="insertTextPlaceholder('jobTitle'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.jobInformation.jobTitle' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().jobTitle || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action (click)="insertTextPlaceholder('companyName'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.jobInformation.companyName' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().companyName || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-divider />

    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.contactInformation.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action (click)="insertTextPlaceholder('workEmail'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.contactInformation.workEmail' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().workEmail || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action (click)="insertTextPlaceholder('workPhoneNumber'); dropdownTrigger.close()">
      <ng-container slot="title">
        {{ 'richTextEditor.actions.dynamicValues.contactInformation.workPhoneNumber' | translate }}
      </ng-container>

      <ng-container slot="caption">
        {{ dynamicValues$().workPhoneNumber || 'richTextEditor.actions.dynamicValues.previewNotAvailable' | translate }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>

import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { getWorkspaceIdByAlias } from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import {
  ConversationsCategory,
  externalAccountCategorySegmentParam,
  labelsCategorySegmentParam,
  StatusCategorySegmentOption,
  statusCategorySegmentParam,
} from '@conversations/conversations/categories';
import { ConversationStatus } from '@conversations/conversations/state/conversations/conversations-state.model';
import { ConversationCategoryFilterParams } from '@conversations/conversations/state/conversations/conversations.service';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';

export interface ConversationsResolverParams {
  workspaceId: number | undefined;
  categoryId: ConversationsCategory;
  filterParams: ConversationCategoryFilterParams;
}

export const conversationsResolver: ResolveFn<ConversationsResolverParams> = (
  route: ActivatedRouteSnapshot,
): Observable<ConversationsResolverParams> => {
  const store = inject(Store);

  const { workspaceAlias } = route.parent.params;
  const [categorySegment] = route.url;

  const workspaces = store.selectSnapshot(WorkspacesSelectors.workspaces);
  const workspaceId = getWorkspaceIdByAlias(workspaces, workspaceAlias);

  return of({
    workspaceId,
    categoryId: categorySegment.path as ConversationsCategory,
    filterParams: {
      labelIds: parseLabelParam(categorySegment.parameters[labelsCategorySegmentParam]),
      externalAccountId: parseExternalAccountParam(categorySegment.parameters[externalAccountCategorySegmentParam]),
      status: parseStatusParam(categorySegment.parameters[statusCategorySegmentParam]),
    },
  });
};

export function parseLabelParam(parameter: string | undefined): number[] | undefined {
  if (!parameter) return undefined;

  const labelIds = parameter.split(',').map(Number);
  return labelIds.some(isNaN) ? undefined : labelIds;
}

export function parseExternalAccountParam(parameter: string | undefined): number | undefined {
  if (!parameter) return undefined;

  const externalAccountId = Number(parameter);
  return isNaN(externalAccountId) ? undefined : externalAccountId;
}

export function parseStatusParam(parameter: string): ConversationStatus | undefined {
  const statusMap: Record<StatusCategorySegmentOption, ConversationStatus | undefined> = {
    [StatusCategorySegmentOption.Open]: ConversationStatus.Open,
    [StatusCategorySegmentOption.Closed]: ConversationStatus.Closed,
    [StatusCategorySegmentOption.All]: undefined,
  };

  return statusMap[parameter as StatusCategorySegmentOption] ?? undefined;
}

import { ChangeDetectionStrategy, Component, output, signal } from '@angular/core';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
  templateUrl: './link-info.component.html',
  styleUrls: ['./link-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkInfoComponent {
  title = signal('');
  href = signal('');

  dismiss = output<void>();
  edit = output<void>();
  delete = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}

<div
  class="company-wiki-tree-view-item"
  [style.gap.px]="itemsGap"
>
  <div
    class="company-wiki-tree-view-item__root"
    [class.company-wiki-tree-view-item__root--selected]="selected$ | async"
    role="button"
    (click)="selectItem()"
  >
    <div
      class="company-wiki-tree-view-item__root-depth-spacer"
      [style.width.px]="spacerWidth"
    ></div>

    <div
      class="company-wiki-tree-view-item__root-toggle"
      [class.company-wiki-tree-view-item__root-toggle--hidden]="!hasChildren"
      [class.company-wiki-tree-view-item__root-toggle--expanded]="expanded"
      role="button"
      (click)="toggleItem($event)"
    >
      <i class="icon-right-small-fill"></i>
    </div>

    <div class="company-wiki-tree-view-item__root-content">
      <i class="company-wiki-tree-view-item__root-content-icon icon-document-alt"></i>

      <span class="company-wiki-tree-view-item__root-content-title">
        {{ document.name }}
      </span>
    </div>
  </div>

  @if (hasChildren) {
    <div
      [@toggleChildren]="expanded"
      class="company-wiki-tree-view-item__children"
      [class.company-wiki-tree-view-item__children--collapsed]="!expanded"
      [style.gap.px]="itemsGap"
    >
      @for (child of document.children; track child) {
        <cc-company-wiki-tree-view-item
          [document]="child"
          [depth]="depth + 1"
        ></cc-company-wiki-tree-view-item>
      }
    </div>
  }
</div>

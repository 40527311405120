<ng-scrollbar
  #scrollbar
  (ccResize)="scrollbarHeight = $event.newRect.height"
  (scroll)="handleScroll()"
  (scrolled)="loadMessages('next')"
  (scrolledUp)="loadMessages('prev')"
  [class.conversation-messages--scroll-snap]="positionFromBottom <= 1"
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="0"
  [infiniteScrollUpDistance]="4"
  [scrollWindow]="false"
  cdkScrollable
  class="conversation-messages"
  infiniteScroll
  orientation="vertical"
>
  <div
    #messagesList
    [animationDisabled]="!animationsEnabled"
    [ccAutoAnimate]="{ duration: 150 }"
    class="conversation-messages__content"
  >
    @for (group of messages | ccGroupMessages: pendingMessages; track group.dateISO) {
      <div
        [animationDisabled]="!animationsEnabled"
        [ccAutoAnimate]="{ duration: 150 }"
        class="conversation-messages__content-group"
      >
        <cc-message-group-date-header
          (dateSelect)="jumpToDate($event)"
          [date]="group.dateISO"
          [maxDate]="details.lastMessage.createdAt"
          [minDate]="details.createdAt"
          class="conversation-messages__content-group-header"
        />

        @for (block of group.messageBlocks; track block.message.id) {
          <cc-message
            [replyForbidden]="details.replyForbidden"
            (reply)="replyToMessage(block.message)"
            (jumpToMessage)="jumpToMessage($event)"
            [actionsDisabled]="!!accountIssue || block.message.traceId"
            [appearance]="block.compact ? 'compact' : 'default'"
            [message]="block.message"
            [mode]="!block.message.traceId ? 'message' : 'pending'"
          />

          @if (block.message.drafts; as drafts) {
            @for (draft of drafts; track draft.id) {
              <cc-message
                [replyForbidden]="details.replyForbidden"
                (jumpToMessage)="jumpToMessage($event)"
                (draftDelete)="deleteDraft(draft)"
                (draftEdit)="editDraft(draft)"
                [actionsDisabled]="!!accountIssue"
                appearance="default"
                [message]="draft"
                mode="draft"
              />
            }
          }
        }
      </div>
    }

    <div class="conversation-messages__content-overflow-anchor"></div>
  </div>
</ng-scrollbar>

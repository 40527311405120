<ng-scrollbar
  (scroll)="handleScroll()"
  #scrollbar
  orientation="vertical"
  class="cc-scrollbar"
  [class.cc-scrollbar--scroll-snap]="scrollFromBottom() <= 1"
>
  <div
    class="assistant-chat"
    [ccAutoAnimate]
  >
    @for (message of messages(); track message.id) {
      <cc-assistant-chat-message
        [sender]="message.sender"
        [text]="message.text"
        [timestamp]="message.timestamp"
      />
    }
  </div>

  <div class="cc-overflow-anchor"></div>
</ng-scrollbar>

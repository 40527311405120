import { Label, LabelsStateModel } from '@conversations/workspaces/state/labels/labels-state.model';
import { LabelsState } from '@conversations/workspaces/state/labels/labels.state';
import { createSelector, Selector } from '@ngxs/store';

export class LabelsSelectors {
  @Selector([LabelsState])
  static labels(state: LabelsStateModel): Label[] {
    return state.labels;
  }

  static labelsByWorkspaceId(workspaceId: number) {
    return createSelector([LabelsSelectors.labels], (labels: Label[]) => {
      return labels.filter((label) => label.workspaceId === workspaceId);
    });
  }

  static labelsByWorkspaceIds(workspaceIds: number[]) {
    return createSelector([LabelsSelectors.labels], (labels: Label[]) => {
      return labels.filter((label) => workspaceIds.includes(label.workspaceId));
    });
  }

  static labelsByIds(labelIds: number[]) {
    return createSelector([LabelsSelectors.labels], (labels: Label[]) => {
      return labels.filter((label) => labelIds.includes(label.id));
    });
  }
}

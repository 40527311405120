@if (editorActive) {
  <cc-composer-recipients-selector-editor
    (recipientsChange)="recipientsChange.emit($event)"
    [recipients]="recipients"
  />
} @else {
  <cc-composer-recipients-selector-preview
    (click)="editorActive = true; $event.stopPropagation()"
    [recipients]="recipients"
  />
}

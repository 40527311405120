import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import type { Contact } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { ContactsService } from '@clover/conversations-v4/conversation/state/contacts/contacts.service';
import { type EmailParticipants } from '@clover/conversations-v4/conversation/state/conversation/conversation-state.model';
import { TranslateService } from '@ngx-translate/core';
import { map, of, switchMap, type Observable } from 'rxjs';
import { TaskEmailAccessLevel } from '../tasks.model';
import { TasksService } from '../tasks.service';
import {
  TaskAccessNoticeDialogComponent,
  type TaskAccessNoticeDialogData,
  type TaskAccessNoticeDialogResult,
} from './task-access-notice-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TaskAccessService {
  private tasksService = inject(TasksService);
  private readonly contactsService = inject(ContactsService);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);

  displayNotice(emails: string[]): Observable<void> {
    return this.getContacts(emails).pipe(
      switchMap((contacts) => {
        return this.dialog.open<TaskAccessNoticeDialogResult, TaskAccessNoticeDialogData>(
          TaskAccessNoticeDialogComponent,
          {
            data: {
              contacts: contacts,
            },
          },
        ).closed;
      }),
      map(() => undefined),
    );
  }

  confirmUnauthorizedTaskRecipientsIfExist(
    tasksIds: number[],
    emailRecipients: Omit<EmailParticipants, 'from'>,
  ): Observable<boolean> {
    const recipientEmails = emailRecipients
      ? [
          ...new Set([
            ...(emailRecipients.to || []).map((to) => to.email),
            ...(emailRecipients.cc || []).map((cc) => cc.email),
            ...(emailRecipients.bcc || []).map((bcc) => bcc.email),
          ]),
        ]
      : [];

    const getUnauthorizedRecipients = () => {
      if (!tasksIds || tasksIds.length === 0) return of([]);
      if (recipientEmails.length === 0) return of([]);

      return this.tasksService.getTasksPreviews(tasksIds, recipientEmails).pipe(
        map((tasks) =>
          tasks
            .flatMap((task) => task.emailAccess)
            .filter(({ access }) => access === TaskEmailAccessLevel.None)
            .map(({ email }) => email),
        ),
      );
    };

    return getUnauthorizedRecipients().pipe(
      switchMap((contacts) => {
        if (contacts.length === 0) return of(true);
        return this.displayPreSendNotice(contacts);
      }),
    );
  }

  private getContacts(emails: string[]): Observable<(Contact | string)[]> {
    return this.contactsService.searchEmailContactsByEmails(emails).pipe(
      map((contacts) => {
        return emails.map((email) => {
          const contact = contacts.find((c) => c.emails.map((e) => e.email).includes(email));
          return contact ? contact : email;
        });
      }),
    );
  }

  private displayPreSendNotice(emails: string[]): Observable<boolean> {
    return this.getContacts(emails).pipe(
      switchMap((contacts) => {
        return this.dialog.open<TaskAccessNoticeDialogResult, TaskAccessNoticeDialogData>(
          TaskAccessNoticeDialogComponent,
          {
            data: {
              contacts: contacts,
              dismissButtonLabel: this.translate.instant('common.buttons.cancel'),
              confirmButtonLabel: this.translate.instant('conversations-v4.tasks.taskAccessDialog.sendAnyway'),
            },
          },
        ).closed;
      }),
    );
  }
}

import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  Message,
  MessageType,
  PendingMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { DateService } from '@core/services/date.service';
import * as R from 'ramda';
import { formatISO, startOfDay } from 'date-fns';

export interface MessageGroup {
  id: number;
  dateISO: string;
  messageBlocks: MessageBlock[];
}

export interface MessageBlock {
  compact: boolean;
  message: Message | PendingMessage;
}

@Pipe({
  standalone: true,
  name: 'ccGroupMessages',
})
export class GroupMessagesPipe implements PipeTransform {
  private readonly dateService = inject(DateService);

  transform(messages: Message[], pendingMessages: PendingMessage[]): MessageGroup[] {
    const groupFn = R.groupBy<Message | PendingMessage, string>((message: Message | PendingMessage) => {
      const profileTimezoneMessageDate = this.dateService.representLocalDateInProfileTimezoneDate(
        new Date(message.createdAt),
      );

      return formatISO(startOfDay(profileTimezoneMessageDate)).toString();
    });

    return Object.entries(groupFn([...messages, ...pendingMessages])).map(([dateISO, messages]) => {
      const id = new Date(messages[0].createdAt).getTime();
      const messageBlocks: MessageBlock[] = [];
      let previousMessage: Message | PendingMessage | undefined;

      for (const message of messages) {
        const sameSender = previousMessage?.sender?.id === message.sender.id;
        const sameType = previousMessage?.type === message.type;
        const sameReplyToMessage = previousMessage?.replyToMessage?.id === message.replyToMessage?.id;
        const notEmail = message.type !== MessageType.Email;
        const noDrafts = previousMessage?.drafts?.length === 0; // Drafts are not displayed in the same block

        if (sameSender && sameType && sameReplyToMessage && notEmail && noDrafts) {
          messageBlocks.push({
            compact: true,
            message,
          });
        } else {
          messageBlocks.push({
            compact: false,
            message,
          });
        }

        previousMessage = message;
      }

      return {
        id,
        dateISO: formatISO(this.dateService.representProfileTimezoneDateInLocalDate(new Date(dateISO))),
        messageBlocks,
      };
    });
  }
}

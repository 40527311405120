<div class="external-account-table-row">
  <div class="external-account-table-row__channel">
    <img
      [alt]="providerName"
      [ngSrc]="providerLogo"
      class="external-account-table-row__channel-provider-logo"
      height="72"
      width="72"
    />

    <div class="external-account-table-row__channel-details">
      <div class="external-account-table-row__channel-details-email">{{ account.email }}</div>
      <div class="external-account-table-row__channel-details-provider">{{ providerName }}</div>
    </div>
  </div>

  <div class="external-account-table-row__status">
    @switch (account.status) {
      @case (ExternalAccountStatus.Initializing) {
        <div class="external-account-table-row__status--initializing cc-ellipsis-animation">
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.initializing' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Syncing) {
        <div class="external-account-table-row__status--syncing cc-ellipsis-animation">
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.syncing' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Active) {
        <div class="external-account-table-row__status--active">
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.active' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Disconnected) {
        <div class="external-account-table-row__status--disconnected">
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.disconnected' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Disconnecting) {
        <div class="external-account-table-row__status--disconnecting cc-ellipsis-animation">
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.disconnecting' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Expired) {
        <div class="external-account-table-row__status--expired">
          <i class="icon-no-connection-fill"></i>
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.expired' | translate }}
        </div>
      }
      @case (ExternalAccountStatus.Failed) {
        <div class="external-account-table-row__status--failed">
          <i class="icon-sync-auth-error"></i>
          {{ 'conversations-v4.workspaces.settings.accounts.statuses.failed' | translate }}
        </div>
      }
    }
  </div>

  @if (![ExternalAccountStatus.Disconnecting].includes(account.status)) {
    <div class="external-account-table-row__action">
      <div #actionsDropdownTrigger>
        <app-button
          (click)="actionsDropdownVisible = !actionsDropdownVisible"
          [ccTooltip]="'common.buttons.moreActions' | translate"
          [size]="ButtonSize.Small"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-more"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>
    </div>

    <ng-template
      (detach)="actionsDropdownVisible = false"
      (overlayOutsideClick)="
        actionsDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, actionsDropdownTrigger)
      "
      [cdkConnectedOverlayOpen]="actionsDropdownVisible"
      [cdkConnectedOverlayOrigin]="actionsDropdownTrigger"
      [cdkConnectedOverlayPositions]="actionsDropdownPositionStrategy"
      cdkConnectedOverlay
    >
      <cc-dropdown width="fit-content">
        @if (canReconnect) {
          <div class="external-account-table-row__action-reconnect">
            <cc-dropdown-text class="external-account-table-row__action-reconnect-title">
              <ng-container slot="title">
                {{ 'conversations-v4.workspaces.settings.accounts.reconnectAccount' | translate }}
              </ng-container>
            </cc-dropdown-text>

            @switch (account.providerType) {
              @case (ProviderType.Google) {
                <cc-google-button
                  (click)="reconnect.emit(account); actionsDropdownVisible = false"
                  [text]="GoogleButtonText.ContinueWithGoogle"
                  class="external-account-table-row__action-reconnect-button"
                ></cc-google-button>
              }
              @case (ProviderType.Google) {
                <cc-microsoft-button
                  (click)="reconnect.emit(account); actionsDropdownVisible = false"
                  [text]="MicrosoftButtonText.SignInWithMicrosoft"
                  class="external-account-table-row__action-reconnect-button"
                >
                </cc-microsoft-button>
              }
            }
          </div>
        }

        <cc-dropdown-action
          (click)="remove.emit(account); actionsDropdownVisible = false"
          leadingIcon="icon-remove-attachment"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.workspaces.settings.accounts.removeAccount' | translate }}
          </ng-container>
        </cc-dropdown-action>
      </cc-dropdown>
    </ng-template>
  }
</div>

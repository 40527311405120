import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { UserService } from '@clover/core/services/user.service';
import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { DateFormatPipe } from '@clover/core/pipes/date-format.pipe';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'cc-assistant-chat-message',
  standalone: true,
  imports: [DateFormatPipe, MarkdownComponent, TooltipDirective, NgScrollbar],
  templateUrl: './assistant-chat-message.component.html',
  styleUrl: './assistant-chat-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideMarkdown()],
})
export class AssistantChatMessageComponent {
  sender = input.required<'user' | 'assistant'>();
  text = input.required<string>();
  timestamp = input.required<string>();

  private readonly userService = inject(UserService);

  readonly senderName = computed(() => {
    if (this.sender() === 'user') return this.userService.userProfile.fullName;
    return 'Clover';
  });

  readonly senderAvatar = computed(() => {
    if (this.sender() === 'user') {
      const userAvatarUrl = this.userService.userProfile.logoUrl;
      if (userAvatarUrl) return userAvatarUrl;

      const { firstName, lastName } = this.userService.userProfile;
      return generateGenericAvatar(firstName, lastName, 64);
    }

    return 'assets/svg/common/clover.svg';
  });

  protected readonly showThinkingIndicator = computed(() => this.sender() === 'assistant' && !this.text().trim());

  protected readonly TooltipAlignment = TooltipAlignment;
}

<div class="company-wiki-file-explorer">
  <div class="company-wiki-file-explorer__search-bar">
    <app-textbox
      [formControl]="searchFormControl"
      icon="icon-search"
      placeholder="Enter page title"
      width="100%"
    >
    </app-textbox>
  </div>

  <div class="company-wiki-file-explorer__tree">
    <cc-company-wiki-tree-view [documents]="documents$ | async"></cc-company-wiki-tree-view>
  </div>

  @if (canEdit$ | async) {
    <div class="company-wiki-file-explorer__new-page-button">
      <app-button
        (click)="createPage()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        <span class="company-wiki-file-explorer__new-page-button-content">
          <i
            class="icon-add"
            slot="start-icon"
          ></i>
          New page
        </span>
      </app-button>
    </div>
  }
</div>

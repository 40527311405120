import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cc-conversation-placeholder',
  standalone: true,
  templateUrl: './conversation-placeholder.component.html',
  styleUrls: ['./conversation-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPlaceholderComponent {
  @Input()
  status: 'void' | 'loading' | 'error' = 'void';
}

import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  LoadConversation,
  ResetConversationState,
} from '@conversations/conversation/state/conversation/conversation.actions';

export const conversationResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const { conversationId } = route.params || {};
  const store = inject(Store);

  if (conversationId) {
    store.dispatch(new LoadConversation(conversationId));
  } else {
    store.dispatch(new ResetConversationState());
  }

  return of(true);
};

import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { ActivatedRoute } from '@angular/router';
import {
  ExternalAccount,
  ExternalAccountActionType,
  ExternalAccountState,
  ExternalAccountStatus,
  ProviderType,
  Workspace,
  WorkspaceOwnerType,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { Store } from '@ngxs/store';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { map, startWith, take } from 'rxjs/operators';
import { ConfigService } from '@core/services/config.service';
import { WINDOW } from '@core/helpers/global-objects';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { GoogleButtonText } from '@design/buttons/google-button/google-button';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { HttpParams } from '@angular/common/http';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ConfirmAccountRemovalModalComponent } from '@conversations/workspaces/modals/confirm-account-removal-modal/confirm-account-removal-modal.component';
import { DisconnectExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces.actions';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { GoogleButtonComponent } from '@design/buttons/google-button/google-button.component';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { ExternalAccountTableRowComponent } from '@conversations/workspaces/modals/workspace-settings-modal/workspace-settings-inbox/external-account-table-row/external-account-table-row.component';
import { MicrosoftButtonComponent } from '@design/buttons/microsoft-button/microsoft-button.component';
import { MicrosoftButtonText } from '@design/buttons/microsoft-button/microsoft-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-workspace-settings-inbox',
  standalone: true,
  imports: [
    CommonModule,
    NgScrollbarModule,
    CdkScrollableModule,
    ReactiveFormsModule,
    DropdownActionComponent,
    DropdownComponent,
    DropdownTextComponent,
    OverlayModule,
    DialogModule,
    TextboxComponent,
    ButtonComponent,
    GoogleButtonComponent,
    ExternalAccountTableRowComponent,
    MicrosoftButtonComponent,
    TranslateModule,
  ],
  templateUrl: './workspace-settings-inbox.component.html',
  styleUrls: ['./workspace-settings-inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsInboxComponent implements OnInit {
  protected workspace: Workspace;
  protected searchFormControl = new FormControl<string>('');
  protected externalAccounts$: Observable<ExternalAccount[]>;

  protected canConnectNewAccount$: Observable<boolean>;

  protected addChannelDropdownVisible = false;
  protected readonly addChannelDropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      offsetX: 12,
      offsetY: 6,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      panelClass: null,
    },
  ];

  protected readonly TextboxType = TextboxType;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly GoogleButtonText = GoogleButtonText;
  protected readonly WorkspaceOwnerType = WorkspaceOwnerType;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly MicrosoftButtonText = MicrosoftButtonText;
  protected readonly ProviderType = ProviderType;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly store = inject(Store);
  private readonly window = inject(WINDOW);
  private readonly dialog = inject(Dialog);

  ngOnInit(): void {
    this.workspace = this.activatedRoute.parent.snapshot.data['workspace'];

    this.externalAccounts$ = combineLatest([
      this.store.select(WorkspacesSelectors.externalAccountsByWorkspaceId(this.workspace.id)),
      this.searchFormControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([externalAccounts, searchQuery]) => {
        if (!searchQuery) return externalAccounts;

        return externalAccounts.filter((account) => {
          return (
            account.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            account.providerType.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
      }),
    );

    this.canConnectNewAccount$ = this.externalAccounts$.pipe(
      map((accounts) => {
        if (this.workspace.ownerType === WorkspaceOwnerType.Team) return true;

        const activeAccounts = accounts.filter((account) => account.status !== ExternalAccountStatus.Disconnected);
        return activeAccounts.length === 0;
      }),
    );
  }

  connectAccount(account: ExternalAccount | undefined, provider: ProviderType | undefined): void {
    const state: ExternalAccountState = {
      workspaceId: this.workspace.id.toString(),
      actionType: account ? ExternalAccountActionType.Reconnect : ExternalAccountActionType.Connect,
    };

    const mapProvider = (provider: ProviderType) => {
      switch (provider) {
        case ProviderType.Google:
          return 'google';
        case ProviderType.Microsoft:
          return 'microsoft';
      }
    };

    const _provider = provider || account.providerType;
    if (!_provider) return;

    const params = new HttpParams()
      .set('client_id', ConfigService.settings.nylasClientId)
      .set('redirect_uri', `${this.window.location.origin}/${CONVERSATIONS_BASE_URL}`)
      .set('response_type', 'code')
      .set('provider', mapProvider(_provider))
      .set('state', encodeURIComponent(JSON.stringify(state)));

    if (account) params.set('login_hint', account.email);
    this.window.location.replace(`https://api.us.nylas.com/v3/connect/auth?${params.toString()}`);
  }

  removeAccount(account: ExternalAccount): void {
    const dialog = this.dialog.open(ConfirmAccountRemovalModalComponent, {
      data: account,
    });

    dialog.closed.pipe(take(1)).subscribe((account: ExternalAccount | undefined) => {
      if (!account) return;
      this.store.dispatch(new DisconnectExternalAccount(account.workspaceId, account.id));
    });
  }
}

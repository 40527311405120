import { Injector } from '@angular/core';
import { mergeAttributes, Node } from '@tiptap/core';
import { AngularNodeViewRenderer } from 'ngx-tiptap';
import { ImageComponent } from './image.component';

export const minImageHeight = 24;
export const minImageWidth = 24;
export const maxImageHeight = 512;
export const maxImageWidth = 512;

export interface ImageOptions {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  radius?: number;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      insertImage: (options: ImageOptions) => ReturnType;
    };
  }
}

export const ImageExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'image',
    group: 'inline',
    inline: true,
    atom: true,
    draggable: true,

    addAttributes() {
      return {
        src: {
          isRequired: true,
        },
        alt: {
          default: '',
        },
        width: {
          default: 128,
        },
        height: {
          default: 128,
        },
        radius: {
          default: 0,
        },
      };
    },

    parseHTML() {
      return [{ tag: 'img[src]' }];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'img',
        mergeAttributes(HTMLAttributes, {
          style: [
            `width: ${CSS.px(HTMLAttributes['width']).toString()}`,
            `height: ${CSS.px(HTMLAttributes['height']).toString()}`,
            `border-radius: ${CSS.px(HTMLAttributes['radius']).toString()}`,
            'object-fit: cover',
          ].join(';'),
        }),
      ];
    },

    addNodeView() {
      return AngularNodeViewRenderer(ImageComponent, { injector });
    },

    addCommands() {
      return {
        insertImage:
          (options: ImageOptions) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          },
      };
    },
  });
};

export function withImage(injector: Injector) {
  return [ImageExtension(injector)];
}

import { ChangeDetectionStrategy, Component, input, signal, viewChild, type AfterViewInit } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { AssistantChatMessageComponent } from './assistant-chat-message/assistant-chat-message.component';
import type { AssistantMessage } from '../state/assistant.entities';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';

@Component({
  selector: 'cc-assistant-chat',
  standalone: true,
  imports: [NgScrollbar, AssistantChatMessageComponent, AutoAnimateDirective],
  templateUrl: './assistant-chat.component.html',
  styleUrl: './assistant-chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantChatComponent implements AfterViewInit {
  messages = input.required<AssistantMessage[]>();

  scrollbarRef = viewChild.required<NgScrollbar>('scrollbar');

  protected readonly scrollFromBottom = signal<number>(0);

  ngAfterViewInit(): void {
    this.scrollbarRef().scrollTo({ bottom: 0, duration: 0 });
  }

  handleScroll(): void {
    const target = this.scrollbarRef().nativeElement;
    const positionFromBottom = Math.max(target.scrollHeight - target.scrollTop - target.clientHeight, 0);

    this.scrollFromBottom.set(positionFromBottom);
  }
}

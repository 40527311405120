<button
  [disabled]="disabled"
  class="cc-microsoft-button"
>
  <svg
    class="cc-microsoft-button__icon"
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>MS-SymbolLockup</title>
    <rect
      fill="#f25022"
      height="9"
      width="9"
      x="1"
      y="1"
    />
    <rect
      fill="#00a4ef"
      height="9"
      width="9"
      x="1"
      y="11"
    />
    <rect
      fill="#7fba00"
      height="9"
      width="9"
      x="11"
      y="1"
    />
    <rect
      fill="#ffb900"
      height="9"
      width="9"
      x="11"
      y="11"
    />
  </svg>

  @if (type !== MicrosoftButtonType.Icon) {
    <span class="cc-microsoft-button__text">
      {{ buttonTextKey | translate }}
    </span>
  }
</button>

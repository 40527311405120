import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import * as R from 'ramda';

import { CoreModule } from '@core/core.module';
import { ResizeDirective } from '@core/directives/resize.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ComposerRecipientsSelectorPreviewItemComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-preview/composer-recipients-selector-preview-item/composer-recipients-selector-preview-item.component';
import {
  EmailParticipant,
  EmailParticipants,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface RecipientsGroup {
  type: 'to' | 'cc' | 'bcc';
  recipients: EmailParticipant[];
}

interface HiddenRecipients {
  to: number;
  cc: number;
  bcc: number;
}

@Component({
  selector: 'cc-composer-recipients-selector-preview',
  standalone: true,
  templateUrl: './composer-recipients-selector-preview.component.html',
  styleUrls: ['./composer-recipients-selector-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerRecipientsSelectorPreviewItemComponent,
    CoreModule,
    ResizeDirective,
    TooltipDirective,
    TranslateModule,
  ],
})
export class ComposerRecipientsSelectorPreviewComponent {
  @Input()
  recipients: Omit<EmailParticipants, 'from'>;

  @ViewChild('items', { static: false, read: ElementRef })
  itemsContainer: ElementRef<HTMLDivElement>;

  hiddenRecipients: HiddenRecipients = {
    to: 0,
    cc: 0,
    bcc: 0,
  };

  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly translate = inject(TranslateService);

  get groups(): RecipientsGroup[] {
    return [
      { type: 'to', recipients: this.recipients.to },
      { type: 'cc', recipients: this.recipients.cc },
      { type: 'bcc', recipients: this.recipients.bcc },
    ];
  }

  get hasRecipients(): boolean {
    const { to, cc, bcc } = this.recipients;
    return to.length > 0 || cc.length > 0 || bcc.length > 0;
  }

  get totalHiddenRecipients(): number {
    return this.hiddenRecipients.to + this.hiddenRecipients.cc + this.hiddenRecipients.bcc;
  }

  get hiddenRecipientsString(): string {
    if (this.totalHiddenRecipients === 0) return '';

    let hiddenRecipientsString = `+${this.totalHiddenRecipients}`;

    if (this.hiddenRecipients.cc > 0 || this.hiddenRecipients.bcc > 0) {
      hiddenRecipientsString += ' (';
      if (this.hiddenRecipients.cc > 0)
        hiddenRecipientsString += `${this.hiddenRecipients.cc} ${this.translate.instant('conversations-v4.composer.recipientSelector.cc')}`;
      if (this.hiddenRecipients.cc > 0 && this.hiddenRecipients.bcc > 0) hiddenRecipientsString += ', ';
      if (this.hiddenRecipients.bcc > 0)
        hiddenRecipientsString += `${this.hiddenRecipients.bcc} ${this.translate.instant('conversations-v4.composer.recipientSelector.cc')}`;
      hiddenRecipientsString += ')';
    }

    return hiddenRecipientsString;
  }

  recalculateHiddenRecipients(): void {
    if (!this.itemsContainer) return;

    const container = this.itemsContainer.nativeElement;
    const children = [...(container.children as any)] as HTMLDivElement[];

    const containerTop = container.getBoundingClientRect().top;

    const hiddenChildren = children
      .map((child) => {
        const rect = child.getBoundingClientRect();
        const type = child.dataset.type as 'to' | 'cc' | 'bcc';

        return {
          top: rect.top - containerTop,
          type,
        };
      })
      .filter(({ top }) => top > 0);

    this.hiddenRecipients = {
      to: R.count((item) => item.type === 'to', hiddenChildren),
      cc: R.count((item) => item.type === 'cc', hiddenChildren),
      bcc: R.count((item) => item.type === 'bcc', hiddenChildren),
    };
  }
}

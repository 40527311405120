import { ConversationFolder } from '@conversations/conversation/state/conversation/conversation-state.model';

const baseKey = 'conversations-v4.conversation.dialogs.movingFolders';

export const getMoveToFolderDialogTitleKey = (currentFolder: ConversationFolder, newFolder: ConversationFolder) => {
  switch (newFolder) {
    case ConversationFolder.Inbox:
      if (currentFolder === ConversationFolder.Archive) return `${baseKey}.title.restoreFromArchive`;

      if (currentFolder === ConversationFolder.Spam) return `${baseKey}.title.restoreFromSpam`;

      if (currentFolder === ConversationFolder.Trash) return `${baseKey}.title.restoreFromTrash`;

      return undefined;

    case ConversationFolder.Archive:
      return `${baseKey}.title.moveToArchive`;

    case ConversationFolder.Spam:
      return `${baseKey}.title.moveToSpam`;

    case ConversationFolder.Trash:
      return `${baseKey}.title.moveToTrash`;
  }
};

export const getMoveToFolderDialogMessageKey = (currentFolder: ConversationFolder, newFolder: ConversationFolder) => {
  switch (newFolder) {
    case ConversationFolder.Inbox:
      if (currentFolder === ConversationFolder.Archive) return `${baseKey}.message.restoreFromArchive`;
      if (currentFolder === ConversationFolder.Spam) return `${baseKey}.message.restoreFromSpam`;
      if (currentFolder === ConversationFolder.Trash) return `${baseKey}.message.restoreFromTrash`;

      return undefined;

    case ConversationFolder.Archive:
      return `${baseKey}.message.moveToArchive`;
    case ConversationFolder.Spam:
      return `${baseKey}.message.moveToSpam`;
    case ConversationFolder.Trash:
      return `${baseKey}.message.moveToTrash`;
  }
};

export const getMoveToFolderDialogConfirmTextKey = (newFolder: ConversationFolder) => {
  switch (newFolder) {
    case ConversationFolder.Inbox:
      return `${baseKey}.confirmText.inbox`;
    case ConversationFolder.Archive:
      return `${baseKey}.confirmText.archive`;
    case ConversationFolder.Spam:
      return `${baseKey}.confirmText.spam`;
    case ConversationFolder.Trash:
      return `${baseKey}.confirmText.trash`;
  }
};

import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import {
  ExternalAccount,
  ExternalAccountStatus,
  ProviderType,
} from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { GoogleButtonText } from '@design/buttons/google-button/google-button';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { MicrosoftButtonText } from '@design/buttons/microsoft-button/microsoft-button';
import { MicrosoftButtonComponent } from '@design/buttons/microsoft-button/microsoft-button.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { GoogleButtonComponent } from '@design/buttons/google-button/google-button.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cc-external-account-table-row',
  standalone: true,
  imports: [
    NgOptimizedImage,
    DropdownComponent,
    DropdownTextComponent,
    DropdownDividerComponent,
    DropdownActionComponent,
    OverlayModule,
    ButtonComponent,
    TooltipDirective,
    GoogleButtonComponent,
    MicrosoftButtonComponent,
    TranslateModule,
  ],
  templateUrl: './external-account-table-row.component.html',
  styleUrls: ['./external-account-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalAccountTableRowComponent {
  @Input()
  account: ExternalAccount;

  reconnect = output<ExternalAccount>();

  remove = output<ExternalAccount>();

  protected actionsDropdownVisible = false;
  protected readonly actionsDropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      offsetX: 12,
      offsetY: 4,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      panelClass: null,
    },
  ];

  protected readonly ExternalAccountStatus = ExternalAccountStatus;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly GoogleButtonText = GoogleButtonText;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly MicrosoftButtonText = MicrosoftButtonText;
  protected readonly ProviderType = ProviderType;

  private readonly translate = inject(TranslateService);

  get providerLogo(): string {
    switch (this.account.providerType) {
      case ProviderType.Google:
        return 'assets/svg/providers/google.svg';
      case ProviderType.Microsoft:
        return 'assets/svg/providers/microsoft.svg';
    }
  }

  get providerName(): string {
    switch (this.account.providerType) {
      case ProviderType.Google:
        return this.translate.instant('conversations-v4.workspaces.settings.accounts.providers.google.name');
      case ProviderType.Microsoft:
        return this.translate.instant('conversations-v4.workspaces.settings.accounts.providers.microsoft.name');
    }
  }

  get canReconnect(): boolean {
    return [ExternalAccountStatus.Disconnected, ExternalAccountStatus.Expired, ExternalAccountStatus.Failed].includes(
      this.account.status,
    );
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import type { BPIOrder } from '../business-object';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { DateFormatDistancePipe, DateFormatPipe } from '@clover/core/pipes/date-format.pipe';

@Component({
  selector: 'cc-bpi-order-drawer',
  standalone: true,

  imports: [ButtonComponent, TooltipDirective, NgOptimizedImage, DateFormatDistancePipe, DateFormatPipe, CurrencyPipe],
  templateUrl: './bpi-order-drawer.component.html',
  styleUrls: ['./bpi-order-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpiOrderDrawerComponent {
  @Input()
  order: BPIOrder;

  @Output()
  closeDrawer = new EventEmitter<void>();

  @Output()
  selectOrder = new EventEmitter<BPIOrder>();

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  getStatusText(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return 'Partially Invoiced';
      default:
        return 'Unknown';
    }
  }

  getStatusFgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#725616';
      default:
        return '#000000';
    }
  }

  getStatusBgColor(order: BPIOrder): string {
    switch (order.status) {
      case 'partially-invoiced':
        return '#F9E8C0';
      default:
        return '#FFFFFF';
    }
  }
}

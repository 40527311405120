import { Component, inject, Input, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { Store } from '@ngxs/store';
import { WikiSelectors } from '@network/company/state/wiki/wiki.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectDocument } from '@network/company/state/wiki/wiki.actions';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cc-company-wiki-tree-view-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './company-wiki-tree-view-item.component.html',
  styleUrls: ['./company-wiki-tree-view-item.component.scss'],
  animations: [
    trigger('toggleChildren', [
      state('true', style({ height: '*', opacity: 1 })),
      state('false', style({ height: 0, opacity: 0 })),
      transition('true <=> false', animate('0.2s ease-in-out')),
    ]),
  ],
})
export class CompanyWikiTreeViewItemComponent implements OnInit {
  activeDocument$ = inject(Store).select(WikiSelectors.activeDocument);

  @Input()
  document: ICompanyWikiDocument;

  @Input()
  expanded = true;

  @Input()
  depth: number;

  selected$: Observable<boolean>;

  private readonly store = inject(Store);

  get hasChildren(): boolean {
    return this.document.children?.length > 0;
  }

  get spacerWidth(): number {
    if (this.depth === 0) return 2;
    return this.depth * 12;
  }

  get itemsGap(): number {
    if (this.depth === 0) return 4;
    return 2;
  }

  ngOnInit(): void {
    this.selected$ = this.activeDocument$.pipe(map((activeDocument) => activeDocument?.id === this.document.id));
  }

  selectItem(): void {
    this.store.dispatch(new SelectDocument(this.document.id));
  }

  toggleItem(event: MouseEvent): void {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }
}

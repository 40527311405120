import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TaskProduct } from '@conversations/tasks/tasks.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-task-product-card',
  standalone: true,
  templateUrl: './task-product-card.component.html',
  styleUrls: ['./task-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, TooltipDirective, TranslateModule],
})
export class TaskProductCardComponent {
  @Input()
  product: TaskProduct;

  protected readonly TooltipAlignment = TooltipAlignment;
}

<ng-scrollbar
  class="cc-table--scrollbar"
  (scrolled)="loadNextCompanies()"
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="0"
  [infiniteScrollUpDistance]="4"
  [scrollWindow]="false"
  orientation="vertical"
  cdkScrollable
  infiniteScroll
>
  <div class="cc-table--scrollbar-content">
    <cc-table
      (searchChange)="handleSearchChange($event)"
      [ccSearchable]="true"
      [ccSearchPlaceholder]="'Search companies...'"
      [ccEmptyRowTemplate]="emptyRowTemplate"
      class="cc-table"
      ccPadding="small"
    >
      <ng-container slot="cc-table-title">
        @if (loadingStatus() === 'loading' && !loadedCompanies()) {
          <span class="cc-ellipsis-animation">
            {{ 'common.strings.loading' | translate }}
          </span>
        } @else {
          @if (companiesTotal() === 1) {
            1 company
          } @else {
            {{ companiesTotal() }} companies
          }
        }
      </ng-container>

      <ng-container slot="cc-table">
        <thead>
          <tr ccTr>
            <th ccTh>Company</th>
          </tr>
        </thead>

        <tbody>
          @for (company of companies(); track company.id) {
            <tr
              ccTr
              (click)="handleCompanySelect(company)"
              [ccClickable]="true"
              [ccSelected]="selectedCompany()?.id === company.id"
            >
              <td ccTd>
                <cc-company-card [company]="company" />
              </td>
            </tr>
          }

          <ng-template #emptyRowTemplate>
            @if (loadingStatus() === 'loading') {
              Loading companies...
            } @else {
              There are no companies to display.
            }
          </ng-template>
        </tbody>
      </ng-container>
    </cc-table>
  </div>
</ng-scrollbar>

import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextEditorBaseControlComponent } from '../base-action.component';

@Component({
  selector: 'cc-rich-text-editor-italic-control',
  standalone: true,
  imports: [ButtonComponent, TranslateModule, TooltipDirective],
  template: `
    <app-button
      (click)="toggle()"
      [active]="active()"
      [type]="ButtonType.Ghost"
      [size]="ButtonSize.Big"
      [ccTooltip]="'richTextEditor.actions.italic' | translate"
    >
      <i
        class="icon-italic"
        slot="ghost-icon"
      ></i>
    </app-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorItalicControlComponent extends RichTextEditorBaseControlComponent {
  active = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive('italic');
  });

  toggle(): void {
    if (!this.activeEditor()) return;

    this.activeEditor().chain().focus().toggleItalic().run();
    this.selectionNotifier.notify();
  }
}

<div class="conversation-header-details">
  <div
    [class.conversation-header-details__subject--empty]="subjectEmpty"
    class="conversation-header-details__subject"
  >
    @if (!subjectEmpty) {
      {{ details.subject }}
    } @else {
      {{ 'conversations-v4.common.noSubject' | translate }}
    }
  </div>

  @if (systemLabels.length) {
    <button
      (click)="systemLabelsVisible = !systemLabelsVisible"
      [ccTooltip]="
        systemLabelsVisible
          ? ('conversations-v4.conversation.header.hideSystemLabels' | translate)
          : ('conversations-v4.conversation.header.showSystemLabels' | translate)
      "
      [class.conversation-header-details__system-labels-toggle--active]="systemLabelsVisible"
      [tooltipPosition]="TooltipPosition.Bottom"
      class="conversation-header-details__system-labels-toggle"
    >
      <i class="icon-more"></i>
    </button>

    @if (systemLabelsVisible) {
      @for (label of systemLabels; track label.id) {
        <cc-label-chip
          [clickable]="false"
          [label]="label"
          [showRemoveButton]="false"
          class="conversation-header-details__label"
          [labelTooltip]="'conversations-v4.conversation.header.systemLabel' | translate"
        />
      }
    }
  }

  @for (label of nonSystemLabels; track label.id) {
    <cc-label-chip
      (labelClick)="navigateToLabel(label)"
      (labelRemove)="removeLabel(label)"
      [clickable]="true"
      [label]="label"
      [showRemoveButton]="assignee && !accountIssue"
      class="conversation-header-details__label"
      [labelTooltip]="'conversations-v4.conversation.header.goToLabel' | translate"
      [removeLabelTooltip]="'conversations-v4.conversation.header.removeLabel' | translate"
    />
  }
</div>

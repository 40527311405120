import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { AsyncPipe } from '@angular/common';
import { ConversationPlaceholderComponent } from '@conversations/conversation/conversation-placeholder/conversation-placeholder.component';
import { ActiveConversationComponent } from '@conversations/conversation/active-conversation/active-conversation.component';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterSelectors, RouterStateParams } from '@clover/custom-router-state-serializer';

@Component({
  standalone: true,
  imports: [
    NgScrollbarModule,
    ScrollingModule,
    AutoAnimateDirective,
    AsyncPipe,
    ConversationPlaceholderComponent,
    ActiveConversationComponent,
  ],
  templateUrl: './conversation-container.component.html',
  styleUrls: ['./conversation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationContainerComponent implements OnInit {
  loadingStatus$: Observable<'void' | 'loading' | 'loaded' | 'error'>;

  private readonly store = inject(Store);

  ngOnInit(): void {
    const hasActiveConversation$ = this.store.select(RouterSelectors.state).pipe(
      map(({ params }: RouterStateParams) => {
        return Object.hasOwn(params, 'conversationId');
      }),
    );

    this.loadingStatus$ = combineLatest([
      hasActiveConversation$,
      this.store.select(ConversationSelectors.loadingStatus),
    ]).pipe(
      map(([hasActiveConversation, loadingStatus]) => {
        if (!hasActiveConversation) return 'void';
        return loadingStatus;
      }),
    );
  }
}

<div
  [class.label-chip--clickable]="clickable"
  [class.label-chip--private]="label.private"
  [style.background]="backgroundColor"
  [style.color]="foregroundColor"
  class="label-chip"
>
  <div
    (click)="clickable && labelClick.emit()"
    [ccTooltip]="labelTooltip"
    [tooltipDisabled]="!labelTooltip"
    [tooltipPosition]="TooltipPosition.Bottom"
    class="label-chip--wrapper"
  >
    @if (label.private) {
      <i class="icon-lock label-chip__private-icon"></i>
    }

    <div class="label-chip__name">
      {{ label.name }}
    </div>
  </div>

  @if (showRemoveButton) {
    <div
      (click)="$event.stopPropagation(); labelRemove.emit()"
      [ccTooltip]="removeLabelTooltip"
      [tooltipAlignment]="TooltipAlignment.End"
      [tooltipDisabled]="!removeLabelTooltip"
      [tooltipPosition]="TooltipPosition.Bottom"
      class="label-chip__remove-button"
    >
      <i class="icon-close-small"></i>
    </div>
  }
</div>

export interface WikiStateModel {
  documents: ICompanyWikiDocument[];
  activeDocument: ICompanyWikiDocument | null;
  editorMode: WikiEditorMode;
  companyId: string;
}

interface ICompanyWikiDocumentAuthor {
  firstName: string;
  lastName: string;
  logoUrl: string;
}

export interface ICompanyWikiDocument {
  id: number;
  name: string;
  companyId: number;
  parentPageId: number;
  createdAt?: string;
  createdBy?: ICompanyWikiDocumentAuthor;
  updatedAt?: string;
  updatedBy?: ICompanyWikiDocumentAuthor;
  children?: ICompanyWikiDocument[];
  content?: string;
  path?: WikiDocumentPath[];
}

export interface WikiDocumentPath {
  id: number;
  title: string;
}

export enum WikiEditorMode {
  Preview = 'preview',
  Edit = 'edit',
}

export interface IUpdatePageResponse {
  companyId: number;
  createdAt: string;
  createdBy: number;
  id: number;
  name: string;
  parentPageId: number;
  updatedAt: string;
  updatedBy: number;
}

export interface ISubPageResponse extends IUpdatePageResponse {
  children: ICompanyWikiDocument[];
}

export enum MockDocumentsType {
  RetailerInc = 'retailer-inc',
  Default = 'default',
}

export const DEFAULT_WIKI_STATE: WikiStateModel = {
  documents: [],
  activeDocument: null,
  editorMode: WikiEditorMode.Preview,
  companyId: null,
};

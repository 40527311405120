import { createSelector, Selector } from '@ngxs/store';
import { AssistantState } from './assistant.state';
import type { AssistantStateModel, AssistantThread } from './assistant.entities';

export class AssistantSelectors {
  @Selector([AssistantState])
  static threads(state: AssistantStateModel): AssistantThread[] {
    return state.threads;
  }

  static threadById(threadId: string) {
    return createSelector([AssistantSelectors.threads], (threads: AssistantThread[]): AssistantThread | undefined => {
      return threads.find((thread) => thread.id === threadId);
    });
  }

  static threadByRelatedConversationId(conversationId: string) {
    return createSelector([AssistantSelectors.threads], (threads: AssistantThread[]): AssistantThread | undefined => {
      return threads.find((thread) => thread.metadata.relatedConversationId === conversationId);
    });
  }
}

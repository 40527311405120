<div class="assistant">
  <cc-assistant-header
    [canDeleteThread]="canDeleteThread()"
    (threadDelete)="deleteCurrentThread()"
    class="assistant__header"
  />

  @if (thread(); as thread) {
    <cc-assistant-chat
      class="assistant__chat"
      [messages]="thread.messages"
    />
  } @else {
    <cc-assistant-placeholder class="assistant__placeholder" />
  }

  <cc-assistant-composer
    [sendDisabled]="sendDisabled()"
    class="assistant__composer"
    (messageSend)="sendMessage($event)"
  />
</div>

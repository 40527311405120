import { Component, Input } from '@angular/core';
import {
  GOOGLE_BUTTON_TEXT_MAP,
  GoogleButtonText,
  GoogleButtonType,
} from '@design/buttons/google-button/google-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class GoogleButtonComponent {
  @Input()
  type: GoogleButtonType = GoogleButtonType.Standard;

  @Input()
  text: GoogleButtonText = GoogleButtonText.SignInWithGoogle;

  @Input()
  disabled = false;

  protected readonly GoogleButtonType = GoogleButtonType;

  get buttonTextKey(): string {
    return GOOGLE_BUTTON_TEXT_MAP.get(this.text) || '';
  }
}

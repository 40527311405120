import { Injector } from '@angular/core';
import { Node } from '@tiptap/core';
import { AngularNodeViewRenderer } from 'ngx-tiptap';
import { textPlaceholderSelector } from '../dynamic-values.service';
import type { TextPlaceholderType } from './placeholders';
import { TextPlaceholderComponent } from './text-placeholder.component';

export interface TextPlaceholderOptions {
  type: TextPlaceholderType;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textPlaceholder: {
      insertTextPlaceholder: (options: TextPlaceholderOptions) => ReturnType;
    };
  }
}

export const TextPlaceholderExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'textPlaceholder',
    group: 'inline',
    inline: true,
    atom: true,
    draggable: true,

    addAttributes() {
      return {
        type: {
          isRequired: true,
        },
      };
    },

    parseHTML() {
      return [{ tag: textPlaceholderSelector }];
    },

    renderHTML({ HTMLAttributes: { type } }) {
      return [textPlaceholderSelector, { type }];
    },

    addNodeView() {
      return AngularNodeViewRenderer(TextPlaceholderComponent, { injector });
    },

    addCommands() {
      return {
        insertTextPlaceholder:
          ({ type }: TextPlaceholderOptions) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: { type },
            });
          },
      };
    },
  });
};

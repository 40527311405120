import { Component, inject, OnInit } from '@angular/core';

import { CompanyWikiFileExplorerComponent } from '@network/company/company-wiki/company-wiki-file-explorer/company-wiki-file-explorer.component';
import { ICompanyWikiDocumentPreviewComponent } from '@network/company/company-wiki/company-wiki-document-preview/company-wiki-document-preview.component';
import { Observable } from 'rxjs';
import { WikiSelectors } from '@network/company/state/wiki/wiki.selectors';
import { Store } from '@ngxs/store';
import { ICompanyWikiDocumentEditorComponent } from '@network/company/company-wiki/company-wiki-document-editor/company-wiki-document-editor.component';
import { AsyncPipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cc-company-wiki',
  standalone: true,
  imports: [
    CompanyWikiFileExplorerComponent,
    ICompanyWikiDocumentPreviewComponent,
    ICompanyWikiDocumentEditorComponent,
    AsyncPipe,
  ],
  templateUrl: './company-wiki.component.html',
  styleUrls: ['./company-wiki.component.scss'],
})
export class CompanyWikiComponent implements OnInit {
  activeDocument$ = inject(Store).select(WikiSelectors.activeDocument); // ToDo [Andrii S.];
  isEditing$ = inject(Store).select(WikiSelectors.isEditing);

  showFileExplorer$: Observable<boolean>;

  ngOnInit(): void {
    this.showFileExplorer$ = this.isEditing$.pipe(map((isEditing) => !isEditing));
  }
}

export const imageCache = new Map<string, string>();

/**
 * Caches images found in the provided HTML content.
 *
 * This function scans the given HTML content for `<img>` tags and processes their `src` attributes.
 * If an image source is not already cached, it schedules the image to be cached.
 * If an image source is already cached, it replaces the `src` attribute with the cached image source.
 *
 * @param htmlContent - The HTML content containing `<img>` tags to be processed.
 * @returns The HTML content with updated image sources where applicable.
 */
export function cacheImages(htmlContent: string): string {
  const images = htmlContent.match(/<img[^>]+src="([^">]+)"/g);
  if (!images) return htmlContent;

  images.forEach((image) => {
    const srcMatch = image.match(/src="([^"]+)"/);
    if (!srcMatch) return;

    const src = srcMatch[1];

    if (!imageCache.has(src)) {
      // Schedule the image to be cached
      cacheImage(src);
    } else {
      // Replace the image src with the cached image
      const cachedImage = imageCache.get(src);
      htmlContent = htmlContent.replace(src, cachedImage);
    }
  });

  return htmlContent;
}

async function cacheImage(src: string): Promise<void> {
  try {
    const response = await fetch(src);
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64data = reader.result as string;
      imageCache.set(src, base64data);
    };

    reader.readAsDataURL(blob);
  } catch {
    // Ignore errors
  }
}

import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { DEFAULT_DOCUMENT } from '@network/company/state/wiki/wiki.mock';

export const RETAILER_COMPANY_DOCUMENTS: ICompanyWikiDocument[] = [
  {
    ...DEFAULT_DOCUMENT,
    id: 1,
    name: 'Tory Burch Supplier Portal',
    content: `![Living_Spaces_Logo (1).jpg](https://ledgerset.blob.core.windows.net/static/ls_wiki_header.png)

# Tory Burch Supplier Resources
Welcome to the Tory Burch Supplier Portal. We are thrilled to have you as an integral part of our journey in creating stylish and high-quality fashion products. Our portal is designed to provide you with valuable insights and tools to enhance our collaboration. Here, you will find detailed information on policies, processes, and procedures. This comprehensive resource is dedicated to supporting our shared goals and driving our mutual success. Let's work together to innovate and achieve excellence in every aspect of our partnership!

[Code of Conduct](Tory%20Burch%20Supplier%20Portal/Code%20of%20Conduct.md)

[Purchase Order Management (EDI)](Tory%20Burch%20Supplier%20Portal/Purchase%20Order%20Management%20(EDI).md)

[Item Setup](Tory%20Burch%20Supplier%20Portal/Item%20Setup.md)

[Merchandising Requirements](Tory%20Burch%20Supplier%20Portal/Merchandising%20Requirements.md)

[Assembly Requirements & Parts](Tory%20Burch%20Supplier%20Portal/Assembly%20Requirements%20&%20Parts.md)

[Shipping & Variance Policies](Tory%20Burch%20Supplier%20Portal/Shipping%20&%20Variance%20Policies.md)

[Purchase Order Requirements](Tory%20Burch%20Supplier%20Portal/Purchase%20Order%20Requirements.md)

[New Store Opening Agreement](Tory%20Burch%20Supplier%20Portal/New%20Store%20Opening%20Agreement.md)

[Supplier Responsibilities](Tory%20Burch%20Supplier%20Portal/Supplier%20Responsibilities.md)

[Packaging Requirements](Tory%20Burch%20Supplier%20Portal/Packaging%20Requirements.md)

[Labeling Requirements](Tory%20Burch%20Supplier%20Portal/Labeling%20Requirements.md)

[Shipping Requirements](Tory%20Burch%20Supplier%20Portal/Shipping%20Requirements.md)

[Quality Requirements](Tory%20Burch%20Supplier%20Portal/Quality%20Requirements.md)

[Domestic Routing Instructions](Tory%20Burch%20Supplier%20Portal/Domestic%20Routing%20Instructions.md)

[Dropship Parcel & LTL Standards](Tory%20Burch%20Supplier%20Portal/Dropship%20Parcel%20&%20LTL%20Standards.md)

[LTL Requirements](Tory%20Burch%20Supplier%20Portal/LTL%20Requirements.md)

[Direct Container Shipping Requirements](Tory%20Burch%20Supplier%20Portal/Direct%20Container%20Shipping%20Requirements.md)

[Supplier Invoice Processing](Tory%20Burch%20Supplier%20Portal/Supplier%20Invoice%20Processing.md)

[Return to Supplier](Tory%20Burch%20Supplier%20Portal/Return%20to%20Supplier.md)

[Chargeback Schedule](Tory%20Burch%20Supplier%20Portal/Chargeback%20Schedule.md)

# Contact Information

For any questions or concerns, please reach out to our Supplier Relations Team at [supplier.relations@livingspaces.com](mailto:supplier.relations@livingspaces.com). Our team is dedicated to ensuring a smooth and efficient collaboration. We appreciate your partnership.`,
    children: [
      {
        ...DEFAULT_DOCUMENT,
        ...DEFAULT_DOCUMENT,
        id: 11,
        name: 'Assembly Requirements & Parts',
        content: `# Assembly Requirements & Parts

Table of contents:

## Overview

Tory Burch requires that parts list and assembly instructions are easy to understand and be interpreted by our customers, distribution centers and drivers.

Parts lists and assembly instructions must contain the Tory Burch logo, Tory Burch collection name, and be approved by the supplier relations team prior to the first shipment. The approved assembly instructions must be included with the furniture on every shipment.

After SKU creation, all items must have an agreed upon parts list that is uploaded via Brandfolder to Tory Burch in the form of a PDF file. **Assembly instructions must be uploaded via Brandfolder to Tory Burch 30 days prior to the initial PO being shipped to Tory Burch.** *Items that do not have assembly instructions uploaded in to Brandfolder by the first receipt date will be subject to chargebacks.*

LTL and Dropship products do not require assembly instructions to have Tory Burch logos. Assembly instructions are required to be uploaded to Brandfolder as soon as product is approved through in-River.

## **Assembly Instructions**

- Assembly instructions are required to be branded with the Tory Burch logo and not the supplier’s logo.
- Assembly instructions must be secured in the master carton as easily accessible.

Below is the list of assembly instruction requirements:

- Parts must be identified in a diagram and must show the following:
    - Part SKU number (supplier parts sku number)
    - Image of parts
    - Part description
    - Part quantity
- Image of fully assembled item
- Required resources
    - How many people will it take to assemble the item?
    - How long will it take to assemble the item? This time must match what is entered in In-River at the time of SKU creation.
    - Tools required for assembly
- Step by Step assembly instructions
    - Clearly identified steps in the assembly process
    - Must show the part SKU number being used
    - Must display arrows to demonstrate the direction or orientation of the parts when being assembled.
- QR Code linked to an assembly video is required for all items requiring assembly (other than putting knobs or simple components on the pieces). **This would apply to ALL product that requires assembly over 15 minutes of assembly time.**

### **Assembly Video Requirements**

- Assembly videos should be created for items that require complicated assembly OR for products with assembly time 15 minutes or over.
- Assembly videos should be 2-3 minutes in length at max.
- The assembly videos should depict step by step instructions to complete the assembly process.
- Approved Assembly Video Links:
    - **Dressers:** [https://youtu.be/j8pQMHkAsE8](https://youtu.be/j8pQMHkAsE8)
    - **Beds:** [https://youtu.be/cxs6h6RvF-I](https://youtu.be/cxs6h6RvF-I)
    - **Dining:** [https://youtu.be/7BnyWrxmxe8](https://youtu.be/7BnyWrxmxe8)

### **Assembly Instructions Example**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Assembly%20Requirements%20&%20Parts%20730f708bfc8944f2a4565b4a7eb6a66b/Untitled.png)

## **Parts Process**

### Overview

The goal of the parts process is to ensure that parts are delivered to the respective party (either direct to the customer or to the specific warehouse) within the required time frame and to ensure all parties have the necessary information.

Timeline Expectations:

- Domestic Parts: must be fulfilled within 1 week (5 days) of receiving the part order.
- Import Parts: must be received by the end customer within 4-5 weeks (20 days) of receiving the part order.

### Supplier Process

- Part order is created and emailed to the supplier part order contact on file and uploaded to the parts portal.
- Suppliers are required to update the Tory Burch parts portal: [https://vendors.livingspaces.com/PartsOrderLogin.aspx](https://vendors.livingspaces.com/PartsOrderLogin.aspx)
- All suppliers are required to review the part orders on the parts portal and provide regular updates. This is a crucial part of the parts process with Tory Burch. Supplier compliance will be monitoring this process regularly to ensure we are receiving the required updates.
    - If there are known delays or other pertinent delivery information (such as tracking #, etc.) ensure to provide the update in the comments section and save.
    - When part orders are shipped, ensure to change the status from “pending” to “shipped” and include the tracking number or other pertinent information to track part orders.
- As you provide updates on the parts portal and save them, the comments are provided back to our guest services team to utilize when navigating guest phone calls related to parts. If we don’t have the necessary updates on the portal, our team cannot successfully help resolve guest calls which negatively impacts overall guest satisfaction.
- If there are questions related to part orders, please communicate via email to the appropriate team below:
    - Stock part orders: [warranty@livingspaces.com](mailto:warranty@livingspaces.com)
    - Special Order part orders: [so_group@livingspaces.com](mailto:so_group@livingspaces.com)
    - Warehouse part orders: [99-partsgroup@livingspaces.com](mailto:99-partsgroup@livingspaces.com)
    - Other: [compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)
- For parts shipped to any of our DC’s, please ensure parts are packaged and shipped as described below:
    - Parts should be separated by receiving DC / All DCs should have separate package.
    
    ![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Assembly%20Requirements%20&%20Parts%20730f708bfc8944f2a4565b4a7eb6a66b/Untitled%201.png)
    
- Individual part orders within the box should be labeled as such:
    - Company
    - Part Order type
    - LSF Part Order Number
    - Shipping instructions (for stock parts you will input what PO the part is being shipped with)
    
    ![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Assembly%20Requirements%20&%20Parts%20730f708bfc8944f2a4565b4a7eb6a66b/Untitled%202.png)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 12,
        name: 'Chargeback Schedule',
        content: `# Chargeback Schedule

The purpose of chargebacks is not to generate revenue for Tory Burch, but to recover operating expenses that are incurred because of correcting supplier errors. This policy allows us to provide feedback to our suppliers, ensures cost recovery and helps supports more efficient practices.

| Type | Chargeback Code | Chargeback/Compliance Issue | Chargeback Expense |
| --- | --- | --- | --- |
| General Terms & Conditions | 
  CB001
   |  Merchandise not ordered | $100 per incident |
| General Terms & Conditions | 
  CB002
   |  Fill rate less than 90% at PO line level (855 vs invoice) | $100 per incident |
| General Terms & Conditions | 
  CB003
   | Supplier shipped a canceled order | $100 per incident + freight charges |
| Data Entry/InRiver | 
  CB004
   | Inaccurate SKU data entered into InRiver | $100 per SKU |
| Data Entry/InRiver | 
  CB0022
   | Missing assembly instructions | $100 per item |
| EDI | 
  CB005
   | Late EDI 855 received (not received with 24 hours of PO) | $100 per incident (DS $25 per incident)
   |
| EDI | 
  CB006
   | Late EDI 810 received (not received with 24 hours of PO) | $100 per incident (DS $25 per incident) |
| EDI | 
  CB007
   |  Missing ASN (must be received prior to PO being delivered) | $100 per incident (DS $25 per incident) |
| EDI | 
  CB008
   | Invalid/Useable 855 (ACK) | $100 per incident (DS $25 per incident) |
| EDI | 
  CB009
   | Invalid/Useable 856 (ASN) | $100 per incident (DS $25 per incident) |
| EDI | 
  CB010
   | Invalid/Useable 810 | $100 per incident (DS $25 per incident) |
| Shipping/Packaging | 
  CB011
   | Incorrect or missing packing slips | $100 per incident  |
| Shipping/Packaging | 
  CB012
   | BOL missing required information | $100 per incident |
| Shipping/Packaging | 
  CB015
   | Merchandise shipped to incorrect location | $100 per incident + $5 per carton |
| Labeling | 
  CB020
   | Duplicate carton label used | $5 per label
   |
| Labeling | 
  CB021
   | Product contents don’t match carton label/PO | $5 per label
 |
| Labeling | 
  CB022
   | Labels not legible/smudged or scannable | $5 per label |
| Labeling | 
  CB023
   | Incorrect label placement | $5 per label |
| Labeling | 
  CB024
   | Missing carton labels | $5 per label |
| Labeling | 
  CB025
   | Incorrectly labeled special order | $5 per label |`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 13,
        name: 'Code of Conduct',
        content: `# Code of Conduct

Table of Contents:

## Overview

Tory Burch is committed to maintaining the highest ethical standards in all business dealings and partnerships. This commitment to ethical conduct starts at the highest levels of the organization and extends to every team member. All suppliers are expected to comply with all applicable laws and regulations that are legislated by the country in which the merchandise is being manufactured and distributed.

## **Discrimination**

Any form of discrimination in hiring and employment practices, including any distinction, exclusion or preference based on race, caste, skin color, gender, age, religious belief, political opinion, membership in workers’ organizations, physical or mental disability, ethnic, national, or social background, nationality, sexual orientation, or any other personal characteristic protected by applicable law, is prohibited.

## **Gift Policy**

The purpose of Tory Burch Gifts and Favors Policy is to outline the circumstances in which receiving or giving gifts, gratuities and favors is acceptable. Under no circumstances may gifts, gratuities, or favors influence business decisions. Every Team Member has the responsibility to maintain and enhance Tory Burch’ public image and business integrity and to avoid practices that might undermine the objectivity of Tory Burch’ business decisions. Team Members must avoid doing anything that suggests their decisions may be influenced by any irrelevant or improper consideration whether illegal, such as a kickback or bribe, or technically legal, such as personal friendship, favors, gifts, or free entertainment.

No team member shall solicit or accept for personal use, or for the use of others, any gift, favor, loan, gratuity, reward, promise of future employment, or any other thing of monetary value that might influence, or appear to influence, the judgment or conduct of the Team Member in the performance of their job. Team Members are not to give, offer, or promise, directly or indirectly, anything of value to any representative of a customer, a potential customer, a supplier or potential supplier, financial institution, or potential financial institution with whom Tory Burch has or may have a business relationship.

Team members can accept occasional unsolicited courtesy gifts or favors (such as business meals, tickets to sporting events or cultural events, holiday baskets, flowers, etc) so long as the gifts or favors are legal, infrequent in nature, and do not create a sense of obligation. The gifts must have a market value under $25, are customary in the industry, and do not influence or appear to influence the judgment or conduct of the team member. Any exceptions to this amount must be approved by a senior level manager within Tory Burch. Business meals will not have a dollar limit if the meal occurs in one sitting. Gifts of cash in any amount are not allowed.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 14,
        name: 'Customs Trades Partnership against Terrorism (C-TPAT)',
        content: `# Customs Trades Partnership against Terrorism (C-TPAT)

## Overview

Tory Burch requires that all import manufacturers develop and implement security procedures. We highly recommend a C-TPAT certification but is not required. Participation in the C-TPAT program requires following Customs’ “Minimum Security Criteria”. These criteria define a baseline of security measures that apply to all C-TPAT members. It is our expectation that our suppliers are actively working toward meeting all these requirements if they have not already done so. In addition, most countries have developed or are developing their own C-TPAT type programs that allow exporters to be certified as businesses with secure operations and procedures.

### **Security Procedures**

For those business suppliers eligible for C-TPAT certification (carriers, ports, terminals, brokers, consolidators, etc.) the importer must have documentation (e.g., C-TPAT certificate, SVI number, etc.) indicating whether these business suppliers are or are not C-TPAT certified. For those business suppliers not eligible for C-TPAT certification, importers must require their business suppliers to demonstrate that they are meeting C-TPAT security criteria via written/electronic confirmation (e.g., contractual obligations; via a letter from a senior business supplier officer attesting to compliance; a written statement from the business supplier demonstrating their compliance with C-TPAT security criteria or an equivalent WCO accredited security program administered by a foreign customs authority; or, by providing a completed importer security questionnaire). Based upon a documented risk assessment process, non-CTPAT eligible business suppliers must be subject to verification of compliance with C-TPAT security criteria by the importer.

### **Point of Origin**

Importers must ensure business suppliers develop security processes and procedures consistent with the C-TPAT security criteria to enhance the integrity of the shipment at point of origin. Periodic reviews of business suppliers’ processes and facilities must be conducted based on risk and must maintain the security standards required by the importer.

### **Container Security**

Container integrity must be maintained to protect against the introduction of unauthorized material and/or persons. At point of stuffing, procedures must be in place to properly seal and maintain the integrity of the shipping containers. A high security seal must be affixed to all loaded containers bound for the U.S. All seals must meet or exceed the current PAS ISO 17712 standards for high security seals.

### **Container Inspection**

Procedures must be in place to verify the physical integrity of the container structure prior to stuffing, to include the reliability of the locking mechanisms of the doors. A seven-point inspection process is recommended for all containers:

- Front Wall
- Left Side
- Right Side
- Floor
- Ceiling
- Inside/Outside doors
- Outside/Undercarriage

### **Container Seals**

Written procedures must stipulate how seals are to be controlled and affixed to loaded containers - to include procedures for recognizing and reporting compromised seals and/or containers to US Customs and Border Protection or the appropriate foreign authority. Only designated employees must distribute container seals for integrity purposes.

### **Container Storage**

Containers must be stored in a secure area to prevent unauthorized access and/or manipulation. Procedures must be in place for reporting and neutralizing unauthorized entry into containers or container storage areas.

### **Physical Access Controls**

Access controls prevent unauthorized entry to facilities, maintain control of employees and visitors, and protect company assets. Access controls must include the positive identification of all employees, visitors, and suppliers at all points of entry.

### **Employees**

An employee identification system must be in place for positive identification and access control purposes. Employees must only be given access to those secure areas needed for the performance of their duties. Company management or security personnel must adequately control the issuance and removal of employee, visitor, and supplier identification badges. Procedures for the issuance, removal and changing of access devices (e.g., keys, key cards, etc.) must be documented.

### **Visitors**

Visitors must present photo identification for documentation purposes upon arrival. All visitors must be escorted and visibly display temporary identification. Deliveries (including mail) Proper supplier ID and/or photo identification must be presented for documentation purposes upon arrival by all suppliers. Arriving packages and mail must be periodically screened before being disseminated.

### **Challenging and Removing Unauthorized Persons**

Procedures must be in place to identify, challenge and address unauthorized/unidentified persons.

### **Personnel Security**

Procedures must be in place to identify, challenge and address unauthorized/unidentified persons.

### **Pre-Employment Verification**

Application information, such as employment history and references must be verified prior to employment.

### **Background checks / Investigations**

Consistent with foreign, federal, state, and local regulations, background checks and investigations must be conducted for prospective employees. Once employed, periodic checks and reinvestigations must be performed based on cause, and/or the sensitivity of the employee’s position.

### **Personnel Termination Procedures**

Companies must have procedures in place to remove identification, facility, and system access for terminated employees.

### **Procedural Security**

Security measures must be in place to ensure the integrity and security of processes relevant to the transportation, handling, and storage of cargo in the supply chain.

### **Documentation Processing**

Procedures must be in place to ensure that all information used in the clearing of merchandise/cargo, is legible, complete, accurate, and protected against the exchange, loss or introduction of erroneous information. Documentation control must include safeguarding computer access and information.

### **Manifesting Procedures**

To help ensure the integrity of cargo received from abroad, procedures must be in place to ensure that information received from business suppliers is reported accurately and timely.

### **Shipping & Receiving**

Arriving cargo must be reconciled against information on the cargo manifest. The cargo must be accurately described, and the weights, labels, marks, and piece count indicated and verified. Departing cargo must be verified against purchase or delivery orders. Drivers delivering or receiving cargo must be positively identified before cargo is received or released.

### **Cargo Discrepancies**

All shortages, overages, and other significant discrepancies or anomalies must be resolved and/or investigated appropriately. Customs and/or other appropriate law enforcement agencies must be notified if illegal or suspicious activities are detected – as appropriate.

### **Security Training and Threat Awareness**

A threat awareness program must be established and maintained by security personnel to recognize and foster awareness of the threat posed by terrorists at each point in the supply chain. Employees must be made aware of the procedures the company has in place to address a situation and how to report it. Additional training must be provided to employees in the shipping and receiving areas, as well as those receiving and opening mail.

Additionally, specific training must be offered to assist employees in maintaining cargo integrity, recognizing internal conspiracies, and protecting access controls. These programs must offer incentives for active employee participation.

### **Physical Security**

Cargo handling and storage facilities in domestic and foreign locations must have physical barriers and deterrents that guard against unauthorized access. Importers must incorporate the following C-TPAT physical security criteria throughout their supply chains as applicable.

### **Fencing**

Perimeter fencing must enclose the areas around cargo handling and storage facilities. Interior fencing within a cargo handling structure must be used to segregate domestic, international, high value, and hazardous cargo. All fencing must be regularly inspected for integrity and damage.

### **Gates and Gate Houses**

Gates through which vehicles and/or personnel enter, or exit must be manned and/or monitored. The number of gates must be kept to the minimum necessary for proper access and safety.

### **Parking**

Private passenger vehicles must be prohibited from parking in or adjacent to cargo handling and storage areas.

### **Building Structure**

Buildings must be constructed of materials that resist unlawful entry. The integrity of structures must be maintained by periodic inspection and repair.

### **Locking Devices and Key Controls**

All external and internal windows, gates and fences must be secured with locking devices. Management or security personnel must control the issuance of all locks and keys.

### **Lighting**

Adequate lighting must be provided inside and outside the facility including the following areas: entrances and exits, cargo handling and storage areas, fence lines and parking areas.

### **Alarm Systems & Video Surveillance Cameras**

Alarm systems and video surveillance cameras must be utilized to monitor premises and prevent unauthorized access to cargo handling and storage areas.

### **Information Technology Security**

Password Protection Automated systems must use individually assigned accounts that require a periodic change of password. IT security policies, procedures and standards must be in place and provided to employees in the form of training.

### **Accountability**

A system must be in place to identify the abuse of IT including improper access, tampering, or the altering of business data. All system violators must be subject to appropriate disciplinary actions for abuse.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 15,
        name: 'Direct Container Shipping Requirements',
        content: `# Direct Container Shipping Requirements

Table of contents:

## **Maersk SCM**

To support our import supply chain, Tory Burch utilizes Maersk SCM. Maersk delivers complete supply chain visibility and business intelligence, to improve business processes, increase efficiency and reduce costs.

- An import supplier will be setup with Maersk upon on boarding with Tory Burch. The factories producing Tory Burch goods will be setup and trained on the Maersk system and expectations.
- ***Booking Timing Expectation*** – all import suppliers are required to book within 4-6 weeks of ETD to secure sailing space.
- ***Documentation Upload Timing Expectation*** – all import suppliers are required to be uploaded to Maersk portal 2 days from sailing date.

## **New Factory On-Boarding Process**

To ensure that new factories are setup timely and accurately, please ensure to make the request with supplier compliance, the product manager, and planner. Please ensure to include the new factory setup form when requesting the setup of a new factory.

This is crucial to ensure that Maersk has adequate time to setup the new factory with the Maersk platform and to ensure factories are trained properly on how to book and process shipments through Maersk.

Ensure to give a month lead time before needing to ship orders from the new factory for the teams to get approvals, setup process and training completed.

Supplier must provide all shipping docs to Tory Burch (commercial invoice, packing list, Lacey - if applicable, bill of lading and TSCA declaration).

## **Commercial Invoice**

- Must include parts called out with a value associated plus what the parts are for (chair, table, bed, etc.). This is important as all have different tariff codes.
- Must list all SKU's and quantities on container
- Must list invoice total (before discount)
- All replacement parts must be listed as “Parts” and must have a value greater than $0 USD
- Invoice subtotal total must include the value of parts. Invoice grand total must show the value of parts being deducted for all part that are free of charge as listed in the image below
- Must list Tory Burch PO number
- Must list container number
- Must list seal number

## **Packing List**

- Packing list must list item quantities and carton count
- Parts must also be listed on packing list as well as declaring their carton count.
- Must list the container number
- Must list the Tory Burch PO number

## **Lacey Act (if applicable)**

- Must have the Estimated Date of Arrival filled out (MM/DD/YYYY)
- Must list container number
- Must list Bill of Lading number
- Must have "Tory Burch Furniture, LLC" as the consignee’s name
- Must have "14501 Artesia Blvd., La Mirada, CA, 90638" as the consignee address
- Must include the common species name of wood utilized (if applicable)

## **Bill of Lading**

- Must have the correct container, BOL, and carton count listed
- Must have "Tory Burch Furniture, LLC" as the consignee’s name
- Must list Damco as the notify party

## **TSCA – Toxic Substance Control Act**

- Must be included in shipment that contain substances listed in the TSCA regulations – Medium density fiberboard.

Declaration must state if substance complies or does not comply to TSCA regulations.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 16,
        name: 'Domestic Routing Instructions',
        content: `# Domestic Routing Instructions

Tory Burch is streamlining communications with our suppliers and leverage opportunities associated with day-to-day transportation management. To support our supply chain, Tory Burch utilizes BluJay Solution Transportation Management System (TMS). The TMS delivers complete transportation planning, execution, settlement, and procurement, as well as supply chain visibility and business intelligence, to improve business processes, increase efficiency and reduce costs. 

Suppliers can contact their regular Tory Burch contact with questions relating to Transportation requirements and expectations.  For more detailed contact information, please see below:

| Reason for Contact | Who to Contact | Contact Information |
| --- | --- | --- |
| - All account-level questions
- All operational questions on PO’s, shipment dates, delivery dates, etc. 
- Any questions where you are not sure where who to ask
 | Tory Burch | - Miguel Cazun (mailto:miguel.cazun@livingspaces.com)
- Andy Tinoco  (mailto:andy.tinoco@livingspaces.com) 
- Abigail Lopez (mailto:abigail.lopez@livingspaces.com) 
- Alfonso Fernandez (mailto:alfonso.fernandez@livingspaces.com) 
- Other
(mailto:logistics@livingspaces.com)  |

Orders are entered as requests and cannot be planned for transportation until ready-to-ship information has been entered in the SIM Portal.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 17,
        name: 'Dropship Parcel & LTL Standards',
        content: `# Dropship Parcel & LTL Standards

Table of contents:

## Overview

Dropship is defined by supplier holding inventory in their warehouse and ships direct to Tory Burch customers via UPS (parcel) or Convey/P44 (LTL).

## **EDI Requirements**

- **EDI 850-Purchase Order:**
    - All dropship purchase orders will be transmitted to the supplier via an EDI 850 transmission at the end of every day.
    - Individual lines on the purchase order will provide a specific carrier to ship with (UPS for parcel or PAAF for Pilot (LTL/non parcel eligible shipments). This is at the line level (not the PO header).
- **EDI 855- Acknowledgement Requirements:**
    - An EDI 855 is required from the supplier within 24hrs of receiving the EDI 850.
    - IF a purchase order cannot be fulfilled it is the suppliers’ responsibility to notify Tory Burch within 24hrs of receiving the EDI 850 via the 855.
- **Dropship 856-Advanced Shipping Notice Requirements:**
    - Dropship partners must submit a single 856 shipment notice per dropship order.
    - Dropship 856 documents have additional mapping requirements and it is imperative the accurate tracking information is included (e.g. Carrier and Tracking number).
- **EDI 810 – Supplier Purchase Order Invoice Requirements:**
    - All EDI 810 invoices must be sent to Tory Burch within 24 hours of the shipment leaving your facility. Each 810 Invoice must contain a unique invoice number; multiple invoices per 810 will be rejected.
- **EDI 846 – Inventory Inquiry / Advice Requirements:**
    - Dropship suppliers must provide a list of their items and the available supplier stock per item with the accurate supplier inventory daily. Tory Burch will leverage this information for replenishment planning and dropship inventory availability. A single 846 muse be used to provide supplier inventory for all catalog items.
    - Dropship suppliers must submit at least once daily EDI 846 with current inventory. Tory Burch does have the capability to accept 846 feeds to be transmitted multiple times per day.

To ensure Tory Burch can deliver a consistent level of quality to all customers, all dropship products that meet the UPS Parcel shipping requirements must follow ISTA 3A transit standards. These standards are designed to ensure that we can offer and deliver our wide assortment of merchandise to as many customers as possible within the United States.

Below is a list of the UPS parcel shipping requirements, these requirements can also be found at [www.ups.com](http://www.ups.com).

“UPS has established specific weight and size limits for the packages that you send with all UPS services. The restrictions below only pertain to individual packages. There are no limits to the total weight of your shipment or the total number of packages in your shipment. Shipments larger than the limits listed below will require the use of our UPS Freight services or UPS Worldwide Express FreightSM.”

- Packages can be up to 150 lbs.
- Packages can be up to 165 inches in length and girth combined ((Length + girth; girth = width + width + height + height)
- Packages can be up to 108 inches in length.

## **Dropship Parcel Shipping Requirements via UPS**

Tory Burch requires all dropship product that is parcel eligible to ship via UPS direct to Tory Burch customer.

Tory Burch requires all non-parcel eligible product that is drop shipping to be processed via Convey with a dedicated carrier (see the next section of this guide).

- All dropship purchase orders must ship within 48 hours of being transmitted to the supplier.
    - If there will be a delay (3 days or more) in processing the dropship order, reach out to Supplier Compliance ([compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)) and Guest Services Dropship Team ([dropship@livingspaces.com](mailto:dropship@livingspaces.com))
- Supplier Compliance will communicate to supplier their specific UPS shipper account # during the on-boarding process:
    - Parcel Shipments - Utilize your “Tory Burch Shipper Account Number” as Prepaid and Bill Third Party to Shipper Number Y799R9. *If you need your specific Tory Burch shipper account number, please reach out to [compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)*
    - LTL/Oversized (not parcel eligible) – Utilize Convey application to ship via a dedicated carrier that is populated. *See next section for Convey requirements & expectations.*
- Supplier is required to include specific reference fields:
    - Reference Field 1 – Provide the full-length SO # e., “SO-005567568”
    - Reference Field 2 – Provide the full-length PO # e., “PO-0002420559”
- Tory Burch does not require a physical packing slip to be shipped with the product.
- The UPS shipping label must show Tory Burch as the Shipper, the end consumer as the Ship to name and address. and ensure the label reflects 3rd party billing and the reference fields as described above. Tory Burch will need to approve the label prior to shipping for the first time.

## **Dropship LTL Shipping via Convey Platform**

IF product is not parcel shippable, Tory Burch requires to ship direct to end customer via Convey platform that will rate shop LTL carriers to utilize for shipping based on product dims and cost to ship.

- Suppliers are required to login with their credentials provided during the on boarding process by visiting, [https://app.getconvey.com/login](https://app.getconvey.com/login).
    - IF you don’t have login access – please reach out to [compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)
- Once you are logged into Convey, you’ll see a navigation bar on the left side of the page.
- Click on Pickups > Schedule Pickup
- This will automatically populate with the warehouse locations for company.
    - You’ll want to be sure to choose the correct origin address for the pickup.
- Enter Customer Shipping Information:
    - Name, Address, Phone Number, Service Level (default is Curbside/Loading Dock)
- Enter Additional Shipment Information:
    - PO #, Order #, Reference # (if applicable)
- The Commodity Information section is where you’ll enter or choose the product information for each item within the shipment.
    - Product Name/Description – Manufacturer and SKU ▪ Total Weight Class
    - Package *defaults to pallet
    - Handling Units *defaults to 1
    - Pieces *defaults to 1
    - If there is more than 1 item being shipped, you’ll want to click ADD ROW to enter each products information.
- You’ll want to select the Pickup Date when the product is packed and ready to be picked up by the Carrier.
    - **NOTE:** The ready time and dock close must be at least 2 hours apart.
- Once you’ve entered in the Origin Information, Destination Information, Product Information, Pickup Date Information, you can now **click Assign Carrier**.
- Convey will automatically choose best carrier for the shipment based on cost, service level, origin, and destination this will be reflected at the line level on each PO.
- Once you have confirmed that all information is accurate, **click Schedule Shipment**.
- Supplier is responsible for contacting the carrier directly to schedule a new pickup date.
- Supplier is responsible for contacting the carrier directly to cancel pickup request.

## Returns & Exchanges

- All customer returns will be shipped to one of our approved Tory Burch locations and should not be returned to the supplier.
- Tory Burch will not pay a restocking fee for any merchandise that is returned to the supplier for any reason.
- If there are any questions or callouts from a return’s perspective, please reach out to Supplier Compliance ([compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)).

## Dropship Contacts

Customer Service: (877) 266-7300 [dropship@livingspaces.com](mailto:dropship@livingspaces.com)

Supplier Compliance: [supplier.compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 18,
        name: 'Item Setup',
        content: `# Item Setup

Table of contents:

## **SKU Creation**

In River is our Product Information Management (PIM) tool that is used for SKU creation. Suppliers will be issued an in-River account during the supplier onboarding process. During the supplier on-boarding process suppliers will be required to become in River certified. Purchase Orders will not be able to be placed for any product that has not been published and approved by Livings Spaces in in River.

All suppliers are required to submit all new items through in River once the merchant has approved an item for purchase within one week.

## **Item Attribute Requirements**

We have expanded our use of attributes to improve the customer experience. Not only do the attributes help customers with information to make a purchase, but they are also very important for our ranking in the Google search engine. We rely on very detailed attributes by category to improve overall performance.

Please ensure to enter all attributes and in-river fields accurately. Please ensure to fill out each field properly per the described definition. Please see the Product How to Guide for detailed information (please reach out to supplier compliance to attain the How to Guide).

Failure to do so we will result in rejecting the item submission until completed and will delay new product orders.

## **Responding to Product Related Questions for Tory Burch Guests**

Tory Burch has multiple ways for guests on the web to ask questions about products. These include Bazaarvoice Q&A, chats, and our email address [webhelp@livingspaces.com](mailto:webhelp@livingspaces.com). About 80% of the time, we can answer the guests’ questions on our own. In the other 20% of cases, we need help from our vendor partners. The expectation is that each vendor will respond within 48 hours of being contacted by a Tory Burch employee (excluding weekends). However, the earlier we get a response, the better.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 19,
        name: 'Labeling Requirements',
        content: `# Labeling Requirements

Table of contents:

## Overview

Tory Burch requires all suppliers to pre-label shipments with custom barcode labels to improve the receiving process.  This helps eliminate operator mislabel and improves efficiency.

Below are examples and instructions for the Tory Burch In-house Stock and Special-order labels. Failure to comply with the pre-labeling requirements will be subject to charges as per the chargeback schedule included in the operations guide.

- Labels must be sized to fit a standard 4”x 6” label
- Labels must scan and be free of smudging, markings or tears that may interfere with the bar code
- Special order product labels must contain the Customer details and specific Special-Order product information. The barcode, as seen in the examples below, must be Code 128 (Font name: BC C128 HD Wide)

## **EDI Standard Labels**

A Stock purchase order is identified in the BEG 02 segment as PO type “SA”.

- EDI 850 example BEG*00*SA*PO-0002038121**20170502~

Below is an example of our existing in-house Stock product label. It is our expectation that the labels you attach prior to shipping to Tory Burch will replicate the format and will be sized to fit a standard 4”x 6” label. All fields are passed through in the EDI (850) purchase order document.

- The barcode is transmitted in the LIN03 segment of the 850 as follows: LIN**BN*0000000000000676384~
- Label Barcode: **00000000000006763848**

The checksum (Last digit) is not transmitted through EDI. This is added by the barcode generator on your end.

The barcode (License plate) number is unique and crucial to be successfully scanned into our system. The last number (**8** in this instance) is a checksum that will be added automatically by your barcode printer. These are transmitted in the LIN03 that we send over via EDI and are unique for each individual piece.  Note:  The same barcode (License plate) will never be used more than once.  If quantity 20 of item A are being shipped, each piece will be labeled with a unique barcode.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled.png)

### EDI Standard Label Field Mapping

| Label Field | EDI Data Field |
| --- | --- |
| SKU:  93874 | PO1*1*1*EA*000016.63**SK*93874*VC*513876-51*BL*SEAL~ |
| Model Number: 513876-51 | PO1*1*1*EA*000016.63**SK*93874*VC*513876-51*BL*SEAL~ |
| P/O: PO-0002038121 | BEG*00*SA*PO-0002038121**20170502~ |
| Description: RANDOLPH TERRACE QUEEN MATTRESS | PID*F****RANDOLPH TERRACE QUEEN MATTRESS~ |
| Special Order Info:\t           | Leave Blank  |
| Barcode: 0000000000000676 | LIN**BN*0000000000000676384~ (Note: The label must contain the provided 19 characters plus the check sum for the full 20-digit barcode number) |
| Supplier: SEAL | PO1*1*1*EA*000016.63**SK*93874*VC*513876-51*BL*SEAL~ |
| Print Date (the date under SEAL): 05/02/2017 | Dynamic Date when the label is printed |

**See Stock Example Label Below:**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%201.png)

## **EDI Special Order Label**

A Special-order purchase order is identified in the BEG 02 segment as PO type “OS”.

- EDI 850 example BEG*00*OS*PO-0002001337**20170611~

Below is an example of our existing in-house Special-order product label. It is our expectation that the labels you attach prior to shipping to Tory Burch will replicate the format and will be sized to fit a standard 4”x 6” label. All fields are passed through in the EDI (850) purchase order document.

The barcode is transmitted in the LIN03 segment of the 850 as follows:

- LIN**BN*0000000000000390969~
- Label Barcode: **00000000000003909690**

The checksum (Last digit) is not transmitted through EDI. This is added by the barcode generator on your end.

The barcode (License plate) number is unique and crucial to be successfully scanned into our system. The last number (**0** in this instance) is a checksum that will be added automatically by your barcode printer. These are transmitted in the LIN03 that we send over via EDI and are unique for each individual piece.  Note:  The same barcode (License plate) will never be used more than once.  If quantity 20 of item “A” are being shipped, each piece will be labeled with a unique barcode.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%202.png)

### **Special Order Label Mapping**

| Label Field | EDI Data Field |
| --- | --- |
| Tory Burch SKU:  69696 | PO1*1*1*EA*000016.63**SK*69696*VC*D3698-13R*BL*UMAE~ |
| Order Info: | N1*RQ*SO-0367202~
N2*CUSTOMER NAME~
N3*123 FAKE ST~
N4-LA MIRADA*CA*90638~ |
| Product Info: | Second and Third PID segments
PID*F****fabric/finish: Bella Chocolate~
PID*F****Bella Coffee (Large Toss Pillow) x2, Tate Sable (Large Toss Pillow) x2~ |
| P/O: PO-075770 | BEG*00*OS* PO-0002001337**20170611~ |
| Description: GLAMOUR RAF TUX SOFA | First PID segment
PID*F****GLAMOUR RAF TUX SOFA~ |
| Barcode: 0000000000000676 | LIN**BN*0000000000000390969~ (Note: The label must contain the provided 19 characters plus the check sum for the full 20-digit barcode number) |
| Supplier: UMAE | PO1*1*1*EA*000016.63**SK*69696*VC*D3698-13R*BL*UMAE~ |
| Model Number: D3698-13R | PO1*1*1*EA*000016.63**SK*69696*VC*D3698-13R*BL*UMAE~ |
| Print Date (the date under SEAL): 05/02/2017 | Dynamic Date when the label is printed |

**See Special Order Example Label Below:**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%203.png)

## **Check Digit Barcode Algorithim**

### **Example 1**

BN sent in the 850: “0000000000000676384”

1. Add the odd number digits: 0+0+0+0+0+0+0+7+3+4 = 14.
2. Multiply the result by 3: 14 × 3 = **42**.
3. Add the even number digits: 0+0+0+0+0+0+6+6+8 = **20**.
4. Add the two results together: 42 + 20 = **62**.
5. To calculate the check digit:
    1. Divide the sum, from step 4, by 10 (62 / 10)
    2. Take the remainder of (62 / 10), which is also known as (62 modulo 10)
        1. **If remainder is not 0**, subtract the remainder from 10. Therefore (62 / 10) = 6 remainder 2; 10 - 2 = **8**.
        2. If remainder 0 then Check Digit is Zero
6. Check Digit = “**8**”
7. Barcode = **00000000000006763848**

### **Example 2**

BN sent in the 850: “0000000000000390969”

1. Add the odd number digits: 0+0+0+0+0+0+0+9+9+9 = 27.
2. Multiply the result by 3: 27 × 3 = **81**.
3. Add the even number digits: 0+0+0+0+0+0+3+0+6 = **9**.
4. Add the two results together: 81 + 9 = **90**.
5. To calculate the check digit,
    1. Divide the sum, from step 4, by 10 (90 / 10)
    2. Take the remainder of (90 / 10), which is also known as (90 modulo 10)
        1. If remainder is not 0, subtract the remainder from 10.
        2. **If remainder is 0**, then Check Digit is Zero. Therefore (90 / 10) = 9 remainder **0**.
6. Check Digit = “**0**”
7. Barcode = **00000000000003909690**

## **Tory Burch Label Printer Specifications**

**Zebra Printer model 105SL**

**Sku**: 10500-2001-1070

**Manufacturer**: Zebra

**Description**: 105SL 203DPI

**Cost**:  $2,200

**Zebra Thermal Transfer Paper Label Roll**

**Description**:  4 inches continuous, 500’ Thermal transfer paper label roll

**Cost**:  $30 per roll

## **Web Portal Labels**

The Tory Burch link below can be used as an alternate option to print out the barcode labels for both stock orders and special orders. Both are formatted to size and displayed in a PDF format.

[http://suppliers.livingspaces.com/](http://vendors.livingspaces.com/)

Click on the “Vendors.Livingspaces.com Support**”** button for additional information on how to print the labels, how to setup the Zebra printer, and see contact information for any technical questions.

Once the Zebra printer has been set up, it is easy to print out the barcode labels.  Click on the **“**Standard Order Labels**”** link for stock orders and then enter in the PO # and your four-letter Supplier Code. (Typically, the first four letters of your supplier name).

The labels will pop up in a PDF format, click the print button and select the Zebra printer.  Labels will print in perfect formatting for each piece on the order.

The “Special Order Labels” link can be used to print out the 8” x 11” customer label.

Enter in the PO # and your four-letter Supplier Code. (Typically, the first four letters of your supplier name).

Note:  The suppliers.livingspaces.com will not generate the special order 4x6 label shown on page 4 of this document. The 4x6 special order label has been approved for suppliers printing these from zebra printers.

## **Label Placement (Case & Upholstery)**

Having a consistent standardized labeling process allows Tory Burch to standardize our receiving process and create efficiencies within our supply chain. For this reason, it is imperative that all our suppliers follow our label placement guide below. All cartons received with labels that do not meet our requirements or that are not placed correctly will be subject to chargebacks as listed on the chargeback schedule.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%204.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%205.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Labeling%20Requirements%2042dd7369676e4cd992fcaafe8944b9ce/Untitled%206.png)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 110,
        name: 'LTL Requirements',
        content: `# LTL Requirements

Table of contents:

## Overview

LTL is defined as supplier holds inventory and requests pick up with Tory Burch transportation. Tory Burch will induct LTL orders through our supply chain and deliver out to customers.

## **EDI Requirements**

- **EDI 850-Purchase Order:**
    - All LTL purchase orders will be transmitted to the supplier via an EDI 850 transmission. When an LTL PO is transmitted it will look like a regular domestic PO (however, it will have 1 or 2 pieces and sales order is populated in comments).
- **EDI 855- Acknowledgement Requirements:**
    - An EDI 855 is required from the supplier within 24hrs of receiving the EDI 850.
    - IF a purchase order cannot be fulfilled it is the suppliers’ responsibility to notify Tory Burch within 24hrs of receiving the EDI 850 via the 855.
- **EDI 856-Advanced Shipping Notice Requirements:**
    - LTL partners must submit a single 856 shipment notice per purchase order.
    - Appointment number must be provided on ASN in trailer number field (this will be provided by the TMS (e2Open) when routing for pick up.
- **EDI 810 – Supplier Purchase Order Invoice Requirements:**
    - Suppliers must abide by our electronic invoice policy by providing on time and accurate invoices. All EDI 810 invoices must be sent to Tory Burch within 24 hours of the shipment leaving your facility. Each 810 Invoice must contain a unique invoice number; multiple invoices per 810 will be rejected.
- **EDI 846 – Inventory Inquiry / Advice Requirements:**
    - Trading Partners must provide a list of their items and the available supplier stock per item with the accurate supplier inventory daily. Tory Burch will leverage this information for replenishment planning and inventory availability.
    - Suppliers must submit at least once daily EDI 846 with current inventory. Tory Burch does have the capability to accept 846 feeds to be transmitted multiple times per day.

## Shipping Requirements

- The PO Ship Date is the date that LTL orders need to be shipped on. LTL shipments must ship no later than 8 days after receiving the PO.
- All LTL shipments should be scheduled for pick up in e2Open (blujay). Tory Burch will not be able to pick up if shipments are not systemically scheduled.
- For e2Open support or transportation questions, please reach out to Andy Tinoco ([tinoco@livingspaces.com](mailto:andy.tinoco@livingspaces.com)) and Miguel Cazun ([miguel.cazun@livingspaces.com](mailto:miguel.cazun@livingspaces.com)).

## Labeling Requirements

- The LTL supplier is required to label the order with a specific label which is simply a white 8x11 piece of paper with the end destination DC name and number (the end destination DC can be found on the PO).

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/LTL%20Requirements%20c867ec9f9b75476db26442d16a1d7092/Untitled.png)

- LTL suppliers are required to label the product with standard PO labels (see below). These labels can be found via the supplier portal. You will enter your PO # (in full) and your supplier alias code (this code can be provided by Supplier Compliance).

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/LTL%20Requirements%20c867ec9f9b75476db26442d16a1d7092/Untitled%201.png)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 111,
        name: 'Merchandising Requirements',
        content: `# Merchandising Requirements

Table of contents:

## Overview

One of the goals for this Supplier Operations Guide is to complement, but not replace the communication that exists between our suppliers and our merchandising teams. Another goal is to foster the relationship with our suppliers and provide them with the requirements that are expected from our ongoing relationship.

## **Design Protection, and Confidentiality**

To deliver on our commitments listed above, it is important that our suppliers continue to offer Tory Burch’ merchants original designs and product ideas that are available for our use without conflict with other parties’ rights. Designs and other intellectual property that we share must be preserved as ours and ours only. All Suppliers will be required to review and agree to the terms of the attached Confidentiality Agreement.

## **Production Changes**

Once a Tory Burch merchant has reviewed and approved a production sample, we ask that no changes be made to the product or factory location without approval from the Tory Burch merchant. We ask that no changes to packaging are approved through Tory Burch Supplier Compliance. In the event where there are changes to the materials being used or the manufacturing process changes, you may be required to provide Tory Burch with a new sample for approval.

## **Price Changes**

Pricing changes require mutually agreed upon advance notice to Tory Burch. Tory Burch requires a 30-day notice on all price changes (increases and decreases), day one is counted on the day we receive the formal e-mail informing Tory Burch of the price change, we require the supplier to honor the current price until the 30th day of the notice. This is important to ensure there is proper time to update items, purchase orders, hang tags, etc.

## **Hangtags**

For Tory Burch to deliver a consistent message that aligns with our marketing efforts, it is essential that all suppliers follow our requirements on product bulletins and hang tags. Tory Burch requires that our merchandise is free of any supplier branded hang tags, medallions, and warranty information. All hangtags and product information included on the product must be approved by the merchant and branded for Tory Burch when appropriate. All product not shipped in compliance to these requirements may incur chargebacks for all labor incurred to remove/change all hangtags and labels.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 112,
        name: 'New Store Opening Agreement',
        content: `# New Store Opening Agreement

## Overview

- Tory Burch will write PO’s that will be required to ship **AND** other PO’s that will be credit only POs on inventory we already have in inventory in our DC’s.
- Accessory suppliers are responsible for paying the freight cost to the end destination on the PO.
- Furniture suppliers should follow the shipping process for all other orders with Tory Burch.
    - For example, if the supplier ships regular stock orders directly, then LS requires to ship direct or if the supplier requests pick up, then supplier would follow that process as well for new store PO’s).
- Supplier is required to email [logistics@livingspaces.com](mailto:logistics@livingspaces.com) to make a delivery appointment for the new store furniture PO’s. We must have the visibility to the movement of product & what is delivering. **Appointment number must be submitted in the trailer number filed on the 856.**
    - If you are not familiar with submitting the appointment number in the ASN, please reach out to your IT/EDI company to ensure these requirements are fulfilled.
- EDI 855 Acknowledgement MUST be submitted within 48 hours after 850 Purchase Order has been transmitted (excluding weekends and holidays). Backorders must be transmitted systemically so we can find replacements if they will not be available to ship on time. If backorders are not communicated, we will run into issues on the back end with setting up the new store. Please ensure this crucial step is completed and accurate to avoid any potential EDI chargebacks as outlined in this guide.
- **Please note we will place credit only POs for the store credits, PO comments will note DO NOT SHIP. Please DO NOT ship and be sure to read all PO comments. We will refuse delivery/RTV (at supplier’s expense) any po’s that are shipped in error.**

Please reach out to your corresponding MRP team member for any new store opening order questions.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 113,
        name: 'Packaging Requirements',
        content: `# Packaging Requirements

Table of contents:

## Overview

Product must be packed to protect the product from damage throughout the entire supply chain from manufacturing to the customer’s home. At Tory Burch product can be handled up to twenty plus times and product needs to arrive without damages.

***Suppliers will incur a chargeback fee for non-compliance with these standards.***

Upho

## **Upholstery**

This applies to all sofas, sectionals, ottomans, (all upholstery).

Please follow the guidelines below when packaging upholstery products. **These products will be stored in Tory Burch distribution centers on their ends.**  All packaging materials must protect and not result in damages.

Tory Burch requires upholstery to be packaged following the below requirements:

### **Upholstery Carton Specifications**

Cartons must adhere to the requirements listed in this document, including carton strength, void space limits, and flute direction. The master carton must provide sufficient strength to protect and contain the product. The carton should be sized correctly, allowing no movement between the product and the packaging components. Excess space leads to damage and potential increase in transportation costs. To avoid this, minimize excess space to not exceed ¼” within the packaging (this can help the unpacking process).

Tory Burch prefers all master cartons to have a Box Manufacturer Certification (BMC) stamp providing:

- Name and location of the entity certifying the information
- Minimum strength material specification being certified (ECT or burst strength)
- Gross weight and size limits
- Must be located on the outside surface
- Must be circular (3 inch in diameter required)
- Rectangular BMC’s must be 3.5” x 2”

**BMC Stamp Example**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled.png)

### **Carton Strength**

The carton strength must be appropriate for the weight and the stacking strength required to support the full weight of the cartons. The following box strength requirements are based on packaging industry standards.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%201.png)

### **Flute Direction**

Flutes keep the linerboard sheets of corrugated board separated and provide the rigidity against bending. When pressure is applied to the side of the board, the space between the flutes acts as a cushion to protect the container’s contents. Flutes also provide some protection against sudden temperature changes by acting as an insulator. To prevent damages due to compression, containers oriented in their shipping direction should have flutes in the vertical direction. To prevent damages that may occur due to compression, when the carton is positioned in the shipping direction the flute must be in a vertical direction.

### **Storage and Handling Labels**

Tory Burch requires symbols, verbiage, and concrete lines to be printed in black or red ink. Handling symbols are required on two minor vertical carton sides. Symbols should be centered. Symbols should be proportionate to carton size, ranging from 2” x 3” or 4” x 6” for each symbol.

- ***Up Arrow Symbol*** – this arrow indicates the orientation in which the product should be shipped and stored. This arrow can be printed or labeled onto the master carton.
- ***Fragile Symbol*** – required for all furniture and glass, ceramic, mirrors, and lighting products.
- ***Stack Height Symbol*** – typically is congruent with the fragile symbol. It is the supplier’s responsibility to calculate appropriate stacking quantities.
- ***Recycle Symbol*** – required for all recyclable packaging materials
- ***2 Person Lift Symbol*** – required on all master cartons weighing 60lbs or more

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%202.png)

****

### **FULLY Encapsulated Upholstery in Polyethylene Film (Fabric/Stationary Upholstery)**

***This applies to all fabric/stationary upholstery.***

Polybags are required to be a minimum of 4MIL outer poly bag. Thicker MIL polybags are approved.

Corrugate tray fully encapsulating the bottom and sides of the upholstery are required.

Upholstery needs to be fully covered to protect products from tears, snags, dust/dirt, moisture, etc.

All upholstered and leather furniture should be wrapped with a non-woven, non-abrasive material. Minimum thickness of 2oz/yd2 (70g/m2).

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%203.png)

### **MOSTLY Wrapped Upholstery in Polyethylene Film (Fabric/Stationary Upholstery)**

***This applies to all fabric/stationary upholstery.***

- Outer polybag is required to be a minimum of 4MIL. Thicker MIL polybags are approved.
- Foam sheeting with minimum thickness of 2oz/yd2 (70g/m2) OR inner polybag (minimum of 1MIL) is required to cover the fabric. This is required to go in between the fabric and the cardboard boot.
- Corrugate boot protection is required on each end of the upholstery piece. Please ensure the flute (is against the plastic and not directly on the fabric or material of the upholstery (to prevent fabric indents)).
- Corrugate dust cover protection is required to be applied to the bottom of the product. The dust cover is required to extend the entire length and width of the dust cover.
- All contents (pillows, cushions, etc) need to be secured to assure no movement within the packaging.
- Void fillers need to be added to the bottom of the end of upholstery pieces to stabilize the piece when stored on its end.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%204.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%205.png)

### **Detached Legs**

Legs should be fully wrapped with a non-woven material (PP, PET) or PE foam sheeting. Minimum thickness of 2oz/yd2 (70g/m2). All upholstery must include leg placement placard on the outer polybag that identifies where the legs are located.

**See image example below**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%206.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%207.png)

### **Attached Legs**

Legs should be fully wrapped in foam sheeting to protect from abrasion (minimum thickness of 0.0625” (1.5mm)). The attached legs should not bear the weight of the load during transit, distribution, and storage.

Legs must be suspended and protected from direct contact with the bottom of the corrugate. To avoid potential damage, we require suspending the legs a minimum of 1” from the bottom of the corrugate (see example below):

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%208.png)

## **Case Goods**

This applies (but not limited to) cabinets, dressers, storage, media, bookcases, desks, nightstands, assembled tables, dining tables, desks, occasional tables, accent tables, beds etc.

Case Goods need to be packed to protect the product from damage throughout the entire supply chain from the manufacturing facility to the customer’s home. At Tory Burch product can be handled up to fifteen times and product needs to arrive without damages.

### **General Packaging Standards**

Tory Burch takes steps to protect product through improved packaging materials and designs, with the expectation that our suppliers comply with these standards. ***Suppliers will incur a chargeback fee for non-compliance.***

- All products should be in-house drop tested. All products must pass any applicable transit tests with an approved, certified lab upon receipt of PO.
- All packaging materials must protect and not result in an increase in damages.

**Unacceptable Packaging Materials**

- Polyurethane Cushioning (Foam-in-Place)
- Metal Banding
- Staples (used as a carton closure method) – Staples are unacceptable as they can cause injury to our customers, employees, or drivers.
- Loose fill (i.e., packing peanuts, cornstarch, etc.)

### **Carton Specifications**

Cartons being used may have both a master and inner carton (if applicable). The inner carton is the carton immediately surrounding the product. The master carton is the larger carton containing inner cartons. Both the inner and master cartons must adhere to the requirements listed in this document, including carton strength, void space limits, and flute direction. Inner and master cartons will follow the below guidelines.

The master carton must provide sufficient strength to protect and contain the product. The carton should be sized correctly, allowing no movement between the product and the packaging components. Excess space leads to damage and potential increase in transportation costs. To avoid this, minimize excess space to not exceed ¼” within the packaging (this can help the unpacking process).

Tory Burch prefers all master cartons to have a Box Manufacturer Certification (BMC) stamp providing:

- Name and location of the entity certifying the information
- Minimum strength material specification being certified (ECT or burst strength)
- Gross weight and size limits
- Must be located on the outside surface
- Must be circular (3 inch in diameter required)
- Rectangular BMC’s must be 3.5” x 2”

**See BMC example below**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%209.png)

### **Carton Strength**

The carton strength must be appropriate for the weight and the stacking strength required to support the full weight of the cartons. The following box strength requirements are based on packaging industry standards.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2010.png)

### **Flute Direction**

Flutes keep the linerboard sheets of corrugated board separated and provide the rigidity against bending. When pressure is applied to the side of the board, the space between the flutes acts as a cushion to protect the container’s contents. Flutes also provide some protection against sudden temperature changes by acting as an insulator. To prevent damages due to compression, containers oriented in their shipping direction should have flutes in the vertical direction. To prevent damages that may occur due to compression, when the carton is positioned in the shipping direction the flute must be in a vertical direction.

### **Storage and Handling Labels**

Tory Burch requires symbols, verbiage, and concrete lines to be printed in black or red ink. Handling symbols are required on two minor vertical carton sides. Symbols should be centered. Symbols should be proportionate to carton size, ranging from 2” x 3” or 4” x 6” for each symbol.

- ***Up Arrow Symbol*** – this arrow indicates the orientation in which the product should be shipped and stored. This arrow can be printed or labeled onto the master carton.
- ***Fragile Symbol*** – required for all furniture and glass, ceramic, mirrors, and lighting products.
- ***Stack Height Symbol*** – typically is congruent with the fragile symbol. It is the supplier’s responsibility to calculate appropriate stacking quantities.
- ***Recycle Symbol*** – required for all recyclable packaging materials
- ***2 Person Lift Symbol*** – required on all master cartons weighing 60lbs or more

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2011.png)

All packaging needs to have carton concrete lines on the outside of the packaging (detailing where packaging is supporting the product) which provides our internal teams on how to properly handle the product throughout our network. All containers/trailers MUST be loaded according to the carton concrete lines (please see the load quality section in this guide for more detail).

### **Internal Packaging Standards**

Internal packaging options listed below are to maintain product quality and meet Tory Burch packaging goals:

- **Corrugate** – Corrugate is the most common protective packaging component. Corrugate can be produced in variety of styles such as multi-layered, flat, die cut, angled channel, u-channel, and pyramid. Used for load compression protection in cartons, internal blocking and protection for inner components and void fills, and impact protection from drops.
- **Honeycomb** - Honeycomb is a paper based protective material offering a strong, lightweight alternative to standard corrugated and foam. Like corrugate, it is composed of two faces and a middle core. Honeycomb can be used for void fillers, blocking and bracing, and die-cut to nest in product.
- **Foam Cushioning** - These types of foams include many used in packaging, but not limited to, Polyethylene (PE), recycled Polyethylene (rPET), Polypropylene (PP), Polystyrene (EPS). Types of foam can be seen below; sheets, pads, molded edge, and corner cushions, are used for product and impact protection. These types of foams are less sustainable but are sometimes recommended on highly fragile products.

### **Silica Gel Desiccants**

**Silica Gel Desiccants must be used in all master cartons to prevent damage to the product and packaging.** Silica Gel packs help prevent any possible rusting on metals, as well as mold and mildew on textiles, wood and paper products, and packaging. This is of particular concern if products are produced, packaged, or loaded into containers during high humidity seasons.

The amount of silica gel required in a package can differ depending on several factors, including – but not limited to – product characteristics, container volume, and environmental conditions. Utilize a usage guide from your silica gel supplier. **For a general reference, 5 grams of silica gel is required for 1 cubic foot.**

### **Internal Protection**

Surface Protection: All case goods product surfaces (front, top, sides, etc) need to be fully covered to protect product from dents, scratches, dust/dirt, moisture, etc. Case Goods need to be wrapped with a non-woven material (PP, PE) or PE foam sheeting. Minimum thickness of 2oz/yd2 (70g/m2).

Corner Protection: All corners of the product must be protected using approved cushioning materials. Cushioning must be sufficient for the size and weight of the product. Please follow the general guidelines for cushion thickness on the below chart.

Edge Protection: It is recommended that 50% of any given length of the edges be covered with protective material. Edge protection is required on any edge greater than 12”. Please refer to the cushion thickness chart below for thickness requirements.

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2012.png)

Attached Legs: Legs should be fully wrapped in foam sheeting to protect from abrasion (minimum thickness of 0.0625” (1.5mm)). The attached legs should not bear the weight of the load during transit, distribution, and storage. Legs must be suspended and protected from direct contact with the bottom of the corrugate. To avoid potential damage, Tory Burch requires suspending the legs a minimum of 1” from the bottom of the corrugate.

All case goods require a wood pallet protection on the bottom of the carton.

Doors, Drawers, & Glass: Glass, doors & drawer surfaces must be protected with foam sheeting to protect from abrasion (minimum thickness of 0.0625” (1.5mm)). Drawers need additional foam sheeting between drawer and base of product in case drawers (see example below).

Shelves: all shelves need to be protected with foam sheeting to protect from abrasion, with a minimum thickness of 0.0625” (1.5mm). All loose items must be secured in place within the carton and should not be allowed to move during the transit and handling process.

Hardware: all knobs/pulls need surface abrasion protection. Please refer to the hardware section in this guide on how to properly pack and label hardware components.

### **Product/Carton Fit**

Products within a carton should be secured and not able to move. The maximum amount of void space in any direction should not exceed 1/4”. Void space is the space between the edge of the product and the interior wall of the carton. Please ensure product is snug against the carton (without extra space for product to move during transit and handling).

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2013.png)

**Failure to eliminate void space in cartons will result in chargebacks being issued.** Void space can cause stacking instability and lead to damages within our distribution centers and in-transit to our customers.

### **Hardware/Part Standards**

Hardware packaging is required to be clearly labeled and easy to identify, as it could be disposed of or missed by the customer, driver or internal teams when unpacking the carton.

By utilizing the packaging method below, the possibility of missing hardware is greatly reduced:

- Hardware is **preferred** to be packaged in a blister pack (as seen in the below example). Hardware must be labeled with corresponding part number/letter which ties back to the assembly instructions

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2014.png)

- If blister packs are not a viable option, hardware must be packaged in a red bag and attached to a red ribbon/streamer. Tory Burch requires one of these two options (no other options are available for packaging hardware).
- Hardware is required to be secured inside the packaging that will not damage the physical merchandise while in-transit but is easy to identify when the carton is opened.
- The red ribbon/streamer should be secured to the top or side of the carton (not in a corner). The red ribbon/streamer should be labeled “Hardware Enclosed” in English & Spanish. The ribbon/streamer must be secured down with tape (as seen in the below example).

**Example of Approved Hardware Packaging**

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2015.png)

## Rugs/Floor Coverings

This applies to rugs, floor coverings, door mats, etc.

All rugs must be rolled tightly ensuring that the ends are aligned, and the rug is not “telescoping”.

- Rolled rugs must be secured with stretch film or ties at each end.
- Rugs that cannot be rolled must be bagged and boxed.

All rugs must be poly-bagged with a minimum of 5 MIL thickness. Larger rugs (i.e., greater than 5’ x 8’) must have a higher MIL thickness of 8 MIL to not allow rips/tears during transit. Rugs 8’ x 10’ or greater must be double bagged or have a minimum MIL thickness of 8 MIL or greater.

Rugs must be packed snug in the polybag. Any excess material must be folded over and taped down securely.

The ends must be heat sealed or secured shut and taped down securely. The width of the polybag tubing must be sized to fit tightly around the rolled rug.

Please roll the rug in a compact and tight manner, this enables Tory Burch to reduce shipping charges.

When rolling a rug, please roll so the smallest dimension is the length (for example, for rolling 5’ x 8’ rug, roll the rug so the length is 5’.

Each rug must be individually folded and put in a carton or rolled. Do not roll multiple quantities of rugs into one package.

Rugs must be palletized by PO # and ship to location (see example below).

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2016.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Packaging%20Requirements%200622c4df87ee451d8ffbfdae3ffbd954/Untitled%2017.png)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 114,
        name: 'Purchase Order Management (EDI)',
        content: `# Purchase Order Management (EDI)

Table of Contents:

## Overview

EDI is the electronic interchange of information using standardized formats to send and receive the various required business documents. EDI is a paperless process which allows multiple trading suppliers to seamlessly send and receive information without the need for manual data entry.

Tory Burch requires all suppliers to be EDI compliant, meeting the Tory Burch mapping requirements. It is the supplier’s responsibility to ensure they can send and receive the required EDI documents. If a Supplier is unable to send/receive the EDI documents, we can recommend TrueCommerce (Tory Burch EDI Provider) EDI portal account. The supplier will be responsible for any cost associated with using the TrueCommerce EDI portal. This account will allow the trading supplier to view the supplier specific Tory Burch orders, acknowledge, provide shipping details, and send order invoices. It is still the supplier’s responsibility to become EDI compliant as our EDI process is subject to change and grow at any time.

Suppliers will be scored on the turnaround time and accuracy of all their EDI documents. Inaccurate and late data will impact our ability to schedule, plan and manage inventory. **Repeated infractions will lead to chargebacks and other penalties.**

## Tory Burch EDI Document Requirements

### **EDI 850 – Purchase Order**

Tory Burch submits a single unique 850 per purchase order; we will not combine multiple purchase orders within a single 850 document. Tory Burch will regularly send purchase orders throughout the day. Dropship orders are transmitted in batch jobs at the end of every night to our dropship suppliers.

All dropship 850 purchase orders are transmitted with SCAC (carrier) codes at the line level and expected to ship via that SCAC (carrier) code.

### **EDI 855 – Purchase Order Acknowledgement**

*Suppliers must submit the Purchase Order Acknowledgement EDI 855 document within 24 hours of receiving the 850-purchase order submission.*

A unique 855 document is required per Purchase order. Do not send multiple purchase orders within a single 855 Purchase Order Acknowledgement. Suppliers are responsible for sending accurate acknowledgement order information. If a quantity cannot be fulfilled in full, the Supplier must provide the current available quantity which can be fulfilled; along with the backorder date (the future date for which Supplier inventory will be available to Tory Burch).

Tory Burch will adjust their purchase orders and replenishment planning based on the provided 855 acknowledgment information. Inconsistencies and inaccuracies will lead to serious issues with receiving, planning and customer fulfillment.

Out of stock items, those that cannot be immediately fulfilled, may be subject to cancelation/removal from the PO at Tory Burch discretion. The supplier is to cancel any out-of-stock items from the purchase order and Living spaces will reissue a purchase order once that item becomes available based on the back-order date provided.  An EDI 860 change request will be submitted for import orders.

- **Special Orders** - If a special order cannot be delivered by the PO ship date, suppliers must send an updated EDI 855 (PO Acknowledgement) with the updated ship date as the backorder date. For example, if a PO is to ship on 3/30/2023 per PO ship date and you know the PO will not ship on time, we ask that you send a second updated EDI 855 with the updated ship date at least 48hrs before the required ship date. We will use this updated ship date to update our purchase orders automatically and update our customers of any delays. ***Please ensure dates are accurate to avoid miscommunication to our customers and internal teams.***
- **Drop ship 855 requirements** An EDI 855 is required from the supplier within 24hrs of receiving the EDI 850. If a purchase order cannot be fulfilled it is the suppliers’ responsibility to notify Tory Burch within 24hrs of receiving the EDI 850.

If the item is discontinued, please ensure that the date 12/31/2049 is transmitted as the backorder date.

### **EDI 860 – Purchase Order Change Request (import/container orders only)**

Tory Burch will submit an EDI 860 PO change request, for one or more of the following reasons: full order cancelation, line cancelation, quantity adjustments (positive or negative), price changes, date changes (ETA and/or ETD), and/or the addition of new product lines. Currently we only process the EDI 860 change requests for our import container orders, not domestic orders. A single import order could undergo multiple changes during the fulfillment lifecycle; therefore, multiple 860s may be submitted.

**Important note:**  EDI 860 document requirements and mapping are managed and provided by our EDI VAN. Currently we’re passing the “860” as an EDI 850 with a Transaction Set Purpose Code of 01 (full order cancel) or 04 (PO change).

### **EDI 856 – Advanced Shipping Notification (ASN)**

- **All Non-Dropship 856 Requirements:**
Suppliers must submit the ASN same day as inventory ships from their warehouse. This document must contain the shipped quantity, shipment date, shipment number, BOL number, trailer number, appointment number, etc.

A unique 856 Advanced Ship Notice must be submitted per trailer or container. The 856 documents may contain multiple purchase orders, as a trailer may include multiple purchase orders; but a single 856 may not contain multiple trailers. This EDI document is required for all PO types. Additionally, the EDI 856 must arrive be transmitted and received by Tory Burch prior to the shipment arriving at its destination. Lastly the 856 must reflect the quantity in the shipment and not the original ordered quantity.

Tory Burch requires the appointment number to be transmitted in the trailer number field on the 856 ASN.

*IF freight is being billed to Tory Burch, please ensure to reach out [supplier.compliance@livingspaces.com](mailto:supplier.compliance@livingspaces.com) prior to the product shipping and being received so that the team has the appropriate amount of time of adding the freight to the PO in time.*
- **Dropship 856 Requirements:**
Dropship suppliers must submit a single 856 shipment notice per shipment (IF the purchase order contains multiple lines and each line has a separate tracking number then we need a unique 856/ASN). Dropship 856 documents have additional mapping requirements, and it is imperative the tracking information is included (e.g., Carrier and Tracking number).

### **EDI 810 – Purchase Order Invoice**

Suppliers must abide by our electronic invoice policy by providing on time and accurate invoices via EDI. All EDI 810 invoices must be sent to Tory Burch within 24 hours of the shipment leaving your facility. Each 810 Invoice must contain a unique invoice number; multiple invoices per 810 will be rejected.

Please note – any surcharges, handling fee’s, freight charges etc. must be submitted as a charge on the invoice. Do not include charges as a line item on the invoice it will cause the invoice to fail and be rejected systemically. Please work with [EDI@livingspaces.com](mailto:EDI@livingspaces.com) to determine how to include as a charge.

### **EDI 846 – Inventory Inquiry / Advice**

Suppliers must provide a list of their items and the available supplier stock per item daily. Tory Burch can accept multiple 846 transmissions throughout the day. All suppliers are required to transmit the inventory feed minimum of once per day. Inventory from multiple different warehouses can be compiled into one feed but must be able to ship within 2 days from either facility (for dropship orders).

Tory Burch will leverage this information for replenishment planning and dropship (ship direct to guest) and LTL (ship to LS DC) inventory availability.

### **EDI 997 – Functional Acknowledgement**

The EDI 997 serves as an acknowledgement of receipt for a specific or group of EDI transactions. It does not serve as a contract or validation of any of the contents within the EDI transaction, that the EDI document was received.

## EDI Testing

New suppliers must undergo mandatory EDI document testing for all file types. Supplier transactions will not commence until all test scenarios are proven successful.

Please contact, [EDI@livingspaces.com](mailto:EDI@livingspaces.com) for all questions, concerns, and Tory Burch EDI mapping documents.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 115,
        name: 'Purchase Order Requirements',
        content: `# Purchase Order Requirements

Table of contents:

## **Domestic Stock Purchase Orders**

A domestic stock purchase order is considered on time if the purchase order is shipped by the ship date that is stated on the purchase order. These delivery dates are entered by the merchandising operations agent prior to the purchase order transmission via EDI to the supplier. The ship date is based on the mutually agreed upon lead time by the supplier and Tory Burch. Domestic purchase orders must be requested to be picked up within 48 hours of the requested pick-up. Failure to meet the agreed upon service levels may be subject to additional chargebacks as per the chargeback schedule included in the operations guide.

## **Container Purchase Orders**

A container purchase order is considered on time if the purchase order is shipped from the factory by the PO ship date that is specified on the purchase order. The ship date is based on the mutually agreed upon lead time at the time of supplier set up. Failure to meet the agreed upon service levels may be subject to additional chargebacks as per the chargeback schedule included in the operations guide.

## **Special Order Purchase Orders**

A special order is assigned to a specific customer sales transaction. Tory Burch will mutually agree upon specific special order lead times. Tory Burch requires special order suppliers to adhere and ship according to the mutually agreed lead times. **The supplier is required to ship by the PO ship date on the 850-purchase order that is derived from the mutually agreed upon lead times.** Tory Burch target special order lead time is to ship within 10 calendar days.

The special-order supplier is required to proactively monitor the mutually agreed upon lead times and make Tory Burch aware of any changes or closures that may impact shipping to our customers.

Tory Burch will measure special order on-time delivery by measuring the success of shipping (the ASN sent date) to the PO ship date. The ASN should be transmitted upon order being ready to ship.

IF half loads present themselves, **please do not hold the PO’s until a full trailer is ready**, please reach out to [transportation@livingspaces.com](mailto:transportation@livingspaces.com) and provide the PO’s, DC and units ready for pick up. They will arrange a pickup of the half load that is ready. Please be proactive and provide 24-48 hours of notice to the team.

Mislabeled special orders cause Tory Burch to deliver incorrect product into customer’s home. Mislabeled special orders will incur a $100 chargeback per special order PO.

### **Special Order Fabric Requirements**

As part of the Special-Order Upholstery, fabric visibility is imperative metric to run the business effectively. As a partner you are required to provide timely monthly updates around fabric inventory position and monthly usage.

At times you may be required to provide ad hoc inventory position within the month. As an internal business process please ensure your team can properly communicate this information in a timely manner.

As part of the weekly communication, suppliers must communicate fabric outages every Tuesdays/Fridays before 8:30am to Gisele Vargas.

Although LSF provides fabric forecast it's the responsibility of the supplier to review and provide feedback as needed to mitigate any issues with fabric inventory.

## **Backorder Purchase Orders**

A customer back-order purchase order is assigned to a customer sales transaction and must be shipped NO LATER than the back-order date provided by the supplier on the EDI 855 transaction. Please ensure to utilize the weekly performance report to review if current backorder dates are accurate. It is the supplier’s responsibility to ensure the backorder dates are accurate.

If the backorder date of 12/31/2049 is transmitted to Tory Burch this indicates that the inventory is discontinued and no longer an item that can be ordered.

Failure to meet the agreed upon service levels may be subject to additional chargebacks as per the chargeback schedule included in the operations guide.

## **Dropship & LTL Purchase Orders**

Dropship purchase orders must be shipped to the customer within 48 hours of the purchase orders being received by the supplier.

LTL purchase order must be shipped to the distribution center on the purchase order within 2-7 days of receiving the purchase order from Tory Burch.

Failure to meet the agreed upon service levels may be subject to additional chargebacks as per the chargeback schedule included in the operations guide.

## **New Product Purchase Orders**

All new Product PO’s will be placed with a future agreed upon ship date. New product PO’s must ship complete NO EXCEPTIONS.

## **Sample Purchase Orders**

Sample purchase orders need to be delivered directly to our La Mirada Studio. Please make sure to schedule a delivery time with the respective product manager.

Samples are to be free of charge to Tory Burch, unless otherwise approved by the Product Manager.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 116,
        name: 'Quality Requirements',
        content: `# Quality Requirements

Table of contents:

## Quality Inspections

To ensure our guests continue to receive high quality product Tory Burch conducts inbound inspection audits based off key indicators/triggers to help improve overall quality throughout the organization. Inspections conducted that indicate a quality defect and damaged concealed in original packaging will be subject to more frequent audits and to potential chargebacks.

Based off results from the inspection audits, Supplier Compliance will create a corrective action & preventive plan (CAPA) for suppliers to action.

## Quality Chargebacks

The purpose of chargebacks is not to generate revenue for Tory Burch, but to recover operating expenses that are incurred because of correcting supplier quality errors. This policy allows us to provide feedback to our suppliers, ensures cost recovery and helps supports more efficient practices.

All Tory Burch quality chargebacks will require suppliers to ensure Tory Burch is made whole from an inventory perspective in form of an RA credit, freight costs associated to poor quality pieces, labor costs associated for our DC teams to handle the product.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 117,
        name: 'Return to Supplier',
        content: `# Return to Supplier

Merchandise will be returned to Supplier for the following reasons:

- Merchandise not ordered
- Merchandise found to be substandard, defective, or not in sellable condition

To enable Tory Burch to manage RTV’s consistently across our organization, we have outlined our RTV process below and **must be followed by all suppliers**.

In the event where a Return to Supplier is needed, Tory Burch will request a Return Authorization (RA) from the supplier.

- The supplier will have 5 business days to respond with a Return Authorization (RA)/Credit:
    - The supplier will be responsible for authorizing the return and have the merchandise picked up from our locations by the carrier of your choice at the supplier’s expense.
        - If your carrier does not pick up the merchandise within , Tory Burch will arrange for the merchandise to be returned to your facility or disposed of. We will ship via our carrier of choice and chargeback the supplier the expense.
            
            seven days after the RA is approved/communicated by the supplier
            
    - The supplier will also have the option to authorize the payment of the RTV product but request that Tory Burch dispose of the merchandise in lieu of returning it to the supplier. If this option is selected, **the Supplier will be responsible for all disposal fees incurred**.
    - Under certain circumstances Tory Burch may replace a customer’s product due to a defect that is beyond repair. If this occurs, we will ask for a credit from the supplier and present the technician report and pictures of the damage in lieu of the actual product.
- **If we do not hear back within 7 business days, Tory Burch will assume that our RA request has been approved and will return the product in the RA with a carrier of our choice at the supplier’s expense.**

Tory Burch will NOT consolidate all RTV merchandise to one location. The Supplier is responsible for planning to have all product picked up from all Distributions Centers and will be responsible for all transportation cost.

The Supplier must provide the following information for all Return Authorizations (RAs):

- Supplier/Company Name
- Return to Address
- Return Authorization Number
- Carrier the supplier has selected to pick up and date of pick up

**Tory Burch will also chargeback any DC labor & transportation costs (Customer clearance fees, line haul fees plus fuel surcharge fees and any accessorial fees that we may have paid on vendor shipped goods) incurred to pick, stage, and repair any product not ordered by Tory Burch or product found to be substandard, defective, or not in sellable condition.**`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 118,
        name: 'Shipping & Variance Policies',
        content: `# Shipping & Variance Policies

Table of contents:

## On-Time Shipping Policy

To achieve consistent service and product offering to our customers, it is essential that our suppliers deliver all purchase orders on time and in full. As a company we invest a significant portion of our budget advertising your product to attract new customers and retain life-long guests. When we don’t have the product we advertise, we not only sacrifice revenue, but we also disappoint our number one asset, our customers. For this reason, it is essential that our suppliers understand our shipping policies and can adhere to them.

## PO Quantity Variance

Tory Burch expects all suppliers to ship the exact quantity that is acknowledged on a purchase order.

If the shipment is over the acknowledged quantity, one or more of the following may occur:

- Tory Burch reserves the right to return the excess units at the supplier’s expense.
- Tory Burch may decide to keep the excess units in exchange for a 20% discount from the purchase price of the excess units.

If the shipment is under the acknowledged quantity chargebacks may be incurred if the PO is filled at less than 90% for the acknowledged quantity. See Chargeback schedule for details.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 119,
        name: 'Shipping Requirements',
        content: `# Shipping Requirements

Table of contents:

## **Packing List Requirements**

The packing list is a valuable tool used by our warehouse staff to ensure that your product is received accurately and efficiently. For domestic shipments there must be one packing list per shipment sent to Tory Burch.

## **Packing List Specifications**

- Date
- Ship From Information
- Sold to Information
- Ship to Location
- Tory Burch Item Number
- Tory Burch Item Name
- Tory Burch PO Number
- Total Quantity Shipped per SKU
- Total Cartons Shipped
- Total Units Shipped

## **Packing List Placement**

Please make sure that the packing list is attached to the lead carton of the shipment and that it can be easily identified by the warehouse receiving staff as shown below. Please ensure the packing list is in a plastic pouch/envelope upon arrival. Additionally, the driver delivering the shipment must have an extra hard copy of the packing list that can be handed to the receiving team when the shipment is delivered.

## **Load Quality Standards**

- All shipments whether floor loaded or palletized must be loaded in a manner that will prevent damage to the product that is being transported. The supplier will be responsible for the cost of damaged goods due to improper trailer loading.
- Heaviest product must always be loaded on the bottom layer of the trailer to prevent damage to the product on the bottom.
- The supplier must make every attempt to load same SKU together in trailer.
- The load inside of the container/trailer is required to be secured with a load bar or strap.
- Product is required to be loaded in an organized manner, preventing the load from shifting inside of the container/trailer preventing product from falling over, being scattered and unorganized inside.
- The load inside of the container/trailer is required to be loaded following carton marking instructions (concrete lines and directional arrows). Concrete lines should indicate where internal packaging is and would dictate how product is loaded in the container to avoid potential damages.
- All products should be loaded in a way that does not allow to be crushed while in transit. The carton should hold up with transit. If the carton is not in good condition, Tory Burch will look to RTV due to the ever-growing threshold business.
- Ensure that cartons are not loaded too high and tight making it difficult or pose a safety risk for DC inbound teams to offload the container/trailer.
- Ensure that container/trailer is free of any potential water damage, nails, broken pieces, or loose debris prior to loading product. Ensure the container/trailer is free from holes in top, sides, or bottom floor. *Suppliers are responsible for ensuring the integrity of the container/trailer is in good condition prior to shipping to Tory Burch or an RTV may be utilized if product is damaged.*
- Column stacking should be followed to maximize stacking strength of cartons. Stacking quantities printed on the carton must be followed. Heavier cartons should be loaded on the bottom, with lighter cartons on top.

***See the below example***

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Shipping%20Requirements%20b91b7f0483c9433cb3c2a2e30ba482ad/Untitled.png)

![Untitled](assets/markdown/company-wiki/demo-assets/Living%20Spaces%20Supplier%20Portal/Shipping%20Requirements%20b91b7f0483c9433cb3c2a2e30ba482ad/Untitled%201.png)`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 120,
        name: 'Supplier Invoice Processing',
        content: `# Supplier Invoice Processing

Table of contents:

## Overview

All invoices must be sent electronically. All merchandise invoices must be transmitted via EDI.

The following are instructions for invoice processing. Please include the following information when transmitting an invoice. Only one purchase order per invoice is allowed.

- Ship from information
- Ship to information
- Unique invoice number
- Invoice Date
- Purchase order number
- Purchase terms
- Tory Burch SKU Number
- Item Description
- Quantity ordered
- Quantity shipped
- Ship Date
- Unit Price
- Extended Price
- Additional charges or credits (freight, discounts, etc.)
- Total Price

## **Accounts Payable Inquiries**

Please send any questions or payment inquiries to [apinventory@livingspaces.com](mailto:apinventory@livingspaces.com).  Please reference the Purchase Order number and the invoice number in question.`,
        children: [],
      },
      {
        ...DEFAULT_DOCUMENT,
        id: 121,
        name: 'Supplier Responsibilities',
        content: `# Supplier Responsibilities

## Overview

Suppliers may be asked to participate in a bi-annual business review at the La Mirada home office or virtually. Supplier Compliance will schedule all business reviews on a bi-annual basis.

Suppliers are no longer required to visit stores monthly. Tory Burch will assume the expense of floor sample quality and signage. While we will provide a sign in sheet for those suppliers that wish to visit stores on a regular basis, it is no longer mandated by Tory Burch.

A supplier account representative must be available to visit our Distribution Centers on an as needed basis to review any quality and performance issues. Visits must be scheduled in a timely and reasonable manner at a time that is agreeable to the Merchandising Team and the corresponding Warehouse Quality Team.`,
        children: [],
      },
    ],
  },
];

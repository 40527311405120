import { Injector } from '@angular/core';
import { Node } from '@tiptap/core';
import { AngularNodeViewRenderer } from 'ngx-tiptap';
import { imagePlaceholderSelector } from '../dynamic-values.service';
import { ImagePlaceholderComponent } from './image-placeholder.component';
import type { ImagePlaceholderType } from './placeholders';

export interface ImagePlaceholderOptions {
  type: ImagePlaceholderType;
  width?: number;
  height?: number;
  radius?: number;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imagePlaceholder: {
      insertImagePlaceholder: (options: ImagePlaceholderOptions) => ReturnType;
    };
  }
}

export const ImagePlaceholderExtension = (injector: Injector): Node => {
  return Node.create({
    name: 'imagePlaceholder',
    group: 'inline',
    inline: true,
    atom: true,
    draggable: true,

    addAttributes() {
      return {
        type: {
          isRequired: true,
        },
        width: {
          default: 128,
        },
        height: {
          default: 128,
        },
        radius: {
          default: 0,
        },
      };
    },

    parseHTML() {
      return [{ tag: imagePlaceholderSelector }];
    },

    renderHTML({ HTMLAttributes }) {
      return [imagePlaceholderSelector, HTMLAttributes];
    },

    addNodeView() {
      return AngularNodeViewRenderer(ImagePlaceholderComponent, { injector });
    },

    addCommands() {
      return {
        insertImagePlaceholder:
          (options: ImagePlaceholderOptions) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          },
      };
    },
  });
};
